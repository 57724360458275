import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import contents from './blogContents';
import CTASection from './CTASection';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import ReactMarkdown from 'react-markdown';
import './BlogPost.css'; // Import the custom CSS file

const BlogPost = () => {
  const { postId } = useParams();
  const post = contents[postId];

  if (!post) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-lg font-medium text-gray-600 dark:text-gray-300">Post not found</p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <Helmet>
        <title>{post.title} | Your Blog Name</title>
        <meta name="description" content={post.desc} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.desc} />
        <meta property="og:image" content={post.cover} />
        <meta property="og:url" content={window.location.href} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "description": post.desc,
            "image": post.cover,
            "author": {
              "@type": "Person",
              "name": "Your Name",
            },
            "publisher": {
              "@type": "Organization",
              "name": "Your Blog Name",
              "logo": {
                "@type": "ImageObject",
                "url": "Your Logo URL"
              }
            },
            "datePublished": post.datePublished,
            "dateModified": post.dateModified,
          })}
        </script>
      </Helmet>
      <Header />
      <main className="flex-grow container mx-auto px-6 lg:px-16 py-12 lg:py-24">
        <h1 className="text-3xl lg:text-5xl font-bold text-gray-800 dark:text-white leading-tight mb-8">
          {post.title}
        </h1>
        
        {/* Social Media Sharing Buttons */}
        <div className="flex items-center space-x-4 mb-8">
          <FacebookShareButton url={window.location.href} quote={post.title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.href} title={post.title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton url={window.location.href} title={post.title}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
        <img src={post.cover} alt={`${post.title} cover image`} className="my-8 full-cover-image rounded-lg shadow-lg" />

        <article className="blog-content prose dark:prose-dark max-w-none text-gray-600 dark:text-gray-300 leading-relaxed">
          <p className="post-desc">{post.desc}</p> {/* Updated class name for post.desc */}
          <ReactMarkdown>{post.content}</ReactMarkdown>
        </article>
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default BlogPost;

/**
 * ResultsHeader Component
 *
 * This component is responsible for displaying the header of the arbitrage results feed. It provides functionalities for filtering, sorting, and viewing options of the results. The header includes interactive elements to manage and display the state of the results list, such as search input, filter options, view mode toggle, and bulk actions.
 *
 * Props:
 * - viewMode (string): The current view mode of the results (either 'table' or 'card').
 * - setViewMode (function): Function to change the view mode of the results.
 * - conditionFilter (string): Current filter applied based on item condition (e.g., 'all', 'new', 'used').
 * - setConditionFilter (function): Function to change the condition filter.
 * - searchTermFilter (string): Current search term filter applied.
 * - setSearchTermFilter (function): Function to change the search term filter.
 * - filteredSearchTerms (array): Array of search terms available for filtering.
 * - searchQuery (string): Current search query string.
 * - setSearchQuery (function): Function to change the search query string.
 * - sortOption (string): Current sorting option applied (e.g., 'profit', 'price-high2low').
 * - setSortOption (function): Function to change the sorting option.
 * - action (string): Current bulk action selected.
 * - handleActionChange (function): Function to handle changes in bulk action selection.
 * - handlePassAll (function): Function to handle the 'Pass All' action.
 * - toggleStatusFilter (function): Function to toggle the status filter.
 * - statusFilter (string): Current filter applied based on item status (e.g., 'pending', 'watching').
 * - setFilter (function): Function to set filters in the Redux state.
 * - totalResults (number): Total number of opportunities available.
 * - totalAvailableResults (number): Number of opportunities available after applying filters.
 *
 * State Management:
 * - showFilters (boolean): State to manage the visibility of the advanced filters section.
 * - showInfo (boolean): State to manage the visibility of the informational text.
 *
 * Functionality:
 * - areFiltersApplied: Checks if any filters are currently applied.
 * - resetFilters: Resets all filters to their default values.
 * - Handles search input changes, filter selections, sort option changes, bulk actions, and view mode toggles.
 *
 * Interaction with ArbieResultsAFV2.jsx:
 * - This component is used in ArbieResultsAFV2.jsx to render the header section of the results feed.
 * - The `setViewMode`, `setConditionFilter`, `setSearchTermFilter`, `setSearchQuery`, `setSortOption`, `handleActionChange`, `handlePassAll`, `toggleStatusFilter`, and `setFilter` functions are passed as props from ArbieResultsAFV2.jsx to manage the state and trigger actions in the Redux store.
 *
 * Interaction with autoFeedSlice.js:
 * - The component interacts with the Redux slice `autoFeedSlice.js` by dispatching actions to update the filter and sort options in the Redux state.
 * - When the user interacts with the filters or sort options, the corresponding state updates trigger the `setFilter` action to update the filters in the Redux store.
 * - The `setFilter` action in `autoFeedSlice.js` applies the new filters and sorts the `allItems` array, updating the `opportunities` array with the filtered and sorted items.
 *
 * State Update Process:
 * - The component's local state (`showFilters`, `showInfo`) manages the visibility of certain UI elements.
 * - The Redux state updates are triggered by calling the `setFilter` function, which dispatches the `setFilter` action from `autoFeedSlice.js`.
 * - The `setFilter` action in `autoFeedSlice.js` updates the `filters` object in the Redux state and applies the filters and sorting to the `allItems` array.
 * - The filtered and sorted items are then updated in the `opportunities` array, which is used to render the results in ArbieResultsAFV2.jsx.
 *
 */

import React, { useState } from 'react'
import {
  ViewGridIcon,
  ViewListIcon,
  SearchIcon,
  FilterIcon,
  AdjustmentsIcon,
  XIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid'

const ResultsHeader = ({
  viewMode,
  setViewMode,
  conditionFilter,
  setConditionFilter,
  searchTermFilter,
  setSearchTermFilter,
  filteredSearchTerms,
  searchQuery,
  setSearchQuery,
  sortOption,
  setSortOption,
  action,
  handleActionChange,
  handlePassAll,
  toggleStatusFilter,
  statusFilter,
  setFilter,
  totalResults,
  totalAvailableResults,
}) => {
  const [showFilters, setShowFilters] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  // Check if any filters are applied
  const areFiltersApplied = () => {
    return (
      conditionFilter !== 'all' ||
      searchTermFilter !== '' ||
      searchQuery !== '' ||
      sortOption !== '' ||
      statusFilter !== 'pending'
    )
  }

  // Reset filters to default values
  const resetFilters = () => {
    setConditionFilter('all')
    setSearchTermFilter('')
    setSearchQuery('')
    setSortOption('')
    setFilter({ statusFilter: 'pending' })
  }

  const isDesktop = window.innerWidth >= 768

  return (
    <div className='flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 pt-4'>
      <div className='flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-xl shadow-lg dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border'>
        <div className='flex items-center space-x-4 mb-4 md:mb-0'>
          <ViewGridIcon className='w-10 h-10 text-indigo-600 dark:text-indigo-400' />
          <h1 className='text-3xl font-bold text-gray-900 dark:text-white'>
            Results Feed
          </h1>
        </div>
        <div className='flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4'>
          <div
            className='flex items-center space-x-2 text-gray-900 dark:text-white cursor-pointer'
            onClick={() => setShowInfo(!showInfo)}
          >
            <InformationCircleIcon className='w-6 h-6 text-indigo-600 dark:text-indigo-400' />
            <span className='font-medium'>
              {areFiltersApplied()
                ? `${totalAvailableResults} / ${totalResults}`
                : `${totalResults}`}
            </span>
          </div>
          {showInfo && (
            <div className='text-gray-900 dark:text-white mt-2 md:mt-0 text-center'>
              {areFiltersApplied()
                ? `${totalAvailableResults} results viewable given the current filter out of ${totalResults} total opportunities`
                : `There are a total of ${totalResults} opportunities`}
            </div>
          )}
          <button
            onClick={() => setShowFilters(!showFilters)}
            className='bg-gradient-to-r from-gray-400 to-gray-600 text-white font-medium py-2 px-4 rounded-lg hover:from-gray-500 hover:to-gray-700 flex items-center space-x-2 transition duration-300 ease-in-out border'
          >
            <FilterIcon className='w-5 h-5' />
            <span>{showFilters ? 'Hide Filters' : 'Advanced Filters'}</span>
          </button>
          {isDesktop && (
            <button
              onClick={() =>
                setViewMode(viewMode === 'table' ? 'card' : 'table')
              }
              className='bg-gradient-to-r from-indigo-500 to-indigo-800 text-white font-medium py-2 px-4 rounded-lg hover:from-indigo-600 hover:to-indigo-900 flex items-center space-x-2 transition duration-300 ease-in-out border'
            >
              {viewMode === 'table' ? (
                <>
                  <ViewListIcon className='w-5 h-5' />
                  <span>Show Cards</span>
                </>
              ) : (
                <>
                  <ViewGridIcon className='w-5 h-5' />
                  <span>Show Table</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {!showFilters && (
        <div className='p-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 border'>
          <div className='relative flex items-center flex-1'>
            <SearchIcon className='absolute w-5 h-5 text-gray-700 dark:text-gray-300 ml-3' />
            <input
              type='text'
              id='table-search'
              className='block w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500'
              placeholder='Search results'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className='flex items-center space-x-2'>
            <label
              htmlFor='sortOption'
              className='font-medium text-gray-900 dark:text-white'
            >
              Sort by:
            </label>
            <select
              id='sortOption'
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className='w-full max-w-xs text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-2 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
            >
              <option value=''>None</option>
              <option value='profit'>
                Potential Profit (Highest to Lowest)
              </option>
              <option value='price-high2low'>Price (Highest to Lowest)</option>
              <option value='price-low2high'>Price (Lowest to Highest)</option>
            </select>
          </div>
          {areFiltersApplied() && (
            <button
              onClick={resetFilters}
              className='bg-red-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300 ease-in-out border self-end md:self-center flex items-center space-x-2'
            >
              <XIcon className='w-5 h-5' />
              <span>Reset Filters</span>
            </button>
          )}
        </div>
      )}

      {showFilters && (
        <div className='transition-all duration-500 p-6 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg border'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
            <div className='flex flex-col space-y-4'>
              <div className='flex items-center space-x-2'>
                <FilterIcon className='w-5 h-5 text-gray-700 dark:text-gray-300' />
                <label
                  htmlFor='conditionFilter'
                  className='font-medium text-gray-900 dark:text-white'
                >
                  Condition:
                </label>
                <select
                  id='conditionFilter'
                  value={conditionFilter}
                  onChange={(e) => setConditionFilter(e.target.value)}
                  className='w-full max-w-xs text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-2 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
                >
                  <option value='all'>All</option>
                  <option value='new'>New</option>
                  <option value='used'>Used</option>
                </select>
              </div>
              <div className='flex items-center space-x-2'>
                <AdjustmentsIcon className='w-5 h-5 text-gray-700 dark:text-gray-300' />
                <label
                  htmlFor='searchTermFilter'
                  className='font-medium text-gray-900 dark:text-white'
                >
                  Search Term:
                </label>
                <select
                  id='searchTermFilter'
                  value={searchTermFilter}
                  onChange={(e) => setSearchTermFilter(e.target.value)}
                  className='w-full max-w-xs text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-2 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
                >
                  <option value=''>
                    All ({filteredSearchTerms.length} Terms)
                  </option>
                  {filteredSearchTerms.map((term) => (
                    <option key={term._id} value={term.term}>
                      {term.term}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='flex flex-col space-y-4'>
              <div className='flex items-center space-x-2'>
                <AdjustmentsIcon className='w-5 h-5 text-gray-700 dark:text-gray-300' />
                <label
                  htmlFor='sortOption'
                  className='font-medium text-gray-900 dark:text-white'
                >
                  Sort by:
                </label>
                <select
                  id='sortOption'
                  value={sortOption}
                  onChange={(e) => setSortOption(e.target.value)}
                  className='w-full max-w-xs text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-2 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
                >
                  <option value=''>None</option>
                  <option value='profit'>
                    Potential Profit (Highest to Lowest)
                  </option>
                  <option value='price-high2low'>
                    Price (Highest to Lowest)
                  </option>
                  <option value='price-low2high'>
                    Price (Lowest to Highest)
                  </option>
                </select>
              </div>
              <div className='relative flex items-center'>
                <SearchIcon className='absolute w-5 h-5 text-gray-700 dark:text-gray-300 ml-3' />
                <input
                  type='text'
                  id='table-search'
                  className='block w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500'
                  placeholder='Search results'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className='flex flex-col space-y-4'>
              <div className='flex items-center space-x-2'>
                <label
                  htmlFor='bulk-action'
                  className='font-medium text-gray-900 dark:text-white'
                >
                  Bulk Action:
                </label>
                <select
                  id='bulk-action'
                  value={action}
                  onChange={handleActionChange}
                  className='w-full max-w-xs text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-indigo-200 font-medium rounded-lg text-sm px-2 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-700'
                >
                  <option value=''>Select Action</option>
                  <option value='passed'>Pass Selected</option>
                  <option value='watching'>Watch Selected</option>
                </select>
              </div>
              <div className='flex items-center space-x-2 w-full'>
                <button
                  onClick={handlePassAll}
                  className='flex-1 text-gray-900 border border-gray-300 bg-white py-2 px-3 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-indigo-200 dark:bg-gray-800 dark:border-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700 text-sm'
                >
                  Pass All
                </button>
                <button
                  onClick={toggleStatusFilter}
                  className='flex-1 text-gray-900 border border-gray-300 bg-white py-2 px-3 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-indigo-200 dark:bg-gray-800 dark:border-gray-600 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700 text-sm'
                >
                  {statusFilter === 'watching'
                    ? 'Show Pending'
                    : 'Show Watched'}
                </button>
                {areFiltersApplied() && (
                  <button
                    onClick={resetFilters}
                    className='bg-red-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300 ease-in-out border self-center md:self-end flex items-center space-x-2'
                  >
                    <XIcon className='w-5 h-5' />
                    <span>Reset Filters</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ResultsHeader

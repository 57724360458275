import React from 'react';
import {
  PencilIcon,
  TrashIcon,
  CameraIcon,
  ExternalLinkIcon,
} from '@heroicons/react/outline';

const STATUS_CONFIG = {
  draft: { color: 'bg-yellow-500', label: 'Draft' },
  active: { color: 'bg-green-500', label: 'Active' },
  inactive: { color: 'bg-red-500', label: 'Inactive' },
  disabled: { color: 'bg-gray-500', label: 'Disabled' },
  relist: { color: 'bg-blue-500', label: 'Relist' },
  pending: { color: 'bg-yellow-500', label: 'Pending' },
  completed: { color: 'bg-green-500', label: 'Completed' },
  hold: { color: 'bg-orange-500', label: 'Hold' },
  sold: { color: 'bg-red-500', label: 'Sold' },
  deleted: { color: 'bg-gray-500', label: 'Deleted' },
};

const ProductsDataCard = ({
  items,
  onEdit,
  onDelete,
  onEditPhotos,
  onCardClick,
}) => {
  const defaultImageUrl =
    'https://cdn-icons-png.flaticon.com/512/3342/3342243.png';

  const getEbayStatus = (listToChannels) => {
    if (!listToChannels || listToChannels.length === 0) {
      return 'disabled';
    }
    const ebayChannels = listToChannels.filter(
      (channel) => channel.platform.toLowerCase() === 'ebay'
    );
    return ebayChannels.some((channel) => channel.listingStatus === 'active')
      ? 'active'
      : ebayChannels[0].listingStatus || 'disabled';
  };

  const StatusIndicator = ({ status, tooltip }) => {
    const config = STATUS_CONFIG[status] || STATUS_CONFIG['disabled'];
    return (
      <div className="flex items-center space-x-1">
        <div className={`h-4 w-4 rounded-full ${config.color}`} title={tooltip} />
        <span className="text-xs text-gray-600 dark:text-gray-300">{tooltip}</span>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {items.length > 0 ? (
        items.map((item) => {
          const ebayStatus = getEbayStatus(item.listToChannels);
          const photographyStatus = item.photographyStatus;
          const listingStatus = item.recordCompletionStatus;

          return (
            <div
              key={item._id}
              className="bg-white border dark:border-gray-700 dark:bg-gray-800 shadow rounded-lg p-5 flex flex-col space-y-4 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
              onClick={() => onCardClick(item._id)}
            >
              <div className="flex items-center space-x-4">
                <div className="relative group">
                  <img
                    src={
                      item.images && item.images.length > 0
                        ? item.images[0].awsUrl
                        : defaultImageUrl
                    }
                    alt={item.name}
                    className="w-20 h-20 rounded-lg object-cover"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditPhotos(item);
                    }}
                  />
                  <div
                    className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 opacity-0 group-hover:opacity-100 rounded-lg"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditPhotos(item);
                    }}
                  >
                    <CameraIcon className="h-7 w-7 text-white" />
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <h2 className="text-base font-semibold text-gray-900 dark:text-white truncate">
                    {item.sku}
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    {item.brand} {item.model} {item.item_description}
                  </p>
                  <p className="text-lg text-indigo-600 dark:text-indigo-400 font-bold">
                    ${item.price?.toFixed(2) || '0.00'}
                  </p>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Qty: {item.quantity || 0} | Condition: {item.condition || 'N/A'}
                  </p>
                </div>
              </div>

              <div className="flex justify-between items-center text-xs text-gray-500 dark:text-gray-300">
                <div className="flex flex-col space-y-1">
                  <StatusIndicator
                    status={ebayStatus}
                    tooltip={`eBay: ${STATUS_CONFIG[ebayStatus]?.label || 'N/A'}`}
                  />
                  <StatusIndicator
                    status={photographyStatus}
                    tooltip={`Photo: ${STATUS_CONFIG[photographyStatus]?.label || 'N/A'}`}
                  />
                  <StatusIndicator
                    status={listingStatus}
                    tooltip={`Record: ${STATUS_CONFIG[listingStatus]?.label || 'N/A'}`}
                  />
                </div>

                <div className="flex space-x-3" onClick={(e) => e.stopPropagation()}>
                  <button
                    onClick={() => onEdit(item)}
                    className="text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-500 p-2"
                    aria-label="Edit Product"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  {item.ebayDetails?.listingId && (
                    <a
                      href={`https://www.ebay.com/itm/${item.ebayDetails.listingId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-500 p-2"
                      aria-label="View on eBay"
                    >
                      <ExternalLinkIcon className="h-5 w-5" />
                    </a>
                  )}
                  <button
                    onClick={() => onDelete(item)}
                    className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-500 p-2"
                    aria-label="Delete Product"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                  {photographyStatus === 'completed' && (
                    <button
                      onClick={() => onEditPhotos(item)}
                      className="text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-400 p-2"
                      aria-label="Edit Photos"
                    >
                      <CameraIcon className="h-5 w-5" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center col-span-full py-8">
          <p className="text-gray-500 dark:text-gray-400">No products available</p>
        </div>
      )}
    </div>
  );
};

export default ProductsDataCard;

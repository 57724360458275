import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CallToAction from '../components/AboutUs/CallToAction';
import Timeline from '../components/AboutUs/Timeline';
import MissionSection from '../components/AboutUs/MissionSection';
import Statistics from '../components/AboutUs/Statistics';
import Team from '../components/AboutUs/Team';


const AboutUs = () => {
  return (
    <div className='relative min-h-screen overflow-hidden bg-gray-100 dark:bg-gray-900'>

      {/* Top Left Gradient */}
      <div
        className="absolute inset-x-0 top-0 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Middle Left Gradient */}
      <div
        className="absolute inset-x-0 top-1/4 transform-gpu overflow-hidden blur-3xl "
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Bottom Left Gradient */}
      <div
        className="absolute inset-x-0 top-[60%] transform-gpu overflow-hidden blur-3xl "
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[80.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400 opacity-30 dark:opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Right Side Gradient 1 */}
      <div
        className="absolute top-0 right-0 transform-gpu overflow-hidden blur-3xl sm:top-10"
        aria-hidden="true"
      >
        <div
          className="relative right-[calc(50%-10rem)] aspect-[1155/678] w-[40.125rem] translate-x-1/2 rotate-[60deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 dark:opacity-30  sm:right-[calc(50%-5rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      {/* Right Side Gradient Statistics Section*/}
      <div
        className="absolute top-1/2 right-0 transform-gpu overflow-hidden blur-3xl "
        aria-hidden="true"
      >
        <div
          className="relative right-[calc(50%-10rem)] aspect-[1155/678] w-[40.125rem] translate-x-1/2 rotate-[60deg] bg-gradient-to-tr from-blue-400 to-pink-500 opacity-30 dark:opacity-30  sm:right-[calc(50%-5rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

     

      <Header />
      <CallToAction />
      <Timeline />
      <MissionSection />
      <Statistics />
      <Team />
      <Footer />
    </div>
  );
};

export default AboutUs;

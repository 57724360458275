import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaExternalLinkAlt } from 'react-icons/fa'
import {
  XCircleIcon,
  ShoppingCartIcon,
  StatusOnlineIcon,
  FireIcon,
  ExclamationIcon,
  TruckIcon,
  BadgeCheckIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  ArrowDownIcon,
  UserIcon,
} from '@heroicons/react/solid'
import Tooltip from '@mui/material/Tooltip'
import ApexCharts from 'react-apexcharts'

import MarketResearchDataCard from './MarketResearchDataCard'
import NavigationActionBar from './NavigationActionBar'
import silverbackImage from '../../../images/silverback.png'
import monkeyImage from '../../../images/monkey.png'
import smallMonkeyImage from '../../../images/small_monkey.png'
import chimpImage from '../../../images/chimp.png'
import bananaImage from '../../../images/banana.png'


const MobileMarketResearchModal = ({
  onClose,
  opportunity,
  handleStatusUpdateAndNext,
  navigateToNext,
  navigateToPrevious,
}) => {
  const { predictedMPNData } = useSelector((state) => state.autoFeed)
  const [filteredItems, setFilteredItems] = useState([])
  const [selectedCondition, setSelectedCondition] = useState('All')
  const [searchTerm, setSearchTerm] = useState('')
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  )

  useEffect(() => {
    if (opportunity) {
      setFilteredItems(opportunity.dataPoints || [])
    }
  }, [opportunity])

  useEffect(() => {
    if (predictedMPNData) {
      setFilteredItems(predictedMPNData.items)
    }
  }, [predictedMPNData])

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme)
    }

    window.addEventListener('themeChange', handleThemeChange)

    return () => {
      window.removeEventListener('themeChange', handleThemeChange)
    }
  }, [])

  const uniqueConditions =
    [...new Set(predictedMPNData?.items.map((item) => item.condition))] || []

  const {
    title = 'Unknown Item',
    seller = {},
    condition = 'Unknown',
    generalized_condition = 'Unknown',
    image = {},
    itemWebUrl = '#',
    Label,
    latestPrice = 0,
    latestShippingCost,
    summary_statistics: summaryStatistics = {},
    attributes = {},
  } = opportunity || {}

  const { averageLifeTimePrice = 0 } = summaryStatistics

  const imageUrl = image?.imageUrl || 'https://via.placeholder.com/150'
  const feedbackScore = seller?.feedbackScore || 'N/A'
  const belowBaselineDollarAmount = (
    averageLifeTimePrice - latestPrice
  ).toFixed(2)

  const priceRangeData = predictedMPNData ? predictedMPNData.priceRangeData : []
  const stats = predictedMPNData ? predictedMPNData.statistics : []

  const allPriceBrackets = [
    ...new Set(
      priceRangeData.flatMap((conditionData) =>
        conditionData.priceBrackets.map((bracket) => bracket.bracket)
      )
    ),
  ].sort(
    (a, b) => parseInt(a.split('-')[0], 10) - parseInt(b.split('-')[0], 10)
  )

  const aggregatedPriceRanges = priceRangeData.map((conditionData) => ({
    name: conditionData.condition,
    data: allPriceBrackets.map((bracket) => {
      const foundBracket = conditionData.priceBrackets.find(
        (b) => b.bracket === bracket
      )
      return foundBracket ? foundBracket.count : 0
    }),
  }))

  const textColor = themeMode === 'dark' ? '#fff' : '#000'

  const priceRangeOptions = {
    chart: {
      type: 'bar',
      height: 250,
      background: 'transparent',
      toolbar: { show: true },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: '55%', borderRadius: 4 },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: allPriceBrackets,
      labels: { style: { colors: textColor } },
    },
    yaxis: {
      axisTicks: { show: true },
      axisBorder: { show: true, color: textColor },
      labels: {
        style: { colors: textColor },
        formatter: (value) => value.toFixed(2),
      },
      title: { text: 'Item Count' },
    },
    tooltip: {
      y: { formatter: (val) => `${val} items` },
    },
    title: {
      text: 'Item Count by Price Range for Each Condition',
      align: 'left',
      style: { color: textColor },
    },
    theme: { mode: themeMode },
    legend: { labels: { colors: textColor } },
    colors: ['#6366F1', '#34D399', '#F59E0B', '#EF4444'],
  }

  const priceDistributionOptions = {
    chart: {
      type: 'bar',
      height: 250,
      stacked: false,
      toolbar: { show: true },
      zoom: { enabled: true },
      background: 'transparent',
    },
    title: {
      text: 'Price Distribution and Statistics by Item Condition',
      align: 'left',
      style: { color: textColor },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: '55%', borderRadius: 4 },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: stats.map((stat) => stat._id),
      labels: { style: { colors: textColor } },
    },
    yaxis: [
      {
        axisTicks: { show: true },
        axisBorder: { show: true, color: textColor },
        labels: {
          style: { colors: textColor },
          formatter: (value) => value.toFixed(2),
        },
        title: { text: 'Prices ($)', style: { color: textColor } },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      x: { show: false },
      y: { formatter: (value) => `$${value.toFixed(2)}` },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
      labels: { colors: textColor },
    },
    theme: { mode: themeMode },
    colors: ['#6366F1', '#34D399', '#F59E0B', '#EF4444'],
  }

  const priceDistributionSeries = [
    {
      name: 'Average Price',
      type: 'column',
      data: stats.map((stat) => stat.averagePrice),
    },
    {
      name: 'Min Price',
      type: 'column',
      data: stats.map((stat) => stat.minPrice),
    },
    {
      name: 'Max Price',
      type: 'column',
      data: stats.map((stat) => stat.maxPrice),
    },
  ]

  const handleEbaySearch = () => {
    const conditionMap = {
      new: 3,
      used: 4,
    }
    const conditionId = conditionMap[generalized_condition.toLowerCase()] || 0
  
    // Assuming you want to always sort by "Price + Shipping: lowest first"
    const sortOption = 15
  
    const searchUrl = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}&_sacat=0&LH_Sold=1&LH_Complete=1&LH_ItemCondition=${conditionId}&_sop=${sortOption}`
    window.open(searchUrl, '_blank')
  }

  const handleAmazonSearch = () => {
    const searchUrl = `https://www.amazon.com/s?k=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}`
    window.open(searchUrl, '_blank')
  }

  
  const handleTerapeakSearch = () => {
    const conditionMap = {
      new: 1000,
      used: 3000,
    }
    const conditionId = conditionMap[generalized_condition.toLowerCase()] || 0
  
    // Set dayRange and calculate startDate and endDate
    const dayRange = 180
    const endDate = Date.now()
    const startDate = endDate - dayRange * 24 * 60 * 60 * 1000
  
    const searchUrl = `https://www.ebay.com/sh/research?marketplace=EBAY-US&keywords=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}&dayRange=${dayRange}&endDate=${endDate}&startDate=${startDate}&categoryId=0&conditionId=${conditionId}&offset=0&limit=50&tabName=SOLD&tz=America%2FChicago`
    
    window.open(searchUrl, '_blank')
  }

  const handleSearchChange = (e) => setSearchTerm(e.target.value)
  const handleConditionChange = (e) => setSelectedCondition(e.target.value)

  const filteredAndSearchedItems = filteredItems.filter(
    (item) =>
      (selectedCondition === 'All' || item.condition === selectedCondition) &&
      (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.latestPrice.toString().includes(searchTerm))
  )

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50'>
      <div className='relative w-full max-w-3xl h-full bg-white dark:bg-gray-800 shadow-lg flex flex-col rounded-lg'>
        <div className='sticky top-0 bg-indigo-600 dark:bg-gray-900 p-4 flex justify-between items-center z-10 rounded-t-lg'>
          <h2 className='text-lg font-semibold text-white truncate'>{title}</h2>
          <button onClick={onClose} className='text-white focus:outline-none'>
            <XCircleIcon className='h-6 w-6' />
          </button>
        </div>
        <div className='flex-grow overflow-auto p-4'>
          <div className='border p-4 rounded-lg'>
            <div className='flex flex-col sm:flex-row gap-4'>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  <img
                    className='w-44 h-44 object-cover bg-white dark:bg-gray-700 rounded-xl border'
                    src={imageUrl}
                    alt='Product'
                  />
                </div>
                <div className='ml-4 flex flex-col'>
                  <a
                    href={itemWebUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded mb-2 w-full sm:w-auto text-center'
                  >
                    View Opportunity <FaExternalLinkAlt className='ml-2' />
                  </a>
                  <button
                    onClick={handleEbaySearch}
                    className='inline-flex items-center bg-indigo-500 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded mb-2 w-full sm:w-auto text-center'
                  >
                    eBay Sold Listings <FaExternalLinkAlt className='ml-2' />
                  </button>
                  <button
                    onClick={handleAmazonSearch}
                    className='inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mb-2 w-full sm:w-auto text-center'
                  >
                    Amazon <FaExternalLinkAlt className='ml-2' />
                  </button>
                  <button
                    onClick={handleTerapeakSearch}
                    className='inline-flex items-center bg-yellow-500 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded w-full sm:w-auto text-center'
                  >
                    Terapeak <FaExternalLinkAlt className='ml-2' />
                  </button>
                </div>
              </div>
            </div>
            <div className='flex flex-row sm:items-center sm:space-x-4 mt-4'>
              <div className='flex flex-col'>
                <p className='font-medium text-gray-900 dark:text-white'>
                  <CurrencyDollarIcon className='w-4 h-4 text-indigo-600 inline-block mr-1' />
                  Buy It Now: ${latestPrice.toFixed(2)}
                </p>
                <p className='font-medium text-gray-900 dark:text-white'>
                  <CalculatorIcon className='w-4 h-4 text-green-500 inline-block mr-1' />
                  Average Price: ${averageLifeTimePrice.toFixed(2)}
                </p>
                <p className='font-medium text-gray-900 dark:text-white'>
                  <FireIcon className='w-4 h-4 text-red-500 inline-block mr-1' />
                  Potential Profit: ${belowBaselineDollarAmount}
                </p>
                <p className='font-medium text-gray-900 dark:text-white'>
                  <StatusOnlineIcon className='w-4 h-4 text-yellow-500 inline-block mr-1' />
                  Condition: {condition}
                </p>
              </div>
              <div className='flex flex-col items-center ml-14'>
                <Tooltip title={Label}>
                  <img
                    src={
                      Label === 'Silverback Buy'
                        ? silverbackImage
                        : Label === 'Strong Buy'
                          ? chimpImage
                          : Label === 'Mild Buy'
                            ? monkeyImage
                            : Label === 'Buy'
                              ? smallMonkeyImage
                              : bananaImage
                    }
                    alt={Label}
                    className='w-12 h-12 inline-block ml-4 mr-4 cursor-pointer mb-2'
                  />
                </Tooltip>
                <div className='flex space-x-2'>
                  {attributes.minPrice && (
                    <Tooltip title='Minimum Price'>
                      <ArrowDownIcon className='w-6 h-6 text-green-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {attributes.sold_count_30days && (
                    <Tooltip title='Fast Moving (30 days)'>
                      <FireIcon className='w-6 h-6 text-red-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {attributes.saturated_listing && (
                    <Tooltip title='Saturated Listing'>
                      <ExclamationIcon className='w-6 h-6 text-yellow-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {attributes.sold_listing && (
                    <Tooltip title='Sold Listing'>
                      <ShoppingCartIcon className='w-6 h-6 text-red-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {latestShippingCost === 0 && (
                    <Tooltip title='Free Shipping'>
                      <TruckIcon className='w-6 h-6 text-indigo-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {opportunity.topRatedBuyingExperience && (
                    <Tooltip title='Top Rated Buying Experience'>
                      <BadgeCheckIcon className='w-6 h-6 text-purple-500 cursor-pointer' />
                    </Tooltip>
                  )}
                  {feedbackScore <= 50 && (
                    <Tooltip title='Inexperienced Seller (Low Reviews)'>
                      <UserIcon className='w-6 h-6 text-gray-500 cursor-pointer ml-2' />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='mt-4 p-2 border rounded-xl'>
            <label
              htmlFor='search-input'
              className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              Search Similar Items:
            </label>
            <input
              type='text'
              id='search-input'
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder='Search by name, price...'
              className='bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-gray-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            />
            <label
              htmlFor='condition-select'
              className='block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white'
            >
              Filter by condition:
            </label>
            <select
              id='condition-select'
              value={selectedCondition}
              onChange={handleConditionChange}
              className='bg-gray-50 dark:bg-gray-800 border border-gray-300 dark:border-gray-700 text-gray-900 dark:text-gray-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
            >
              <option value='All'>All Conditions</option>
              {uniqueConditions.map((condition, index) => (
                <option key={index} value={condition}>
                  {condition}
                </option>
              ))}
            </select>

            <div
              className='mt-4 overflow-y-auto  pt-2'
              style={{ maxHeight: '400px' }}
            >
              {filteredAndSearchedItems.map((item, index) => (
                <MarketResearchDataCard key={index} item={item} />
              ))}
            </div>
          </div>
          <div className='mt-4'>
            <div className='mb-4 border shadow-sm rounded-lg p-4'>
              <ApexCharts
                options={priceDistributionOptions}
                series={priceDistributionSeries}
                type='bar'
                height={350}
                className='mb-4'
              />
            </div>
            <div className='mb-4 border shadow-sm rounded-lg p-4'>
              <ApexCharts
                options={priceRangeOptions}
                series={aggregatedPriceRanges}
                type='bar'
                height={350}
                className='mb-4'
              />
            </div>
          </div>
        </div>
        <div className='sticky bottom-0 p-2 bg-white dark:bg-gray-800 z-10 rounded-b-lg'>
          <NavigationActionBar
            onWatch={() =>
              handleStatusUpdateAndNext([opportunity.itemId], 'watched')
            }
            onPass={() =>
              handleStatusUpdateAndNext([opportunity.itemId], 'passed')
            }
            onNext={navigateToNext}
            onPrev={navigateToPrevious}
          />
        </div>
      </div>
    </div>
  )
}

export default MobileMarketResearchModal

import React from 'react'
import Modal from '../ResultsFeed/Modals/Modal' // Import your existing modal component

const ConfirmationModal = ({ isOpen, onClose, onConfirm, term }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-2xl font-bold mb-4'>Confirm Add Term</h2>
        <p>Are you sure you want to add the term "<strong>{term}</strong>" to your research list?</p>
        <div className='mt-6 flex justify-end space-x-4'>
          <button
            onClick={onClose}
            className='bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded'
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              onConfirm(term)
              onClose()
            }}
            className='bg-indigo-600 hover:bg-indigo-800 text-white py-2 px-4 rounded'
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal

import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const metricsUrl = `${apiUrl}/api/metrics`

// Setup headers for Authorization
const getConfig = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
})

export const fetchMetricsData = async (token, endpoint, userId = null) => {
  const config = getConfig(token)
  const url = userId
    ? `${metricsUrl}/${endpoint}?userId=${userId}`
    : `${metricsUrl}/${endpoint}`
  const { data } = await axios.get(url, config)
  return data
}

// ModalHeader.js
import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { XCircleIcon } from '@heroicons/react/solid';

const ModalHeader = ({ handleAmazonSearch, handleEbaySearch, handleTerapeakSearch, itemWebUrl, onClose }) => {
  return (
    <div className='bg-gradient-to-r from-indigo-500 to-indigo-600 dark:from-gray-800 dark:to-gray-900 p-5 rounded-t-lg flex items-center justify-between border'>
      <div className='flex items-center'>
        <span className='text-white font-semibold mr-4'>
          Opportunity Research Links:
        </span>
        <button
          onClick={handleAmazonSearch}
          className='inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mr-2'
        >
          Search Amazon <FaExternalLinkAlt className='ml-2' />
        </button>
        <button
          onClick={handleEbaySearch}
          className='inline-flex items-center bg-indigo-500 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded mr-2'
        >
          Search Sold Items on eBay <FaExternalLinkAlt className='ml-2' />
        </button>
        <button
          onClick={handleTerapeakSearch}
          className='inline-flex items-center bg-yellow-500 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded mr-2'
        >
          Search Terapeak <FaExternalLinkAlt className='ml-2' />
        </button>
        <span className='mx-4 text-white'>|</span>
        <a
          href={itemWebUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded'
        >
          View Arbi Result on eBay <FaExternalLinkAlt className='ml-2' />
        </a>
      </div>
      <button onClick={onClose} className='text-white focus:outline-none'>
        <XCircleIcon className='h-6 w-6' />
      </button>
    </div>
  );
};

export default ModalHeader;
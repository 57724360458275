// inventorySelectors.js

import { createSelector } from '@reduxjs/toolkit';

// Selectors to retrieve parts of the Redux state
export const selectDefaultCustomFields = (state) => state.inventory.defaultCustomFields || [];
export const selectInventoryCustomFields = (state) => state.inventory.itemDetails.customFields || [];
export const selectMissingCustomFields = (state) => state.inventory.missingCustomFields || [];
export const selectAutoFillPreferences = (state) => ({
  autoFillMissingFields: state.inventory.autoFillMissingFields,
  autoFillMissingFieldsValue: state.inventory.autoFillMissingFieldsValue,
});

/**
 * Selector to merge defaultCustomFields, inventoryCustomFields, and missingCustomFields,
 * then group them by fieldName while aggregating platforms.
 */
export const selectMergedCustomFields = createSelector(
  [
    selectDefaultCustomFields,
    selectInventoryCustomFields,
    selectMissingCustomFields,
  ],
  (defaultFields, inventoryFields, missingFields) => {
    const fieldMap = {};

    // Helper function to process fields
    const processFields = (fields, source) => {
      fields.forEach((field) => {
        const key = field.fieldName;

        if (!fieldMap[key]) {
          fieldMap[key] = {
            fieldName: field.fieldName,
            isRequired: field.isRequired || false,
            platforms: new Set(),
            value: '',
            sources: new Set(),
          };
        }

        // Add platforms
        fieldMap[key].platforms.add(field.platform || 'General');
        // Add sources
        fieldMap[key].sources.add(source);

        // Value precedence: inventory > missing > default
        if (source === 'inventory' && (field.value !== undefined && field.value !== null)) {
          fieldMap[key].value = field.value;
        } else if (
          source === 'missing' &&
          (fieldMap[key].value === '' || fieldMap[key].value === undefined)
        ) {
          fieldMap[key].value = field.value || '';
        } else if (
          source === 'default' &&
          (fieldMap[key].value === '' || fieldMap[key].value === undefined)
        ) {
          fieldMap[key].value = field.value || '';
        }
      });
    };

    // Process fields from each source
    processFields(defaultFields, 'default');
    processFields(inventoryFields, 'inventory');
    processFields(missingFields, 'missing');

    // Convert fieldMap to an array for display
    return Object.values(fieldMap).map((field) => ({
      ...field,
      platforms: Array.from(field.platforms),
      sources: Array.from(field.sources),
    }));
  }
);

/**
 * Selector to prepare the custom fields payload for backend submission.
 * It maps each merged custom field to individual platform-specific entries.
 */
export const selectCustomFieldsPayload = createSelector(
  [selectMergedCustomFields, selectAutoFillPreferences],
  (mergedFields, autoFillPrefs) => {
    const { autoFillMissingFields, autoFillMissingFieldsValue } = autoFillPrefs;
    const payload = [];

    mergedFields.forEach((field) => {
      const { fieldName, isRequired, platforms, value } = field;

      platforms.forEach((platform) => {
        let fieldValue = value;

        // Determine if the field should be auto-populated
        if (
          (!fieldValue || fieldValue.trim() === '') &&
          autoFillMissingFields &&
          autoFillMissingFieldsValue
        ) {
          fieldValue = autoFillMissingFieldsValue;
        }

        payload.push({
          fieldName,
          value: fieldValue,
          isRequired,
          platform,
        });
      });
    });

    return payload;
  }
);

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function generateWords() {
  const dataWords = [
    'pieces of information',
    'knowledge segments',
    'statistical data',
    'performance metrics',
    'analytical insights',
    'fact collections',
    'numerical figures',
    'data points',
    'dataset compilations',
    'input variables',
    'raw materials',
    'recorded entries',
    'observational studies',
    'feature sets',
    'sample groups',
    'training datasets',
    'signal patterns',
    'customer datasets',
    'transaction records',
    'sales datasets',
    'revenue reports',
    'purchase histories',
    'sales trend analyses',
    'market data analyses',
    'customer insights',
    'sales analytics reports',
    'sales performance metrics',
    'product data analyses',
    'user behavior studies',
    'inventory levels',
    'financial reports',
    'demographic profiles',
  ];

  const decisionsWords = [
    'strategic choices',
    'judgment calls',
    'conclusive findings',
    'selected options',
    'resolution paths',
    'determination points',
    'resulting outcomes',
    'issued verdicts',
    'proposed solutions',
    'predictive models',
    'recommendation lists',
    'strategic plans',
    'actionable steps',
    'algorithmic adjustments',
    'modeling approaches',
    'optimization techniques',
    'classification schemes',
    'forecasting methods',
    'automation processes',
    'sales strategy adaptations',
    'pricing models',
    'marketing strategies',
    'sales forecasts',
    'inventory management systems',
    'product recommendation engines',
    'sales optimization strategies',
    'customer targeting methods',
    'sales conversion tactics',
    'sales growth strategies',
    'business strategy decisions',
    'data-driven insights',
    'decision-making frameworks',
    'strategic planning sessions',
    'outcome analyses',
  ];

  const randomDataWord = dataWords[Math.floor(Math.random() * dataWords.length)];
  const randomDecisionsWord = decisionsWords[Math.floor(Math.random() * decisionsWords.length)];

  return {
    data: randomDataWord,
    decisions: randomDecisionsWord,
  };
}

function HeroSection() {
  const [words, setWords] = useState(generateWords());
  const [bouncy, setBouncy] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setBouncy(true);
      setTimeout(() => {
        setWords(generateWords());
        setTimeout(() => {
          setBouncy(false);
        }, 500);
      }, 2000);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleGetStartedClick = () => {
    if (user) {
      navigate('/arbie');
    } else {
      navigate('/register-closed');
    }
  };

  return (
    <section
      className="w-full mx-auto flex justify-center items-center relative h-auto overflow-hidden  pt-2 sm:pt-1 lg:mb-10"
      aria-label="Hero Section"
    >
      <Helmet>
        <title>ArbiEngine</title>
        <meta
          name="description"
          content="ArbiEngine helps you discover profitable e-commerce arbitrage opportunities and scale your business with powerful tools and automation."
        />
        <meta
          name="keywords"
          content="ecommerce, arbitrage, business automation, ecommerce tools, ecommerce opportunities, scaling business"
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://arbiengine.com" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebPage",
            name: "Arbi Engine - Find Ecommerce Arbitrage Opportunities",
            description:
              "Arbi Engine helps you discover profitable e-commerce arbitrage opportunities and scale your business with powerful tools and automation.",
            url: "https://arbiengine.com",
            mainEntity: {
              "@type": "Organization",
              name: "Arbi Engine",
              logo: "https://arbiengine.com/logo.png",
              sameAs: [
                "https://x.com/AEngine35248",
                "https://www.instagram.com/arbiengine",
                "https://www.linkedin.com/company/arbiengine",
              ],
            },
          })}
        </script>
        <meta
          property="og:title"
          content="Arbi Engine - Find Ecommerce Arbitrage Opportunities"
        />
        <meta
          property="og:description"
          content="Discover e-commerce arbitrage opportunities and scale your business with Arbi Engine's tools and automation."
        />
        <meta
          property="og:image"
          content="https://arbiengine.com/path-to-image.jpg"
        />
        <meta property="og:url" content="https://arbiengine.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Arbi Engine - Find Ecommerce Arbitrage Opportunities"
        />
        <meta
          name="twitter:description"
          content="Discover e-commerce arbitrage opportunities and scale your business with Arbi Engine's tools and automation."
        />
        <meta
          name="twitter:image"
          content="https://arbiengine.com/path-to-image.jpg"
        />
      </Helmet>
      <div className="container my-0 mx-10 max-w-4xl py-32 sm:py-48 lg:py-32 relative z-10">
        <div className="hidden md:mb-8 md:flex md:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 dark:text-gray-300 ring-1 ring-gray-900/10 hover:ring-gray-900/20 dark:ring-gray-300/10 dark:hover:ring-gray-300/20">
            <span>Join us on our journey to revolutionize e-commerce.</span>{' '}
            <a
              href="/about-us"
              className="font-semibold text-indigo-600 dark:text-indigo-400"
            >
              <span className="absolute inset-0" aria-hidden="true" />
              Learn more about our team <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <div className="text-center">
          <h1
            className={`${bouncy ? 'animate-pulse' : ''
              } text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl`}
          >
            Where{' '}
            <span className="text-indigo-600 dark:text-indigo-400">
              {words.data}
            </span>{' '}
            drive{' '}
            <span className="text-indigo-600 dark:text-indigo-400">
              {words.decisions}
            </span>
            .
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
            Unlock the power of data-driven decision-making with our all-in-one
            platform. From real-time market analysis to AI-powered
            recommendations, ArbiEngine simplifies every step of the e-commerce
            journey. Scale smarter with automated processes, actionable
            insights, and the tools you need to thrive.
          </p>
          <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4">
            <button
              onClick={handleGetStartedClick}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:scale-105"
              aria-label={user ? "Go to Dashboard" : "Join the Waitlist"}
            >
              {user ? 'Go to Dashboard' : 'Join the Waitlist'}
            </button>
            <a
              href="/about-us"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white hover:scale-105"
            >
              Learn More About Us <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="mt-20">
          <p className="font-display text-center text-gray-900 dark:text-white">
            Trusted by these companies so far
          </p>
          <ul
            role="list"
            className="mt-8 flex flex-wrap items-center justify-center gap-8"
          >
            <li>
              <img
                alt="eBay logo"
                loading="lazy"
                width="158"
                height="48"
                className="h-12 w-auto"
                src="https://upload.wikimedia.org/wikipedia/commons/4/48/EBay_logo.png"
              />
            </li>
            <li>
              <img
                alt="Shopify logo"
                loading="lazy"
                width="105"
                height="48"
                className="h-12 w-auto"
                src="https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-512.png"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import { forgotPasswordConfirm } from '../features/auth/authSlice'
import { PolygonBackground1, PolygonBackground2 } from '../components/PolygonBackground'
import Header from '../components/Header'
import 'react-toastify/dist/ReactToastify.css'

import CryptoJS from 'crypto-js';


const ForgotPasswordConfirm = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorShake, setErrorShake] = useState(false)
  const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
  const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();

  

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      console.error(message)
      setErrorShake(true)

      setTimeout(() => {
        setErrorShake(false)
      }, 800)
    }
    if (isSuccess) {
      handleForgotPasswordConfirmSuccess()
      navigate('/')
    }
  }, [isError, isSuccess])

  const onSubmitConfirm = async (e) => {
    e.preventDefault()

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')

    try {
      const userData = {
        password: password,
        confirmPassword: confirmPassword,
        token: token,
        encryptedPassword: encryptedPassword,
      }
      await dispatch(forgotPasswordConfirm(userData))
    } catch (error) {
      console.error('Failed to reset password:', error.message)
    }
  }

  const handleForgotPasswordConfirmSuccess = () => {
    toast.dismiss()
    toast.success('Password reset successful. Redirecting to the home page.', {
      position: 'top-right',
      autoClose: 5000,
    })
  }

  return (
    <div className='relative min-h-screen overflow-hidden dark:bg-gray-900'>
      <Header hideGetStarted={true} />
      <ToastContainer position='top-right' autoClose={5000} />
      <PolygonBackground1 />
      <div className='flex flex-col justify-center items-center h-screen'>
        <h1 className='text-5xl text-center mb-12 font-bold dark:text-white'>
          Reset Password
        </h1>
        <div className='max-w-md w-full p-8 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white rounded-lg overflow-hidden shadow-2xl border border-gray-300'>
          <div className={`${errorShake ? 'shake' : ''}`}>
            <form onSubmit={onSubmitConfirm}>
              <div className='mb-6'>
                <label
                  htmlFor='password'
                  className='block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300'
                >
                  Password
                </label>
                <input
                  type='password'
                  id='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 border border-transparent focus:outline-none hover:shadow-md'
                />
              </div>
              <div className='mb-6'>
                <label
                  htmlFor='confirmPassword'
                  className='block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300'
                >
                  Confirm Password
                </label>
                <input
                  type='password'
                  id='confirmPassword'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 border border-transparent focus:outline-none hover:shadow-md'
                />
              </div>
              <button className='w-full p-3 mt-4 bg-indigo-600 dark:bg-indigo-500 text-white rounded shadow hover:bg-indigo-700 dark:hover:bg-indigo-600'>
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <PolygonBackground2 />
    </div>
  )
}

export default ForgotPasswordConfirm

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, reset } from '../features/auth/authSlice';
import ArbiEngineLogo from '../images/logo.png';
import { PolygonBackground1, PolygonBackground2 } from '../components/PolygonBackground';
import Header from '../components/Header';
import '../pages_css/Animations.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha'

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    captcha: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorShake, setErrorShake] = useState(false);

  const { email, password, captcha } = formData
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message, needs2FA } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      console.error(message);
      setErrorShake(true);
      toast.error(message);

      setTimeout(() => {
        setErrorShake(false);
      }, 800);
    }

    if (isSuccess && needs2FA) {
      navigate('/2fa');
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, needs2FA, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const onCaptchaChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      captcha: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault()

    if (process.env.REACT_APP_NODE_ENV !== 'development' && !captcha) {
      toast.error('Please complete the CAPTCHA');
      return
    }

    const userData = {
      email,
      password,
      captcha,
    }

    dispatch(login(userData))
  }

  return (
    <div className='relative min-h-screen overflow-hidden dark:bg-gray-900'>
      <Header hideGetStarted={true} />
      <PolygonBackground1 />
      <div className='container mx-auto p-8 flex justify-center items-center h-screen'>
        <div className='max-w-md w-full mx-auto form-container'>
          <img
            src={ArbiEngineLogo}
            alt='ArbiEngine Logo'
            className='mx-auto mb-8'
            style={{ width: '300px' }}
          />
          <div
            className={`bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white rounded-lg overflow-hidden shadow-2xl border border-gray-300 ${errorShake ? 'shake' : ''}`}
          >
            <div className='p-8'>
              <form onSubmit={onSubmit}>
                <div className='mb-5'>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300'
                  >
                    Email
                  </label>
                  <input
                    type='text'
                    value={email}
                    onChange={onChange}
                    name='email'
                    className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>
                <div className='mb-5'>
                  <label
                    htmlFor='password'
                    className='block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300'
                  >
                    Password
                  </label>
                  <div className='relative w-full'>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={onChange}
                      name='password'
                      className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 border border-transparent focus:outline-none hover:shadow-md'
                    />
                    <div className='absolute inset-y-0 right-0 flex items-center px-2'>
                      <input
                        id='toggle'
                        type='checkbox'
                        className='hidden js-password-toggle'
                        onChange={togglePasswordVisibility}
                      />
                      <label
                        htmlFor='toggle'
                        className='bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500 rounded px-2 py-1 text-sm text-gray-600 dark:text-gray-300 font-mono cursor-pointer js-password-label'
                      >
                        {showPassword ? 'hide' : 'show'}
                      </label>
                    </div>
                  </div>
                </div>
                {process.env.REACT_APP_NODE_ENV !== 'development' && (
                  <div className="flex justify-center mb-4">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                      onChange={onCaptchaChange}
                    />
                  </div>
                )}
                <button className='w-full p-3 mt-4 bg-indigo-600 dark:bg-indigo-500 text-white rounded shadow hover:bg-indigo-700 dark:hover:bg-indigo-600'>
                  {isLoading ? 'Logging in...' : 'Login'}
                </button>
              </form>
            </div>
            <div className='flex justify-between p-8 text-sm border-t border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800'>
              <a href='/register' className='font-medium text-indigo-500 dark:text-indigo-400'>
                Create account
              </a>
              <a href='/forgot-password' className='text-gray-600 dark:text-gray-300'>
                Forgot password?
              </a>
            </div>
          </div>
        </div>
      </div>
      <PolygonBackground2 />
      <ToastContainer position='top-right' autoClose={5000} />
    </div>
  )
}

export default Login

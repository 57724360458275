import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import purchasesService from './purchasesService';

const initialState = {
  purchases: [],
  filteredItems: [],
  allItems: [],
  purchase: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  page: 1,
  itemsPerPage: 25,
  hasMoreItems: true,
  filters: {
    connectionFilter: 'all',
    searchQuery: '',
    dateRange: { start: null, end: null },
  },
  totalAvailableResults: 0,
};

// Helper function to apply filters and sorting to purchases
const applyFiltersAndSort = (items, filters) => {
  const { connectionFilter, searchQuery, dateRange } = filters;

  let filteredItems = items.filter((item) => {
    const connectionMatch = connectionFilter === 'all' || item.accountID === connectionFilter;

    const searchQueryMatch = !searchQuery || item.items.some((subItem) => {
      const itemMatches = (
        subItem.brand?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.model?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.category?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.itemId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.ebayId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.convertedSKU?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subItem.expectedCondition?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const purchaseMatches = (
        item.sellerUsername?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.purchaseComments?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return itemMatches || purchaseMatches;
    });

    const dateMatch = (!dateRange.start || new Date(item.purchaseDate) >= new Date(dateRange.start)) &&
      (!dateRange.end || new Date(item.purchaseDate) <= new Date(dateRange.end));

    return connectionMatch && searchQueryMatch && dateMatch;
  });

  // Sort by most recent purchases
  filteredItems.sort((a, b) => new Date(b.purchaseDate) - new Date(a.purchaseDate));

  return filteredItems;
};

// Fetch all purchases and refresh data from platforms
export const fetchAllPurchases = createAsyncThunk(
  'purchases/fetchAllPurchases',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await purchasesService.fetchAllPurchases(token);
      return await purchasesService.fetchPurchases(token); // No need for connectionIDs
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch purchases from MongoDB
export const fetchPurchases = createAsyncThunk(
  'purchases/fetchPurchases',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.fetchPurchases(token); // Fetches all purchases from MongoDB
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch a single purchase by ID
export const fetchPurchaseById = createAsyncThunk(
  'purchases/fetchPurchaseById',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.fetchPurchaseById(id, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create a new purchase
export const createPurchase = createAsyncThunk(
  'purchases/createPurchase',
  async (purchaseData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.createPurchase(purchaseData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update a purchase
export const updatePurchase = createAsyncThunk(
  'purchases/updatePurchase',
  async (purchaseData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.updatePurchase(purchaseData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete a purchase
export const deletePurchase = createAsyncThunk(
  'purchases/deletePurchase',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.deletePurchase(id, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create or update inventory drafts
export const createOrUpdateInventoryDrafts = createAsyncThunk(
  'purchases/createOrUpdateInventoryDrafts',
  async ({ purchaseId, lineItems }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await purchasesService.createOrUpdateInventoryDrafts({ purchaseId, lineItems }, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    reset: (state) => {
      state.purchases = [];
      state.filteredItems = [];
      state.purchase = null;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.page = 1;
      state.hasMoreItems = true;
    },
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      state.page = 1;
      const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
      state.filteredItems = filteredItems;
      state.purchases = filteredItems.slice(0, state.itemsPerPage);
      state.totalAvailableResults = filteredItems.length;
      state.hasMoreItems = filteredItems.length > state.itemsPerPage;
    },
    fetchMorePurchasesFromState: (state) => {
      const startIndex = state.page * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      const newPurchases = state.filteredItems.slice(startIndex, endIndex);
      state.purchases = [...state.purchases, ...newPurchases];
      state.page += 1;
      if (newPurchases.length < state.itemsPerPage) {
        state.hasMoreItems = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPurchases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllPurchases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allItems = Array.isArray(action.payload) ? action.payload : [];
        const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
        state.filteredItems = filteredItems;
        state.purchases = filteredItems.slice(0, state.itemsPerPage);
        state.totalAvailableResults = filteredItems.length;
        state.hasMoreItems = filteredItems.length > state.itemsPerPage;
      })
      .addCase(fetchAllPurchases.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPurchases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPurchases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allItems = Array.isArray(action.payload) ? action.payload : [];
        const filteredItems = applyFiltersAndSort(state.allItems, state.filters);
        state.filteredItems = filteredItems;
        state.purchases = filteredItems.slice(0, state.itemsPerPage);
        state.totalAvailableResults = filteredItems.length;
        state.hasMoreItems = filteredItems.length > state.itemsPerPage;
      })
      .addCase(fetchPurchases.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPurchaseById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPurchaseById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.purchase = action.payload;
      })
      .addCase(fetchPurchaseById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createPurchase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPurchase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.purchases.push(action.payload);
      })
      .addCase(createPurchase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePurchase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePurchase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.purchases.findIndex(
          (purchase) => purchase._id === action.payload._id
        );
        if (index !== -1) {
          state.purchases[index] = action.payload;
        }
      })
      .addCase(updatePurchase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePurchase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePurchase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.purchases = state.purchases.filter(
          (purchase) => purchase._id !== action.payload._id
        );
      })
      .addCase(deletePurchase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createOrUpdateInventoryDrafts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrUpdateInventoryDrafts.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = 'Inventory drafts created/updated successfully';
      })
      .addCase(createOrUpdateInventoryDrafts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setFilter, fetchMorePurchasesFromState } = purchasesSlice.actions;
export default purchasesSlice.reducer;

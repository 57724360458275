import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = () => {
  return (
    <div className="bg-[#f5f5f5] dark:bg-gray-900 py-20 text-center">
      {/* Top Divider */}
      <div className="w-64 h-1 bg-indigo-600 mx-auto mb-10"></div>

      <h2 className="text-3xl font-semibold text-gray-800 dark:text-white mb-6">
        Boost Your eCommerce Potential
      </h2>
      <p className="text-2xl text-gray-600 dark:text-gray-300 mb-10 max-w-2xl mx-auto">
        Join ArbiEngine today and gain access to powerful tools that streamline your eCommerce workflow.
      </p>
      <Link to="/register-closed">
        <button className="bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-4 px-10 rounded-full text-2xl transition duration-300">
          Sign Up Now
        </button>
      </Link>
    </div>
  );
};

export default CTASection;

import React, { useState } from 'react';
import { PlusCircleIcon, InformationCircleIcon, PencilIcon, CheckCircleIcon, SearchIcon } from '@heroicons/react/outline'; // Import SearchIcon
import InfoModal from './InfoModal';
import ConfirmationModal from './ConfirmationModal'; // Import the new ConfirmationModal

const SearchTermTable = ({ searchTerms, onAddTerm }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editTermIndex, setEditTermIndex] = useState(null);
  const [editTermValue, setEditTermValue] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [termToConfirm, setTermToConfirm] = useState('');
  const [addedTerms, setAddedTerms] = useState([]); // State to track added terms

  const validateTerm = (term) => {
    if (!term) {
      return "Search term can't be empty.";
    } else if (term.length < 3) {
      return 'Search term must be at least 3 characters long.';
    }
    return '';
  };

  const addTermToDatabase = (term) => {
    const error = validateTerm(term);
    if (error) {
      setValidationError(error);
      return;
    }
    setTermToConfirm(term);
    setIsConfirmationModalOpen(true); // Open the confirmation modal
  };

  const handleConfirmAddTerm = (term) => {
    onAddTerm && onAddTerm(term);
    setAddedTerms((prevAddedTerms) => [...prevAddedTerms, term]); // Use functional setState
    setValidationError('');
  };

  const handleEditClick = (term, index) => {
    setEditTermIndex(index);
    setEditTermValue(term);
    setValidationError('');
  };

  const handleTermChange = (event) => {
    setEditTermValue(event.target.value);
    setValidationError('');
  };

  const handleTermSave = () => {
    const error = validateTerm(editTermValue);
    if (error) {
      setValidationError(error);
      return;
    }
    if (editTermIndex !== null) {
      searchTerms[editTermIndex].term = editTermValue;
      setEditTermIndex(null);
      setEditTermValue('');
      setValidationError('');
    }
  };

  const handleEbaySearch = (term) => {
    const ebaySearchUrl = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(term)}`;
    window.open(ebaySearchUrl, '_blank');
  };

  const getBarInfo = (count) => {
    if (count < 500) {
      return { bars: 1, color: 'red' };
    } else if (count >= 500 && count <= 5000) {
      return { bars: 2, color: 'orange' };
    } else if (count > 5000 && count <= 15000) {
      return { bars: 3, color: 'green' };
    } else {
      return { bars: 4, color: 'red' };
    }
  };

  const renderBars = ({ bars, color }) => {
    const colorClass = {
      red: 'bg-red-500',
      orange: 'bg-orange-500',
      green: 'bg-green-500',
    }[color];

    return (
      <div className='flex items-center'>
        {Array.from({ length: bars }).map((_, index) => (
          <div
            key={index}
            className={`h-2 w-4 mr-1 ${colorClass} rounded`}
          ></div>
        ))}
      </div>
    );
  };

  return (
    <div className='space-y-6'>
      <div className='mt-2 overflow-auto rounded-lg shadow'>
        <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-700'>
          <thead className='bg-gray-50 dark:bg-gray-700'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                Search Term
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider flex items-center'>
                eBay Results Count
                <button
                  onClick={() => setIsModalOpen(true)}
                  className='ml-2 text-gray-600 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400 transition-colors'
                >
                  <InformationCircleIcon className='h-4 w-4 inline' />
                </button>
              </th>
              <th className='px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className='bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700'>
            {searchTerms.map((termObj, index) => {
              const barInfo = getBarInfo(termObj.ebayResultsCount);
              const isAdded = addedTerms.includes(termObj.term); // Check if term is added
              return (
                <tr key={index}>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>
                    {editTermIndex === index ? (
                      <input
                        type='text'
                        value={editTermValue}
                        onChange={handleTermChange}
                        className='bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-300 px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500'
                      />
                    ) : (
                      termObj.term
                    )}
                    {editTermIndex === index && validationError && (
                      <p className='text-red-500 text-xs mt-1'>{validationError}</p>
                    )}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 flex items-center'>
                    ~{termObj.ebayResultsCount}
                    <div className='ml-2'>{renderBars(barInfo)}</div>
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                    {editTermIndex === index ? (
                      <>
                        <button
                          onClick={handleTermSave}
                          className='text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-500 mr-2'
                        >
                          <CheckCircleIcon className='h-5 w-5 inline' /> Save
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => handleEditClick(termObj.term, index)}
                          className='text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-500 mr-2'
                        >
                          <PencilIcon className='h-5 w-5 inline' /> Edit
                        </button>
                        {isAdded ? (
                          <CheckCircleIcon className='h-5 w-5 text-green-600 dark:text-green-400 inline' />
                        ) : (
                          <button
                            type="button"
                            onClick={() => addTermToDatabase(termObj.term)}
                            className='text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-500 mr-2'
                          >
                            <PlusCircleIcon className='h-5 w-5 inline' /> Add
                          </button>
                        )}
                        {/* eBay Search Button */}
                        <button
                          type="button"
                          onClick={() => handleEbaySearch(termObj.term)}
                          className='text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-500 ml-2'
                        >
                          <SearchIcon className='h-5 w-5 inline' /> Search
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleConfirmAddTerm}
        term={termToConfirm}
      />
    </div>
  );
};

export default SearchTermTable;

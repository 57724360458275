import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div className='min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500'>
      <div className='text-center p-8 rounded-md shadow-lg bg-white dark:bg-gray-800'>
        <h1 className='text-9xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-pink-500 animate-pulse'>
          404
        </h1>
        <p className='text-2xl font-semibold text-gray-800 dark:text-gray-200 mt-4'>
          Oops! Page Not Found
        </p>
        <p className='text-md text-gray-600 dark:text-gray-400 mt-2'>
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link
          to='/'
          className='mt-6 px-6 py-3 inline-block bg-indigo-600 text-white font-bold rounded-md shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105'
        >
          Go Home
        </Link>
      </div>
    </div>
  )
}

export default Error404

import React, { useState } from 'react';
import {
  SearchIcon,
  ChartBarIcon,
  LightningBoltIcon,
  CogIcon,
  ShoppingCartIcon,
  ClipboardCheckIcon,
  SpeakerphoneIcon,
  UsersIcon,
} from '@heroicons/react/outline';

const features = [
  {
    id: 'product-sourcing',
    icon: <SearchIcon className="h-6 w-6" />,
    title: 'Product Sourcing',
    description: 'Uncover profitable products effortlessly, leveraging advanced data-driven tools to stay ahead of the competition.',
  },
  {
    id: 'data-analysis',
    icon: <ChartBarIcon className="h-6 w-6" />,
    title: 'Data Analysis',
    description: 'Transform raw data into actionable insights, empowering you to make decisions that boost sales and growth.',
  },
  {
    id: 'automation',
    icon: <LightningBoltIcon className="h-6 w-6" />,
    title: 'Process Automation',
    description: 'Automate mundane tasks, freeing up time to focus on scaling your business and achieving more with less effort.',
  },
  {
    id: 'system-integration',
    icon: <CogIcon className="h-6 w-6" />,
    title: 'System Integration',
    description: 'Integrate seamlessly with your existing tools and platforms to create a cohesive and efficient workflow.',
  },
  {
    id: 'multichannel-listing',
    icon: <ShoppingCartIcon className="h-6 w-6" />,
    title: 'Multichannel Listing',
    description: 'Expand your reach by managing and optimizing product listings across multiple channels with ease.',
  },
  {
    id: 'order-fulfillment',
    icon: <ClipboardCheckIcon className="h-6 w-6" />,
    title: 'Order Fulfillment',
    description: 'Streamline order processing and fulfillment, ensuring fast delivery and satisfied customers.',
  },
  {
    id: 'branding',
    icon: <SpeakerphoneIcon className="h-6 w-6" />,
    title: 'Brand Building',
    description: 'Create a compelling brand presence and engage your audience through targeted marketing and outreach.',
  },
  {
    id: 'crm',
    icon: <UsersIcon className="h-6 w-6" />,
    title: 'Customer Relationship Management',
    description: 'Build lasting relationships with customers through personalized experiences and robust CRM tools.',
  },
];

const SecondaryFeatures = () => {
  const [selectedFeature, setSelectedFeature] = useState(features[0].id);

  return (
    <section
      id="secondary-features"
      aria-label="Features for simplifying everyday business tasks"
      className="pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            Simplify your business workflow.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-gray-600 dark:text-gray-300">
            Empower your business with the tools you need to automate, integrate, and scale efficiently.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-8 gap-y-16 mt-16">
          {features.map((feature) => (
            <div
              key={feature.id}
              onClick={() => setSelectedFeature(feature.id)}
              className={`p-6 cursor-pointer rounded-lg transition-transform transform ${selectedFeature === feature.id
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white'
                } hover:scale-105 shadow-md`}
            >
              <div
                className={`flex items-center justify-center h-12 w-12 rounded-md mb-4 ${selectedFeature === feature.id ? 'bg-white text-indigo-600' : 'bg-indigo-600 text-white'
                  }`}
              >
                {feature.icon}
              </div>
              <h3 className="text-lg font-medium leading-6">
                {feature.title}
              </h3>
              <p className="mt-4 text-base">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SecondaryFeatures;

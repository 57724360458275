import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { XIcon, InformationCircleIcon } from '@heroicons/react/solid'

import { performResearch, fetchAllCategories } from '../../features/productResearch/productResearchSlice'

const NewSearchModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const [isExplanationMode, setIsExplanationMode] = useState(false)
  const [formData, setFormData] = useState({
    searchTerm: '',
  })
  const { user } = useSelector((state) => state.auth)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!formData.searchTerm.trim()) {
      alert('Search term is required')
      return
    }

    const researchData = { ...formData, userId: user._id }
    try {
      await dispatch(performResearch(researchData)).unwrap()
      dispatch(fetchAllCategories(user._id))
      onClose()
    } catch (error) {
      console.error('Failed to perform research:', error)
    }
  }

  const getExplanationContent = (key) => {
    switch (key) {
      case 'searchTerm':
        return 'Enter a search term that represents the category or product line you want to research. For example, "Siemens Sirius", "Apple MacBook", "Nike Air Max", "Dell XPS", or "Bosch Power Tools".'
      default:
        return ''
    }
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-gray-900 bg-opacity-70 flex justify-center items-center z-50'>
      <div
        className='relative w-full max-w-lg bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6'
        style={{ maxHeight: '90%' }}
      >
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-semibold text-gray-900 dark:text-white'>
            {isExplanationMode ? 'What Are We Doing?' : 'Start New Research'}
          </h2>
          <div className='flex space-x-3'>
            <button
              onClick={() => setIsExplanationMode(!isExplanationMode)}
              className='text-indigo-500 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-600'
            >
              <InformationCircleIcon className='h-6 w-6' />
            </button>
            <button
              onClick={onClose}
              className='text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-600'
            >
              <XIcon className='h-6 w-6' />
            </button>
          </div>
        </div>
        {isExplanationMode ? (
          <div>
            <p className='text-gray-700 dark:text-gray-300 mb-4'>
              We’re helping you find the best categories for product research based on your input. Simply enter a search term that describes the product line or category you're interested in. We’ll do the heavy lifting and suggest relevant categories for you to explore.
            </p>
            <p className='text-gray-700 dark:text-gray-300'>
              {getExplanationContent('searchTerm')}
            </p>
            <button
              onClick={() => setIsExplanationMode(false)}
              className='mt-6 w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition duration-200 ease-in-out'
            >
              Back to Form
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className='mb-6'>
              <label
                htmlFor='searchTerm'
                className='block text-sm font-medium text-gray-900 dark:text-gray-300'
              >
                Search Term*
              </label>
              <input
                type='text'
                name='searchTerm'
                id='searchTerm'
                value={formData.searchTerm}
                onChange={handleChange}
                required
                placeholder='Enter search term (e.g., Siemens Sirius)'
                className='mt-1 block w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-white transition duration-200 ease-in-out'
              />
            </div>
            <button
              type='submit'
              className='w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-lg shadow-sm transition duration-200 ease-in-out'
            >
              Start Research
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default NewSearchModal

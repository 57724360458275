import React from 'react'
import { Dialog, Transition } from '@headlessui/react'

const ConfirmationModal = ({ isOpen, onClose, plan }) => {
  const confirmationNumber = Math.floor(100000 + Math.random() * 900000) // Temporary placeholder

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto'
        onClose={onClose}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />
          </Transition.Child>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg'>
              <Dialog.Title
                as='h3'
                className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100'
              >
                Subscription Confirmation
              </Dialog.Title>
              <div className='mt-4'>
                {plan ? (
                  <>
                    <p className='text-sm text-gray-500 dark:text-gray-300'>
                      Thank you for your purchase! Your subscription to the{' '}
                      <span className='font-bold'>{plan.name}</span> plan has
                      been processed successfully.
                    </p>
                    <p className='text-sm text-gray-500 dark:text-gray-300 mt-2'>
                      Confirmation Number:{' '}
                      <span className='font-bold'>{confirmationNumber}</span>
                    </p>
                    <p className='text-sm text-gray-500 dark:text-gray-300 mt-2'>
                      You will receive an email with the receipt shortly.
                    </p>
                  </>
                ) : (
                  <p className='text-sm text-gray-500 dark:text-gray-300'>
                    Loading plan details...
                  </p>
                )}
              </div>
              <div className='mt-6'>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600'
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ConfirmationModal

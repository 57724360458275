// TermsOfService.jsx

import React from 'react';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer';

const TermsOfService = () => {
  return (
    <div className="relative min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <Header />
      <main className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-center text-indigo-600 dark:text-indigo-400">
            Terms of Service
          </h1>
          <p className="mt-6">
            Welcome to ArbiEngine. By using our platform, you agree to comply with the following terms and conditions. Please read them carefully.
          </p>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">1. Acceptance of Terms</h2>
            <p className="mt-4">
              By accessing and using ArbiEngine, you acknowledge that you have read, understood, and agreed to these Terms of Service. If you do not agree, please discontinue using our platform.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">2. Use of the Platform</h2>
            <p className="mt-4">
              You may use ArbiEngine for lawful purposes only. You are prohibited from using the platform to distribute harmful or malicious content, infringe upon the rights of others, or engage in any illegal activities.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">3. User Content and Conduct</h2>
            <p className="mt-4">
              You are responsible for the content you upload or share on ArbiEngine. Ensure that your contributions are respectful and do not violate any applicable laws or the rights of others.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">4. Intellectual Property</h2>
            <p className="mt-4">
              All content, logos, and trademarks displayed on ArbiEngine are the property of ArbiEngine or its licensors. Unauthorized use is strictly prohibited.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">5. Limitation of Liability</h2>
            <p className="mt-4">
              ArbiEngine is not liable for any damages resulting from the use or inability to use the platform. We make no warranties or representations about the accuracy or completeness of content on the platform.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">6. Changes to Terms</h2>
            <p className="mt-4">
              We may modify these Terms of Service at any time. Continued use of the platform constitutes acceptance of the modified terms. Please review these terms periodically.
            </p>
          </section>

          <p className="mt-8">
            If you have any questions or concerns about these Terms of Service, please contact us at support@arbiengine.com.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TermsOfService;

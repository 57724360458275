import React from 'react';
import { SearchIcon, PhotographIcon } from '@heroicons/react/solid';

const PhotographyActions = ({ value, onChange, pendingRecordsCount }) => {
  return (
    <div className="flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 pt-4">
      {/* Main Header Section */}
      <div className="flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-xl shadow-lg dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border">
        {/* Title Section */}
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <PhotographIcon className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Photography Queue</h1>
        </div>

        {/* Pending Records Section */}
        <div className="flex items-center">
          <span className="text-sm font-semibold text-gray-700 dark:text-gray-300">
            Pending Records: {pendingRecordsCount}
          </span>
        </div>
      </div>

      {/* Search Bar Section */}
      <div className="flex flex-col md:flex-row items-center justify-between p-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg border space-y-4 md:space-y-0">
        {/* Search Bar */}
        <div className="relative w-full max-w-md">
          <SearchIcon className="absolute w-5 h-5 text-gray-700 dark:text-gray-300 left-3 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            value={value}
            onChange={onChange}
            className="w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
            placeholder="Search by SKU or Title..."
          />
        </div>
      </div>
    </div>
  );
};

export default PhotographyActions;

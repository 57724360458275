// src/components/Dashboard/Ecommerce/ProductDetailsPage/ManageConnectionsModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// Define the status configurations
const STATUS_CONFIG = {
  draft: {
    color: 'bg-yellow-500',
    label: 'Draft',
    description: 'Ready for publishing once all required fields are complete.',
  },
  active: {
    color: 'bg-green-500',
    label: 'Active',
    description: 'Listing is active and visible on the sales channel.',
  },
  inactive: {
    color: 'bg-red-500',
    label: 'Inactive',
    description: 'Listing is inactive but can be reactivated.',
  },
  disabled: {
    color: 'bg-gray-500',
    label: 'Disabled',
    description: 'Listing is disabled and not visible on the sales channel.',
  },
  relist: {
    color: 'bg-blue-500',
    label: 'Relist',
    description: 'Listing is set to relist and will become active once conditions are met.',
  },
};

// Helper function to determine allowed transitions based on initial status
const getAllowedTransitions = (initialStatus) => {
  switch (initialStatus) {
    case 'draft':
      return ['draft', 'disabled'];
    case 'active':
      return ['active', 'inactive'];
    case 'inactive':
      return ['inactive', 'relist'];
    case 'relist':
      return ['relist', 'active'];
    case 'disabled':
      return ['disabled', 'draft'];
    default:
      return ['disabled'];
  }
};

const ManageConnectionsModal = ({
  isOpen,
  closeModal,
  connections,
  selectedConnections,
  setSelectedConnections,
  darkMode,
  formData = { listToChannels: [] },
  setFormData,
  isEditMode,
  initialListToChannels = [],
}) => {
  const [localSelections, setLocalSelections] = useState({});
  const [initialStatuses, setInitialStatuses] = useState({});
  const modalRef = useRef(null);

  // Initialize local selections and statuses based on initialListToChannels
  useEffect(() => {
    if (initialListToChannels && connections.length > 0) {
      const initialSelections = {};
      const initialStatusesTemp = {};
      connections.forEach((conn) => {
        const channel = initialListToChannels.find(
          (ch) => ch.connectionId === conn.id
        );
        const status = channel ? channel.listingStatus : 'disabled';
        initialSelections[conn.id] = status;
        initialStatusesTemp[conn.id] = status;
      });
      setLocalSelections(initialSelections);
      setInitialStatuses(initialStatusesTemp);
    }
  }, [connections, initialListToChannels]);

  // Close modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCancel();
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Close modal on ESC key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleCancel();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isOpen]);

  // Function to toggle the status of a connection
  const toggleConnection = (connectionId) => {
    setLocalSelections((prev) => {
      const initialStatus = initialStatuses[connectionId];
      const currentStatus = prev[connectionId];
      const allowedTransitions = getAllowedTransitions(initialStatus);

      // Determine the next status to toggle to
      const otherStatus = allowedTransitions.find(
        (status) => status !== currentStatus
      );
      if (otherStatus) {
        return { ...prev, [connectionId]: otherStatus };
      }

      // If no other status is allowed, do not change
      return prev;
    });
  };

  // Handle saving changes
  const handleSave = () => {
    if (!formData?.listToChannels) {
      toast.error('Invalid form data. Please try again.', {
        toastId: 'invalid-form-data',
      });
      return;
    }

    const updatedListToChannels = connections.map((conn) => {
      const newStatus = localSelections[conn.id];
      const existingChannel = formData.listToChannels.find(
        (ch) => ch.connectionId === conn.id
      );

      if (existingChannel) {
        return {
          ...existingChannel,
          listingStatus: newStatus,
        };
      } else {
        return {
          connectionId: conn.id,
          platform: conn.platform,
          listingStatus: newStatus,
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        };
      }
    });

    // Update formData with the new listToChannels
    setFormData((prevData) => ({
      ...prevData,
      listToChannels: updatedListToChannels,
    }));

    // Update selectedConnections based on new statuses
    const updatedSelected = updatedListToChannels
      .filter((ch) => ch.listingStatus !== 'disabled')
      .map((ch) => ch.connectionId);
    setSelectedConnections(updatedSelected);

    toast.success('Sales channels updated successfully!', {
      toastId: 'update-success',
    });
    closeModal();
  };

  // Handle cancel action
  const handleCancel = () => {
    if (initialListToChannels) {
      const resetSelections = {};
      connections.forEach((conn) => {
        const initialStatus = initialStatuses[conn.id];
        resetSelections[conn.id] = initialStatus || 'disabled';
      });
      setLocalSelections(resetSelections);
    }
    closeModal();
  };

  // Determine which status definitions to show based on current selections
  const getRelevantStatuses = () => {
    const statuses = new Set();
    Object.values(localSelections).forEach((status) => statuses.add(status));
    return Array.from(statuses)
      .map((status) => STATUS_CONFIG[status])
      .filter(Boolean);
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex justify-end" // Added justify-end to align modal to the right
      aria-modal="true"
      role="dialog"
      aria-labelledby="modal-title"
    >
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        aria-hidden="true"
        onClick={handleCancel}
      ></div>

      {/* Drawer */}
      <div
        ref={modalRef}
        className={`relative w-full max-w-2xl bg-white dark:bg-gray-800 shadow-xl transform transition-transform duration-300 ease-in-out ${
          // Slide in from right
          isOpen ? 'translate-x-0' : 'translate-x-full'
          } flex flex-col h-full`}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200 dark:border-gray-700">
          <h2 id="modal-title" className="text-xl font-semibold text-gray-800 dark:text-gray-100">
            Manage Sales Channels
          </h2>
          <button
            onClick={handleCancel}
            className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-gray-100"
            aria-label="Close modal"
          >
            <XIcon className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Description */}
        <div className="p-4">
          <p className="text-gray-700 dark:text-gray-300">
            Adjust the status of each sales channel to control where your product is listed. Changing a channel's status will
            determine its visibility and activity on that platform.
          </p>
        </div>

        {/* Status Definitions */}
        <div className="px-4">
          <h3 className="text-lg font-medium text-gray-800 dark:text-gray-100 mb-2">Status Definitions:</h3>
          <ul className="space-y-2">
            {getRelevantStatuses().map((status) => (
              <li key={status.label} className="flex items-start">
                <span
                  className={`inline-block h-4 w-4 ${status.color} rounded-full mt-1 mr-2`}
                ></span>
                <div>
                  <strong className="text-gray-800 dark:text-gray-100">{status.label}:</strong>
                  <p className="text-gray-600 dark:text-gray-300">{status.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Connections List */}
        <div className="flex-1 overflow-y-auto px-4 py-2">
          {connections.length === 0 ? (
            <p className="text-gray-700 dark:text-gray-300">No sales channels available.</p>
          ) : (
            connections.map((conn) => {
              const status = localSelections[conn.id];
              const config = STATUS_CONFIG[status] || STATUS_CONFIG['disabled'];

              // Determine if toggling is allowed based on mode and current status
              let isToggleAllowed = false;
              if (!isEditMode) {
                // Creation Mode: Only allow toggling between draft and disabled
                isToggleAllowed = ['draft', 'disabled'].includes(status);
              } else {
                // Edit Mode: Allow specific transitions based on initial status
                isToggleAllowed = ['draft', 'active', 'inactive', 'relist', 'disabled'].includes(status);
              }

              return (
                <div
                  key={conn.id}
                  className={`flex items-center justify-between p-3 mb-3 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-100'
                    }`}
                >
                  <div className="flex items-center space-x-3">
                    <span
                      className={`h-5 w-5 ${config.color} rounded-full`}
                      title={config.label}
                    ></span>
                    <div>
                      <h4 className="text-md font-medium text-gray-800 dark:text-gray-100">
                        {conn.accountName}
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        {conn.platform}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className="text-sm text-gray-700 dark:text-gray-300">{config.label}</span>
                    {isToggleAllowed ? (
                      <button
                        onClick={() => toggleConnection(conn.id)}
                        className={`relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                          // Use Tailwind classes for colors based on status
                          (status === 'active' || status === 'relist')
                            ? 'bg-green-500'
                            : status === 'inactive'
                              ? 'bg-red-500'
                              : status === 'draft'
                                ? 'bg-yellow-500'
                                : 'bg-gray-500'
                          } transition-colors duration-200 ease-in-out`}
                        aria-pressed={status !== 'disabled'}
                        aria-label={`Toggle ${conn.accountName} to ${config.label}`}
                      >
                        <span
                          className={`inline-block w-4 h-4 bg-white rounded-full transition-transform ${['active', 'relist', 'inactive', 'draft'].includes(status)
                              ? 'translate-x-6'
                              : 'translate-x-1'
                            }`}
                        />
                      </button>
                    ) : (
                      <span className="text-xs text-gray-400">Cannot Toggle</span>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>

        {/* Action Buttons */}
        <div className="px-4 py-3 border-t border-gray-200 dark:border-gray-700 flex justify-end space-x-3">
          <button
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Save Changes
          </button>
        </div>
      </div>

      
    </div>
  );
};

// Define PropTypes for better type checking
ManageConnectionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      accountName: PropTypes.string.isRequired,
      platform: PropTypes.string.isRequired,
      marketplaceId: PropTypes.string,
    })
  ).isRequired,
  selectedConnections: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedConnections: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    listToChannels: PropTypes.arrayOf(
      PropTypes.shape({
        connectionId: PropTypes.string.isRequired,
        platform: PropTypes.string.isRequired,
        listingStatus: PropTypes.string.isRequired,
        marketplaceId: PropTypes.string,
      })
    ),
  }),
  setFormData: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  initialListToChannels: PropTypes.arrayOf(
    PropTypes.shape({
      connectionId: PropTypes.string.isRequired,
      listingStatus: PropTypes.string.isRequired,
      marketplaceId: PropTypes.string,
    })
  ),
};

export default ManageConnectionsModal;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subscriptionService from './subscriptionService';

const initialState = {
  subscription: JSON.parse(localStorage.getItem('subscription')) || null,
  selectedPlan: JSON.parse(localStorage.getItem('selectedPlan')) || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Async thunks
export const getSubscription = createAsyncThunk(
  'subscription/get',
  async (userId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.fetchSubscription(userId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  'subscription/create',
  async (subscriptionData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.createSubscription(subscriptionData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  'subscription/update',
  async ({ subscriptionId, newPlanId, subscriptionItemId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.updateSubscription(
        { subscriptionId, newPlanId, subscriptionItemId },
        token
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  'subscription/cancel',
  async ({ subscriptionId, subscriptionItemId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await subscriptionService.cancelSubscriptionItem(subscriptionId, subscriptionItemId, token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetSubscriptionState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    setSelectedTier: (state, action) => {
      state.selectedPlan = action.payload;
      localStorage.setItem('selectedPlan', JSON.stringify(action.payload));
    },
    setSelectedPlanFromStorage: (state, action) => {
      state.subscription = action.payload.subscription;
      state.selectedPlan = action.payload.selectedPlan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload;
        localStorage.setItem('subscription', JSON.stringify(action.payload));
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.subscription = null;
        localStorage.removeItem('subscription');
      })
      .addCase(createSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload;
        localStorage.setItem('subscription', JSON.stringify(action.payload));
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = action.payload;
        localStorage.setItem('subscription', JSON.stringify(action.payload));
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subscription = null;
        localStorage.removeItem('subscription');
        localStorage.removeItem('selectedPlan');
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  resetSubscriptionState,
  setSelectedTier,
  setSelectedPlanFromStorage,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

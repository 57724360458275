import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const OrderRevenueOverTimeChart = ({ data }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  // Prepare chart data and options
  const chartData = {
    series: [{
      name: 'Daily Revenue',
      data: data.map((entry) => entry.revenue),
    }],
    categories: data.map((entry) => entry.date),
  };

  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: { show: false },
      background: 'transparent',
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: chartData.categories,
      labels: { style: { colors: textColor } },
      title: { text: 'Date', style: { color: textColor } },
    },
    yaxis: {
      labels: { style: { colors: textColor } },
      title: { text: 'Revenue ($)', style: { color: textColor } },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: ['#FF4560', '#775DD0'],
        inverseColors: false,
        stops: [0, 100],
      },
    },
    tooltip: {
      theme: themeMode,
      x: { format: 'yyyy-MM-dd' },
    },
    theme: { mode: themeMode },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4 text-xl font-bold text-gray-800 dark:text-white">
        Order Revenue Over Time (Last 60 Days)
      </div>
      <Chart options={chartOptions} series={chartData.series} type="line" height={300} />
    </div>
  );
};

export default OrderRevenueOverTimeChart;

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Lottie from 'react-lottie'

import animationData from '../lottie/check_mark_animation.json'
import { verifyUser, reset } from '../features/auth/authSlice'
import {
  PolygonBackground1,
  PolygonBackground2,
} from '../components/PolygonBackground'

function VerificationPage() {
  const [authToken, setAuthToken] = useState('')
  const defaultOptions = {
    autoplay: true,
    loop: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const { unverifiedUser, isLoading, isError, message, user } = useSelector(
    (state) => state.auth
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [errorShake, setErrorShake] = useState(false)

  const handleVerificationSubmit = async (e) => {
    e.preventDefault()
    try {
      const userData = {
        email: unverifiedUser.email,
        name: unverifiedUser.name,
        authToken: authToken,
        is2FA: false, // This is for initial account verification, 2fa uses the same backend point so need to distinguish between the two
      }
      dispatch(verifyUser(userData))
    } catch (error) {
      console.error('Error verifying code:', error)
    }
  }

  useEffect(() => {
    if (isError) {
      console.error(message)
      setErrorShake(true)

      setTimeout(() => {
        setErrorShake(false)
      }, 800)
    }
    if (user) {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
    dispatch(reset())
  }, [message, user, dispatch, navigate, isError])

  return (
    <div className='w-screen mx-auto flex justify-center pt-[200px] dark:bg-gray-900'>
      <PolygonBackground1 />
      <div className='container mx-auto p-8'>
        <div className='max-w-md w-full mx-auto form-container h-screen pt-10'>
          <h1 className='text-5xl text-center mb-12 font-bold dark:text-white'>Verification</h1>
          <div
            className={`bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white rounded-lg overflow-hidden shadow-2xl border border-gray-300 ${errorShake ? 'shake' : ''}`}
          >
            {user ? (
              <div className='text-center'>
                <h2>Verification Successful. Redirecting you to Home Page</h2>
                <Lottie options={defaultOptions} height={400} width={400} />
              </div>
            ) : (
              <form onSubmit={handleVerificationSubmit}>
                <div className='p-8'>
                  <h2 className='mb-6 dark:text-gray-300'>Enter Verification Code</h2>
                  <div className='mb-6'>
                    <input
                      type='text'
                      value={authToken}
                      onChange={(e) => setAuthToken(e.target.value)}
                      className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 border border-transparent focus:outline-none hover:shadow-md'
                    />
                  </div>
                  <button className='w-full p-3 mt-4 bg-indigo-600 dark:bg-indigo-500 text-white rounded shadow hover:bg-indigo-700 dark:hover:bg-indigo-600'>
                    {isLoading ? 'Verifying...' : 'Verify'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <PolygonBackground2 />
    </div>
  )
}

export default VerificationPage

import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  XCircleIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  ShoppingCartIcon,
  CheckCircleIcon,
  UserIcon,
  BadgeCheckIcon,
  ClipboardListIcon,
  PlusCircleIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';

const PurchasesModal = ({ isOpen, onClose, purchase = {}, onSave, isNew }) => {
  const [themeMode, setThemeMode] = useState(localStorage.getItem('theme') === 'dark' ? 'dark' : 'light');
  const [editableItems, setEditableItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [purchaseComments, setPurchaseComments] = useState(purchase?.purchaseComments || '');
  const [purchaseDetails, setPurchaseDetails] = useState({
    orderNumber: purchase?.orderNumber || '',
    sellerUsername: purchase?.sellerUsername || '',
    sellerFeedback: purchase?.sellerFeedback || '',
    purchasePrice: purchase?.purchasePrice || 0,
    platformOrigin: purchase?.platformOrigin || '',
    timestamp: purchase?.timestamp || new Date().toISOString(),
  });
  const [editMode, setEditMode] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (purchase) {
      setEditableItems(purchase.items || []);
      setPurchaseComments(purchase.purchaseComments || '');
      setPurchaseDetails({
        orderNumber: purchase?.orderNumber || '',
        sellerUsername: purchase?.sellerUsername || '',
        sellerFeedback: purchase?.sellerFeedback || '',
        purchasePrice: purchase?.purchasePrice || 0,
        platformOrigin: purchase?.platformOrigin || '',
        timestamp: purchase?.timestamp || new Date().toISOString(),
      });
    }
  }, [purchase]);

  // Track unsaved changes in any purchase details or comments
  useEffect(() => {
    setUnsavedChanges(true);
  }, [purchaseComments, purchaseDetails, editableItems, newItems]);

  const handleAddItem = () => {
    setNewItems([
      ...newItems,
      {
        brand: '',
        model: '',
        description: '',
        category: '',
        quantityPurchased: 0,
        purchasePrice: 0,
        resellPrice: 0,
        validationStatus: 'inbound',
        convertedToInventory: false,
        condition: 'Select-Condition',
      },
    ]);
    setUnsavedChanges(true);
  };

  const handleItemChange = (index, key, value, isEditable = true) => {
    setUnsavedChanges(true);
    if (isEditable) {
      const updatedItems = [...editableItems];
      updatedItems[index] = { ...updatedItems[index], [key]: value };
      setEditableItems(updatedItems);
    } else {
      const updatedItems = [...newItems];
      updatedItems[index] = { ...updatedItems[index], [key]: value };
      setNewItems(updatedItems);
    }
  };

  const handleDeleteItem = (index, isEditable = true) => {
    setUnsavedChanges(true);
    if (isEditable) {
      const updatedItems = [...editableItems];
      updatedItems.splice(index, 1);
      setEditableItems(updatedItems);
    } else {
      const updatedItems = [...newItems];
      updatedItems.splice(index, 1);
      setNewItems(updatedItems);
    }
  };

  const handleDetailsChange = (key, value) => {
    setUnsavedChanges(true);
    setPurchaseDetails((prevDetails) => ({ ...prevDetails, [key]: value }));
  };

  const handleSaveChanges = () => {
    const updatedPurchase = {
      ...purchase,
      ...purchaseDetails,
      items: [...editableItems, ...newItems],
      purchaseComments,
    };
    onSave(updatedPurchase);
    setUnsavedChanges(false);
    onClose();
  };

  const handleEditToggle = (index, isEditable = true) => {
    setEditMode((prev) => ({
      ...prev,
      [isEditable ? `editable-${index}` : `new-${index}`]: !prev[isEditable ? `editable-${index}` : `new-${index}`],
    }));
  };

  const handleClose = () => {
    if (unsavedChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmCloseWithoutSaving = () => {
    setShowConfirmation(false);
    setUnsavedChanges(false);
    onClose();
  };

  const cancelClose = () => {
    setShowConfirmation(false);
  };

  const formatPrice = (price) => (typeof price === 'number' ? price.toFixed(2) : 'N/A');

  const totalItems = editableItems.reduce((total, item) => total + item.quantityPurchased, 0) || 0;
  const totalSoldItems = editableItems.reduce((total, item) => total + (item.soldQuantity || 0), 0) || 0;

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen px-4">
          <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-6xl mx-auto overflow-hidden border">
            <div className="bg-gradient-to-r from-indigo-500 to-indigo-600 dark:from-gray-800 dark:to-gray-900 p-5 rounded-t-lg flex items-center justify-between border-b">
              <h3 className="text-white font-semibold">
                {isNew ? 'Create New Purchase' : `Purchase Details | Order # ${purchaseDetails.orderNumber}`}
              </h3>
              <div className="flex items-center space-x-3">
                <button
                  onClick={() => setIsEditingDetails(!isEditingDetails)}
                  className="flex items-center px-3 py-2 bg-indigo-700 text-white rounded-md hover:bg-gray-800 transition"
                >
                  <PencilIcon className="h-5 w-5 mr-1" />
                  {isEditingDetails ? 'Save Details' : 'Edit Purchase Details'}
                </button>
                <button onClick={handleClose} className="text-white focus:outline-none">
                  <XCircleIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div className="p-6 max-h-[75vh] overflow-y-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-b pb-4 mb-4">
                {isEditingDetails ? (
                  <>
                    <div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Order Number:</label>
                        <input
                          type="text"
                          value={purchaseDetails.orderNumber}
                          onChange={(e) => handleDetailsChange('orderNumber', e.target.value)}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Seller Username:</label>
                        <input
                          type="text"
                          value={purchaseDetails.sellerUsername}
                          onChange={(e) => handleDetailsChange('sellerUsername', e.target.value)}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Seller Feedback:</label>
                        <input
                          type="text"
                          value={purchaseDetails.sellerFeedback}
                          onChange={(e) => handleDetailsChange('sellerFeedback', e.target.value)}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Order Total:</label>
                        <input
                          type="number"
                          value={purchaseDetails.purchasePrice}
                          onChange={(e) => handleDetailsChange('purchasePrice', Number(e.target.value))}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Platform Origin:</label>
                        <input
                          type="text"
                          value={purchaseDetails.platformOrigin}
                          onChange={(e) => handleDetailsChange('platformOrigin', e.target.value)}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                      <div className="flex flex-col text-gray-700 dark:text-gray-300 mb-4">
                        <label className="font-semibold">Timestamp:</label>
                        <input
                          type="datetime-local"
                          value={new Date(purchaseDetails.timestamp).toISOString().slice(0, -1)}
                          onChange={(e) => handleDetailsChange('timestamp', e.target.value)}
                          className="bg-gray-50 dark:bg-gray-700 rounded-md p-2"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <ClipboardListIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Order Number:</strong>
                        <span>{purchaseDetails.orderNumber || 'N/A'}</span>
                      </div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <UserIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Seller Username:</strong>
                        <span>{purchaseDetails.sellerUsername || 'N/A'}</span>
                      </div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <BadgeCheckIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Seller Feedback:</strong>
                        <span>{purchaseDetails.sellerFeedback || 'N/A'}</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <CurrencyDollarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Order Total:</strong>
                        <span>${purchaseDetails.purchasePrice?.toFixed(2) || 'N/A'}</span>
                      </div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <ShoppingCartIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Platform Origin:</strong>
                        <span>{purchaseDetails.platformOrigin || 'N/A'}</span>
                      </div>
                      <div className="flex items-center text-gray-700 dark:text-gray-300 mb-2">
                        <CalendarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                        <strong className="mr-1">Timestamp:</strong>
                        <span>{new Date(purchaseDetails.timestamp).toLocaleString()}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <div className="pr-4 lg:border-r-2">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Items</h3>
                    <button
                      onClick={handleAddItem}
                      className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
                    >
                      <PlusCircleIcon className="h-5 w-5 mr-1" />
                      Add Item
                    </button>
                  </div>
                  <div className="flex overflow-x-auto space-x-4 pb-4">
                    {editableItems.map((item, index) => (
                      <div
                        key={index}
                        className="bg-gradient-to-r from-white to-gray-100 border dark:from-gray-800 dark:to-gray-900 rounded-lg shadow p-4 flex flex-col space-y-2 min-w-[250px]"
                      >
                        {editMode[`editable-${index}`] ? (
                          <Fragment>
                            <div className="relative">
                              <div className="absolute top-0 right-0 flex items-center space-x-2">
                                <CheckIcon
                                  onClick={() => handleEditToggle(index, true)}
                                  className="h-5 w-5 text-green-500 cursor-pointer"
                                />
                                <TrashIcon
                                  onClick={() => handleDeleteItem(index, true)}
                                  className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                              </div>
                              <div className="text-gray-700 dark:text-gray-300 space-y-2">
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Brand:</strong>
                                  <input
                                    type="text"
                                    value={item.brand}
                                    onChange={(e) => handleItemChange(index, 'brand', e.target.value)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Model:</strong>
                                  <input
                                    type="text"
                                    value={item.model}
                                    onChange={(e) => handleItemChange(index, 'model', e.target.value)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Description:</strong>
                                  <input
                                    type="text"
                                    value={item.description}
                                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Category:</strong>
                                  <input
                                    type="text"
                                    value={item.category}
                                    onChange={(e) => handleItemChange(index, 'category', e.target.value)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CurrencyDollarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                                  <strong className="mr-1">Purchase Price:</strong>
                                  <input
                                    type="number"
                                    value={item.purchasePrice}
                                    onChange={(e) => handleItemChange(index, 'purchasePrice', Number(e.target.value))}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CurrencyDollarIcon className="h-5 w-5 text-green-500 mr-2" />
                                  <strong className="mr-1">Resell Price:</strong>
                                  <input
                                    type="number"
                                    value={item.resellPrice}
                                    onChange={(e) => handleItemChange(index, 'resellPrice', Number(e.target.value))}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Condition:</strong>
                                  <select
                                    value={item.expectedCondition}
                                    onChange={(e) => handleItemChange(index, 'condition', e.target.value)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  >
                                    <option value="Select-Condition">Select Condition</option>
                                    <option value="NEW">New</option>
                                    <option value="USED_EXCELLENT">Used - Excellent</option>
                                    <option value="NEW_OTHER">New Other</option>
                                    <option value="FOR_PARTS_OR_NOT_WORKING">For Parts Only</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className="relative">
                              <div className="absolute top-0 right-0 flex items-center space-x-2">
                                <PencilIcon
                                  onClick={() => handleEditToggle(index, true)}
                                  className="h-5 w-5 text-indigo-500 cursor-pointer"
                                />
                                <TrashIcon
                                  onClick={() => handleDeleteItem(index, true)}
                                  className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                              </div>
                              <div className="text-gray-700 dark:text-gray-300 space-y-2">
                                <p className="truncate">
                                  <strong>Brand:</strong> {item.brand || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Model:</strong> {item.model || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Description:</strong> {item.description || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Category:</strong> {item.category || 'N/A'}
                                </p>
                                <p>
                                  <strong>Purchase Price:</strong> ${formatPrice(item.purchasePrice)}
                                </p>
                                <p>
                                  <strong>Resell Price:</strong> ${formatPrice(item.resellPrice)}
                                </p>
                                <p>
                                  <strong>Condition:</strong> {item.expectedCondition || 'N/A'}
                                </p>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    ))}
                    {newItems.map((item, index) => (
                      <div
                        key={index}
                        className="bg-gradient-to-r from-white to-gray-100 border dark:from-gray-800 dark:to-gray-900 rounded-lg shadow p-4 flex flex-col space-y-2 min-w-[250px]"
                      >
                        {editMode[`new-${index}`] ? (
                          <Fragment>
                            <div className="relative">
                              <div className="absolute top-0 right-0 flex items-center space-x-2">
                                <CheckIcon
                                  onClick={() => handleEditToggle(index, false)}
                                  className="h-5 w-5 text-green-500 cursor-pointer"
                                />
                                <TrashIcon
                                  onClick={() => handleDeleteItem(index, false)}
                                  className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                              </div>
                              <div className="text-gray-700 dark:text-gray-300 space-y-2">
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Brand:</strong>
                                  <input
                                    type="text"
                                    value={item.brand}
                                    onChange={(e) => handleItemChange(index, 'brand', e.target.value, false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Model:</strong>
                                  <input
                                    type="text"
                                    value={item.model}
                                    onChange={(e) => handleItemChange(index, 'model', e.target.value, false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Description:</strong>
                                  <input
                                    type="text"
                                    value={item.description}
                                    onChange={(e) => handleItemChange(index, 'description', e.target.value, false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Category:</strong>
                                  <input
                                    type="text"
                                    value={item.category}
                                    onChange={(e) => handleItemChange(index, 'category', e.target.value, false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CurrencyDollarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                                  <strong className="mr-1">Purchase Price:</strong>
                                  <input
                                    type="number"
                                    value={item.purchasePrice}
                                    onChange={(e) => handleItemChange(index, 'purchasePrice', Number(e.target.value), false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CurrencyDollarIcon className="h-5 w-5 text-green-500 mr-2" />
                                  <strong className="mr-1">Resell Price:</strong>
                                  <input
                                    type="number"
                                    value={item.resellPrice}
                                    onChange={(e) => handleItemChange(index, 'resellPrice', Number(e.target.value), false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  />
                                </div>
                                <div className="flex items-center space-x-2">
                                  <strong className="mr-1">Condition:</strong>
                                  <select
                                    value={item.expectedCondition}
                                    onChange={(e) => handleItemChange(index, 'condition', e.target.value, false)}
                                    className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                  >
                                    <option value="Select-Condition">Select Condition</option>
                                    <option value="NEW">New</option>
                                    <option value="USED_EXCELLENT">Used - Excellent</option>
                                    <option value="NEW_OTHER">New Other</option>
                                    <option value="FOR_PARTS_OR_NOT_WORKING">For Parts Only</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className="relative">
                              <div className="absolute top-0 right-0 flex items-center space-x-2">
                                <PencilIcon
                                  onClick={() => handleEditToggle(index, false)}
                                  className="h-5 w-5 text-indigo-500 cursor-pointer"
                                />
                                <TrashIcon
                                  onClick={() => handleDeleteItem(index, false)}
                                  className="h-5 w-5 text-red-500 cursor-pointer"
                                />
                              </div>
                              <div className="text-gray-700 dark:text-gray-300 space-y-2">
                                <p className="truncate">
                                  <strong>Brand:</strong> {item.brand || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Model:</strong> {item.model || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Description:</strong> {item.description || 'N/A'}
                                </p>
                                <p className="truncate">
                                  <strong>Category:</strong> {item.category || 'N/A'}
                                </p>
                                <p>
                                  <strong>Purchase Price:</strong> ${formatPrice(item.purchasePrice)}
                                </p>
                                <p>
                                  <strong>Resell Price:</strong> ${formatPrice(item.resellPrice)}
                                </p>
                                <p>
                                  <strong>Condition:</strong> {item.expectedCondition || 'N/A'}
                                </p>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col space-y-4 ">
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Purchase Comments</h4>
                    <textarea
                      value={purchaseComments}
                      onChange={(e) => setPurchaseComments(e.target.value)}
                      rows="6"
                      className="bg-gray-50 dark:bg-gray-700 dark:text-white rounded-md p-2 w-full mt-2"
                    />
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Summary</h4>
                    <div className="flex items-center mb-2">
                      <ShoppingCartIcon className="h-5 w-5 text-indigo-500 mr-2" />
                      <p className="text-gray-700 dark:text-gray-300">
                        <strong className="mr-1">Total Items:</strong> {totalItems}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                      <p className="text-gray-700 dark:text-gray-300">
                        <strong className="mr-1">Total Sold Items:</strong> {totalSoldItems}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 flex justify-between">
              <button
                className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                onClick={handleSaveChanges}
              >
                Submit Changes
              </button>
              <button
                className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>

        {showConfirmation && (
          <Transition show={showConfirmation} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={cancelClose}>
              <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />
                <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full mx-auto p-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Unsaved Changes</h3>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    You have unsaved changes. Do you want to save them before closing?
                  </p>
                  <div className="mt-4 flex justify-end space-x-3">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                      onClick={confirmCloseWithoutSaving}
                    >
                      Don't Save
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={cancelClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </Dialog>
    </Transition>
  );
};

export default PurchasesModal;

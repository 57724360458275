import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const TopOrderSearchTermsChart = ({ data }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  // Sort data in descending order based on totalRevenue
  const sortedData = [...data].sort((a, b) => b.totalRevenue - a.totalRevenue);

  const chartData = {
    categories: sortedData.map(item => item._id),
    series: [{
      name: 'Total Revenue',
      data: sortedData.map(item => item.totalRevenue),
    }],
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: { style: { colors: textColor } },
    },
    yaxis: { labels: { style: { colors: textColor } } },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        gradientToColors: ['#F15A29', '#FF4560', '#775DD0'],
        stops: [0, 50, 100],
      },
    },
    theme: { mode: themeMode },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4 text-xl font-bold text-gray-800 dark:text-white">
        Top Order Search Terms by Revenue
      </div>
      <Chart options={chartOptions} series={chartData.series} type="bar" height={300} />
    </div>
  );
};

export default TopOrderSearchTermsChart;

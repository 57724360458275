import React from 'react'
import { useSelector } from 'react-redux'
import { TrendingUpIcon, TrendingDownIcon } from '@heroicons/react/solid'
import {
  SearchIcon,
  ArchiveIcon,
  EyeIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline'

const formatNumber = (value) => {
  if (!isNaN(value)) {
    return new Intl.NumberFormat('en-US').format(value)
  }
  return value
}

const MetricCard = ({ icon: Icon, title, value, trend, timeframe = '' }) => {
  const TrendIcon =
    trend && trend.startsWith('-') ? TrendingDownIcon : TrendingUpIcon
  const formattedValue = formatNumber(value)
  const trendText = trend ? `${trend} change in the last ${timeframe}` : 'N/A'

  return (
    <div className='flex flex-col justify-between bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6'>
      <div className='flex items-center mb-4'>
        <div className='flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 dark:bg-indigo-600 text-indigo-600 dark:text-indigo-100'>
          <Icon className='h-6 w-6' />
        </div>
        <div className='ml-4 flex-1'>
          <h3 className='text-lg font-semibold text-gray-700 dark:text-gray-300'>
            {title}
          </h3>
          <p className='text-sm text-gray-500 dark:text-gray-400'>
            {trendText}
          </p>
        </div>
      </div>
      <div className='flex items-baseline'>
        <span className='text-3xl font-bold text-gray-900 dark:text-white'>
          {formattedValue}
        </span>
        <span
          className={`ml-2 inline-flex items-center ${trend && trend.startsWith('-') ? 'text-red-600' : 'text-green-600'}`}
        >
          <TrendIcon className='ml-1 w-5 h-5' />
          <span className='ml-1 text-sm'>{trend}</span>
        </span>
      </div>
    </div>
  )
}

const SummaryMetrics = () => {
  const {
    searchTermsMetrics,
    arbitrageOpportunitiesMetrics,
    inventoryGrowthMetrics,
    analyzedListingsMetrics,
  } = useSelector((state) => state.metrics)

  return (
    <div className=' grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4'>
      <MetricCard
        icon={SearchIcon}
        title='Automated Search Terms'
        value={formatNumber(searchTermsMetrics.totalSearchTermsCount || 0)}
        trend={searchTermsMetrics.percentChange || '0%'}
        timeframe='30 days'
      />
      <MetricCard
        icon={ArchiveIcon}
        title='Total Inventory Items'
        value={formatNumber(inventoryGrowthMetrics.totalInventoryItems || 0)}
        trend={inventoryGrowthMetrics.percentChange || '0%'}
        timeframe='30 days'
      />
      <MetricCard
        icon={EyeIcon}
        title='Arbi-Found Items'
        value={formatNumber(analyzedListingsMetrics.recentAnalyzedCount || 0)}
        trend={analyzedListingsMetrics.percentChange || '0%'}
        timeframe='30 days'
      />
      <MetricCard
        icon={LightningBoltIcon}
        title='Strong Arbitrage Opportunities'
        value={formatNumber(
          arbitrageOpportunitiesMetrics.currentPeriodCount || 0
        )}
        trend={arbitrageOpportunitiesMetrics.percentChange || '0%'}
        timeframe='30 days'
      />
    </div>
  )
}

export default SummaryMetrics

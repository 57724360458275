import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Filter from 'bad-words'
import { toast, ToastContainer } from 'react-toastify'
import CryptoJS from 'crypto-js'


import { register, setMessage, setError } from '../features/auth/authSlice'
import {
  PolygonBackground1,
  PolygonBackground2,
} from '../components/PolygonBackground'
import 'react-toastify/dist/ReactToastify.css'

function Register() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [company, setCompany] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [dob, setDOB] = useState(null)
  const { user, isLoading, isError, isSuccess, message, bypassSuccessful } = useSelector(
    (state) => state.auth
  )
  const navigate = useNavigate()
  const profanityFilter = new Filter()
  const dispatch = useDispatch()
  const [errorShake, setErrorShake] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  useEffect(() => {
    if (isError) {
      console.error(message)
      setErrorShake(true)

      setTimeout(() => {
        dispatch(setError(true))
        setErrorShake(false)
      }, 800)
    }
    if (isSuccess || user) {
      if (bypassSuccessful) {
        navigate('/register')
      } else {
        navigate('/verification')
      }
    }
  }, [user, isError, isSuccess, message, bypassSuccessful, navigate, dispatch])

  const handleRegistrationSuccess = () => {
    toast.success('Registration successful. Verification email sent.', {
      position: 'top-right',
      autoClose: 5000,
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      dispatch(setError(true))
      dispatch(setMessage('Invalid Email'))
      return
    }

    if (!name || !email || !password || !dob || !phoneNumber) {
      dispatch(setError(true))
      dispatch(setMessage('Please Fill in Required Fields'))
      return
    }

    const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
    const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();

    const userData = {
      name,
      email,
      password,
      dob,
      phoneNumber,
      company,
      encryptedPassword,
    }

    for (const key in userData) {
      if (profanityFilter.isProfane(userData[key])) {
        dispatch(setError(true))
        dispatch(
          setMessage('Inappropriate content detected. Please revise the input.')
        )
        return
      }
    }

    try {
      dispatch(register(userData))
      if (isSuccess) {
        handleRegistrationSuccess()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    )
  }

  return (
    <div className='w-screen mx-auto flex justify-center items-center relative h-screen'>
      <ToastContainer position='top-right' autoClose={5000} />
      <PolygonBackground1 />
      <div className='container mx-auto p-8 '>
        <div className='max-w-md w-full mx-auto form-container h-screen pt-10'>
          <h1 className='text-5xl text-center mb-12 font-bold'>Register</h1>
          <div
            className={`bg-white rounded-lg overflow-hidden shadow-2xl border border-gray-300 ${errorShake ? 'shake' : ''}`}
          >
            <div className='p-8'>
              <form onSubmit={onSubmit}>
                <div className='mb-6'>
                  <label
                    htmlFor='name'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Name*
                  </label>
                  <input
                    type='text'
                    id='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='email'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Email*
                  </label>
                  <input
                    type='text'
                    id='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='phoneNumber'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Phone Number*
                  </label>
                  <input
                    type='text'
                    id='phoneNumber'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='dob'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Date of Birth*
                  </label>
                  <input
                    type='date'
                    id='dob'
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                    className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>
                <div className='mb-6'>
                  <label
                    htmlFor='company'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Company Name (Optional)
                  </label>
                  <input
                    type='text'
                    id='company'
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                  />
                </div>

                <div className='mb-6'>
                  <label
                    htmlFor='password'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Password *
                  </label>
                  <div className='relative w-full'>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      id='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                    />
                  </div>
                </div>

                <div className='mb-6'>
                  <label
                    htmlFor='confirmPassword'
                    className='block mb-2 text-sm font-medium text-gray-600'
                  >
                    Confirm Password *
                  </label>
                  <div className='relative w-full'>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      id='confirmPassword'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className='block w-full p-3 rounded bg-gray-200 border border-transparent focus:outline-none hover:shadow-md'
                    />
                    <div className='absolute inset-y-0 right-0 flex items-center px-2'>
                      <input
                        id='toggleConfirmPassword'
                        type='checkbox'
                        className='hidden js-password-toggle'
                        onChange={toggleConfirmPasswordVisibility}
                      />
                      <label
                        htmlFor='toggleConfirmPassword'
                        className='bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label'
                      >
                        {showConfirmPassword ? 'hide' : 'show'}
                      </label>
                    </div>
                  </div>
                </div>

                <button
                  className='w-full p-3 mt-4 bg-indigo-600 text-white rounded shadow hover:bg-indigo-700'
                  disabled={password !== confirmPassword}
                >
                  {isLoading ? 'Registering Account...' : 'Register'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <PolygonBackground2 />
    </div>
  )
}

export default Register

// ProductDetailsPage.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import {
  getInventoryItem,
  updateItem,
  addInventoryItem,
  clearItemDetails,
} from '../../features/inventory/inventorySlice';
import { fetchConnections } from '../../features/connections/connectionSlice';
import Spinner from '../../components/Spinner';
import ProductBasicDetails from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductBasicDetails';
import ProductOrganization from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductOrganization';
import ProductConnections from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductConnections';
import ProductMediaSection from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ProductMediaSection';
import ManageConnectionsModal from '../../components/Dashboard/Ecommerce/ProductDetailsPage/ManageConnectionsModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import selectors for custom fields
import {
  selectMergedCustomFields,
  selectCustomFieldsPayload,
  selectMissingCustomFields,
} from '../../features/inventory/inventorySelectors';

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { itemDetails, loading, error } = useSelector((state) => state.inventory);
  const { connections, loading: connectionsLoading } = useSelector((state) => state.connection);

  // Import merged custom fields from selector
  const mergedCustomFields = useSelector(selectMergedCustomFields);
  const customFieldsPayload = useSelector(selectCustomFieldsPayload);
  const missingCustomFields = useSelector(selectMissingCustomFields);

  // Dark mode state
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );
  useEffect(() => {
    const handleThemeChange = () => {
      setDarkMode(localStorage.getItem('theme') === 'dark');
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  // Initialize formData with default structure
  const [formData, setFormData] = useState({
    basicDetails: {
      brand: '',
      model: '',
      condition: 'Select Condition',
      quantity: 1,
      item_description: '',
      dimensions: { length: '', width: '', height: '', unit: 'INCH' },
      weight: { value: '', unit: 'POUND' },
      shippingMethods: ['UPSGround'],
      price: 0,
      additional_info: '',
      bestOffer: false,
      acceptsReturns: true,
      autoAcceptPrice: 0,
      autoDeclinePrice: 0,
    },
    category: '',
    listToChannels: [],
    photographyStatus: 'pending',
    recordCompletionStatus: 'draft',
  });
  const [formErrors, setFormErrors] = useState({});
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission

  const isEditMode = Boolean(productId);
  const pageTitle = isEditMode ? 'Edit Product' : 'Create New Product';

  // New state to preserve initial listToChannels
  const [initialListToChannels, setInitialListToChannels] = useState([]);

  // Modal State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Clear item details if creating a new product
  useEffect(() => {
    if (!isEditMode) {
      dispatch(clearItemDetails());
    }
  }, [dispatch, isEditMode]);

  // Fetch inventory item and connections on mount or when productId changes
  useEffect(() => {
    if (isEditMode) {
      dispatch(getInventoryItem(productId));
    }
    dispatch(fetchConnections());
  }, [dispatch, productId, isEditMode]);

  // Populate formData and initialListToChannels when itemDetails are loaded (Edit Mode)
  useEffect(() => {
    if (itemDetails && isEditMode) {
      setFormData((prevData) => ({
        ...prevData,
        basicDetails: {
          brand: itemDetails.brand || '',
          model: itemDetails.model || '',
          item_description: itemDetails.item_description || '',
          condition: itemDetails.condition || 'Select Condition',
          quantity: itemDetails.quantity || 1,
          dimensions:
            itemDetails.dimensions || {
              length: '',
              width: '',
              height: '',
              unit: 'INCH',
            },
          weight: itemDetails.weight || { value: '', unit: 'POUND' },
          shippingMethods: itemDetails.shippingMethods || ['UPSGround'],
          additional_info: itemDetails.additional_info || '',
          price: itemDetails.price || 0,
          bestOffer: itemDetails?.bestOfferTerms?.bestOfferEnabled || false,
          autoAcceptPrice: itemDetails?.bestOfferTerms?.autoAcceptPrice?.value || 0,
          autoDeclinePrice: itemDetails?.bestOfferTerms?.autoDeclinePrice?.value || 0,
          acceptsReturns: itemDetails?.acceptsReturns || true,
        },
        category: itemDetails.category || '',
        listToChannels: itemDetails.listToChannels || [],
        photographyStatus: itemDetails.photographyStatus || 'pending',
        recordCompletionStatus: itemDetails.recordCompletionStatus || 'draft',
      }));

      setInitialListToChannels(itemDetails.listToChannels || []);
      setSelectedConnections(connections.map((conn) => conn.id));
    }
  }, [itemDetails, isEditMode, connections]);

  // Initialize listToChannels for new records based on listToByDefault
  useEffect(() => {
    if (!isEditMode && connections.length > 0 && formData.listToChannels.length === 0) {
      const defaultConnections = connections
        .filter((conn) => conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'draft',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      const nonDefaultConnections = connections
        .filter((conn) => !conn.listToByDefault)
        .map((conn) => ({
          platform: conn.platform,
          connectionId: conn.id,
          listingStatus: 'disabled',
          marketplaceId: conn.marketplaceId || 'EBAY_US',
        }));

      setSelectedConnections(connections.map((conn) => conn.id));

      setFormData((prevData) => ({
        ...prevData,
        listToChannels: [...defaultConnections, ...nonDefaultConnections],
        photographyStatus: 'pending',
        recordCompletionStatus: 'draft',
      }));
    }
  }, [connections, isEditMode, formData.listToChannels.length]);

  // Merge existing connections with any new connections and preserve listingStatus (Edit Mode)
  useEffect(() => {
    if (isEditMode && connections.length > 0 && formData.listToChannels.length > 0) {
      const existingConnectionIds = formData.listToChannels.map(
        (channel) => channel.connectionId
      );
      const newConnections = connections.filter(
        (conn) => !existingConnectionIds.includes(conn.id)
      );

      const newListToChannels = newConnections.map((conn) => ({
        platform: conn.platform,
        connectionId: conn.id,
        listingStatus: 'disabled',
        marketplaceId: conn.marketplaceId || 'EBAY_US',
      }));

      if (newListToChannels.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          listToChannels: [...prevData.listToChannels, ...newListToChannels],
        }));
      }
    }
  }, [isEditMode, connections, formData.listToChannels]);

  // Handle missing custom fields from backend
  useEffect(() => {
    if (missingCustomFields.length > 0) {
      // Display toast notification
      toast.error('Please fill in the missing required custom fields.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  }, [missingCustomFields]);

  // Handle changes in basic details
  const handleBasicDetailsChange = (updatedBasicDetails) => {
    setFormData((prevData) => ({
      ...prevData,
      basicDetails: updatedBasicDetails,
    }));
  };

  // Custom fields state
  const [customFields, setCustomFields] = useState([]);

  // Initialize customFields from mergedCustomFields
  useEffect(() => {
    setCustomFields(mergedCustomFields);
  }, [mergedCustomFields]);

  // Handle custom fields change
  const handleCustomFieldsChange = (index, newValue) => {
    const updatedCustomFields = [...customFields];
    updatedCustomFields[index].value = newValue;
    setCustomFields(updatedCustomFields);
  };

  // Handle adding a new custom field
  const handleAddCustomField = (newCustomField) => {
    // Check for duplicates
    const duplicate = customFields.some(
      (field) =>
        field.fieldName.toLowerCase() === newCustomField.fieldName.toLowerCase()
    );
    if (duplicate) {
      toast.error('This field already exists.');
      return;
    }
    setCustomFields((prevFields) => [...prevFields, newCustomField]);
  };

  // Handle category change
  const handleCategoryChange = (newCategory) => {
    setFormData((prevData) => ({
      ...prevData,
      category: newCategory,
    }));
  };

  // Validate form based on mode (draft or completed)
  const handleFormValidation = (mode) => {
    const errors = {};

    const capitalizeFieldName = (field) =>
      field.replace('.', ' ').replace(/^\w/, (c) => c.toUpperCase());

    const draftRequiredFields = ['brand', 'condition'];
    const completedRequiredFields = [
      'brand',
      'model',
      'condition',
      'quantity',
      'dimensions.length',
      'dimensions.width',
      'dimensions.height',
      'weight.value',
      'category',
    ];

    const requiredFields =
      mode === 'completed' ? completedRequiredFields : draftRequiredFields;

    requiredFields.forEach((field) => {
      let fieldValue;
      if (field === 'category') {
        fieldValue = formData.category;
      } else {
        fieldValue = field.includes('.')
          ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
          : formData.basicDetails[field];
      }

      if (!fieldValue || fieldValue === '') {
        const capitalizedField = capitalizeFieldName(field);
        errors[field] = `${capitalizedField} is required for a ${mode === 'completed' ? 'completed' : 'draft'
          } record.`;
      }
    });

    // Validate custom fields
    customFields.forEach((field) => {
      if (field.isRequired) {
        if (!field.value || field.value.trim() === '') {
          const errorKey = `customField_${field.fieldName}`;
          errors[errorKey] = `${field.fieldName} is required.`;
        }
      }
    });

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, error]) => {
        toast.error(error, { toastId: `validation-${field}` });
      });
    }

    return Object.keys(errors).length === 0;
  };

  // Check if all required fields are filled for 'completed' status
  const checkAllRequiredFieldsFilled = () => {
    const completedRequiredFields = [
      'brand',
      'model',
      'condition',
      'quantity',
      'dimensions.length',
      'dimensions.width',
      'dimensions.height',
      'weight.value',
      'category',
    ];
    return completedRequiredFields.every((field) => {
      let fieldValue;
      if (field === 'category') {
        fieldValue = formData.category;
      } else {
        fieldValue = field.includes('.')
          ? formData.basicDetails[field.split('.')[0]][field.split('.')[1]]
          : formData.basicDetails[field];
      }
      return fieldValue && fieldValue.toString().trim() !== '';
    });
  };

  // Determine record completion status based on form completion
  const determineRecordCompletionStatus = () => {
    if (isEditMode && itemDetails.recordCompletionStatus === 'completed') {
      return 'completed';
    }
    return checkAllRequiredFieldsFilled() ? 'completed' : 'draft';
  };

  // Convert formData to payload for submission, including listingStatus and photographyStatus
  const convertFormDataToPayload = (recordCompletionStatus) => {
    const {
      brand,
      model,
      condition,
      quantity,
      item_description,
      dimensions,
      weight,
      shippingMethods,
      price,
      additional_info,
      bestOffer,
      autoAcceptPrice,
      autoDeclinePrice,
      acceptsReturns,
    } = formData.basicDetails;

    // Prepare the customFields payload
    const customFieldsArray = [];
    customFields.forEach((field) => {
      field.platforms.forEach((platform) => {
        customFieldsArray.push({
          fieldName: field.fieldName,
          value: field.value,
          isRequired: field.isRequired,
          platform: platform,
          useInTemplates: field.useInTemplates,
        });
      });
    });

    return {
      item_description,
      additional_info,
      condition,
      brand,
      model,
      category: formData.category,
      price,
      quantity,
      dimensions: {
        length: dimensions.length,
        width: dimensions.width,
        height: dimensions.height,
        unit: dimensions.unit || 'INCH',
      },
      weight: {
        value: weight.value,
        unit: weight.unit || 'POUND',
      },
      acceptsReturns,
      shippingMethods,
      bestOfferTerms: {
        bestOfferEnabled: bestOffer,
        autoAcceptPrice: {
          currency: 'USD',
          value: autoAcceptPrice,
        },
        autoDeclinePrice: {
          currency: 'USD',
          value: autoDeclinePrice,
        },
      },
      listToChannels: formData.listToChannels,
      recordCompletionStatus,
      photographyStatus: formData.photographyStatus,
      customFields: customFieldsArray, // Include customFields in payload
    };
  };

  // Handle form submission (save)
  const handleSave = () => {
    const recordCompletionStatus = determineRecordCompletionStatus();
    const isValid = handleFormValidation(recordCompletionStatus);

    if (isValid) {
      const submissionData = convertFormDataToPayload(recordCompletionStatus);
      setIsSubmitting(true); // Start submitting

      const action = isEditMode
        ? dispatch(updateItem({ id: productId, inventoryData: submissionData }))
        : dispatch(addInventoryItem(submissionData));

      action
        .then((res) => {
          if (!res.error) {
            toast.success(
              isEditMode ? 'Product updated successfully!' : 'Product created successfully!'
            );
            navigate('/arbie/ecom-products');
          } else {
            // Handle missing custom fields error
            if (res.payload && res.payload.missingFields) {
              // missingFields are already handled via Redux state and useEffect
            } else {
              toast.error(res.payload?.message || 'Failed to save product.');
            }
          }
        })
        .catch(() => {
          toast.error(
            isEditMode ? 'Failed to update product.' : 'Failed to create product.'
          );
        })
        .finally(() => {
          setIsSubmitting(false); // End submitting
        });
    }
  };

  // Label for save button based on listing status
  const saveButtonLabel =
    determineRecordCompletionStatus() === 'draft' ? 'Save as Draft' : 'Save';

  if (loading || connectionsLoading) return <Spinner />;
  if (error) {
    return (
      <div className="p-6">
        <p className="text-red-500">Error: {error}</p>
      </div>
    );
  }

  return (
    <>
      {isSubmitting && <Spinner />}
      <div className={`mx-auto p-6 lg:pt-4 lg:px-4 ${darkMode ? 'dark' : ''}`}>
        <ToastContainer />
        <div className="flex justify-between mb-4 items-center">
          <div className="flex items-center">
            <button
              onClick={() => navigate(-1)}
              className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100"
            >
              <ArrowLeftIcon className="h-6 w-6 mr-2" />
            </button>
            <h1 className="text-2xl font-bold dark:text-gray-100">{pageTitle}</h1>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={handleSave}
              className={`bg-green-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-green-600 text-sm ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              disabled={isSubmitting}
            >
              {saveButtonLabel}
            </button>
            {isEditMode && (
              <>
                <button className="bg-red-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-red-600 text-sm">
                  Delete
                </button>
                <button className="bg-indigo-500 text-white px-3 py-1.5 rounded-md shadow hover:bg-indigo-600 text-sm">
                  Archive
                </button>
              </>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Product Basic Details */}
          <div className="lg:col-span-3">
            <ProductBasicDetails
              product={formData.basicDetails}
              setBasicDetails={handleBasicDetailsChange}
              formErrors={formErrors}
              darkMode={darkMode}
              customFields={customFields}
              setCustomFields={setCustomFields}
              onCustomFieldChange={handleCustomFieldsChange}
              onAddCustomField={handleAddCustomField}
            />
          </div>

          {/* Product Connections and Organization */}
          <div className="lg:col-span-1">
            <ProductConnections
              selectedConnections={selectedConnections}
              setSelectedConnections={setSelectedConnections}
              inventoryRecord={itemDetails}
              darkMode={darkMode}
              setFormData={setFormData}
              formData={formData}
              isEditMode={isEditMode}
              initialListToChannels={initialListToChannels}
              openModal={() => setIsModalOpen(true)}
            />
            <div className="mt-8">
              <ProductOrganization
                category={formData.category}
                setFormData={setFormData}
                onCategoryChange={handleCategoryChange}
                darkMode={darkMode}
              />
            </div>
          </div>
        </div>

        {/* Product Media Section */}
        <div className="mt-0">
          <ProductMediaSection media={itemDetails?.images || []} darkMode={darkMode} />
        </div>

        {/* Manage Connections Modal */}
        {isModalOpen && (
          <ManageConnectionsModal
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            connections={connections}
            selectedConnections={selectedConnections}
            setSelectedConnections={setSelectedConnections}
            darkMode={darkMode}
            formData={formData}
            setFormData={setFormData}
            isEditMode={isEditMode}
            initialListToChannels={initialListToChannels}
          />
        )}
      </div>
    </>
  );
};

export default ProductDetailsPage;

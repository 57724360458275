import React from 'react'
import { XIcon } from '@heroicons/react/solid'

function PurchaseDetailModal({ purchase, onClose }) {
  if (!purchase) return null

  return (
    <div className='fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex justify-center items-center'>
      <div className='relative mx-4 my-4 p-6 bg-white dark:bg-gray-800 w-full max-w-3xl rounded-xl shadow-lg transition-all duration-300 ease-in-out'>
        <div className='flex items-center justify-between border-b border-gray-200 dark:border-gray-700 pb-3'>
          <h2 className='text-xl lg:text-2xl font-semibold text-gray-800 dark:text-white'>
            Purchase Details
          </h2>
          <button
            onClick={onClose}
            className='p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-all duration-200'
          >
            <XIcon className='h-6 w-6 text-gray-800 dark:text-white' />
          </button>
        </div>

        <div className='mt-4 space-y-4 overflow-y-auto max-h-[70vh]'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                Order Number:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                {purchase.orderNumber}
              </dd>
            </div>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                Purchase Date:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                {new Date(purchase.timestamp).toLocaleDateString()}
              </dd>
            </div>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                Total Order Amount:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                ${purchase.purchasePrice.toFixed(2)}
              </dd>
            </div>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                eBay ID:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                {purchase.ebayId}
              </dd>
            </div>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                Seller Username:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                {purchase.sellerUsername}
              </dd>
            </div>
            <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
              <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
                Seller Feedback:
              </dt>
              <dd className='text-sm text-gray-900 dark:text-gray-100'>
                {purchase.sellerFeedback}
              </dd>
            </div>
          </div>
          <div className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg'>
            <dt className='text-sm font-medium text-gray-600 dark:text-gray-300'>
              Purchase Notes:
            </dt>
            <dd className='text-sm text-gray-900 dark:text-gray-100'>
              {purchase.purchaseNotes}
            </dd>
          </div>

          <div className='mt-4'>
            <h3 className='text-lg font-medium text-gray-800 dark:text-white'>
              Inventory Details
            </h3>
            <div className='grid grid-cols-1 gap-4 mt-2 overflow-y-auto max-h-[40vh]'>
              {purchase.inventoryDetails.map((item, index) => (
                <div
                  key={index}
                  className='bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md'
                >
                  <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Brand:</strong> {item.brand}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Model:</strong> {item.model}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Description:</strong> {item.description}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Purchase Price:</strong> $
                      {item.purchasePrice.toFixed(2)}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Expected Condition:</strong>{' '}
                      {item.expectedCondition}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Expected Quantity:</strong>{' '}
                      {item.expectedQuantity}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Internal Selling Price:</strong> $
                      {item.internalSellingPrice.toFixed(2)}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Category:</strong> {item.category}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Validation Status:</strong>{' '}
                      {item.validationStatus}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Converted to Inventory:</strong>{' '}
                      {item.convertedToInventory ? 'Yes' : 'No'}
                    </p>
                    {item.convertedToInventory && (
                      <>
                        <p className='text-sm text-gray-900 dark:text-gray-100'>
                          <strong>SKU:</strong> {item.convertedSKU}
                        </p>
                        <p className='text-sm text-gray-900 dark:text-gray-100'>
                          <strong>Converted Date:</strong>{' '}
                          {item.convertedToInventoryDate
                            ? new Date(
                                item.convertedToInventoryDate
                              ).toLocaleDateString()
                            : 'N/A'}
                        </p>
                      </>
                    )}
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Sold Quantity:</strong> {item.soldQuantity}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Sales Price:</strong> $
                      {item.salesPrice.toFixed(2)}
                    </p>
                    <p className='text-sm text-gray-900 dark:text-gray-100'>
                      <strong>Sold Date:</strong>{' '}
                      {item.soldDate
                        ? new Date(item.soldDate).toLocaleDateString()
                        : 'N/A'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='mt-6 flex justify-end'>
          <button
            onClick={onClose}
            className='bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-all duration-200'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default PurchaseDetailModal

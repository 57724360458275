import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  clearMarketResearchData,
  fetchMarketResearchData,
} from '../../../features/arbitrage/autoFeedSlice'
import useWindowDimensions from './useWindowDimensions'
import DesktopMarketResearchModal from './DesktopMarketResearchModal'
import MobileMarketResearchModal from './MobileMarketResearchModal'

const MarketResearchModal = ({
  isOpen,
  onClose,
  opportunity,
  handleStatusUpdateAndNext,
  navigateToNext,
  navigateToPrevious,
  currentIndex,
  openImageModal,
}) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  // Clear data when modal closes
  useEffect(() => {
    if (!isOpen) {
      dispatch(clearMarketResearchData())
    }
  }, [isOpen, dispatch])

  // Fetch data when modal opens and opportunity changes
  useEffect(() => {
    if (isOpen && opportunity) {
      dispatch(
        fetchMarketResearchData(encodeURIComponent(opportunity.predictedMPN))
      )
    }
  }, [isOpen, opportunity, dispatch])

  if (!isOpen) return null

  return (
    <>
      {width >= 1200 ? (
        <DesktopMarketResearchModal
          onClose={onClose}
          opportunity={opportunity}
          handleStatusUpdateAndNext={handleStatusUpdateAndNext}
          navigateToNext={navigateToNext}
          navigateToPrevious={navigateToPrevious}
          currentIndex={currentIndex}
          openImageModal={openImageModal}
        />
      ) : (
        <MobileMarketResearchModal
          onClose={onClose}
          opportunity={opportunity}
          handleStatusUpdateAndNext={handleStatusUpdateAndNext}
          navigateToNext={navigateToNext}
          navigateToPrevious={navigateToPrevious}
          currentIndex={currentIndex}
        />
      )}
    </>
  )
}

export default MarketResearchModal

// components/AdminRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const AdminRoute = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    let isMounted = true; // To prevent state updates on unmounted component
    const checkAdminStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/check-admin`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (isMounted) {
          setIsAdmin(response.data.isAdmin);
        }
      } catch (error) {
        if (isMounted) {
          setIsAdmin(false);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (token) {
      checkAdminStatus();
    } else {
      setIsAdmin(false);
      setLoading(false);
    }

    return () => {
      isMounted = false; // Cleanup flag
    };
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return <Navigate to='/login' />;
  }

  if (isAdmin) {
    return children;
  } else {
    return <Navigate to='/unauthorized' />;
  }
};

export default AdminRoute;

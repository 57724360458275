import React from 'react';
import ApexCharts from 'react-apexcharts';
import { FaExternalLinkAlt } from 'react-icons/fa';

const SoldItemsChart = ({ soldItemData, themeMode }) => {
  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const getWeekStartDate = (date) => {
    const d = new Date(date);
    const day = d.getUTCDay();
    const diff = d.getUTCDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    const weekStart = new Date(d.setUTCDate(diff));
    return weekStart.toLocaleDateString();
  };

  // Group sold items by week and generalized condition, and calculate total price and count
  const soldItemsByWeekAndCondition = soldItemData.reduce((acc, item) => {
    const weekStartDate = getWeekStartDate(item.soldDate);
    const condition = item.generalized_condition;

    if (!acc[weekStartDate]) {
      acc[weekStartDate] = {};
    }

    if (!acc[weekStartDate][condition]) {
      acc[weekStartDate][condition] = { count: 0, totalPrice: 0 };
    }

    acc[weekStartDate][condition].count += 1;
    acc[weekStartDate][condition].totalPrice += item.latestPrice;

    return acc;
  }, {});

  // Get unique weeks and conditions
  const soldItemsWeeks = Object.keys(soldItemsByWeekAndCondition).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  const conditions = Array.from(
    new Set(soldItemData.map((item) => item.generalized_condition))
  );

  // Create series data for each condition using average price
  const soldItemsChartSeries = conditions.map((condition) => ({
    name: condition,
    data: soldItemsWeeks.map((week) => {
      const bucket = soldItemsByWeekAndCondition[week][condition];
      return bucket ? (bucket.totalPrice / bucket.count).toFixed(2) : 0;
    }),
  }));

  // Calculate count data for tooltip
  const countDataByConditionAndWeek = conditions.reduce((acc, condition) => {
    acc[condition] = soldItemsWeeks.map((week) => {
      const bucket = soldItemsByWeekAndCondition[week][condition];
      return bucket ? bucket.count : 0;
    });
    return acc;
  }, {});

  const soldItemsChartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      background: 'transparent',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
      },
    },
    xaxis: {
      categories: soldItemsWeeks,
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Average Price',
        style: {
          color: textColor,
        },
      },
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    title: {
      text: 'Average Sold Price Per Week by Condition',
      align: 'left',
      style: {
        color: textColor,
      },
    },
    theme: {
      mode: themeMode,
    },
    colors: ['#F59E0B', '#FF5733', '#33FF57'], // Customize colors for different conditions
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const condition = conditions[seriesIndex];
          const count = countDataByConditionAndWeek[condition][dataPointIndex];
          return `Avg Price: $${value}, Count: ${count}`;
        },
      },
    },
  };

  return (
    <div className='bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 p-4 shadow-sm rounded-lg flex-grow'>
      <ApexCharts
        options={soldItemsChartOptions}
        series={soldItemsChartSeries}
        type='bar'
        height={350}
        className='mb-4'
      />

      <div className='overflow-y-auto' style={{ maxHeight: '300px' }}>
        {soldItemData.length > 0 ? (
          // Sort the soldItemData by soldDate in descending order before mapping
          soldItemData
            .slice()
            .sort((a, b) => new Date(b.soldDate) - new Date(a.soldDate))
            .map((item, index) => (
              <div
                key={index}
                className='bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md mb-4 flex flex-col'
              >
                <div className='flex justify-between items-center'>
                  <p className='text-sm text-gray-700 dark:text-gray-300'>
                    <strong>Condition:</strong> {item.condition}
                  </p>
                  <a
                    href={item.itemWebUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-blue-500 hover:underline'
                  >
                    View Item <FaExternalLinkAlt className='inline-block ml-1' />
                  </a>
                </div>
                <p className='text-sm text-gray-700 dark:text-gray-300'>
                  <strong>Price:</strong> ${item.latestPrice.toFixed(2)}
                </p>
                <p className='text-sm text-gray-700 dark:text-gray-300'>
                  <strong>Creation Date:</strong>{' '}
                  {new Date(item.itemCreationDate).toLocaleDateString()}
                </p>
                <p className='text-sm text-gray-700 dark:text-gray-300'>
                  <strong>Sold Date:</strong>{' '}
                  {new Date(item.soldDate).toLocaleDateString()}
                </p>
                <p className='text-sm text-gray-700 dark:text-gray-300'>
                  <strong>Life Time:</strong> {item.lifeTime}
                </p>
              </div>
            ))
        ) : (
          <div className='text-gray-500 dark:text-gray-400'>
            No sold items found in the last 3 months.
          </div>
        )}
      </div>
    </div>
  );
};

export default SoldItemsChart;

import React, { useState } from 'react';
import { PlusIcon, RefreshIcon, SearchIcon, ClipboardCheckIcon } from '@heroicons/react/solid';

const OrdersHeader = ({
  connections,
  onConnectionChange,
  selectedConnection,
  onAddOrder,
  onRefreshAll,
  onSearch,
  searchQuery,
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastRefreshed, setLastRefreshed] = useState(null);

  const handleRefreshAll = async () => {
    setIsRefreshing(true);
    await onRefreshAll();
    setLastRefreshed(new Date());
    setIsRefreshing(false);
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  return (
    <div className="flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 pt-4">
      {/* Main Header Section */}
      <div className="flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-xl shadow-lg dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border">
        {/* Title Section with ClipboardCheckIcon */}
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <ClipboardCheckIcon className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">Orders</h1>
        </div>

        {/* Action Buttons Section */}
        <div className="flex space-x-4">
          <button
            onClick={handleRefreshAll}
            className={`bg-gradient-to-r from-indigo-500 to-indigo-800 text-white font-medium py-2 px-4 rounded-lg hover:from-indigo-600 hover:to-indigo-900 flex items-center space-x-2 transition duration-300 ease-in-out ${isRefreshing ? 'cursor-not-allowed' : ''
              }`}
            disabled={isRefreshing}
          >
            {isRefreshing ? (
              <RefreshIcon className="h-5 w-5 animate-spin" />
            ) : (
              <RefreshIcon className="h-5 w-5" />
            )}
            <span>Refresh All</span>
          </button>

          <button
            onClick={onAddOrder}
            className="bg-indigo-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-indigo-700 flex items-center space-x-2 transition duration-300 ease-in-out"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add Order</span>
          </button>
        </div>
      </div>

      {/* Search Bar and Connection Filter */}
      <div className="flex flex-col md:flex-row items-center justify-between p-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-lg border space-y-4 md:space-y-0">
        {/* Search Bar */}
        <div className="relative w-full max-w-md">
          <SearchIcon className="absolute w-5 h-5 text-gray-700 dark:text-gray-300 left-3 top-1/2 transform -translate-y-1/2" />
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
            placeholder="Search orders or items..."
          />
        </div>

        {/* Connection Dropdown */}
        <div className="relative w-full max-w-xs">
          <select
            className="block w-full pl-3 pr-10 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm"
            onChange={(e) => onConnectionChange(e.target.value)}
            value={selectedConnection}
          >
            <option value="all">All Accounts</option>
            {connections.map((connection) => (
              <option key={connection.id} value={connection.id}>
                {connection.platform} - {connection.accountName}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Last Refreshed Info */}
      {lastRefreshed && (
        <p className="text-gray-500 dark:text-gray-400 mt-2 text-sm">
          Data refreshed at {lastRefreshed.toLocaleString()}
        </p>
      )}
    </div>
  );
};

export default OrdersHeader;

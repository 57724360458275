import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const SplineGraph = ({ data, isDarkMode, searchTermCreationDates }) => {
  const [selectedOption, setSelectedOption] = useState('both');

  const getChartData = () => {
    let seriesData, opportunitiesSeriesData, colors;

    switch (selectedOption) {
      case 'new':
        seriesData = data.map((item) => item.silverbackNewCount);
        opportunitiesSeriesData = data.map(
          (item) => item.totalOpportunitiesNewCount
        );
        colors = ['#1E90FF', '#87CEFA'];
        break;
      case 'used':
        seriesData = data.map((item) => item.silverbackUsedCount);
        opportunitiesSeriesData = data.map(
          (item) => item.totalOpportunitiesUsedCount
        );
        colors = ['#FF6347', '#FFA07A'];
        break;
      case 'both':
      default:
        seriesData = data.map(
          (item) => item.silverbackNewCount + item.silverbackUsedCount
        );
        opportunitiesSeriesData = data.map(
          (item) =>
            item.totalOpportunitiesNewCount + item.totalOpportunitiesUsedCount
        );
        colors = ['#32CD32', '#98FB98'];
        break;
    }

    // Group annotations by date to handle overlapping
    const groupedAnnotations = searchTermCreationDates.reduce((acc, term) => {
      const date = new Date(term.dateCreated).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(term.term);
      return acc;
    }, {});

    // Create annotations for search term creation dates
    const annotations = {
      xaxis: Object.keys(groupedAnnotations).map((date) => ({
        x: date,
        borderColor: '#FF4560',
        offsetY: -10, // Position above plot area
        label: {
          style: {
            color: '#fff',
            background: '#FF4560',
            fontSize: '10px',
            padding: {
              top: 2,
              bottom: 2,
              left: 5,
              right: 5,
            },
          },
          orientation: 'horizontal',
          text: groupedAnnotations[date].length > 1
            ? `${groupedAnnotations[date].length} terms added`
            : groupedAnnotations[date][0],
        },
      })),
    };

    return {
      series: [
        {
          name: `Silverback/Strong Buys (${selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1)})`,
          type: 'line',
          data: seriesData,
        },
        {
          name: 'Total Opportunities',
          type: 'column',
          data: opportunitiesSeriesData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [4, 4],
        },
        title: {
          text: 'Silverback/Strong Buys and Total Opportunities in the Last 30 Days',
          align: 'left',
          style: {
            fontSize: '24px',
            color: isDarkMode ? '#ffffff' : '#000000',
          },
        },
        xaxis: {
          categories: data.map((item) => item.date),
          title: {
            text: 'Date (UTC)',
            style: {
              color: isDarkMode ? '#ffffff' : '#000000',
            },
          },
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              colors: isDarkMode ? '#ffffff' : '#000000',
            },
          },
        },
        yaxis: [
          {
            seriesName: 'Silverback/Strong Buys',
            title: {
              text: 'Silverback/Strong Buys',
              style: {
                color: isDarkMode ? '#ffffff' : '#000000',
              },
            },
            min: 0,
            opposite: true,
            labels: {
              style: {
                colors: isDarkMode ? '#ffffff' : '#000000',
              },
            },
          },
          {
            seriesName: 'Total Opportunities',
            title: {
              text: 'Total Opportunities',
              style: {
                color: isDarkMode ? '#ffffff' : '#000000',
              },
            },
            min: 0,
            labels: {
              style: {
                colors: isDarkMode ? '#ffffff' : '#000000',
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            format: 'yyyy-MM-dd',
          },
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 0) {
                return `Silverback/Strong Buys: ${value}`;
              } else if (seriesIndex === 1) {
                return `Total Opportunities: ${value}`;
              }
              return value;
            }
          },
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const date = w.globals.labels[dataPointIndex];
            const terms = groupedAnnotations[date] || [];
            return terms.length > 0
              ? `<div class="apexcharts-tooltip-title">${date}</div><div>${terms.join(', ')}</div>`
              : `<div class="apexcharts-tooltip-title">${date}</div><div>Silverback/Strong Buys: ${series[0][dataPointIndex]}</div><div>Total Opportunities: ${series[1][dataPointIndex]}</div>`;
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          labels: {
            colors: isDarkMode ? '#ffffff' : '#000000',
          },
        },
        annotations,  // Integrate improved annotations into the chart options
      },
    };
  };

  return (
    <div className='w-full'>
      <Chart
        options={getChartData().options}
        series={getChartData().series}
        height={350}
      />
      <div className='flex justify-center mb-4 mt-6'>
        <button
          className={`px-4 py-2 mx-1 rounded ${selectedOption === 'new' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setSelectedOption('new')}
        >
          New
        </button>
        <button
          className={`px-4 py-2 mx-1 rounded ${selectedOption === 'used' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setSelectedOption('used')}
        >
          Used
        </button>
        <button
          className={`px-4 py-2 mx-1 rounded ${selectedOption === 'both' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          onClick={() => setSelectedOption('both')}
        >
          Both
        </button>
      </div>
    </div>
  );
};

export default SplineGraph;
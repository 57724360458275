import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/api/purchases/`;
const INVENTORY_API_URL = `${process.env.REACT_APP_API_URL}/api/inventory/`;

// Fetch all purchases (triggers fetching from platforms and saving in MongoDB)
const fetchAllPurchases = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Call the backend to fetch from all platforms and store purchases in MongoDB
  const response = await axios.get(`${API_URL}fetchAll`, config);
  return response.data;
};

// Fetch purchases (retrieves from MongoDB)
const fetchPurchases = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  return response.data;
};

// Fetch single purchase by ID
const fetchPurchaseById = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}${id}`, config);
  return response.data;
};

// Create a new purchase
const createPurchase = async (purchaseData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, purchaseData, config);
  return response.data;
};

// Update a purchase
const updatePurchase = async (purchaseData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}${purchaseData._id}`, purchaseData, config);
  return response.data;
};

// Delete a purchase
const deletePurchase = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(`${API_URL}${id}`, config);
  return response.data;
};

// Create or update inventory drafts
const createOrUpdateInventoryDrafts = async (inventoryData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(`${INVENTORY_API_URL}createOrUpdateDrafts`, inventoryData, config);
  return response.data;
};

const purchasesService = {
  fetchAllPurchases,
  fetchPurchases,
  fetchPurchaseById,
  createPurchase,
  updatePurchase,
  deletePurchase,
  createOrUpdateInventoryDrafts,
};

export default purchasesService;

// PlatformSpecificCustomFields.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDefaultCustomFields,
  updateUserDefaultCustomFields,
  removeCustomField,
} from '../../features/inventory/inventorySlice';
import {
  PencilAltIcon,
  TrashIcon,
  PlusIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

const PlatformSpecificCustomFields = () => {
  const dispatch = useDispatch();
  const {
    defaultCustomFields,
    updatingCustomFields,
    customFieldsError,
    loading,
  } = useSelector((state) => state.inventory);

  const [selectedPlatform, setSelectedPlatform] = useState('eBay');
  const [fields, setFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [editedFields, setEditedFields] = useState({});
  const [newField, setNewField] = useState({
    fieldName: '',
    isRequired: false,
    useInTemplates: false,
    platform: selectedPlatform,
  });
  const [errors, setErrors] = useState({});
  const platforms = ['eBay', 'Amazon', 'Shopify'];

  // Predefined list of possible fields per platform
  const platformFieldOptions = {
    eBay: ['Condition', 'UPC', 'MPN', 'Brand', 'Model', 'EAN', 'ISBN'],
    Amazon: ['ASIN', 'UPC', 'Brand', 'Model', 'Color', 'Size'],
    Shopify: ['Vendor', 'Type', 'Tags', 'Collection', 'Published', 'UPC'],
  };

  useEffect(() => {
    dispatch(fetchDefaultCustomFields());
  }, [dispatch]);

  useEffect(() => {
    if (defaultCustomFields) {
      const platformFields = defaultCustomFields.filter(
        (field) => field.platform === selectedPlatform
      );
      setFields(platformFields);
    }
  }, [defaultCustomFields, selectedPlatform]);

  useEffect(() => {
    // Calculate available fields (those not yet selected)
    const selectedFieldNames = fields.map((field) => field.fieldName);
    const available = platformFieldOptions[selectedPlatform].filter(
      (fieldName) => !selectedFieldNames.includes(fieldName)
    );
    setAvailableFields(available);

    // Reset newField when platform changes
    setNewField({
      fieldName: '',
      isRequired: false,
      useInTemplates: false,
      platform: selectedPlatform,
    });
    setErrors({});
  }, [fields, selectedPlatform]);

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
    setEditedFields({});
    setErrors({});
  };

  const handleAddField = () => {
    if (newField.fieldName) {
      // Check if the field already exists
      if (fields.some((field) => field.fieldName === newField.fieldName)) {
        setErrors({ addField: 'This field is already added.' });
        return;
      }
      const updatedFields = [...fields, { ...newField }];
      setFields(updatedFields);
      setAvailableFields(
        availableFields.filter((name) => name !== newField.fieldName)
      );
      setNewField({
        fieldName: '',
        isRequired: false,
        useInTemplates: false,
        platform: selectedPlatform,
      });
      setErrors({});

      // Dispatch update action
      dispatch(
        updateUserDefaultCustomFields({
          defaultCustomFields: [
            ...defaultCustomFields.filter(
              (field) => field.platform !== selectedPlatform
            ),
            ...updatedFields,
          ],
        })
      );
    } else {
      setErrors({ addField: 'Please select a field to add.' });
    }
  };

  const handleDeleteField = (fieldId) => {
    dispatch(removeCustomField(fieldId))
      .unwrap()
      .then(() => {
        const updatedFields = fields.filter(
          (field) => field._id !== fieldId && field.fieldName !== fieldId
        );
        setFields(updatedFields);
        setErrors({});
      })
      .catch((error) => {
        console.error('Failed to delete custom field:', error);
      });
  };

  const handleEditField = (fieldId) => {
    setEditedFields({
      ...editedFields,
      [fieldId]: true,
    });
    setErrors({});
  };

  const handleSaveField = (fieldId) => {
    // Check for duplicates
    const field = fields.find(
      (field) => field._id === fieldId || field.fieldName === fieldId
    );

    const duplicate = fields.some(
      (f) =>
        f.fieldName === field.fieldName &&
        (f._id !== field._id || !f._id) // Exclude current field
    );

    if (duplicate) {
      setErrors({ [fieldId]: 'This field is already added.' });
      return;
    }

    setEditedFields({
      ...editedFields,
      [fieldId]: false,
    });
    setErrors({});

    // Dispatch update action
    dispatch(
      updateUserDefaultCustomFields({
        defaultCustomFields: [
          ...defaultCustomFields.filter(
            (field) => field.platform !== selectedPlatform
          ),
          ...fields,
        ],
      })
    );
  };

  const handleFieldChange = (fieldId, key, value) => {
    const updatedFields = fields.map((field) =>
      field._id === fieldId || field.fieldName === fieldId
        ? { ...field, [key]: value }
        : field
    );
    setFields(updatedFields);
  };

  const handleNewFieldChange = (key, value) => {
    setNewField({ ...newField, [key]: value });
  };

  // Function to get available field names when editing
  const getAvailableFieldNamesForEditing = (currentFieldName) => {
    const allFieldNames = platformFieldOptions[selectedPlatform];
    const usedFieldNames = fields
      .filter((field) => field.fieldName !== currentFieldName)
      .map((field) => field.fieldName);
    const availableFieldNames = allFieldNames.filter(
      (fieldName) => !usedFieldNames.includes(fieldName)
    );
    // Include currentFieldName in options
    return [currentFieldName, ...availableFieldNames];
  };

  if (loading) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400">
        Loading custom fields...
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-2 space-y-6">
      {/* Header */}
      <div className="flex items-center space-x-2">
        <InformationCircleIcon className="w-6 h-6 text-indigo-500" />
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          Platform-Specific Custom Fields
        </h3>
      </div>

      <p className="text-sm text-gray-600 dark:text-gray-400">
        Manage custom fields specific to each selling platform. These fields help
        tailor your inventory data to meet platform requirements.
      </p>

      {/* Platform Selection */}
      <div className="flex items-center space-x-4">
        <label className="text-gray-700 dark:text-gray-200 font-medium">
          Select Platform:
        </label>
        <select
          value={selectedPlatform}
          onChange={handlePlatformChange}
          className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-700 text-md text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {platforms.map((platform) => (
            <option key={platform} value={platform}>
              {platform}
            </option>
          ))}
        </select>
      </div>

      {/* Input for adding new field */}
      {!Object.values(editedFields).includes(true) && (
        <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md">
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <select
              value={newField.fieldName}
              onChange={(e) => handleNewFieldChange('fieldName', e.target.value)}
              className="flex-grow px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-md text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">Select a field to add</option>
              {availableFields.map((fieldName, idx) => (
                <option key={idx} value={fieldName}>
                  {fieldName}
                </option>
              ))}
            </select>
            <div className="flex items-center space-x-4 mt-2 sm:mt-0">
              <label className="flex items-center text-gray-700 dark:text-gray-200">
                <input
                  type="checkbox"
                  checked={newField.isRequired}
                  onChange={(e) =>
                    handleNewFieldChange('isRequired', e.target.checked)
                  }
                  className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-md">Required</span>
              </label>
              <label className="flex items-center text-gray-700 dark:text-gray-200">
                <input
                  type="checkbox"
                  checked={newField.useInTemplates}
                  onChange={(e) =>
                    handleNewFieldChange('useInTemplates', e.target.checked)
                  }
                  className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-md">Use in Templates</span>
              </label>
            </div>
            <button
              onClick={handleAddField}
              className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md mt-2 sm:mt-0"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Field
            </button>
          </div>
          {errors.addField && (
            <p className="text-red-500 text-sm mt-2">{errors.addField}</p>
          )}
        </div>
      )}

      {/* List of existing fields */}
      {fields.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {fields.map((field) => {
            const fieldId = field._id || field.fieldName;
            return (
              <div
                key={fieldId}
                className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md shadow-sm flex flex-col justify-between"
              >
                {editedFields[fieldId] ? (
                  <>
                    <div className="space-y-2">
                      {/* Field Name Select */}
                      <select
                        value={field.fieldName}
                        onChange={(e) =>
                          handleFieldChange(
                            fieldId,
                            'fieldName',
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-md text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        {getAvailableFieldNamesForEditing(field.fieldName).map(
                          (fieldName) => (
                            <option key={fieldName} value={fieldName}>
                              {fieldName}
                            </option>
                          )
                        )}
                      </select>
                      <div className="flex items-center space-x-4">
                        <label className="flex items-center text-gray-700 dark:text-gray-200">
                          <input
                            type="checkbox"
                            checked={field.isRequired}
                            onChange={(e) =>
                              handleFieldChange(
                                fieldId,
                                'isRequired',
                                e.target.checked
                              )
                            }
                            className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-md">Required</span>
                        </label>
                        <label className="flex items-center text-gray-700 dark:text-gray-200">
                          <input
                            type="checkbox"
                            checked={field.useInTemplates}
                            onChange={(e) =>
                              handleFieldChange(
                                fieldId,
                                'useInTemplates',
                                e.target.checked
                              )
                            }
                            className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-md">Use in Templates</span>
                        </label>
                      </div>
                      {errors[fieldId] && (
                        <p className="text-red-500 text-sm mt-2">
                          {errors[fieldId]}
                        </p>
                      )}
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        onClick={() => handleSaveField(fieldId)}
                        className="px-3 py-1 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md"
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="space-y-2">
                      <h4 className="text-lg font-medium text-gray-800 dark:text-gray-100">
                        {field.fieldName}
                      </h4>
                      <div className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300">
                        <span
                          className={`inline-block px-2 py-1 rounded-full ${field.isRequired
                              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                            }`}
                        >
                          {field.isRequired ? 'Required' : 'Optional'}
                        </span>
                        <span
                          className={`inline-block px-2 py-1 rounded-full ${field.useInTemplates
                              ? 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                            }`}
                        >
                          {field.useInTemplates
                            ? 'Used in Templates'
                            : 'Not Used in Templates'}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        onClick={() => handleEditField(fieldId)}
                        className="text-gray-500 hover:text-indigo-500"
                      >
                        <PencilAltIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteField(fieldId)}
                        className="text-red-500 hover:text-red-600"
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-gray-500 dark:text-gray-400">
          No custom fields added for {selectedPlatform} yet.
        </div>
      )}

      {customFieldsError && (
        <div className="text-red-500 mt-2">{customFieldsError}</div>
      )}
    </div>
  );
};

export default PlatformSpecificCustomFields;

import React, { useState, useEffect, Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BellIcon,
  SunIcon,
  MoonIcon,
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reset, logout } from '../features/auth/authSlice';
import arbiEngineLogoDark from '../images/arbienginelogo.png';
import arbiEngineLogoLight from '../images/arbieBlackNew.png';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header({ hideGetStarted = false }) {
  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, profileImageUrl } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate('/');
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }

    const themeEvent = new CustomEvent('themeChange', {
      detail: { theme: darkMode ? 'dark' : 'light' },
    });
    window.dispatchEvent(themeEvent);
  }, [darkMode]);

  // Define navigation links based on authentication status
  const navigation = user
    ? [
      { name: 'Blog', href: '/blog' },
      { name: 'About Us', href: '/about-us' },
    ]
    : [
      { name: 'Blog', href: '/blog' },
      { name: 'About Us', href: '/about-us' },
      // { name: 'Register', href: '/register' },
    ];

  return (
    <Disclosure as="header" className="relative z-20">
      {({ open }) => (
        <>
          {/* Main Header Container */}
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              {/* Logo Section */}
              <Link
                to="/"
                className="flex ml-2 md:mr-24"
                aria-label="Arbi Engine Home"
              >
                <img
                  src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
                  className="h-8 mr-3"
                  alt="Arbi Engine Logo - Discover Ecommerce Arbitrage Opportunities"
                />
                <span className="hidden lg:inline self-center text-xs font-semibold sm:text-sm whitespace-nowrap dark:text-white">
                  <p className="text-gray-800 dark:text-white font-bold pr-4">
                    Where{' '}
                    <span className="text-indigo-600 dark:text-indigo-400">
                      data
                    </span>{' '}
                    drive{' '}
                    <span className="text-indigo-600 dark:text-indigo-400">
                      decisions
                    </span>
                    .
                  </p>
                </span>
              </Link>

              {/* Desktop Navigation and Action Buttons */}
              <div className="hidden md:flex items-center">
                {/* Navigation Links */}
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="mr-4 text-sm font-semibold text-gray-700 dark:text-gray-300 hover:text-indigo-600 dark:hover:text-indigo-400"
                    title={`Visit our ${item.name}`}
                  >
                    {item.name}
                  </Link>
                ))}

                {/* Theme Toggle */}
                <button
                  id="theme-toggle"
                  type="button"
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                  onClick={() => setDarkMode(!darkMode)}
                  aria-label={
                    darkMode ? 'Switch to light mode' : 'Switch to dark mode'
                  }
                >
                  {darkMode ? (
                    <SunIcon className="w-5 h-5" />
                  ) : (
                    <MoonIcon className="w-5 h-5" />
                  )}
                </button>

                {/* User Actions */}
                {user ? (
                  <>
                    {/* Notifications Button */}
                    <button
                      type="button"
                      className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      aria-label="View notifications"
                    >
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    {/* Dashboard Link */}
                    <Link
                      to="/arbie"
                      className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      title="Go to your Dashboard"
                    >
                      Dashboard
                    </Link>

                    {/* User Profile Dropdown */}
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button
                          className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                          aria-label="Open user menu"
                        >
                          <img
                            className="h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down"
                            src={
                              profileImageUrl ||
                              'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                            }
                            alt="User profile"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/arbie"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 dark:bg-gray-600'
                                    : '',
                                  'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                                )}
                              >
                                Dashboard
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/settings"
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 dark:bg-gray-600'
                                    : '',
                                  'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300'
                                )}
                              >
                                Settings
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={onLogout}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 dark:bg-gray-600'
                                    : '',
                                  'block px-4 py-2 text-sm text-gray-700 dark:text-gray-300 w-full text-left'
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                ) : (
                  !hideGetStarted && (
                    <Link
                      to="/register-closed"
                      className="ml-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      title="Join the Arbi Engine Waitlist"
                    >
                      Join the Waitlist
                    </Link>
                  )
                )}
              </div>

              {/* Mobile Menu Button with Profile Image (if logged in) */}
              <Disclosure.Button className="md:hidden flex items-center">
                {/* Profile Image on Mobile (if user is logged in) */}
                {user && (
                  <img
                    className="h-8 w-8 rounded-full border-dark-purple border-2 object-scale-down mr-2"
                    src={
                      profileImageUrl ||
                      'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
                    }
                    alt="User profile"
                  />
                )}
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon
                    className="block h-6 w-6 text-gray-700 dark:text-gray-300"
                    aria-hidden="true"
                  />
                ) : (
                  <MenuIcon
                    className="block h-6 w-6 text-gray-700 dark:text-gray-300"
                    aria-hidden="true"
                  />
                )}
              </Disclosure.Button>
            </div>
          </div>

          {/* Mobile Menu Panel */}
          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {/* Navigation Links */}
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-700 dark:text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}

              {/* Theme Toggle */}
              <button
                id="theme-toggle-mobile"
                type="button"
                className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                onClick={() => setDarkMode(!darkMode)}
                aria-label={
                  darkMode ? 'Switch to light mode' : 'Switch to dark mode'
                }
              >
                {darkMode ? (
                  <>
                    <SunIcon className="w-5 h-5 mr-2" />
                    Light Mode
                  </>
                ) : (
                  <>
                    <MoonIcon className="w-5 h-5 mr-2" />
                    Dark Mode
                  </>
                )}
              </button>

              {/* User Actions (if logged in) */}
              {user ? (
                <>
                  {/* Notifications Button */}
                  <button
                    type="button"
                    className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center"
                    aria-label="View notifications"
                  >
                    <BellIcon
                      className="h-6 w-6 mr-2"
                      aria-hidden="true"
                    />
                    Notifications
                  </button>

                  {/* Dashboard Link */}
                  <Link
                    to="/arbie"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-indigo-600 dark:hover:text-indigo-400"
                  >
                    Dashboard
                  </Link>

                  {/* Settings Link */}
                  <Link
                    to="/settings"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-indigo-600 dark:hover:text-indigo-400"
                  >
                    Settings
                  </Link>

                  {/* Sign Out Button */}
                  <button
                    onClick={onLogout}
                    className="w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-indigo-600 dark:hover:text-indigo-400"
                  >
                    Sign out
                  </button>
                </>
              ) : (
                !hideGetStarted && (
                  <Link
                    to="/register-closed"
                    className="block w-full text-center px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    title="Join the Arbi Engine Waitlist"
                  >
                    Join the Waitlist
                  </Link>
                )
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';

const ConnectionModal = ({
  isOpen,
  onClose,
  onSubmit,
  platform,
  accountType,
  subscription,
  platformConfig,
  connections,
  editingConnection,
}) => {
  const isFirstAccount = connections.length === 0;
  const [currentAccountType, setCurrentAccountType] = useState(isFirstAccount ? platformConfig.defaultAccountType : accountType);
  const [accountName, setAccountName] = useState(editingConnection ? editingConnection.accountName : '');
  const [listToByDefault, setListToByDefault] = useState(editingConnection ? editingConnection.listToByDefault : false);

  useEffect(() => {
    if (isFirstAccount) {
      setCurrentAccountType(platformConfig.defaultAccountType);
    } else {
      setCurrentAccountType(accountType || platformConfig.defaultAccountType);
    }
  }, [accountType, platformConfig.defaultAccountType, isFirstAccount]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      platform,
      accountType: currentAccountType,
      accountName,
      connectionId: editingConnection ? editingConnection.id : null,
      listToByDefault,
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-y-auto' onClose={onClose}>
        <div className='flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-50 transition-opacity' />
          </Transition.Child>

          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border'>
              <div className='bg-white dark:bg-gray-800 p-6 rounded-lg'>
                <h2 className='text-3xl font-bold mb-6 text-gray-900 dark:text-white border-b-2 border-gray-200 dark:border-gray-600 pb-2'>
                  {isFirstAccount ? 'Add' : 'Edit'} Connection
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className='mb-4'>
                    <label className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'>
                      Account Type
                    </label>
                    <select
                      value={currentAccountType}
                      onChange={(e) => setCurrentAccountType(e.target.value)}
                      className='block w-full bg-gray-200 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-300 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-600'
                      required
                      disabled={!isFirstAccount}
                    >
                      <option value='buying'>Buying</option>
                      <option value='selling'>Selling</option>
                    </select>
                  </div>
                  <div className='mb-4'>
                    <label className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'>
                      Account Name
                    </label>
                    <input
                      type='text'
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      className='block w-full bg-gray-200 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 text-gray-700 dark:text-gray-300 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-600'
                      required
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'>
                        List to Default
                      </label>
                      <InformationCircleIcon className='h-5 w-5 text-gray-400 dark:text-gray-300 cursor-pointer' />
                    </div>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        checked={listToByDefault}
                        onChange={(e) => setListToByDefault(e.target.checked)}
                        className='mr-2 leading-tight'
                      />
                      <span className='text-gray-700 dark:text-gray-300'>
                        Enable this connection as a default listing channel
                      </span>
                    </div>
                  </div>
                  <div className='flex justify-end'>
                    <button
                      type='button'
                      className='bg-gray-500 dark:bg-gray-600 text-white font-semibold py-2 px-4 rounded-full mr-2'
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      className='bg-indigo-500 dark:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full'
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConnectionModal;

import React from 'react'
import Modal from '../ResultsFeed/Modals/Modal' // Import your existing modal component

const InfoModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-3xl font-bold mb-6 border-b-2 border-gray-200 pb-2'>
          eBay Results Count Information
        </h2>
        <p>
          The strength of a search term is determined by the approximate number of eBay
          listings:
        </p>
        <ul className='mt-4'>
          <li>
            <strong className='text-red-500'>Red (1 bar):</strong> Less than 500 items - Too few listings may not provide reliable data.
          </li>
          <li>
            <strong className='text-orange-500'>Orange (2 bars):</strong> 500 - 5000 items - Moderate amount of data, some analysis possible.
          </li>
          <li>
            <strong className='text-green-500'>Green (3 bars):</strong> 5000 - 15000 items - Ideal range for a balanced comparison.
          </li>
          <li>
            <strong className='text-red-500'>Red (4 bars):</strong> More than 15000 items - Too many listings may dilute the analysis.
          </li>
        </ul>
      </div>
    </Modal>
  )
}

export default InfoModal

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tab } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { EmojiHappyIcon, EmojiSadIcon } from '@heroicons/react/solid'

import PurchaseImage from '../../images/LogPurchases.png'
import InventoryImage from '../../images/InventoryLight.png'

const UserWalkthroughModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const [view, setView] = useState('journey') // 'journey' or 'features'
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const steps = {
    Explorer: [
      {
        step: 'Navigate to Saved Searches',
        description:
          'Create searches for items you want to identify arbitrage in. Use the product research tool if needed.',
        docLink: '/docs/saved-searches',
        image: 'https://via.placeholder.com/800x600.png?text=Saved+Searches',
      },
      {
        step: 'Understand Results Feed',
        description:
          'Our automated pipeline analyzes the eBay marketplace to identify actionable arbitrage opportunities.',
        docLink: '/docs/results-feed',
        image: 'https://via.placeholder.com/800x600.png?text=Results+Feed',
      },
      {
        step: 'Log Purchases',
        description: 'Log your purchases.',
        docLink: '/docs/inventory-management',
        image: PurchaseImage,
      },
      {
        step: 'Manage Inventory',
        description:
          'Convert purchases to inventory items, and list them on eBay and Shopify for a profit.',
        docLink: '/docs/inventory-management',
        image: InventoryImage,
      },
    ],
    Accelerator: [
      {
        step: 'Navigate to Saved Searches',
        description:
          'Create searches for items you want to identify arbitrage in. Use the product research tool if needed.',
        docLink: '/docs/saved-searches',
        image: 'https://via.placeholder.com/800x600.png?text=Saved+Searches',
      },
      {
        step: 'Understand Results Feed',
        description:
          'Our automated pipeline analyzes the eBay marketplace to identify actionable arbitrage opportunities.',
        docLink: '/docs/results-feed',
        image: 'https://via.placeholder.com/800x600.png?text=Results+Feed',
      },
      {
        step: 'Log Purchases and Manage Inventory',
        description:
          'Log your purchases, convert them to inventory items, and list them on eBay and Shopify.',
        docLink: '/docs/inventory-management',
        image:
          'https://via.placeholder.com/800x600.png?text=Inventory+Management',
      },
      {
        step: 'Access Market Insights',
        description:
          'Access comprehensive market insights to stay ahead of the competition.',
        docLink: '/docs/market-insights',
        image: 'https://via.placeholder.com/800x600.png?text=Market+Insights',
      },
    ],
    Professional: [
      {
        step: 'Navigate to Saved Searches',
        description:
          'Create searches for items you want to identify arbitrage in. Use the product research tool if needed.',
        docLink: '/docs/saved-searches',
        image: 'https://via.placeholder.com/800x600.png?text=Saved+Searches',
      },
      {
        step: 'Understand Results Feed',
        description:
          'Our automated pipeline analyzes the eBay marketplace to identify actionable arbitrage opportunities.',
        docLink: '/docs/results-feed',
        image: 'https://via.placeholder.com/800x600.png?text=Results+Feed',
      },
      {
        step: 'Log Purchases and Manage Inventory',
        description:
          'Log your purchases, convert them to inventory items, and list them on eBay and Shopify.',
        docLink: '/docs/inventory-management',
        image:
          'https://via.placeholder.com/800x600.png?text=Inventory+Management',
      },
      {
        step: 'Access Market Insights',
        description:
          'Access comprehensive market insights to stay ahead of the competition.',
        docLink: '/docs/market-insights',
        image: 'https://via.placeholder.com/800x600.png?text=Market+Insights',
      },
      {
        step: 'Utilize Advanced Analytics',
        description: 'Utilize advanced analytics to make informed decisions.',
        docLink: '/docs/advanced-analytics',
        image:
          'https://via.placeholder.com/800x600.png?text=Advanced+Analytics',
      },
    ],
    Enterprise: [
      {
        step: 'Navigate to Saved Searches',
        description:
          'Create searches for items you want to identify arbitrage in. Use the product research tool if needed.',
        docLink: '/docs/saved-searches',
        image: 'https://via.placeholder.com/800x600.png?text=Saved+Searches',
      },
      {
        step: 'Understand Results Feed',
        description:
          'Our automated pipeline analyzes the eBay marketplace to identify actionable arbitrage opportunities.',
        docLink: '/docs/results-feed',
        image: 'https://via.placeholder.com/800x600.png?text=Results+Feed',
      },
      {
        step: 'Log Purchases and Manage Inventory',
        description:
          'Log your purchases, convert them to inventory items, and list them on eBay and Shopify.',
        docLink: '/docs/inventory-management',
        image:
          'https://via.placeholder.com/800x600.png?text=Inventory+Management',
      },
      {
        step: 'Access Market Insights',
        description:
          'Access comprehensive market insights to stay ahead of the competition.',
        docLink: '/docs/market-insights',
        image: 'https://via.placeholder.com/800x600.png?text=Market+Insights',
      },
      {
        step: 'Utilize Advanced Analytics',
        description: 'Utilize advanced analytics to make informed decisions.',
        docLink: '/docs/advanced-analytics',
        image:
          'https://via.placeholder.com/800x600.png?text=Advanced+Analytics',
      },
      {
        step: 'Explore Enterprise Solutions',
        description: 'Get tailored solutions for large-scale enterprises.',
        docLink: '/docs/enterprise-solutions',
        image:
          'https://via.placeholder.com/800x600.png?text=Enterprise+Solutions',
      },
    ],
  }

  const explorerFeatures = [
    {
      name: 'Automated Arbitrage',
      description:
        'Leverage our automated pipelines to identify arbitrage opportunities...',
      image:
        'https://via.placeholder.com/800x600.png?text=Automated+Arbitrage+Placeholder',
      docLink: '/docs/automated-arbitrage',
    },
    {
      name: 'Results Feed',
      description: 'Get real-time updates on actionable opportunities...',
      image:
        'https://via.placeholder.com/800x600.png?text=Results+Feed+Placeholder',
      docLink: '/docs/results-feed',
    },
    {
      name: 'Results Feed',
      description: 'Get real-time updates on actionable opportunities...',
      image:
        'https://via.placeholder.com/800x600.png?text=Results+Feed+Placeholder',
      docLink: '/docs/results-feed',
    },
  ]

  const acceleratorFeatures = [
    {
      name: 'Market Insights',
      description:
        'Access comprehensive market insights to stay ahead of the competition...',
      image:
        'https://via.placeholder.com/800x600.png?text=Market+Insights+Placeholder',
      docLink: '/docs/market-insights',
    },
    {
      name: 'Ebay Integration',
      description: 'Seamlessly integrate with Ebay to manage your listings...',
      image:
        'https://via.placeholder.com/800x600.png?text=Ebay+Integration+Placeholder',
      docLink: '/docs/ebay-integration',
    },
    ...explorerFeatures,
  ]

  const professionalFeatures = [
    {
      name: 'Advanced Analytics',
      description: 'Utilize advanced analytics to make informed decisions...',
      image:
        'https://via.placeholder.com/800x600.png?text=Advanced+Analytics+Placeholder',
      docLink: '/docs/advanced-analytics',
    },
    ...acceleratorFeatures,
  ]

  const enterpriseFeatures = [
    {
      name: 'Enterprise Solutions',
      description: 'Get tailored solutions for large-scale enterprises...',
      image:
        'https://via.placeholder.com/800x600.png?text=Enterprise+Solutions+Placeholder',
      docLink: '/docs/enterprise-solutions',
    },
    ...professionalFeatures,
  ]

  const features = {
    Explorer: explorerFeatures,
    Accelerator: acceleratorFeatures,
    Professional: professionalFeatures,
    Enterprise: enterpriseFeatures,
  }

  // Ensure that if the user is unsubscribed, we default to Explorer
  const subscriptionLevel =
    user?.subscriptionLevel && user.subscriptionLevel !== 'Unsubscribed'
      ? user.subscriptionLevel
      : 'Explorer'
  const currentSteps = steps[subscriptionLevel]
  const currentFeatures = features[subscriptionLevel]

  const goToNextStep = () => {
    if (currentSteps && currentStepIndex < currentSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }

  const goToPreviousStep = () => {
    if (currentSteps && currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  if (!user) {
    return null // or display a fallback UI
  }

  if (
    !currentSteps ||
    !currentFeatures ||
    currentSteps.length === 0 ||
    currentFeatures.length === 0
  ) {
    return (
      <div
        className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
      >
        <div
          className='fixed inset-0 bg-black bg-opacity-50'
          onClick={onClose}
        ></div>
        <div className='bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] relative z-10 flex flex-col'>
          <h2 className='text-2xl font-semibold text-gray-800 dark:text-white'>
            Error
          </h2>
          <p className='text-gray-800 dark:text-gray-200'>
            There was an error loading the user walkthrough. Please try again
            later.
          </p>
          <button
            onClick={onClose}
            className='mt-4 bg-indigo-600 text-white px-4 py-2 rounded'
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}
    >
      <div
        className='fixed inset-0 bg-black bg-opacity-50'
        onClick={onClose}
      ></div>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-4xl w-full max-h-[90vh] relative z-10 flex flex-col'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-semibold text-gray-800 dark:text-white'>
            {view === 'journey' ? 'User Journey' : 'Feature Walkthrough'}
          </h2>
          <div className='flex items-center'>
            {user?.subscriptionLevel != 'Unsubscribed' ? (
              <>
                <span className='text-gray-800 dark:text-white mr-2'>
                  Your Subscription Level: {user.subscriptionLevel}
                </span>
                <EmojiHappyIcon className='h-6 w-6 text-green-500' />
              </>
            ) : (
              <>
                <span className='text-red-500 mr-2'>
                  View Only Mode: Enroll to utilize features
                </span>
                <EmojiSadIcon className='h-6 w-6 text-red-500 mr-2' />
                <button
                  onClick={() => navigate('/settings/subscription')}
                  className='bg-indigo-600 text-white px-4 py-1 rounded'
                >
                  Subscribe
                </button>
              </>
            )}
            <button
              onClick={onClose}
              className='text-gray-800 dark:text-white ml-4'
            >
              X
            </button>
          </div>
        </div>
        <div className='flex space-x-4 mb-4'>
          <button
            className={`px-4 py-2 rounded ${view === 'journey' ? 'bg-indigo-600 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white'}`}
            onClick={() => setView('journey')}
          >
            User Journey
          </button>
          <button
            className={`px-4 py-2 rounded ${view === 'features' ? 'bg-indigo-600 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white'}`}
            onClick={() => setView('features')}
          >
            Feature Walkthrough
          </button>
        </div>
        {view === 'journey' ? (
          <div className='flex flex-col flex-1 overflow-hidden'>
            <div className='flex justify-between items-center mb-4 p-2 w-full overflow-hidden'>
              {currentSteps &&
                currentSteps.map((step, idx) => (
                  <div key={idx} className='flex items-center'>
                    <div
                      className={`w-16 h-8 flex items-center justify-center rounded-full ${idx <= currentStepIndex ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                    >
                      Step {idx + 1}
                    </div>
                    {idx < currentSteps.length - 1 && (
                      <div
                        className={`flex-1 h-1 mx-2 ${idx < currentStepIndex ? 'bg-green-500' : 'bg-gray-300'}`}
                      ></div>
                    )}
                  </div>
                ))}
            </div>
            <div className='flex-1 overflow-y-auto'>
              {currentSteps && currentSteps[currentStepIndex] && (
                <div className='flex flex-col md:flex-row items-center mb-4 w-full'>
                  <img
                    src={currentSteps[currentStepIndex].image}
                    alt={currentSteps[currentStepIndex].step}
                    className='w-full md:w-1/2 mb-4 md:mb-0 object-scale-down'
                  />
                  <div className='md:ml-4'>
                    <h3 className='text-xl font-semibold text-gray-800 dark:text-white'>
                      {currentSteps[currentStepIndex].step}
                    </h3>
                    <p className='text-gray-800 dark:text-gray-200'>
                      {currentSteps[currentStepIndex].description}
                    </p>
                    <button
                      onClick={() =>
                        navigate(currentSteps[currentStepIndex].docLink)
                      }
                      className='mt-2 bg-indigo-600 text-white px-4 py-2 rounded'
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className='flex justify-between items-center mt-4'>
              <button
                onClick={goToPreviousStep}
                className='px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-white rounded'
                disabled={currentStepIndex === 0}
              >
                Previous
              </button>
              <button
                onClick={goToNextStep}
                className='px-4 py-2 bg-indigo-600 text-white rounded'
                disabled={currentStepIndex === currentSteps.length - 1}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <Tab.Group>
            <Tab.List className='flex space-x-1 bg-indigo-600 p-1 rounded'>
              {currentFeatures &&
                currentFeatures.map((tab, idx) => (
                  <Tab
                    key={idx}
                    className={({ selected }) =>
                      `w-full py-2.5 text-sm leading-5 font-medium text-white rounded-lg ${selected ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-500'}`
                    }
                  >
                    {tab.name}
                  </Tab>
                ))}
            </Tab.List>
            <Tab.Panels className='mt-2 flex-1 overflow-y-auto'>
              {currentFeatures &&
                currentFeatures.map((tab, idx) => (
                  <Tab.Panel key={idx} className='p-4'>
                    <div className='flex flex-col md:flex-row items-center mb-4 w-full'>
                      <img
                        src={tab.image}
                        alt={tab.name}
                        className='w-full md:w-1/2 mb-4 md:mb-0'
                      />
                      <div className='md:ml-4'>
                        <h3 className='text-xl font-semibold text-gray-800 dark:text-white'>
                          {tab.name}
                        </h3>
                        <p className='text-gray-800 dark:text-gray-200'>
                          {tab.description}
                        </p>
                        <button
                          onClick={() => navigate(tab.docLink)}
                          className='mt-2 bg-indigo-600 text-white px-4 py-2 rounded'
                        >
                          Learn More
                        </button>
                      </div>
                    </div>
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </div>
  )
}

export default UserWalkthroughModal

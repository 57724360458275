import React from 'react'

const SubscriptionTierCard = ({
  tier,
  subscription,
  onSubscribe,
  onUpgradeDowngrade,
  tiers,
}) => {
  const isSubscribed =
    subscription &&
    subscription.items &&
    subscription.items.some((item) => item.plan === tier.name && item.status === 'active');

  const currentTier =
    subscription && subscription.items
      ? tiers.find(
        (t) => t.name === subscription.items.find((item) => item.plan === tier.name && item.status === 'active')?.plan
      )
      : null;

  const canUpgrade = currentTier && tier.hierarchy > currentTier.hierarchy;
  const canDowngrade = currentTier && tier.hierarchy < currentTier.hierarchy;

  return (
    <div
      className={`flex flex-col justify-between p-4 border rounded-lg shadow-lg 
      ${isSubscribed ? 'border-indigo-500 shadow-xl bg-white dark:bg-gray-900' : 'bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900'}`}
    >
      <div>
        <h2
          className={`text-2xl font-bold mb-2 ${isSubscribed ? 'text-gray-900 dark:text-white' : 'text-gray-900 dark:text-white'}`}
        >
          {tier.name}
        </h2>
        <p
          className={`text-xl mb-2 ${isSubscribed ? 'text-gray-900 dark:text-white' : 'text-gray-600 dark:text-white'}`}
        >
          {tier.price}
        </p>
        <p
          className={`mb-2 ${isSubscribed ? 'text-gray-900 dark:text-white' : 'text-gray-600 dark:text-white'}`}
        >
          {tier.description}
        </p>
        <ul className='mb-4 space-y-1'>
          {tier.features.map((feature, index) => (
            <li
              key={index}
              className={`flex items-center ${isSubscribed ? 'text-gray-900 dark:text-white' : 'text-gray-600 dark:text-white'}`}
            >
              <svg
                className='w-5 h-5 text-green-300 mr-2'
                fill='currentColor'
                viewBox='0 0 20 20'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zm4.293-11.707a1 1 0 00-1.414-1.414L9 9.586 7.121 7.707a1 1 0 00-1.414 1.414l2.586 2.586a1 1 0 001.414 0l5.586-5.586z'
                  clipRule='evenodd'
                />
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className='mt-4'>
        {tier.name === 'Enterprise' ? (
          <button
            onClick={onSubscribe}
            className='bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full'
          >
            Contact Us
          </button>
        ) : isSubscribed ? (
          <button
            className='bg-gray-500 text-white font-bold py-2 px-4 rounded w-full cursor-not-allowed'
            disabled
          >
            Subscribed
          </button>
        ) : canUpgrade ? (
          <button
            onClick={() => onUpgradeDowngrade(tier)}
            className='bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full'
          >
            Upgrade
          </button>
        ) : canDowngrade ? (
          <button
            onClick={() => onUpgradeDowngrade(tier)}
            className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded w-full'
          >
            Downgrade
          </button>
        ) : (
          <button
            onClick={onSubscribe}
            className='bg-indigo-700 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded w-full'
          >
            Subscribe
          </button>
        )}
      </div>
    </div>
  )
}

export default SubscriptionTierCard

// ProductBasicDetails.jsx

import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import 'react-quill/dist/quill.snow.css';
import '../../../../components_css/custom-quill.css';

const ProductBasicDetails = ({
  product,
  setBasicDetails,
  formErrors,
  darkMode,
  customFields,
  setCustomFields,
}) => {
  const [formDataLocal, setFormDataLocal] = useState({
    brand: '',
    model: '',
    condition: 'Select Condition',
    quantity: 1,
    dimensions: { length: '', width: '', height: '', unit: 'INCH' },
    weight: { value: '', unit: 'POUND' },
    shippingMethods: [],
    item_description: '',
    additional_info: '',
    price: 0,
    bestOffer: false,
    acceptsReturns: true,
    autoAcceptPrice: 0,
    autoDeclinePrice: 0,
  });

  const [overrideBestOffer, setOverrideBestOffer] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [activeFieldInfo, setActiveFieldInfo] = useState(null);
  const [showAdditionalFieldsInfo, setShowAdditionalFieldsInfo] = useState(false);

  const uspsShippingOptions = [
    { label: 'USPS First Class', value: 'USPSFirstClass' },
    { label: 'USPS Priority Padded Flat Rate Envelope', value: 'USPSPriorityMailPaddedFlatRateEnvelope' },
    { label: 'USPS Priority Small Flat Rate Box', value: 'USPSPriorityMailSmallFlatRateBox' },
    { label: 'USPS Priority Mail Medium Flat Rate Box', value: 'USPSPriorityFlatRateBox' },
    { label: 'USPS Priority Mail Large Flat Rate Box', value: 'USPSPriorityMailLargeFlatRateBox' },
  ];

  const shippingOptions = [
    { label: 'UPS Ground', value: 'UPSGround' },
    ...uspsShippingOptions,
  ];

  const [uspsSelected, setUspsSelected] = useState(false);

  // Calculate default auto-accept and auto-decline prices
  const calculateAutoAcceptPrice = (price) => (price * 0.95).toFixed(2);
  const calculateAutoDeclinePrice = (price) => (price * 0.9).toFixed(2);

  useEffect(() => {
    if (product) {
      setFormDataLocal({
        brand: product.brand || '',
        model: product.model || '',
        condition: product.condition || 'Select Condition',
        quantity: product.quantity || 1,
        dimensions: product.dimensions || { length: '', width: '', height: '', unit: 'INCH' },
        weight: product.weight || { value: '', unit: 'POUND' },
        shippingMethods: product.shippingMethods || ['UPSGround'],
        item_description: product.item_description || '',
        additional_info: product.additional_info || '',
        price: product.price || 0,
        bestOffer: product.bestOffer || false,
        acceptsReturns: product.acceptsReturns || true,
        autoAcceptPrice: product.autoAcceptPrice || calculateAutoAcceptPrice(product.price || 0),
        autoDeclinePrice: product.autoDeclinePrice || calculateAutoDeclinePrice(product.price || 0),
      });

      setUspsSelected(
        product.shippingMethods?.some((method) =>
          uspsShippingOptions.some((option) => option.value === method)
        )
      );
    }
  }, [product]);

  useEffect(() => {
    // Auto-expand the accordion if there are errors in custom fields
    if (
      customFields &&
      customFields.some((field) =>
        formErrors[`customField_${field.fieldName}_${field.platform}`]
      )
    ) {
      setIsAccordionOpen(true);
    }
  }, [formErrors, customFields]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData;

    if (type === 'checkbox') {
      updatedFormData = { ...formDataLocal, [name]: checked };
    } else if (name.includes('.')) {
      const [parent, child] = name.split('.');
      updatedFormData = {
        ...formDataLocal,
        [parent]: {
          ...formDataLocal[parent],
          [child]: value,
        },
      };
    } else {
      updatedFormData = { ...formDataLocal, [name]: value };
    }

    setFormDataLocal(updatedFormData);
    setBasicDetails(updatedFormData);
  };

  const handleShippingChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) => option.value);
    const updatedFormData = { ...formDataLocal, shippingMethods: selectedOptions };
    setFormDataLocal(updatedFormData);
    setBasicDetails(updatedFormData);

    setUspsSelected(
      selectedOptions.some((method) =>
        uspsShippingOptions.map((option) => option.value).includes(method)
      )
    );
  };

  const handleAdditionalInfoChange = (value) => {
    setFormDataLocal((prev) => {
      const updatedFormData = { ...prev, additional_info: value };
      setBasicDetails(updatedFormData);
      return updatedFormData;
    });
  };

  const handleClearShipping = () => {
    setFormDataLocal((prev) => {
      const updatedFormData = { ...prev, shippingMethods: ['UPSGround'] };
      setBasicDetails(updatedFormData);
      return updatedFormData;
    });
    setUspsSelected(false);
  };

  const handleToggleOverride = () => {
    const newOverrideBestOffer = !overrideBestOffer;
    setOverrideBestOffer(newOverrideBestOffer);

    if (!newOverrideBestOffer) {
      const updatedFormData = {
        ...formDataLocal,
        autoAcceptPrice: calculateAutoAcceptPrice(formDataLocal.price),
        autoDeclinePrice: calculateAutoDeclinePrice(formDataLocal.price),
      };
      setFormDataLocal(updatedFormData);
      setBasicDetails(updatedFormData);
    }
  };

  // Handle changes in custom fields
  const handleCustomFieldChange = (index, newValue) => {
    const updatedCustomFields = [...customFields];
    updatedCustomFields[index].value = newValue;
    setCustomFields(updatedCustomFields);
  };

  // Handle field info click
  const handleFieldInfoClick = (index) => {
    setActiveFieldInfo((prevIndex) => (prevIndex === index ? null : index));
  };

  // State for adding new custom field
  const [newCustomField, setNewCustomField] = useState({
    fieldName: '',
    value: '',
    isRequired: false,
    useInTemplates: false,
    platform: 'General',
  });
  const [showAddCustomField, setShowAddCustomField] = useState(false);
  const [errors, setErrors] = useState({});

  const platforms = ['General', 'eBay', 'Amazon', 'Shopify'];

  const platformFieldOptions = {
    eBay: ['Condition', 'UPC', 'MPN', 'Brand', 'Model', 'EAN', 'ISBN'],
    Amazon: ['ASIN', 'UPC', 'Brand', 'Model', 'Color', 'Size'],
    Shopify: ['Vendor', 'Type', 'Tags', 'Collection', 'Published', 'UPC'],
  };

  const handleAddCustomField = () => {
    if (newCustomField.fieldName.trim() === '') {
      setErrors({ addField: 'Field name is required' });
      return;
    }
    // Check for duplicates
    const duplicate = customFields.some(
      (field) =>
        field.fieldName.toLowerCase() === newCustomField.fieldName.toLowerCase() &&
        field.platform === newCustomField.platform
    );
    if (duplicate) {
      setErrors({ addField: 'This field already exists for the selected platform.' });
      return;
    }
    // Add the new custom field
    const updatedCustomFields = [
      ...customFields,
      {
        fieldName: newCustomField.fieldName,
        value: newCustomField.value,
        isRequired: newCustomField.isRequired,
        useInTemplates: newCustomField.useInTemplates,
        platform: newCustomField.platform,
      },
    ];
    setCustomFields(updatedCustomFields);

    // Reset the newCustomField state
    setNewCustomField({
      fieldName: '',
      value: '',
      isRequired: false,
      useInTemplates: false,
      platform: 'General',
    });
    setShowAddCustomField(false);
    setErrors({});
  };

  return (
    <div className={`bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md`}>
      {/* Brand, Model, Item Description */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <label htmlFor="brand" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Brand</label>
          <input
            type="text"
            name="brand"
            id="brand"
            value={formDataLocal.brand}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {formErrors.brand && <p className="text-red-500 mt-1">{formErrors.brand}</p>}
        </div>

        <div>
          <label htmlFor="model" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Model</label>
          <input
            type="text"
            name="model"
            id="model"
            value={formDataLocal.model}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {formErrors.model && <p className="text-red-500 mt-1">{formErrors.model}</p>}
        </div>

        <div>
          <label htmlFor="item_description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Item Description</label>
          <input
            type="text"
            name="item_description"
            id="item_description"
            value={formDataLocal.item_description}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {formErrors.item_description && <p className="text-red-500 mt-1">{formErrors.item_description}</p>}
        </div>
      </div>

      {/* Custom Fields Accordion */}
      {customFields && (
        <div className="mt-2">
          <div className="flex items-center">
            <div className="flex items-center">
              <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Additional Fields
              </span>
              <InformationCircleIcon
                className="h-5 w-5 ml-1 text-gray-500 cursor-pointer"
                onClick={() => setShowAdditionalFieldsInfo(!showAdditionalFieldsInfo)}
              />
              <button
                type="button"
                onClick={() => setShowAddCustomField(true)}
                className="ml-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                title="Add Custom Field"
              >
                <PlusIcon className="h-5 w-5" />
              </button>
              <button
                type="button"
                onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                className="text-gray-700 dark:text-gray-300 focus:outline-none pl-4"
              >
                {isAccordionOpen ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            <div className="flex-grow mx-2 border-t border-gray-300 dark:border-gray-600"></div>
          </div>

          {showAdditionalFieldsInfo && (
            <div className="mt-1 p-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded">
              <p className="text-sm text-gray-700 dark:text-gray-300">
                The additional fields are platform-specific fields that may be required or optional, depending on the platform.
              </p>
            </div>
          )}

          {isAccordionOpen && (
            <>
              {customFields.length > 0 && (
                <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
                  {customFields.map((field, index) => (
                    <div key={`${field.fieldName}-${field.platform}-${index}`}>
                      <div className="flex items-center">
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                          {field.fieldName} {field.isRequired && <span className="text-red-500">*</span>}
                        </label>
                        <InformationCircleIcon
                          className="h-5 w-5 ml-1 text-gray-500 cursor-pointer"
                          onClick={() => handleFieldInfoClick(index)}
                        />
                      </div>
                      <input
                        type="text"
                        value={field.value || ''}
                        onChange={(e) => handleCustomFieldChange(index, e.target.value)}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        placeholder={`Enter ${field.fieldName}`}
                      />
                      {activeFieldInfo === index && (
                        <div className="mt-1 p-2 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded">
                          <p className="text-sm text-gray-700 dark:text-gray-300">
                            <strong>Required:</strong> {field.isRequired ? 'Yes' : 'No'}<br />
                            <strong>Platform:</strong> {field.platform}<br />
                            <strong>Use in Templates:</strong> {field.useInTemplates ? 'Yes' : 'No'}
                          </p>
                        </div>
                      )}
                      {formErrors[`customField_${field.fieldName}_${field.platform}`] && (
                        <p className="text-red-500 mt-1 text-sm">
                          {formErrors[`customField_${field.fieldName}_${field.platform}`]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {/* Add Custom Field Section */}
              {showAddCustomField && (
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Add Custom Field</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Platform Selection */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Platform
                      </label>
                      <select
                        value={newCustomField.platform}
                        onChange={(e) => {
                          setNewCustomField({ ...newCustomField, platform: e.target.value, fieldName: '' });
                          setErrors({});
                        }}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                      >
                        {platforms.map((platform) => (
                          <option key={platform} value={platform}>
                            {platform}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Field Name Input or Dropdown */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Field Name
                      </label>
                      {newCustomField.platform === 'General' ? (
                        <input
                          type="text"
                          value={newCustomField.fieldName}
                          onChange={(e) => setNewCustomField({ ...newCustomField, fieldName: e.target.value })}
                          className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        />
                      ) : (
                        <select
                          value={newCustomField.fieldName}
                          onChange={(e) => setNewCustomField({ ...newCustomField, fieldName: e.target.value })}
                          className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                        >
                          <option value="">Select a field</option>
                          {platformFieldOptions[newCustomField.platform]
                            .filter((fieldName) => {
                              // Exclude already added fields for this platform
                              return !customFields.some(
                                (field) =>
                                  field.platform === newCustomField.platform &&
                                  field.fieldName === fieldName
                              );
                            })
                            .map((fieldName) => (
                              <option key={fieldName} value={fieldName}>
                                {fieldName}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>

                    {/* Value Input */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Value
                      </label>
                      <input
                        type="text"
                        value={newCustomField.value}
                        onChange={(e) => setNewCustomField({ ...newCustomField, value: e.target.value })}
                        className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-1 px-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                      />
                    </div>

                    {/* Is Required Checkbox */}
                    <div className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        checked={newCustomField.isRequired}
                        onChange={(e) => setNewCustomField({ ...newCustomField, isRequired: e.target.checked })}
                        className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Required
                      </label>
                    </div>

                    {/* Use in Templates Checkbox */}
                    <div className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        checked={newCustomField.useInTemplates}
                        onChange={(e) =>
                          setNewCustomField({ ...newCustomField, useInTemplates: e.target.checked })
                        }
                        className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                        Use in Templates
                      </label>
                    </div>
                  </div>

                  {/* Error Message */}
                  {errors.addField && (
                    <p className="text-red-500 text-sm mt-2">{errors.addField}</p>
                  )}

                  {/* Action Buttons */}
                  <div className="mt-4 flex space-x-2">
                    <button
                      onClick={handleAddCustomField}
                      className="px-3 py-1.5 bg-green-500 text-white rounded-md shadow hover:bg-green-600 text-sm"
                    >
                      Add Field
                    </button>
                    <button
                      onClick={() => {
                        setShowAddCustomField(false);
                        setErrors({});
                      }}
                      className="px-3 py-1.5 bg-gray-500 text-white rounded-md shadow hover:bg-gray-600 text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {/* Price, Quantity, Condition */}
      <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Price */}
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Price</label>
          <input
            type="number"
            name="price"
            id="price"
            value={formDataLocal.price}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {formErrors.price && <p className="text-red-500 mt-1">{formErrors.price}</p>}
          <div className="mt-2 flex items-center space-x-6">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="bestOffer"
                id="bestOffer"
                checked={formDataLocal.bestOffer}
                onChange={handleChange}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="bestOffer" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">Enable Best Offer</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="acceptsReturns"
                id="acceptsReturns"
                checked={formDataLocal.acceptsReturns}
                onChange={handleChange}
                className="h-5 w-5 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label htmlFor="acceptsReturns" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">Returns Accepted</label>
            </div>
          </div>
        </div>

        {/* Quantity */}
        <div>
          <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Quantity</label>
          <input
            type="number"
            name="quantity"
            id="quantity"
            value={formDataLocal.quantity}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          />
          {formErrors.quantity && <p className="text-red-500 mt-1">{formErrors.quantity}</p>}
        </div>

        {/* Condition */}
        <div>
          <label htmlFor="condition" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Condition</label>
          <select
            name="condition"
            id="condition"
            value={formDataLocal.condition}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          >
            <option value="Select Condition">Select Condition</option>
            <option value="NEW">New</option>
            <option value="USED_EXCELLENT">Used - Excellent</option>
            <option value="NEW_OTHER">New Other</option>
            <option value="FOR_PARTS_OR_NOT_WORKING">For Parts or Not Working</option>
          </select>
          {formErrors.condition && <p className="text-red-500 mt-1">{formErrors.condition}</p>}
        </div>
      </div>

      {/* Best Offer, Additional Information, and Shipping Information */}
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Side */}
        <div>
          {/* Best Offer Section */}
          {formDataLocal.bestOffer && (
            <div className="mt-2">
              <div className="flex items-center">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Override Auto Accept/Decline Price</label>
                <button
                  onClick={handleToggleOverride}
                  className={`ml-4 px-2 py-1 text-sm rounded ${overrideBestOffer ? 'bg-red-600 text-white' : 'bg-green-500 text-white'}`}
                >
                  {overrideBestOffer ? 'Disable Override' : 'Enable Override'}
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4 mt-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Auto Accept Price</label>
                  <input
                    type="number"
                    name="autoAcceptPrice"
                    value={formDataLocal.autoAcceptPrice}
                    onChange={handleChange}
                    disabled={!overrideBestOffer}
                    className={`mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 ${!overrideBestOffer && 'cursor-not-allowed opacity-50'}`}
                  />
                  {!overrideBestOffer && formDataLocal.autoAcceptPrice !== calculateAutoAcceptPrice(formDataLocal.price) && (
                    <p className="text-sm text-yellow-600 mt-1">Default auto-accept price: {calculateAutoAcceptPrice(formDataLocal.price)}</p>
                  )}
                  {formErrors.autoAcceptPrice && <p className="text-red-500 mt-1">{formErrors.autoAcceptPrice}</p>}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Auto Decline Price</label>
                  <input
                    type="number"
                    name="autoDeclinePrice"
                    value={formDataLocal.autoDeclinePrice}
                    onChange={handleChange}
                    disabled={!overrideBestOffer}
                    className={`mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 ${!overrideBestOffer && 'cursor-not-allowed opacity-50'}`}
                  />
                  {!overrideBestOffer && formDataLocal.autoDeclinePrice !== calculateAutoDeclinePrice(formDataLocal.price) && (
                    <p className="text-sm text-yellow-600 mt-1">Default auto-decline price: {calculateAutoDeclinePrice(formDataLocal.price)}</p>
                  )}
                  {formErrors.autoDeclinePrice && <p className="text-red-500 mt-1">{formErrors.autoDeclinePrice}</p>}
                </div>
              </div>
            </div>
          )}

          {/* Additional Information */}
          <label htmlFor="item_description_rich" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-4">Additional Information</label>
          <ReactQuill
            key={darkMode}
            value={formDataLocal.additional_info}
            onChange={handleAdditionalInfoChange}
            className={`${darkMode ? 'quill-dark' : 'quill-light'} rounded-md h-40 mt-1`}
            theme="snow"
            placeholder=""
          />
        </div>

        {/* Right Side */}
        <div>
          {/* Shipping Information */}
          <fieldset className="border p-4 rounded-md mt-14 md:mt-0">
            <legend className="text-lg font-medium text-gray-700 dark:text-gray-300">Shipping Information</legend>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="col-span-1">
                <label htmlFor="dimensions.length" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Length (in)</label>
                <input
                  type="number"
                  name="dimensions.length"
                  value={formDataLocal.dimensions.length}
                  onChange={handleChange}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {formErrors['dimensions.length'] && <p className="text-red-500 mt-1">{formErrors['dimensions.length']}</p>}
              </div>

              <div className="col-span-1">
                <label htmlFor="dimensions.width" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Width (in)</label>
                <input
                  type="number"
                  name="dimensions.width"
                  value={formDataLocal.dimensions.width}
                  onChange={handleChange}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {formErrors['dimensions.width'] && <p className="text-red-500 mt-1">{formErrors['dimensions.width']}</p>}
              </div>

              <div className="col-span-1">
                <label htmlFor="dimensions.height" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Height (in)</label>
                <input
                  type="number"
                  name="dimensions.height"
                  value={formDataLocal.dimensions.height}
                  onChange={handleChange}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {formErrors['dimensions.height'] && <p className="text-red-500 mt-1">{formErrors['dimensions.height']}</p>}
              </div>

              <div className="col-span-1">
                <label htmlFor="weight.value" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Weight</label>
                <input
                  type="number"
                  name="weight.value"
                  value={formDataLocal.weight.value}
                  onChange={handleChange}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                />
                {formErrors['weight.value'] && <p className="text-red-500 mt-1">{formErrors['weight.value']}</p>}
              </div>

              <div className="col-span-1 md:col-span-2">
                <label htmlFor="weight.unit" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Unit</label>
                <select
                  name="weight.unit"
                  value={formDataLocal.weight.unit}
                  onChange={handleChange}
                  className="w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                >
                  <option value="POUND">POUND</option>
                  <option value="OUNCE">OUNCE</option>
                </select>
              </div>
            </div>

            <div className="mt-4 flex justify-between items-center">
              <label htmlFor="shippingMethods" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Shipping Methods</label>
              {uspsSelected && (
                <button
                  type="button"
                  onClick={handleClearShipping}
                  className="p-1 text-sm rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
                >
                  Reset
                </button>
              )}
            </div>
            <select
              name="shippingMethods"
              id="shippingMethods"
              multiple
              value={formDataLocal.shippingMethods}
              onChange={handleShippingChange}
              className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100"
            >
              {shippingOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </fieldset>
        </div>
      </div>

      {formErrors.basicDetails && (
        <p className="text-red-500 mt-2">{formErrors.basicDetails}</p>
      )}
    </div>
  );
};

export default ProductBasicDetails;

import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  ArchiveIcon,
  ExclamationCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ViewListIcon,
  ViewBoardsIcon,
  ArrowLeftIcon,
} from '@heroicons/react/outline'

import { fetchCategoryDetails, addSearchTerm } from '../features/productResearch/productResearchSlice'
import SearchTermTable from '../components/ProductResearch/SearchTermTable'

const CategoryDetailPage = () => {
  const { categoryId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { researchDetail, loading, error, userSearchTerms, subscriptionDetails } = useSelector(
    (state) => state.productResearch
  )
  const [openIndex, setOpenIndex] = useState(null)
  const [viewMode, setViewMode] = useState('list')
  const [allExpanded, setAllExpanded] = useState(false) // State to track expand/collapse all
  const accordionHeadersRef = useRef([])

  const handleAddTerm = (term) => {
    dispatch(addSearchTerm({ term, termKey: term }))
  }

  const handleGoBack = () => {
    navigate('/arbie/research')
  }

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }

  const toggleViewMode = () => {
    setViewMode(viewMode === 'list' ? 'card' : 'list')
  }

  const handleToggleAll = () => {
    if (allExpanded) {
      setOpenIndex(null) // Collapse all
    } else {
      setOpenIndex(Array.from({ length: researchDetail.refinedCategories.length }, (_, i) => i)) // Expand all
    }
    setAllExpanded(!allExpanded)
  }

  useEffect(() => {
    if (categoryId) {
      dispatch(fetchCategoryDetails(categoryId))
    }
  }, [dispatch, categoryId])

  useEffect(() => {
    if (openIndex !== null && Array.isArray(openIndex) && accordionHeadersRef.current[openIndex[0]]) {
      accordionHeadersRef.current[openIndex[0]].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [openIndex])

  if (loading)
    return (
      <div className='flex justify-center items-center h-screen dark:text-white'>
        <ArchiveIcon className='animate-spin h-8 w-8 text-blue-500 dark:text-blue-400' />
        <span>Loading...</span>
      </div>
    )
  if (error)
    return (
      <div className='text-red-500 dark:text-red-400 flex items-center justify-center m-4'>
        <ExclamationCircleIcon className='h-6 w-6 mr-2' />
        Error: {error}
      </div>
    )

  const searchTermLimit = subscriptionDetails?.searchTermLimit || 0; // Default to 0 if not available
  const remainingSearchTerms = searchTermLimit - userSearchTerms.length;

  return (
    <div className='max-w-7xl mx-auto p-6 dark:bg-gray-900'>
      <div className='flex justify-between items-center mb-6'>
        <div className='flex items-center space-x-3'>
          <button
            onClick={handleGoBack}
            className='p-2 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-300'
          >
            <ArrowLeftIcon className='h-6 w-6 text-gray-800 dark:text-gray-300' />
          </button>
          <ArchiveIcon className='h-8 w-8 text-indigo-600 dark:text-indigo-400' />
          <h1 className='text-3xl font-bold text-gray-900 dark:text-white'>
            {researchDetail.searchTerm}
          </h1>
        </div>
        <div className='flex items-center space-x-3'>
          <span className='text-lg text-gray-600 dark:text-gray-300'>
          Search Term Count: {userSearchTerms.length} / {searchTermLimit} (Remaining: {remainingSearchTerms})
          </span>
          <button
            onClick={toggleViewMode}
            className='p-2 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-300'
          >
            {viewMode === 'list' ? (
              <ViewBoardsIcon className='h-6 w-6 text-gray-800 dark:text-gray-300' />
            ) : (
              <ViewListIcon className='h-6 w-6 text-gray-800 dark:text-gray-300' />
            )}
          </button>
          <button
            onClick={handleToggleAll}
            className='p-2 rounded-full bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 transition duration-300 text-gray-800 dark:text-white'
          >
            {allExpanded ? 'Collapse All' : 'Expand All'}
          </button>
        </div>
      </div>
      {viewMode === 'list' ? (
        researchDetail.refinedCategories?.map((category, index) => (
          <div
            ref={(el) => (accordionHeadersRef.current[index] = el)}
            key={index}
            className='mb-8'
          >
            <button
              onClick={() => toggleAccordion(index)}
              className={`flex justify-between items-center w-full px-5 py-4 text-left font-semibold rounded-lg shadow-lg transition-colors duration-300 ease-in-out ${
                openIndex === index || (Array.isArray(openIndex) && openIndex.includes(index))
                  ? 'bg-indigo-200 text-gray-800 dark:bg-indigo-600 dark:text-white'
                  : 'bg-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-800 dark:text-white'
              }`}
            >
              <h3 className='text-xl font-semibold'>{category.category}</h3>
              {openIndex === index || (Array.isArray(openIndex) && openIndex.includes(index)) ? (
                <ChevronUpIcon className='h-6 w-6' />
              ) : (
                <ChevronDownIcon className='h-6 w-6' />
              )}
            </button>
            {(openIndex === index || (Array.isArray(openIndex) && openIndex.includes(index))) && (
              <div className='mt-2 p-4 bg-white dark:bg-gray-800 rounded-lg shadow'>
                <p className='text-sm text-gray-700 dark:text-gray-300'>
                  {category.description}
                </p>
                <SearchTermTable
                  searchTerms={[
                    {
                      term: category.category,
                      description: category.description,
                      ebayResultsCount: category.ebayResultsCount, // placeholder value
                    },
                  ]}
                  onAddTerm={handleAddTerm}
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
          {researchDetail.refinedCategories?.map((category, index) => (
            <div
              key={index}
              className='p-4 bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out'
            >
              <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
                {category.category}
              </h3>
              <p className='text-sm text-gray-700 dark:text-gray-300 mt-2'>
                {category.description}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CategoryDetailPage

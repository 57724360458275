import React, { useState } from 'react'
import {
  XCircleIcon,
  StarIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  ArrowDownIcon,
  FireIcon,
  ExclamationIcon,
  ShoppingCartIcon,
  TruckIcon,
  BadgeCheckIcon,
  GlobeAltIcon,
  PlusIcon,
  UserIcon,
} from '@heroicons/react/solid'

import silverbackImage from '../../../images/silverback.png'
import monkeyImage from '../../../images/monkey.png'
import chimpImage from '../../../images/chimp.png'
import smallMonkeyImage from '../../../images/small_monkey.png'
import bananaImage from '../../../images/banana.png'

const OpportunityTable = ({
  currentItems,
  selectedItems,
  handleCheckboxChange,
  handleUpdateStatus,
  openOpportunityModal,
  selectAll,
  handleSelectAll,
  openModal,
  calculateBreakEvenPrice,
  openImageModal,
}) => {
  const [hoveredAttribute, setHoveredAttribute] = useState(null)

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const potentialProfit = (averagePrice, currentPrice) => {
    return (averagePrice - currentPrice).toFixed(2)
  }

  const potentialProfitPercentage = (averagePrice, currentPrice) => {
    return (((averagePrice - currentPrice) / averagePrice) * 100).toFixed(2)
  }

  return (
    <div className='overflow-x-auto mt-4'>
      <div className='max-h-[65vh] overflow-y-auto rounded-xl rounded-r-none border scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-800'>
        <table className='min-w-full text-sm text-left text-gray-500 dark:text-gray-300 border'>
          <thead className='sticky top-0 z-10 text-xs text-gray-700 uppercase bg-gray-50 bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 dark:text-white border'>
            <tr>
              <th className='p-4'>
                <input
                  id='checkbox-all'
                  type='checkbox'
                  className='w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600'
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th className='px-4 py-3'>Actions</th>
              <th className='px-4 py-3'>Image</th>
              <th className='px-4 py-3'>Title</th>
              <th className='px-8 py-3'>
                <span className='inline-flex items-center'>
                  Price Info
                  <InformationCircleIcon
                    className='w-5 h-5 ml-1 text-indigo-500 cursor-pointer'
                    onClick={() => openModal('priceInfo')}
                  />
                </span>
              </th>
              <th className='px-4 py-3'>
                <span className='inline-flex items-center'>
                  Label
                  <InformationCircleIcon
                    className='w-5 h-5 ml-1 text-indigo-500 cursor-pointer'
                    onClick={() => openModal('labelInfo')}
                  />
                </span>
              </th>
              <th className='px-4 py-3'>
                <span className='inline-flex items-center'>
                  Attributes
                  <InformationCircleIcon
                    className='w-5 h-5 ml-1 text-indigo-500 cursor-pointer'
                    onClick={() => openModal('attributesInfo')}
                  />
                </span>
              </th>
              <th className='px-4 py-3'>Condition</th>
              <th className='px-4 py-3'>Search Term</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((opportunity) => {
              const isWatched = opportunity.status === 'watching'
              const highlightClass = isWatched
                ? 'bg-gradient-to-r from-indigo-50 to-indigo-300 dark:from-indigo-500 dark:to-indigo-800'
                : ''

              return (
                <tr
                  key={opportunity.itemId}
                  className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer ease-in-out ${highlightClass}`}
                >
                  <td className='p-4'>
                    <input
                      id={`checkbox-${opportunity.itemId}`}
                      type='checkbox'
                      className='w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:bg-gray-700 dark:border-gray-600'
                      checked={selectedItems.includes(opportunity.itemId)}
                      onChange={() => handleCheckboxChange(opportunity.itemId)}
                    />
                  </td>
                  <td className='px-4 py-3'>
                    <div className='flex space-x-2'>
                      <div className='relative group'>
                        <XCircleIcon
                          className='w-6 h-6 text-red-500 cursor-pointer hover:text-red-700'
                          onClick={(e) => {
                            e.stopPropagation()
                            handleUpdateStatus([opportunity.itemId], 'passed')
                          }}
                        />
                        <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                          Pass
                          <svg
                            className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                          >
                            <path d='M10 10l-10 10h20L10 10z' />
                          </svg>
                        </div>
                      </div>
                      <div className='relative group'>
                        {isWatched ? (
                          <CheckCircleIcon
                            className='w-6 h-6 text-green-500 cursor-pointer'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleUpdateStatus(
                                [opportunity.itemId],
                                'pending'
                              )
                            }}
                          />
                        ) : (
                          <StarIcon
                            className='w-6 h-6 text-yellow-500 cursor-pointer hover:text-yellow-700'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleUpdateStatus(
                                [opportunity.itemId],
                                'watching'
                              )
                            }}
                          />
                        )}
                        <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                          {isWatched ? 'Watched' : 'Watch'}
                          <svg
                            className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                          >
                            <path d='M10 10l-10 10h20L10 10z' />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='px-4 py-3'>
                    <div className='relative w-full sm:w-24 md:w-32 h-24 md:h-32 overflow-hidden rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 border'>
                      <img
                        src={opportunity.image.imageUrl}
                        alt={opportunity.title}
                        className='w-full h-full object-cover'
                      />
                      <div
                        className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-25 opacity-0 hover:opacity-100 transition-opacity duration-300'
                        onClick={(e) => {
                          e.stopPropagation()
                          openImageModal(opportunity)
                        }}
                      >
                        <PlusIcon className='w-8 h-8 text-white cursor-pointer' />
                      </div>
                      <p
                        className='absolute bottom-0 left-0 w-full text-center bg-gray-900 bg-opacity-75 text-white text-xs p-1'
                        onClick={(e) => {
                          e.stopPropagation()
                          openImageModal(opportunity)
                        }}
                      >
                        Arbi Identified on:{' '}
                        {formatDate(opportunity.dateArbieFound)}
                      </p>
                    </div>
                  </td>
                  <td
                    className='px-4 py-3 font-medium text-gray-900 dark:text-white'
                    style={{ maxWidth: '150px', wordWrap: 'break-word' }}
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    {opportunity.title}
                  </td>
                  <td
                    className='px-8 py-3'
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    <div className='flex flex-col space-y-1'>
                      <div className='flex items-center space-x-1'>
                        <CalculatorIcon className='w-5 h-5 text-teal-500' />
                        <span className='text-sm text-gray-900 dark:text-white'>
                          $
                          {opportunity.summary_statistics
                            ? opportunity.summary_statistics.averageLifeTimePrice.toFixed(
                                2
                              )
                            : 'N/A'}
                        </span>
                        <span className='ml-2 text-xs text-gray-500 dark:text-indigo-200'>
                          Avg. Lifetime Price
                        </span>
                      </div>
                      <div className='flex items-center space-x-1'>
                        <CurrencyDollarIcon className='w-5 h-5 text-green-500' />
                        <span className='text-sm text-gray-900 dark:text-white'>
                          ${calculateBreakEvenPrice(opportunity.latestPrice)}
                        </span>
                        <span className='ml-2 text-xs text-gray-500 dark:text-indigo-200'>
                          Break-Even Price
                        </span>
                      </div>
                      <div className='flex items-center space-x-1'>
                        <CurrencyDollarIcon className='w-5 h-5 text-blue-500' />
                        <span className='text-sm text-gray-900 dark:text-white'>
                          ${opportunity.latestPrice.toFixed(2)}
                        </span>
                        <span className='ml-2 text-xs text-gray-500 dark:text-indigo-200'>
                          Current Price
                        </span>
                      </div>
                      {opportunity.summary_statistics &&
                        opportunity.summary_statistics.averageLifeTimePrice && (
                          <div className='flex items-center space-x-1'>
                            <CurrencyDollarIcon className='w-5 h-5 text-purple-500' />
                            <span className='text-sm text-gray-900 dark:text-white'>
                              $
                              {potentialProfit(
                                opportunity.summary_statistics
                                  .averageLifeTimePrice,
                                opportunity.latestPrice
                              )}
                            </span>
                            <span className='ml-2 text-xs text-gray-500 dark:text-indigo-200'>
                              Potential Profit (
                              {potentialProfitPercentage(
                                opportunity.summary_statistics
                                  .averageLifeTimePrice,
                                opportunity.latestPrice
                              )}
                              %)
                            </span>
                          </div>
                        )}
                    </div>
                  </td>
                  <td
                    className='px-4 py-3'
                    onMouseEnter={() =>
                      setHoveredAttribute(`label-${opportunity.itemId}`)
                    }
                    onMouseLeave={() => setHoveredAttribute(null)}
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    <div className='relative group'>
                      {opportunity.Label === 'Silverback Buy' && (
                        <img
                          src={silverbackImage}
                          alt='Silverback Buy'
                          className='w-10 h-10 rounded-full'
                        />
                      )}
                      {opportunity.Label === 'Strong Buy' && (
                        <img
                          src={chimpImage}
                          alt='Strong Buy'
                          className='w-10 h-10 rounded-full'
                        />
                      )}
                      {opportunity.Label === 'Mild Buy' && (
                        <img
                          src={monkeyImage}
                          alt='Mild Buy'
                          className='w-10 h-10 rounded-full'
                        />
                      )}
                      {opportunity.Label === 'Buy' && (
                        <img
                          src={smallMonkeyImage}
                          alt='Buy'
                          className='w-10 h-10 rounded-full'
                        />
                      )}
                      {opportunity.Label === 'N/A' && (
                        <img
                          src={bananaImage}
                          alt='N/A'
                          className='w-10 h-10 rounded-full'
                        />
                      )}
                      {hoveredAttribute === `label-${opportunity.itemId}` && (
                        <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                          {opportunity.Label}
                          <svg
                            className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                            fill='currentColor'
                            viewBox='0 0 20 20'
                          >
                            <path d='M10 10l-10 10h20L10 10z' />
                          </svg>
                        </div>
                      )}
                    </div>
                  </td>
                  <td
                    className='px-4 py-3'
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    <div className='relative group flex space-x-1'>
                      {opportunity.attributes &&
                        opportunity.attributes.minPrice && (
                          <div className='relative group'>
                            <ArrowDownIcon
                              className='w-6 h-6 text-green-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `minPrice-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `minPrice-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Minimum Price
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.attributes &&
                        opportunity.attributes.sold_count_30days && (
                          <div className='relative group'>
                            <FireIcon
                              className='w-6 h-6 text-red-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `soldCount-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `soldCount-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Fast Moving (30 days)
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.attributes &&
                        opportunity.attributes.saturated_listing && (
                          <div className='relative group'>
                            <ExclamationIcon
                              className='w-6 h-6 text-yellow-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `saturatedListing-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `saturatedListing-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Saturated Listing
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.attributes &&
                        opportunity.attributes.sold_listing && (
                          <div className='relative group'>
                            <ShoppingCartIcon
                              className='w-6 h-6 text-red-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `soldListing-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `soldListing-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Sold Listing
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.latestShippingCost === 0 && (
                        <div className='relative group'>
                          <TruckIcon
                            className='w-6 h-6 text-indigo-500 cursor-pointer'
                            onMouseEnter={() =>
                              setHoveredAttribute(
                                `freeShipping-${opportunity.itemId}`
                              )
                            }
                            onMouseLeave={() => setHoveredAttribute(null)}
                          />
                          {hoveredAttribute ===
                            `freeShipping-${opportunity.itemId}` && (
                            <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                              Free Shipping
                              <svg
                                className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                              >
                                <path d='M10 10l-10 10h20L10 10z' />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      {opportunity.topRatedBuyingExperience && (
                        <div className='relative group'>
                          <BadgeCheckIcon
                            className='w-6 h-6 text-purple-500 cursor-pointer'
                            onMouseEnter={() =>
                              setHoveredAttribute(
                                `topRated-${opportunity.itemId}`
                              )
                            }
                            onMouseLeave={() => setHoveredAttribute(null)}
                          />
                          {hoveredAttribute ===
                            `topRated-${opportunity.itemId}` && (
                            <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                              Top Rated Buying Experience
                              <svg
                                className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                              >
                                <path d='M10 10l-10 10h20L10 10z' />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      {opportunity.buyingOptions &&
                        opportunity.buyingOptions.includes('BEST_OFFER') && (
                          <div className='relative group'>
                            <CurrencyDollarIcon
                              className='w-6 h-6 text-green-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `bestOffer-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `bestOffer-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Best Offer
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.buyingOptions &&
                        opportunity.buyingOptions.includes('AUCTION') && (
                          <div className='relative group'>
                            <GlobeAltIcon
                              className='w-6 h-6 text-blue-500 cursor-pointer'
                              onMouseEnter={() =>
                                setHoveredAttribute(
                                  `auction-${opportunity.itemId}`
                                )
                              }
                              onMouseLeave={() => setHoveredAttribute(null)}
                            />
                            {hoveredAttribute ===
                              `auction-${opportunity.itemId}` && (
                              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                                Auction
                                <svg
                                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                  fill='currentColor'
                                  viewBox='0 0 20 20'
                                >
                                  <path d='M10 10l-10 10h20L10 10z' />
                                </svg>
                              </div>
                            )}
                          </div>
                        )}
                      {opportunity.seller && opportunity.seller.feedbackScore <= 50 && (
                        <div className='relative group'>
                          <UserIcon
                            className='w-6 h-6 text-gray-500 cursor-pointer'
                            onMouseEnter={() =>
                              setHoveredAttribute(`lowReviews-${opportunity.itemId}`)
                            }
                            onMouseLeave={() => setHoveredAttribute(null)}
                          />
                          {hoveredAttribute === `lowReviews-${opportunity.itemId}` && (
                            <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-30'>
                              Inexperienced Seller (Low Reviews)
                              <svg
                                className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                              >
                                <path d='M10 10l-10 10h20L10 10z' />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                  <td
                    className='px-4 py-3'
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    {opportunity.generalized_condition
                      ? opportunity.generalized_condition.toUpperCase()
                      : 'N/A'}
                  </td>
                  <td
                    className='px-4 py-3'
                    onClick={() => openOpportunityModal(opportunity)}
                  >
                    {opportunity.searchTerm}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='table-last-item' />
      </div>
    </div>
  )
}

export default OpportunityTable

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL + '/api/settings/';

const createTicket = async (ticketData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + 'ticket', ticketData, config);
  return response.data;
};

const sendVerificationEmail = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL + 'send-verification-email',
    { send: true }, // we need this so it returns a response otherwise the backend function will not in this case
    config
  );
  return response.data;
};

const verifyCode = async (verificationCode, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL + 'verify-code',
    { verificationCode },
    config
  );
  return response.data;
};

const disable2FA = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + 'disable-2fa', {}, config);
  return response.data;
};

const getNotificationSettings = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + 'notification-settings', config);
  return response.data;
};

const updateNotificationSettings = async (settings, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL + 'notification-settings', settings, config);
  return response.data;
};

const settingsService = {
  createTicket,
  sendVerificationEmail,
  verifyCode,
  disable2FA,
  getNotificationSettings,
  updateNotificationSettings,
};

export default settingsService;

import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

// Get Reddit OAuth authorization URL from the backend
export const getRedditAuthUrl = async (token, id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id,
      },
    };
    const response = await axios.get(`${apiUrl}/api/social-media/reddit/auth-url`, config);
    return response.data.redditAuthUrl;
  } catch (error) {
    throw error;
  }
};

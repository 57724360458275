// CustomFieldsManager.jsx

import React, { useState } from 'react';
import GeneralCustomFields from './GeneralCustomFields';
import PlatformSpecificCustomFields from './PlatformSpecificCustomFields';
import { CollectionIcon } from '@heroicons/react/solid';

const tabs = [
  { key: 'general', label: 'General' },
  { key: 'platformSpecific', label: 'Platform-Specific' },
];

const CustomFieldsManager = () => {
  const [selectedTab, setSelectedTab] = useState('general');

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'general':
        return <GeneralCustomFields />;
      case 'platformSpecific':
        return <PlatformSpecificCustomFields />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white dark:bg-gray-800">
      {/* Header and Sub Navigation Tabs */}
      <div className="mb-6 border-b border-gray-200 dark:border-gray-700">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
          {/* Header */}
          <div className="flex items-center space-x-2">
            <CollectionIcon className="w-6 h-6 text-indigo-500" />
            <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
              Custom Fields
            </h2>
          </div>
          {/* Tabs */}
          <nav
            className="mt-4 sm:mt-0 flex space-x-4"
            aria-label="Sub Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.key}
                onClick={() => setSelectedTab(tab.key)}
                className={`whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm ${selectedTab === tab.key
                    ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                    : 'border-transparent text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100'
                  }`}
              >
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Tab Content */}
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default CustomFieldsManager;

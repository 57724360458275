import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const NewListingsBySearchTermChart = ({ newListingsCounts }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  // Get top 10 search terms with the most listings
  const sortedListingsCounts = Object.entries(newListingsCounts)
    .sort(([, a], [, b]) => b - a) // Sort descending by count
    .slice(0, 10); // Take the top 10

  const searchTermLabels = sortedListingsCounts.map(([term]) => term);
  const listingsCounts = sortedListingsCounts.map(([, count]) => count);

  const chartOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
      toolbar: {
        show: false, // Remove the download option and toolbar
      },
    },
    xaxis: {
      categories: searchTermLabels,
      labels: {
        style: {
          colors: textColor,
          fontSize: '12px',
        },
        rotate: -45, // Rotate labels for better readability
      },
    },
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: themeMode,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(0)} listings`,
      },
    },
    colors: themeMode === 'dark' ? ['#6ab04c'] : ['#2980b9'], // Adjust color based on theme
    plotOptions: {
      bar: {
        horizontal: true, // Use horizontal bars for better readability
        distributed: true, // Different color per bar
        barHeight: '50%', // Adjust bar height for better appearance
      },
    },
  };

  const chartData = [
    {
      name: 'New Listings',
      data: listingsCounts,
    },
  ];

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4">
        <div className="text-xl font-bold text-gray-800 dark:text-white">
          Terms with Highest # of Results
        </div>
      </div>

      <Chart
        options={chartOptions}
        series={chartData}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default NewListingsBySearchTermChart;

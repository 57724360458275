import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

import CategoryGallery from '../components/ProductResearch/CategoryGallery';
import SearchActionBar from '../components/ProductResearch/SearchActionBar';
import SearchTermCard from '../components/ProductResearch/SearchTermCard';
import {
  fetchUserSearchTermStatistics,
  deleteUserSearchTerm,
} from '../features/productResearch/productResearchSlice';
import SplineGraph from '../components/ProductResearch/SplineGraph';
import Spinner from '../components/Spinner';
import DeleteProductModal from '../components/Dashboard/Ecommerce/DeleteProductModal';

function ProductResearchPage() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const {
    userSearchTermStatistics,
    last30DaysData,
    loading_stats,
    error,
    dataFetched,
  } = useSelector((state) => state.productResearch);
  const scrollContainerRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('silverback_desc');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isCategoryGalleryExpanded, setIsCategoryGalleryExpanded] = useState(true);

  useEffect(() => {
    if (user && !dataFetched) {
      dispatch(fetchUserSearchTermStatistics({ userId: user._id }));
    }
  }, [dispatch, user, dataFetched]);

  useEffect(() => {
    const handleDarkModeChange = () => {
      setIsDarkMode(document.documentElement.classList.contains('dark'));
    };

    handleDarkModeChange();

    const observer = new MutationObserver(handleDarkModeChange);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class'],
    });

    return () => observer.disconnect();
  }, []);

  const handleDeleteClick = (termKey) => {
    setSelectedTerm(termKey);
    setIsModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (user && selectedTerm) {
      dispatch(
        deleteUserSearchTerm({ userId: user._id, termKey: selectedTerm })
      );
      setIsModalOpen(false);
      setSelectedTerm(null);
    }
  };

  const handleDeleteCancel = () => {
    setIsModalOpen(false);
    setSelectedTerm(null);
  };

  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  // Extract dates when search terms were created
  const searchTermCreationDates = userSearchTermStatistics.map((termStat) => ({
    term: termStat.term,
    dateCreated: termStat.createdAt,
  }));

  // Updated to calculate the ratio correctly and sort terms based on it
  const filteredAndSortedTerms = userSearchTermStatistics
    .filter((termStat) =>
      termStat.term.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((termStat) => {
      const silverbackStrongRatio = termStat.itemCount
        ? ((termStat.silverbackBuyCount + termStat.strongBuyCount) / termStat.itemCount).toFixed(4)
        : 0;
      return {
        ...termStat,
        silverbackStrongRatio: parseFloat(silverbackStrongRatio),  // Ensure it's a number for sorting
      };
    })
    .sort((a, b) => {
      switch (sortOrder) {
        case 'silverback_desc':
          return b.silverbackBuyCount - a.silverbackBuyCount;
        case 'silverback_asc':
          return a.silverbackBuyCount - b.silverbackBuyCount;
        case 'date_asc':
          return new Date(a.createdAt) - new Date(b.createdAt);
        case 'date_desc':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'ratio_asc':
          return a.silverbackStrongRatio - b.silverbackStrongRatio;
        case 'ratio_desc':
          return b.silverbackStrongRatio - a.silverbackStrongRatio;
        default:
          return 0;
      }
    });

  const toggleCategoryGallery = () => {
    setIsCategoryGalleryExpanded(!isCategoryGalleryExpanded);
  };

  return (
    <div className='flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 p-4'>
      <div className='flex flex-col md:flex-row items-center justify-between p-6 bg-white rounded-xl dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border'>
        <div className='flex items-center space-x-4'>
          <SearchIcon className='w-10 h-10 text-indigo-600 dark:text-indigo-400' />
          <h1 className='text-3xl font-bold text-gray-900 dark:text-white'>
            Product Research Dashboard
          </h1>
        </div>
      </div>
      <SearchActionBar />
      <div className='w-full pt-6 mx-auto bg-white dark:bg-gray-900 rounded-2xl shadow-xl px-6 lg:px-12 py-8'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-bold text-gray-800 dark:text-white'>
            Categories
          </h2>
          <button
            onClick={toggleCategoryGallery}
            className='flex items-center text-gray-800 dark:text-gray-200'
          >
            {isCategoryGalleryExpanded ? (
              <>
                <ChevronUpIcon className='w-5 h-5 mr-1' />
                <span>Collapse</span>
              </>
            ) : (
              <>
                <ChevronDownIcon className='w-5 h-5 mr-1' />
                <span>Expand</span>
              </>
            )}
          </button>
        </div>
        {isCategoryGalleryExpanded && <CategoryGallery />}
        {loading_stats ? (
          <>
            <h2 className='text-2xl font-bold text-gray-700 dark:text-gray-300 mb-4'>
              Performing Search Term Research...
            </h2>
            <Spinner />
          </>
        ) : error ? (
          <div className='text-red-500 dark:text-red-400'>Error: {error}</div>
        ) : (
          <>
            {userSearchTermStatistics.length > 0 ? (
              <>
                <div className='mt-8'>
                  <h2 className='text-2xl font-bold text-gray-800 dark:text-white mb-2'>
                    Search Term Info
                  </h2>
                  <div className='flex flex-col md:flex-row md:justify-between md:items-center mb-4'>
                    <div className='flex items-center mb-4 md:mb-0'>
                      <label className='mr-2 text-gray-700 dark:text-gray-300'>
                        Sort by:
                      </label>
                      <select
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        className='p-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white'
                      >
                        <option value='silverback_desc'>Silverback Buys: Highest to Lowest</option>
                        <option value='silverback_asc'>Silverback Buys: Lowest to Highest</option>
                        <option value='date_desc'>Date Created: Newest to Oldest</option>
                        <option value='date_asc'>Date Created: Oldest to Newest</option>
                        <option value='ratio_desc'>Silverback/Strong Buys %: Highest to Lowest</option>
                        <option value='ratio_asc'>Silverback/Strong Buys %: Lowest to Highest</option>
                      </select>
                    </div>
                    <div className='relative flex items-center bg-gray-100 dark:bg-gray-800 p-2 rounded-lg shadow-md'>
                      <input
                        type='text'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder='Search terms...'
                        className='p-2 bg-transparent border-none focus:outline-none dark:text-white'
                      />
                      <SearchIcon className='w-5 h-5 text-gray-500 dark:text-gray-400 ml-2' />
                    </div>
                  </div>
                  <h3 className='text-xl text-gray-600 dark:text-gray-400 mb-6'>
                    {userSearchTermStatistics.length} Search Terms Added
                  </h3>
                  <div className='relative'>
                    <div
                      className='overflow-x-auto pb-4'
                      style={{ paddingBottom: '1rem' }}
                      ref={scrollContainerRef}
                    >
                      <div className='flex space-x-4 pr-4'>
                        {filteredAndSortedTerms.map((termStat) => (
                          <SearchTermCard
                            key={termStat.termKey}
                            termStat={termStat}
                            onDelete={() => handleDeleteClick(termStat.termKey)}
                          />
                        ))}
                      </div>
                    </div>
                    <button
                      onClick={scrollLeft}
                      className='absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 transition-colors duration-300'
                    >
                      <ChevronLeftIcon className='w-6 h-6' />
                    </button>
                    <button
                      onClick={scrollRight}
                      className='absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full shadow-lg hover:bg-gray-700 transition-colors duration-300'
                    >
                      <ChevronRightIcon className='w-6 h-6' />
                    </button>
                  </div>
                </div>
                <div className='mt-8'>
                  <SplineGraph 
                    data={last30DaysData} 
                    isDarkMode={isDarkMode}
                    searchTermCreationDates={searchTermCreationDates}  // Pass down creation dates
                  />
                </div>
              </>
            ) : (
              <div className='mt-8 flex items-center justify-center text-xl text-gray-600 dark:text-gray-400'>
                <InformationCircleIcon className='w-8 h-8 text-blue-500 mr-2' />
                No search terms added yet. Please add terms through the product
                research page to see statistics for your search terms.
              </div>
            )}
          </>
        )}
        <DeleteProductModal
          isOpen={isModalOpen}
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          product={selectedTerm}
        />
      </div>
    </div>
  );
}

export default ProductResearchPage;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getInventoryMetrics,
  getArbitrageOpportunitiesMetrics,
  getInventoryGrowthMetrics,
  getAnalyzedListingsMetrics,
  getSearchTermsMetrics,
  getInventoryHistory,
  getInventoryAgeMetrics,
  getAnalyzedListingsMetricsV2,
  getWeeklyInventoryMetrics,
  getUserPurchases,
  getTopSearchTermsMetrics,
  getTopOrderSearchTermsMetrics,
  getOrderRevenueOverTime,
} from '../../features/metrics/metricsSlice';
import InventoryInvestmentVsRevenueChart from '../../components/Dashboard/Metrics/InventoryInvestmentVsRevenueChart';
import RecentArbitragePurchases from '../../components/Dashboard/Metrics/RecentArbitragePurchases';
import SummaryMetrics from '../../components/Dashboard/Metrics/SummaryMetrics';
import InventoryCostChart from '../../components/Dashboard/Metrics/InventoryCostChart';
import InventoryAgeChart from '../../components/Dashboard/Metrics/InventoryAgeChart';
import AnalyzedListingsStatusChart from '../../components/Dashboard/Metrics/AnalyzedListingsStatusChart';
import NewListingsBySearchTermChart from '../../components/Dashboard/Metrics/NewListingsBySearchTermChart'; // Import the new chart component
import WeeklyInventoryChart from '../../components/Dashboard/Metrics/WeeklyInventoryChart'; // Import the new chart component
import HighestSpendChart from '../../components/Dashboard/Metrics/HighestSpendChart'; // Import the new chart component
import MostFrequentlyPurchasedChart from '../../components/Dashboard/Metrics/MostFrequentlyPurchasedChart'; // Import the new chart component
import TopOrderSearchTermsChart from '../../components/Dashboard/Metrics/TopOrderSearchTermsChart'; // Import the new chart component
import OrderRevenueOverTimeChart from '../../components/Dashboard/Metrics/OrderRevenueOverTimeChart'; // Import the new chart component


const Metrics = () => {
  const dispatch = useDispatch();

  const inventoryMetricsState = useSelector((state) => state.metrics || {});
  const inventoryHistoryState = useSelector((state) => state.metrics.inventoryHistory || []);
  const inventoryAgeMetricsState = useSelector((state) => state.metrics.inventoryAgeMetrics || {});
  const analyzedListingsMetricsV2State = useSelector((state) => state.metrics.statusCounts || {});
  const newListingsCountsState = useSelector((state) => state.metrics.newListingsCounts || {});
  const weeklyInventoryMetricsState = useSelector((state) => state.metrics.weeklyInventoryMetrics || {});
  const userPurchasesState = useSelector((state) => state.metrics.purchases || {});
  const topSearchTermsMetrics = useSelector((state) => state.metrics.topSearchTermsMetrics || []);
  const topOrderSearchTermsMetrics = useSelector((state) => state.metrics.topOrderSearchTermsMetrics || []);
  const orderRevenueOverTimeState = useSelector((state) => state.metrics.orderRevenueOverTime || []);
  const authState = useSelector((state) => state.auth || {});
  const userId = authState.user ? authState.user._id : null;

  useEffect(() => {
    dispatch(getInventoryMetrics());
    dispatch(getArbitrageOpportunitiesMetrics());
    dispatch(getInventoryGrowthMetrics());
    dispatch(getAnalyzedListingsMetrics());
    dispatch(getSearchTermsMetrics(userId));
    dispatch(getInventoryHistory());
    dispatch(getInventoryAgeMetrics());
    dispatch(getAnalyzedListingsMetricsV2());
    dispatch(getWeeklyInventoryMetrics());
    dispatch(getUserPurchases());
    dispatch(getTopSearchTermsMetrics());
    dispatch(getTopOrderSearchTermsMetrics());
    dispatch(getOrderRevenueOverTime());
  }, [dispatch, userId]);

  const metricsData =
    inventoryMetricsState.inventoryMetrics?.length > 0
      ? inventoryMetricsState.inventoryMetrics
      : [
          {
            _id: new Date().toISOString().slice(0, 10),
            totalPurchaseCost: 0,
            totalPotentialRevenue: 0,
          },
        ];

  return (
    <main className="bg-gray-50 dark:bg-gray-900 p-4 mt-2 lg:mt-0">
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg col-span-1 xl:col-span-2 dark:border-gray-700">
          {inventoryMetricsState.isLoading ? (
            <p className="text-gray-700 dark:text-gray-300 p-4">Loading charts...</p>
          ) : inventoryMetricsState.isError ? (
            <p className="text-red-500 p-4">Error: {inventoryMetricsState.message}</p>
          ) : (
            <InventoryInvestmentVsRevenueChart metricsData={metricsData} />
          )}
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6 xl:p-8 border border-gray-200 dark:border-gray-700">
          {userPurchasesState.isLoading ? (
            <p className="text-gray-700 dark:text-gray-300">Loading purchases...</p>
          ) : userPurchasesState.isError ? (
            <p className="text-red-500">Error: {userPurchasesState.message}</p>
          ) : (
            <RecentArbitragePurchases purchases={userPurchasesState || []} />
          )}
        </div>
      </div>

      <div className="mt-6">
        <SummaryMetrics />
      </div>

      <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {inventoryMetricsState.isLoading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading inventory history...</p>
        ) : inventoryMetricsState.isError ? (
          <p className="text-red-500">Error: {inventoryMetricsState.message}</p>
        ) : (
          <InventoryCostChart inventoryHistory={inventoryHistoryState} />
        )}

        {inventoryMetricsState.isLoading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading inventory age metrics...</p>
        ) : inventoryMetricsState.isError ? (
          <p className="text-red-500">Error: {inventoryMetricsState.message}</p>
        ) : (
          <InventoryAgeChart inventoryAgeMetrics={inventoryAgeMetricsState} />
        )}

        {analyzedListingsMetricsV2State.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading listings status metrics...</p>
        ) : analyzedListingsMetricsV2State.error ? (
          <p className="text-red-500">Error: {analyzedListingsMetricsV2State.message}</p>
        ) : (
          <AnalyzedListingsStatusChart statusCounts={analyzedListingsMetricsV2State} />
        )}
      </div>

      {/* New row for the New Listings by Search Term Chart */}
      <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {newListingsCountsState.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading new listings metrics...</p>
        ) : newListingsCountsState.error ? (
          <p className="text-red-500">Error: {newListingsCountsState.message}</p>
        ) : (
          <NewListingsBySearchTermChart newListingsCounts={newListingsCountsState} />
        )}

        {weeklyInventoryMetricsState.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading weekly inventory metrics...</p>
        ) : weeklyInventoryMetricsState.error ? (
          <p className="text-red-500">Error: {weeklyInventoryMetricsState.message}</p>
        ) : (
          <WeeklyInventoryChart weeklyInventoryMetrics={weeklyInventoryMetricsState} />
        )}

        {topSearchTermsMetrics.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading weekly inventory metrics...</p>
        ) : topSearchTermsMetrics.error ? (
          <p className="text-red-500">Error: {topSearchTermsMetrics.message}</p>
        ) : (
          <HighestSpendChart data={topSearchTermsMetrics} />
        )}
      </div>

      <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
        {topSearchTermsMetrics.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading weekly inventory metrics...</p>
        ) : topSearchTermsMetrics.error ? (
          <p className="text-red-500">Error: {topSearchTermsMetrics.message}</p>
        ) : (
          <MostFrequentlyPurchasedChart data={topSearchTermsMetrics} />
        )}

        {topOrderSearchTermsMetrics.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading top order search terms metrics...</p>
        ) : topOrderSearchTermsMetrics.error ? (
          <p className="text-red-500">Error: {topOrderSearchTermsMetrics.message}</p>
        ) : (
          <TopOrderSearchTermsChart data={topOrderSearchTermsMetrics} />
        )}

        {orderRevenueOverTimeState.loading ? (
          <p className="text-gray-700 dark:text-gray-300">Loading order revenue metrics...</p>
        ) : orderRevenueOverTimeState.error ? (
          <p className="text-red-500">Error: {orderRevenueOverTimeState.message}</p>
        ) : (
          <OrderRevenueOverTimeChart data={orderRevenueOverTimeState} />
        )}
      </div>
    </main>
  );
};

export default Metrics;

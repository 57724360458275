import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const WeeklyInventoryChart = ({ weeklyInventoryMetrics }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const chartData = {
    categories: weeklyInventoryMetrics.map((week) =>
      `${new Date(week.weekStart).toLocaleDateString()} - ${new Date(week.weekEnd).toLocaleDateString()}`
    ),
    series: [{
      name: 'Inventory Count',
      data: weeklyInventoryMetrics.map(week => week.totalInventoryCount),
    }],
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    fill: {
      colors: ['#34a853'],
    },
    tooltip: {
      y: {
        formatter: (val) => `${val} units`,
      },
    },
    theme: {
      mode: themeMode,
    },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4">
        <div className="text-xl font-bold text-gray-800 dark:text-white">
          Weekly Inventory Additions
        </div>
      </div>

      <Chart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default WeeklyInventoryChart;

import React from 'react'
import {
  XCircleIcon,
  StarIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  ArrowDownIcon,
  FireIcon,
  ExclamationIcon,
  ShoppingCartIcon,
  TruckIcon,
  BadgeCheckIcon,
  PlusIcon,
  StatusOnlineIcon,
  GlobeAltIcon,
  CheckCircleIcon,
  UserIcon,
} from '@heroicons/react/solid'

import silverbackImage from '../../../images/silverback.png'
import monkeyImage from '../../../images/monkey.png'
import smallMonkeyImage from '../../../images/small_monkey.png'  // Added small monkey image
import chimpImage from '../../../images/chimp.png'
import bananaImage from '../../../images/banana.png'

const DataCard = ({
  opportunity,
  openOpportunityModal,
  handleCheckboxChange,
  handleSelectItem,
  selectedItems,
  openModal,
  openImageModal,
}) => {
  const calculateBreakEvenPrice = (price) => {
    const taxRate = 0.1
    const tax = price * taxRate
    const breakEvenPrice = (price + tax) / 0.9
    return breakEvenPrice.toFixed(2)
  }

  const potentialProfit = (averagePrice, currentPrice) => {
    return (averagePrice - currentPrice).toFixed(2)
  }

  function formatDate(dateString) {
    const date = new Date(dateString)
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }

  const potentialProfitPercentage = (averagePrice, currentPrice) => {
    return (((averagePrice - currentPrice) / averagePrice) * 100).toFixed(2)
  }

  const formatPrice = (price) => {
    return price ? price.toFixed(2) : 'N/A'
  }

  const highlightClass =
    opportunity.status === 'watching'
      ? 'bg-gradient-to-r from-indigo-300 to-indigo-500 dark:from-indigo-600 dark:to-indigo-800 border'
      : ''

  return (
    <div
      className='bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 rounded-lg shadow-md p-2 sm:p-2 transition-transform transform hover:scale-95 cursor-pointer'
      onClick={() => openOpportunityModal(opportunity)}
    >
      <div
        className={`bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-2 rounded-t-lg text-white ${highlightClass} mb-1`}
      >
        <div className='flex justify-between items-center'>
          <div className='flex items-center space-x-2'>
            <input
              id={`checkbox-${opportunity.itemId}`}
              type='checkbox'
              className='w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              checked={selectedItems.includes(opportunity.itemId)}
              onChange={() => handleCheckboxChange(opportunity.itemId)}
              onClick={(e) => e.stopPropagation()}
            />
            <label
              htmlFor={`checkbox-${opportunity.itemId}`}
              className='sr-only'
            >
              checkbox
            </label>
            <div className='relative group'>
              <XCircleIcon
                className='w-6 h-6 text-red-500 cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation()
                  handleSelectItem(opportunity.itemId, 'passed')
                }}
              />
              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                Pass
                <svg
                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M10 10l-10 10h20L10 10z' />
                </svg>
              </div>
            </div>
            <div className='relative group'>
              {opportunity.status === 'watching' ? (
                <CheckCircleIcon
                  className='w-6 h-6 text-green-500 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelectItem(opportunity.itemId, 'pending')
                  }}
                />
              ) : (
                <StarIcon
                  className='w-6 h-6 text-yellow-500 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelectItem(opportunity.itemId, 'watching')
                  }}
                />
              )}
              <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                {opportunity.status === 'watching' ? 'Watched' : 'Watch'}
                <svg
                  className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M10 10l-10 10h20L10 10z' />
                </svg>
              </div>
            </div>
          </div>
          <div className='flex items-center space-x-2'>
            <div className='relative group'>
                {opportunity.Label === 'Silverback Buy' ? (
                  <img
                    src={silverbackImage}
                    alt='Silverback Buy'
                    className='w-8 h-8 cursor-pointer'
                    title='Silverback Buy'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('labelInfo')
                    }}
                  />
                ) : opportunity.Label === 'Strong Buy' ? (
                  <img
                    src={chimpImage}
                    alt='Strong Buy'
                    className='w-8 h-8 cursor-pointer'
                    title='Strong Buy'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('labelInfo')
                    }}
                  />
                ) : opportunity.Label === 'Mild Buy' ? (
                  <img
                    src={monkeyImage}
                    alt='Mild Buy'
                    className='w-8 h-8 cursor-pointer'
                    title='Mild Buy'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('labelInfo')
                    }}
                  />
                ) : opportunity.Label === 'Buy' ? (
                  <img
                    src={smallMonkeyImage}
                    alt='Buy'
                    className='w-8 h-8 cursor-pointer'
                    title='Buy'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('labelInfo')
                    }}
                  />
                ) : (
                  <img
                    src={bananaImage}
                    alt='N/A'
                    className='w-8 h-8 cursor-pointer'
                    title='N/A'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('labelInfo')
                    }}
                  />
                )}
                <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                  {opportunity.Label}
                  <svg
                    className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                  >
                    <path d='M10 10l-10 10h20L10 10z' />
                  </svg>
                </div>
              </div>
            <div className='flex space-x-2'>
              {opportunity.attributes && opportunity.attributes.minPrice && (
                <div className='relative group'>
                  <ArrowDownIcon
                    className='w-6 h-6 text-green-500 cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('attributesInfo')
                    }}
                  />
                  <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                    Minimum Price
                    <svg
                      className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 10l-10 10h20L10 10z' />
                    </svg>
                  </div>
                </div>
              )}
              {opportunity.attributes &&
                opportunity.attributes.sold_count_30days && (
                  <div className='relative group'>
                    <FireIcon
                      className='w-6 h-6 text-red-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Fast Moving (30 days)
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
              {opportunity.attributes &&
                opportunity.attributes.saturated_listing && (
                  <div className='relative group'>
                    <ExclamationIcon
                      className='w-6 h-6 text-red-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Saturated Listing
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
              {opportunity.attributes &&
                opportunity.attributes.sold_listing && (
                  <div className='relative group'>
                    <ShoppingCartIcon
                      className='w-6 h-6 text-red-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Sold Listing
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
              {opportunity.lastShippingCost === 0 && (
                <div className='relative group'>
                  <TruckIcon
                    className='w-6 h-6 text-indigo-500 cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('attributesInfo')
                    }}
                  />
                  <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                    Free Shipping
                    <svg
                      className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 10l-10 10h20L10 10z' />
                    </svg>
                  </div>
                </div>
              )}
              {opportunity.topRatedBuyingExperience && (
                <div className='relative group'>
                  <BadgeCheckIcon
                    className='w-6 h-6 text-purple-500 cursor-pointer'
                    onClick={(e) => {
                      e.stopPropagation()
                      openModal('attributesInfo')
                    }}
                  />
                  <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                    Top Rated Buying Experience
                    <svg
                      className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                    >
                      <path d='M10 10l-10 10h20L10 10z' />
                    </svg>
                  </div>
                </div>
              )}
              {opportunity.buyingOptions &&
                opportunity.buyingOptions.includes('BEST_OFFER') && (
                  <div className='relative group'>
                    <CurrencyDollarIcon
                      className='w-6 h-6 text-green-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Best Offer
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
              {opportunity.buyingOptions &&
                opportunity.buyingOptions.includes('AUCTION') && (
                  <div className='relative group'>
                    <GlobeAltIcon
                      className='w-6 h-6 text-blue-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Auction
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
                {opportunity.seller && opportunity.seller.feedbackScore <= 50 && (
                  <div className='relative group'>
                    <UserIcon
                      className='w-6 h-6 text-gray-500 cursor-pointer'
                      onClick={(e) => {
                        e.stopPropagation()
                        openModal('attributesInfo')
                      }}
                    />
                    <div className='absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50'>
                      Inexperienced Seller (Low Reviews)
                      <svg
                        className='absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 10l-10 10h20L10 10z' />
                      </svg>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className='relative mb-1 flex flex-row sm:flex-row'>
        <div className='relative w-1/4 sm:w-24 md:w-32 h-24 md:h-32 overflow-hidden rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 border'>
          <img
            src={opportunity.image.imageUrl}
            alt={opportunity.title}
            className='w-full h-full object-cover'
          />
          <div
            className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-25 opacity-0 hover:opacity-100 transition-opacity duration-300'
            onClick={(e) => {
              e.stopPropagation()
              openImageModal(opportunity)
            }}
          >
            <PlusIcon className='w-8 h-8 text-white cursor-pointer' />
          </div>
          <p
            className='absolute bottom-0 left-0 w-full text-center bg-gray-900 bg-opacity-75 text-white text-xs p-1'
            onClick={(e) => {
              e.stopPropagation()
              openImageModal(opportunity)
            }}
          >
            Arbi Identified on: {formatDate(opportunity.dateArbieFound)}
          </p>
        </div>
        <div className='ml-2 sm:ml-4 mt-0 sm:mt-0 flex flex-col justify-between flex-1'>
          <h2 className='text-lg md:text-xl font-bold text-gray-900 dark:text-white'>
            <span title={opportunity.title}>
              {opportunity.title.length > 30
                ? opportunity.title.substring(0, 30) + '...'
                : opportunity.title}
            </span>
          </h2>

          <p className='text-gray-500 dark:text-gray-400 text-sm'>
            Search Term: {opportunity.searchTerm}
          </p>
          <div className='grid grid-cols-2 gap-2 mt-2'>
            <div className='flex items-center'>
              <StatusOnlineIcon className='w-5 h-5 text-yellow-600' />
              <span className='text-sm text-gray-900 dark:text-white ml-1'>
                Condition:
              </span>
            </div>
            <span className='text-sm text-gray-900 dark:text-white'>
              {opportunity.condition}
            </span>

            <div className='flex items-center'>
              <CalculatorIcon className='w-5 h-5 text-indigo-600' />
              <span className='text-sm text-gray-900 dark:text-white ml-1'>
                Avg. Lifetime Price:
              </span>
            </div>
            <span className='text-sm text-gray-900 dark:text-white'>
              $
              {opportunity.summary_statistics
                ? formatPrice(
                    opportunity.summary_statistics.averageLifeTimePrice
                  )
                : 'N/A'}
            </span>
            <div className='flex items-center'>
              <CurrencyDollarIcon className='w-5 h-5 text-red-500' />
              <span className='text-sm text-gray-900 dark:text-white ml-1'>
                Break-Even Price:
              </span>
            </div>
            <span className='text-sm text-gray-900 dark:text-white'>
              ${calculateBreakEvenPrice(opportunity.latestPrice)}
            </span>

            <div className='flex items-center'>
              <CurrencyDollarIcon className='w-5 h-5 text-green-500' />
              <span className='text-sm text-gray-900 dark:text-white ml-1'>
                Current Price:
              </span>
            </div>
            <span className='text-sm text-gray-900 dark:text-white'>
              ${formatPrice(opportunity.latestPrice)}
            </span>

            {opportunity.summary_statistics &&
              opportunity.summary_statistics.averageLifeTimePrice && (
                <div className='col-span-2 flex justify-between items-center'>
                  <div className='flex items-center'>
                    <CurrencyDollarIcon className='w-5 h-5 text-purple-500' />
                    <span className='text-sm text-gray-900 dark:text-white ml-1'>
                      Potential Profit:
                    </span>
                    <span className='text-sm text-gray-900 dark:text-white ml-1'>
                      $
                      {potentialProfit(
                        opportunity.summary_statistics.averageLifeTimePrice,
                        opportunity.latestPrice
                      )}
                    </span>
                    <span className='text-sm text-gray-900 dark:text-white ml-1'>
                      (
                      {potentialProfitPercentage(
                        opportunity.summary_statistics.averageLifeTimePrice,
                        opportunity.latestPrice
                      )}
                      %)
                    </span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataCard

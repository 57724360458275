import React from 'react';
import { SearchIcon, ChartSquareBarIcon, PuzzleIcon, CogIcon, ShoppingCartIcon, ClipboardCheckIcon, SpeakerphoneIcon, UsersIcon } from '@heroicons/react/outline';

const features = [
  {
    icon: <SearchIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Product Sourcing',
    description: 'Efficiently discover and source products that drive your business forward.',
  },
  {
    icon: <ChartSquareBarIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Data Analysis',
    description: 'Harness data to make informed decisions that maximize your e-commerce potential.',
  },
  {
    icon: <PuzzleIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Process Automation',
    description: 'Automate tedious tasks to streamline operations and boost productivity.',
  },
  {
    icon: <CogIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'System Integration',
    description: 'Seamlessly integrate with your existing systems for a unified workflow.',
  },
  {
    icon: <ShoppingCartIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Multichannel Listing',
    description: 'List and manage your products across multiple channels with ease.',
  },
  {
    icon: <ClipboardCheckIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Order Fulfillment',
    description: 'Streamline the process of fulfilling orders to ensure customer satisfaction.',
  },
  {
    icon: <SpeakerphoneIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Build Brands',
    description: 'Boost your brand’s presence and engage customers through targeted campaigns.',
  },
  {
    icon: <UsersIcon className="w-8 h-8 text-indigo-500 dark:text-indigo-400" />,
    title: 'Customer Relationship Management',
    description: 'Build and maintain strong relationships with your customers.',
  },
];

const MissionSection = () => {
  return (
    <section className="w-full py-16 sm:py-24 text-gray-900 dark:text-white relative">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
        <h2 className="text-4xl sm:text-5xl font-bold text-black-900 dark:text-white mb-12 text-left">
          Empowering Your E-Commerce Journey
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col items-start">
              <div className="mb-3">
                {feature.icon}
              </div>
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">{feature.title}</h3>
              <p className="text-gray-600 dark:text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MissionSection;

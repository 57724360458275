import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendReferAFriendEmail } from './marketingService'; // Assuming you have a service for sending the email

// Initial state for the marketing slice
export const initialState = {
  referralEmail: '',
  loading: false,
  success: false,
  error: null,
};

// Async thunk for sending the refer-a-friend email
export const referAFriend = createAsyncThunk(
  'marketing/referAFriend',
  async ({ email }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token, user },
      } = getState()
      const response = await sendReferAFriendEmail(email, token, user.name);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          'Could not fetch user search term statistics'
      )
    }
  }
);

// Create the marketing slice
const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    resetReferralStatus: (state) => {
      state.success = false;
      state.error = null;
    },
    setReferralEmail: (state, action) => {
      state.referralEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(referAFriend.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(referAFriend.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(referAFriend.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
      });
  },
});

export const { resetReferralStatus, setReferralEmail } = marketingSlice.actions;

export default marketingSlice.reducer;

import React from 'react'
import { CalculatorIcon, CurrencyDollarIcon } from '@heroicons/react/solid'

import Modal from './Modal'

const PriceInfoModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-3xl font-bold mb-6 border-b-2 border-gray-200 pb-2'>
          Price Information
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
          <div className='flex items-center space-x-4'>
            <CalculatorIcon className='w-12 h-12 text-green-500' />
            <p className='text-lg'>
              <strong>Average Lifetime Price:</strong> Average price of this
              item in the dataset span.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <CurrencyDollarIcon className='w-12 h-12 text-green-500' />
            <p className='text-lg'>
              <strong>Break Even Price:</strong> Minimum price to cover all
              costs, fees, and taxes.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <span className='text-blue-500 text-3xl'>$</span>
            <p className='text-lg'>
              <strong>Price:</strong> Amount listed for the item without
              shipping cost and taxes.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PriceInfoModal

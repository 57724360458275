import React from 'react'

const TermsAndConditions = () => {
  return (
    <div className='mt-6 text-sm text-gray-500 dark:text-gray-400'>
      <p>
        By clicking "Submit Payment", you agree to our
        <a
          href='/terms'
          className='text-indigo-600 dark:text-indigo-400 hover:underline'
        >
          {' '}
          Terms and Conditions
        </a>{' '}
        and
        <a
          href='/privacy'
          className='text-indigo-600 dark:text-indigo-400 hover:underline'
        >
          {' '}
          Privacy Policy
        </a>
        .
      </p>
    </div>
  )
}

export default TermsAndConditions

import React, { useState, useEffect } from 'react';
import {
  InformationCircleIcon,
  ClipboardListIcon,
  UserIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  TruckIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { format } from 'date-fns';

const platformLogos = {
  ebay: {
    lightmodeImage: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
    darkmodeImage: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
  },
  default: {
    lightmodeImage: 'path/to/default-light.png',
    darkmodeImage: 'path/to/default-dark.png',
  },
};

const statusIcons = {
  payment: {
    pending: (
      <div className="flex items-center px-3 py-1.5 bg-yellow-100 text-yellow-800 text-sm font-semibold rounded-full">
        <ExclamationCircleIcon className="h-5 w-5 mr-1" />
        Pending Payment
      </div>
    ),
    completed: (
      <div className="flex items-center px-3 py-1.5 bg-green-100 text-green-800 text-sm font-semibold rounded-full">
        <CheckCircleIcon className="h-5 w-5 mr-1" />
        Payment Completed
      </div>
    ),
  },
  fulfillment: {
    pending: (
      <div className="flex items-center px-3 py-1.5 bg-yellow-100 text-yellow-800 text-sm font-semibold rounded-full">
        <TruckIcon className="h-5 w-5 mr-1" />
        Pending Fulfillment
      </div>
    ),
    shipped: (
      <div className="flex items-center px-3 py-1.5 bg-blue-100 text-blue-800 text-sm font-semibold rounded-full">
        <TruckIcon className="h-5 w-5 mr-1" />
        Shipped
      </div>
    ),
    delivered: (
      <div className="flex items-center px-3 py-1.5 bg-green-100 text-green-800 text-sm font-semibold rounded-full">
        <CheckCircleIcon className="h-5 w-5 mr-1" />
        Delivered
      </div>
    ),
    cancelled: (
      <div className="flex items-center px-3 py-1.5 bg-red-100 text-red-800 text-sm font-semibold rounded-full">
        <ExclamationCircleIcon className="h-5 w-5 mr-1" />
        Cancelled
      </div>
    ),
  },
};

const OrdersCard = ({ order, onViewDetails, onGenerateLabel }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const totalItems = order.items.length;

  // Safely handle the order date
  let orderDate = 'Unknown';
  if (order.orderDate) {
    try {
      orderDate = format(new Date(order.orderDate), 'MMMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting order date:', error);
    }
  }

  const totalAmount = order.totalAmount ? order.totalAmount.toFixed(2) : 'N/A';
  const platform = order.platformOrigin?.toLowerCase() || 'default';
  const logos = platformLogos[platform] || platformLogos.default;
  const isDarkMode = themeMode === 'dark';

  return (
    <div className="bg-white rounded-xl shadow-lg dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border p-4 flex flex-col space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-start">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div className="w-20 h-20">
            <img
              src={isDarkMode ? logos.darkmodeImage : logos.lightmodeImage}
              alt={`${platform} logo`}
              className="w-full h-full object-contain rounded-md"
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Order #{order.orderNumber}
            </h2>
            <p className="text-gray-500 dark:text-gray-400">
              Sold by {order.usernameConnectedAccount} on {orderDate}
            </p>
          </div>
        </div>

        {/* Centered buttons and indicators */}
        <div className="flex flex-col items-end justify-center space-y-2">
          <div className="flex space-x-2">
            <button
              onClick={onViewDetails}
              className="flex items-center px-3 py-1.5 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
            >
              <InformationCircleIcon className="h-5 w-5 mr-1" />
              View Details
            </button>
            <button
              onClick={onGenerateLabel}
              className="flex items-center px-3 py-1.5 bg-green-600 text-white rounded-md hover:bg-green-700 transition"
            >
              <ClipboardListIcon className="h-5 w-5 mr-1" />
              Generate Label
            </button>
          </div>
          <div className="flex space-x-2">
            {statusIcons.payment[order.paymentStatus]}
            {statusIcons.fulfillment[order.fulfilmentStatus]}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-1 mt-4">
        <div className="flex items-center space-x-2">
          <UserIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Seller:</strong> {order.sellerUsername}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <CurrencyDollarIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Total Amount:</strong> ${totalAmount}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <ShoppingCartIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Items Sold:</strong> {totalItems}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <TruckIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300">
            <strong>Shipping Service:</strong> {order.shippingService?.service || 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrdersCard;

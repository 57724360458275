import React, { useState } from 'react';
import { PencilIcon, TrashIcon, CheckCircleIcon, PlusIcon, ExclamationCircleIcon, ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/solid';

const ConnectionCard = ({
  platform,
  logoUrl,
  allowMultipleAccounts,
  requestLimit,
  allowedAccounts,
  defaultAccountType,
  isEligible,
  connections,
  isLoading,
  isError,
  onConnect,
  onEdit,
  onDelete,
  onReauthenticate,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteConnectionId, setDeleteConnectionId] = useState(null);

  const handleRefreshToken = (id, accountType, accountName) => {
    onReauthenticate({ platform, accountType, accountName, id });
  };

  const handleDelete = (connection) => {
    setDeleteConnectionId(connection.id);
    setShowDeleteModal(true);
    onDelete(connection);
  };

  const confirmDelete = () => {
    onDelete(deleteConnectionId, platform);
    setShowDeleteModal(false);
    setDeleteConnectionId(null);
  };

  const globalActivePlan = 'Accelerator'; // This should be fetched from the global state

  const getEligibilityStatus = () => {
    return isEligible ? (
      <span className="text-green-500 dark:text-green-300">Eligible to Connect</span>
    ) : (
      <span className="text-red-500">Upgrade to Unlock</span>
    );
  };

  const getAccountLimits = () => {
    const limits = allowedAccounts[globalActivePlan];
    return {
      selling: limits.sellingAccounts,
      buying: limits.buyingAccounts,
    };
  };

  const accountLimits = getAccountLimits();
  const sellingCount = connections.filter((conn) => conn.accountType === 'selling').length;
  const buyingCount = connections.filter((conn) => conn.accountType === 'buying').length;

  const canAddSellingAccount = sellingCount < accountLimits.selling;
  const canAddBuyingAccount = buyingCount < accountLimits.buying;

  return (
    <div className="relative bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-4 shadow-lg rounded-lg border">
      <div className="flex justify-between items-center mb-4">
        <img src={logoUrl} alt={`${platform} Logo`} className="w-16 h-12 object-contain" />
        <div className="text-right">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-200">{platform} Connections</h2>
          <p className="text-sm">{getEligibilityStatus()}</p>
        </div>
      </div>

      {connections.length === 0 ? (
        <div className="flex justify-center">
          <button
            className="bg-green-500 text-white font-semibold py-2 px-4 rounded-full flex items-center"
            onClick={() => onConnect(defaultAccountType)}
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            Add Account
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-2 overflow-x-auto pb-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900">
          {connections.length > 1 && (
            <ArrowLeftIcon className="w-5 h-5 text-gray-500 cursor-pointer" />
          )}
          {connections.map((connection) => {
            const isConnected = connection.refreshTokenStatus;
            const requestRatio = isConnected ? `${connection.requestCount} / ${requestLimit}` : 'N/A';
            const daysUntilRefresh = connection.daysUntilRefreshTokenExpires;

            return (
              <div key={connection.id} className="bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-4 rounded-lg min-w-max border mr-2">
                <div className="flex justify-between items-center mb-2">
                  <p className="text-gray-800 dark:text-gray-200 font-medium">{connection.accountName}</p>
                  <div className="flex items-center space-x-1">
                    {isConnected ? (
                      <CheckCircleIcon className="w-5 h-5 text-green-500" />
                    ) : (
                      <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                    )}
                  </div>
                </div>
                <p className="text-gray-600 dark:text-gray-400 text-sm">Username: {connection.usernameConnectedAccount || 'N/A'}</p>
                <p className="text-gray-600 dark:text-gray-400 text-sm">Type: {connection.accountType}</p>
                <p className="text-gray-600 dark:text-gray-400 text-sm">Requests: {requestRatio}</p>
                <p className="text-gray-600 dark:text-gray-400 text-sm">Days until refresh: {daysUntilRefresh}</p>

                <div className="flex justify-between items-center space-x-2 mt-4">
                  <div className="relative group">
                    <button
                      className={`${isConnected ? 'bg-green-500' : 'bg-red-500'} text-white font-semibold py-2 px-3 rounded-full`}
                      onClick={() => handleRefreshToken(connection.id, connection.accountType, connection.accountName)}
                      disabled={isLoading}
                    >
                      {isConnected ? 'Connected' : 'Reconnect'}
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      {isConnected ? 'Connected' : 'Reconnect'}
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>
                  <div className="relative group">
                    <button
                      className="bg-indigo-500 text-white font-semibold py-2 px-3 rounded-full"
                      onClick={() => onEdit(connection)}
                    >
                      <PencilIcon className="w-5 h-5" />
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      Edit Connection
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>
                  <div className="relative group">
                    <button
                      className="bg-red-500 text-white font-semibold py-2 px-3 rounded-full"
                      onClick={() => handleDelete(connection)}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded-lg py-2 px-3 shadow-lg z-50">
                      Delete Connection
                      <svg
                        className="absolute top-full left-1/2 transform -translate-x-1/2 -mt-1 w-4 h-4 text-gray-800"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 10l-10 10h20L10 10z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {connections.length > 1 && (
            <ArrowRightIcon className="w-5 h-5 text-gray-500 cursor-pointer" />
          )}
          <div className="flex items-center">
            <button
              className={`${canAddSellingAccount || canAddBuyingAccount ? 'bg-indigo-500' : 'bg-gray-500'} text-white font-semibold py-2 px-3 rounded-full flex items-center space-x-2 text-sm`}
              onClick={() => onConnect(canAddSellingAccount ? 'selling' : 'buying')}
              disabled={!canAddSellingAccount && !canAddBuyingAccount}
            >
              <PlusIcon className="w-6 h-6" />
              <span>
                {canAddSellingAccount ? 'Add Selling Account' : canAddBuyingAccount ? 'Add Account' : 'Upgrade to Add Accounts'}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectionCard;

import React from 'react';
import ApexCharts from 'react-apexcharts';

const PriceRangeChart = ({ priceRangeData, themeMode }) => {
  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const allPriceBrackets = [
    ...new Set(
      priceRangeData.flatMap((conditionData) =>
        conditionData.priceBrackets.map((bracket) => bracket.bracket)
      )
    ),
  ].sort(
    (a, b) => parseInt(a.split('-')[0], 10) - parseInt(b.split('-')[0], 10)
  );

  const aggregatedPriceRanges = priceRangeData.map((conditionData) => ({
    name: conditionData.condition,
    data: allPriceBrackets.map((bracket) => {
      const foundBracket = conditionData.priceBrackets.find(
        (b) => b.bracket === bracket
      );
      return foundBracket ? foundBracket.count : 0;
    }),
  }));

  const priceRangeOptions = {
    chart: {
      type: 'bar',
      height: 350,
      background: 'transparent',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: allPriceBrackets,
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: textColor,
      },
      labels: {
        style: {
          colors: textColor,
        },
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
      title: {
        text: 'Item Count',
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val} items`;
        },
      },
    },
    title: {
      text: 'Item Count by Price Range for Each Condition',
      align: 'left',
      style: {
        color: textColor,
      },
    },
    theme: {
      mode: themeMode,
    },
    legend: {
      labels: {
        colors: textColor,
      },
    },
    colors: ['#6366F1', '#34D399', '#F59E0B', '#EF4444'],
  };

  return (
    <div className='bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 p-4 shadow-sm rounded-lg'>
      <ApexCharts
        options={priceRangeOptions}
        series={aggregatedPriceRanges}
        type='bar'
        height={350}
        className='mb-4'
      />
    </div>
  );
};

export default PriceRangeChart;

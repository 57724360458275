import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  createSubscription,
  updateSubscription,
} from '../../features/subscriptions/subscriptionSlice';
import PaymentHeader from '../../components/subscriptions/PaymentHeader';
import PaymentFooter from '../../components/subscriptions/PaymentFooter';
import TermsAndConditions from '../../components/subscriptions/TermsAndConditions';
import SubscriptionDetails from '../../components/subscriptions/SubscriptionDetails';

const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  // Add more countries as needed
];

const stateOptionsUS = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const stateOptionsCA = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'YT', label: 'Yukon' },
];

const PaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscription, selectedPlan } = useSelector(
    (state) => state.subscription
  );
  const { user } = useSelector((state) => state.auth);

  const [billingDetails, setBillingDetails] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    country: 'US', // Default to US
    postalCode: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Persist the subscription and selectedPlan in local storage
    localStorage.setItem('subscription', JSON.stringify(subscription));
    localStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
  }, [subscription, selectedPlan]);

  useEffect(() => {
    // Load the subscription and selectedPlan from local storage on page load
    const storedSubscription = JSON.parse(localStorage.getItem('subscription'));
    const storedSelectedPlan = JSON.parse(localStorage.getItem('selectedPlan'));
    if (storedSubscription && storedSelectedPlan) {
      dispatch({
        type: 'subscription/setSelectedPlanFromStorage',
        payload: {
          subscription: storedSubscription,
          selectedPlan: storedSelectedPlan,
        },
      });
    }
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billingDetails.name,
        address: {
          line1: billingDetails.address,
          city: billingDetails.city,
          state: billingDetails.state,
          country: billingDetails.country,
          postal_code: billingDetails.postalCode,
        },
      },
    });

    if (error) {
      console.log('Error:', error);
      setIsLoading(false);
    } else {
      if (!subscription || !subscription.items || subscription.items.length === 0) {
        // Create new subscription
        dispatch(
          createSubscription({
            userId: user.id,
            plan: selectedPlan.name,
            paymentMethodId: paymentMethod.id,
            billingDetails,
          })
        ).then(() => {
          localStorage.removeItem('subscription');
          localStorage.removeItem('selectedPlan');
          setIsLoading(false);
          navigate('/settings/subscription');
        });
      } else {
        const activePlanItem = subscription.items.find(
          (item) => item.status === 'active' && ['Explorer', 'Accelerator', 'Professional'].includes(item.plan)
        );

        const cancelledPlanItem = subscription.items.find(
          (item) => item.status === 'cancelled' && ['Explorer', 'Accelerator', 'Professional'].includes(item.plan)
        );

        if (activePlanItem && selectedPlan.name !== activePlanItem.plan) {
          dispatch(
            updateSubscription({
              subscriptionId: subscription.stripeSubscriptionId,
              subscriptionItemId: activePlanItem.itemId,
              newPlanId: selectedPlan.name,
            })
          ).then(() => {
            localStorage.removeItem('subscription');
            localStorage.removeItem('selectedPlan');
            setIsLoading(false);
            navigate('/settings/subscription');
          });
        } else if (cancelledPlanItem) {
          // Handle reinstating cancelled subscription
          dispatch(
            updateSubscription({
              subscriptionId: subscription.stripeSubscriptionId,
              subscriptionItemId: cancelledPlanItem.itemId,
              newPlanId: selectedPlan.name,
            })
          ).then(() => {
            localStorage.removeItem('subscription');
            localStorage.removeItem('selectedPlan');
            setIsLoading(false);
            navigate('/settings/subscription');
          });
        }
      }
    }
  };

  const handleCancel = () => {
    localStorage.removeItem('selectedPlan');
    localStorage.removeItem('subscription');
    navigate('/settings/subscription');
  };

  const [darkMode, setDarkMode] = useState(
    () => localStorage.getItem('theme') === 'dark'
  );

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const activePlanItem = subscription?.items?.find(
    (item) => item.status === 'active' && ['Explorer', 'Accelerator', 'Professional'].includes(item.plan)
  );

  const cancelledPlanItem = subscription?.items?.find(
    (item) => item.status === 'cancelled' && ['Explorer', 'Accelerator', 'Professional'].includes(item.plan)
  );

  const isUpgradeDowngrade = selectedPlan && activePlanItem && selectedPlan.name !== activePlanItem.plan;

  const calculatePriceDifference = (newPlanName, currentPlanName) => {
    const tiers = {
      Explorer: 29,
      Accelerator: 69,
      Professional: 129,
      Enterprise: 0, // Assuming custom pricing is set to 0, you may need to handle this differently
    };

    const newPrice = tiers[newPlanName] || 0;
    const currentPrice = tiers[currentPlanName] || 0;

    return newPrice - currentPrice;
  };

  if (!selectedPlan) {
    return <div>Loading...</div>;
  }

  return (
    <div className='min-h-screen flex flex-col'>
      <PaymentHeader darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <div className='flex-grow flex items-center justify-center bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-5xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8'>
          <div className='bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg'>
            <SubscriptionDetails
              selectedPlan={selectedPlan}
              currentSubscription={isUpgradeDowngrade ? subscription : null}
            />
          </div>
          <div className='max-w-md w-full space-y-8'>
            <div>
              <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-gray-100'>
                {selectedPlan
                  ? `Subscribe to ${selectedPlan.name}`
                  : 'Update Subscription'}
              </h2>
              {isUpgradeDowngrade && (
                <p className='text-center text-lg font-semibold text-gray-600 dark:text-gray-300'>
                  {`This change will result in a ${calculatePriceDifference(selectedPlan?.name, activePlanItem.plan) >= 0
                    ? 'cost increase'
                    : 'savings'
                    } of $${Math.abs(calculatePriceDifference(selectedPlan?.name, activePlanItem.plan)).toFixed(2)} per month.`}
                </p>
              )}
              {cancelledPlanItem && !activePlanItem && (
                <p className='text-center text-lg font-semibold text-gray-600 dark:text-gray-300'>
                  {`You were previously subscribed to ${cancelledPlanItem.plan} which was cancelled on ${new Date(cancelledPlanItem.endDate).toLocaleDateString()}.`}
                </p>
              )}
            </div>
            <form onSubmit={handleSubmit} className='mt-8 space-y-6'>
              <div className='rounded-md shadow-sm -space-y-px'>
                <div>
                  <label htmlFor='name' className='sr-only'>
                    Name
                  </label>
                  <input
                    id='name'
                    name='name'
                    type='text'
                    autoComplete='name'
                    required
                    value={billingDetails.name}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                    placeholder='Name'
                  />
                </div>
                <div>
                  <label htmlFor='address' className='sr-only'>
                    Address
                  </label>
                  <input
                    id='address'
                    name='address'
                    type='text'
                    autoComplete='address'
                    required
                    value={billingDetails.address}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                    placeholder='Address'
                  />
                </div>
                <div>
                  <label htmlFor='city' className='sr-only'>
                    City
                  </label>
                  <input
                    id='city'
                    name='city'
                    type='text'
                    autoComplete='city'
                    required
                    value={billingDetails.city}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                    placeholder='City'
                  />
                </div>
                <div>
                  <label htmlFor='state' className='sr-only'>
                    State
                  </label>
                  <select
                    id='state'
                    name='state'
                    required
                    value={billingDetails.state}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                  >
                    <option value=''>Select State</option>
                    {billingDetails.country === 'US' &&
                      stateOptionsUS.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    {billingDetails.country === 'CA' &&
                      stateOptionsCA.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <label htmlFor='country' className='sr-only'>
                    Country
                  </label>
                  <select
                    id='country'
                    name='country'
                    required
                    value={billingDetails.country}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                  >
                    <option value=''>Select Country</option>
                    {countryOptions.map((country) => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor='postalCode' className='sr-only'>
                    Postal Code
                  </label>
                  <input
                    id='postalCode'
                    name='postalCode'
                    type='text'
                    autoComplete='postal-code'
                    required
                    value={billingDetails.postalCode}
                    onChange={handleChange}
                    className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm dark:bg-gray-800 dark:text-gray-100'
                    placeholder='Postal Code'
                  />
                </div>
              </div>
              <div className='p-3 border rounded bg-gray-50 dark:bg-gray-800'>
                <CardElement className='p-3 dark:text-white' />
              </div>
              <div>
                <button
                  type='submit'
                  className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  disabled={!stripe || isLoading}
                >
                  {isLoading ? 'Loading...' : 'Submit Payment'}
                </button>
              </div>
              <div>
                <button
                  type='button'
                  className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
            <TermsAndConditions />
          </div>
        </div>
      </div>
      <PaymentFooter />
    </div>
  );
};

export default PaymentPage;

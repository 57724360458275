import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/marketing`;

/**
 * marketingService
 *
 * This module provides functions to interact with the backend API for marketing-related actions.
 * It includes functions to send a refer-a-friend email and potentially other marketing actions.
 *
 * Functions:
 * - **sendReferAFriendEmail**: Sends a POST request to `/api/marketing/refer-friend` to send a referral email.
 */

// Helper function to get config with token
const getConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

// Send a refer-a-friend email (POST request)
export const sendReferAFriendEmail = async (email, token, senderName) => {
  const body = {
    email,
    senderName
  };

  const { data } = await axios.post(`${baseUrl}/refer-friend`, body, getConfig(token));
  return data;
};

// Additional marketing-related functions can be added here, following the same structure

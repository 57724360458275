import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SearchIcon, PlusIcon } from '@heroicons/react/outline'

import { filterCategories } from '../../features/productResearch/productResearchSlice'
import NewSearchModal from './NewSearchModal'

const SearchActionBar = () => {
  const dispatch = useDispatch()
  const [searchTerm, setSearchTerm] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    dispatch(filterCategories(value))
  }

  const openNewSearchModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='p-4 bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded-xl flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 border mb-4'>
      <div className='relative flex items-center flex-1'>
        <SearchIcon className='absolute w-5 h-5 text-gray-700 dark:text-gray-300 ml-3' />
        <input
          type='text'
          id='search-categories'
          className='block w-full pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500'
          placeholder='Search categories...'
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <button
        onClick={openNewSearchModal}
        className='bg-indigo-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800 flex items-center space-x-2'
      >
        <PlusIcon className='w-5 h-5' />
        <span>New Research</span>
      </button>
      {isModalOpen && (
        <NewSearchModal isOpen={isModalOpen} onClose={closeModal} />
      )}
    </div>
  )
}

export default SearchActionBar

/**
 * ArbieResultsAFV2 Component
 *
 * This component is responsible for displaying and managing the arbitrage results feed.
 * It fetches and displays opportunities for arbitrage based on the user's search terms.
 *
 * Main Features:
 * - **Fetch Opportunities**: Retrieves a list of arbitrage opportunities from the backend based on the user's search terms.
 * - **Infinite Scrolling**: Automatically loads more opportunities as the user scrolls down the list.
 * - **View Modes**: Allows the user to switch between card and table view modes for displaying opportunities.
 * - **Modals**: Displays detailed information in modals for market research, price information, attributes, and labels.
 * - **Filters and Sorting**: Enables filtering and sorting of opportunities based on various criteria.
 * - **Bulk Actions**: Provides functionality to select multiple opportunities and perform bulk actions such as updating statuses.
 * - **Real-Time Updates**: Ensures real-time updates and interactions by leveraging Redux for state management.
 * - **User Interaction**: Handles user interactions such as selecting opportunities, opening modals, updating statuses, and navigating between opportunities.
 *
 * State Management:
 * - Uses Redux to manage the state of opportunities, filters, and user information.
 * - Local state is used for managing modal visibility, selected opportunities, view modes, and action handling.
 *
 * Lifecycle:
 * - Fetches the user's opportunities and search terms only when the user logs in or refreshes the authentication token.
 * - Sets up an IntersectionObserver to handle infinite scrolling and load more items when needed.
 *
 * Props (from Redux):
 * - opportunities: List of arbitrage opportunities.
 * - allItems: Complete list of items.
 * - searchTerms: List of search terms defined by the user.
 * - loadingMore: Flag indicating if more items are being loaded.
 * - hasMoreItems: Flag indicating if there are more items to load.
 * - filters: Current filter settings.
 * - user: User information.
 *
 * Dependencies:
 * - React and Redux for state management and UI rendering.
 * - Heroicons for displaying icons.
 * - React Router for navigation links.
 * - Various custom components for rendering opportunities and modals.
 */

import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { InformationCircleIcon } from '@heroicons/react/solid'

import {
  fetchOpportunities,
  fetchMoreOpportunitiesFromState,
  updateStatus,
  fetchSearchTerms,
  resetOpportunities,
  setFilter,
  resetOpportunitiesFetched,
} from '../features/arbitrage/autoFeedSlice'
import Spinner from '../components/Spinner'
import OpportunityTable from '../components/ResultsFeed/Opportunities/OpportunityTable'
import OpportunityCards from '../components/ResultsFeed/Opportunities/OpportunityCards'
import MarketResearchModal from '../components/ResultsFeed/Modals/MarketResearchModal'
import PriceInfoModal from '../components/ResultsFeed/Modals/PriceInfoModal'
import AttributesInfoModal from '../components/ResultsFeed/Modals/AttributesInfoModal'
import LabelInfoModal from '../components/ResultsFeed/Modals/LabelInfoModal'
import ResultsHeader from '../components/ResultsFeed/ResultsHeader'
import Modal from '../components/ResultsFeed/Modals/Modal'

const ArbieResultsAFV2 = () => {
  const dispatch = useDispatch()
  const {
    opportunities,
    allItems,
    searchTerms,
    loadingMore,
    hasMoreItems,
    filters,
    isOpportunitiesFetched,
    totalAvailableResults,
    filteredItems,
  } = useSelector((state) => state.autoFeed)

  const { user } = useSelector((state) => state.auth)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedOpportunity, setSelectedOpportunity] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [action, setAction] = useState('')
  const [viewMode, setViewMode] = useState('card')

  const [modalState, setModalState] = useState({
    priceInfo: false,
    attributesInfo: false,
    labelInfo: false,
    imageModal: false,
  })

  // Open a modal by name
  const openModal = (modalName) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalName]: true,
    }))
  }

  // Create a reference for the observer
  const observer = useRef()

  // Fetch opportunities and search terms when the user logs in or refreshes the authentication token
  useEffect(() => {
    if (user && !isOpportunitiesFetched) {
      dispatch(resetOpportunities())
      dispatch(fetchOpportunities(user._id))
      dispatch(fetchSearchTerms(user._id))
      dispatch(resetOpportunitiesFetched())
    }
  }, [dispatch, user, isOpportunitiesFetched])

  // Load more items when the user scrolls to the bottom
  const loadMoreItems = useCallback(() => {
    if (hasMoreItems) {
      dispatch(fetchMoreOpportunitiesFromState())
    }
  }, [dispatch, hasMoreItems])

  // Set up IntersectionObserver for infinite scrolling
  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems()
      }
    }, options)

    const lastItem = document.querySelector(
      viewMode === 'table' ? '.table-last-item' : '.last-item'
    )
    if (observer.current && lastItem) {
      observer.current.observe(lastItem)
    }
  }, [opportunities.length, loadMoreItems, viewMode])

  // Retry fetching opportunities when no results are available
  const handleRetry = () => {
    dispatch(fetchOpportunities(user._id))
  }

  // Update the status of selected opportunities
  const handleUpdateStatus = (itemIds, status, fromModal = false) => {
    dispatch(updateStatus({ itemIds, status }))
    setSelectedItems([])
    setSelectAll(false)
    setAction('')
    if (fromModal) {
      handleNextOpportunity()
    }
  }

  // Open the modal for a selected opportunity
  const openOpportunityModal = (opportunity) => {
    setSelectedOpportunity(opportunity)
    setIsModalOpen(true)
  }

  // Close the opportunity modal
  const closeOpportunityModal = () => {
    setIsModalOpen(false)
    setSelectedOpportunity(null)
  }

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([])
    } else {
      setSelectedItems(opportunities.map((item) => item.itemId))
    }
    setSelectAll(!selectAll)
  }

  // Handle selecting a single item
  const handleSelectItem = (itemId, status) => {
    setSelectedItems([...selectedItems, itemId])
    handleUpdateStatus([itemId], status)
  }

  // Handle checkbox change for individual items
  const handleCheckboxChange = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId))
    } else {
      setSelectedItems([...selectedItems, itemId])
    }
  }

  // Handle bulk action change
  const handleActionChange = (e) => {
    const selectedAction = e.target.value
    setAction(selectedAction)
    if (selectedAction !== '') {
      handleUpdateStatus(selectedItems, selectedAction)
    }
  }

  // Navigate to the next opportunity in the modal
  const handleNextOpportunity = (direction = 1) => {
    const currentIndex = opportunities.findIndex(
      (item) => item.itemId === selectedOpportunity.itemId
    )
    let nextIndex = currentIndex + direction
    if (nextIndex < 0) nextIndex = opportunities.length - 1
    if (nextIndex >= opportunities.length) nextIndex = 0
    const nextOpportunity = opportunities[nextIndex]
    setSelectedOpportunity(nextOpportunity)
  }

  // Handle pass all action
  const handlePassAll = () => {
    const confirmPassAll = window.confirm(
      'Are you sure you want to pass all opportunities? This will remove all opportunities you have from the table.'
    )
    if (confirmPassAll) {
      handleUpdateStatus(
        filteredItems.map((item) => item.itemId),
        'passed'
      )
    }
  }

  // Toggle the status filter between 'watching' and 'pending'
  const toggleStatusFilter = () => {
    dispatch(
      setFilter({
        statusFilter:
          filters.statusFilter === 'watching' ? 'pending' : 'watching',
      })
    )
  }

  // Open the image modal
  const openImageModal = (opportunity) => {
    setSelectedOpportunity(opportunity)
    setModalState((prevState) => ({
      ...prevState,
      imageModal: true,
    }))
  }

  // Close the image modal
  const closeImageModal = () => {
    setModalState((prevState) => ({
      ...prevState,
      imageModal: false,
    }))
  }

  // Calculate the break-even price for an item
  const calculateBreakEvenPrice = (price) => {
    const taxRate = 0.1
    const tax = price * taxRate
    const breakEvenPrice = (price + tax) / 0.9
    return breakEvenPrice.toFixed(2)
  }

  const totalResults = allItems.length

  return (
    <div className='min-h-screen flex flex-col p-4 pt-0'>
      <div className='sticky top-0 z-20 bg-gray-50 dark:bg-gray-900'>
        <ResultsHeader
          viewMode={viewMode}
          setViewMode={setViewMode}
          conditionFilter={filters.conditionFilter}
          setConditionFilter={(value) =>
            dispatch(setFilter({ conditionFilter: value }))
          }
          searchTermFilter={filters.searchTermFilter}
          setSearchTermFilter={(value) =>
            dispatch(setFilter({ searchTermFilter: value }))
          }
          filteredSearchTerms={searchTerms}
          searchQuery={filters.searchQuery}
          setSearchQuery={(value) =>
            dispatch(setFilter({ searchQuery: value }))
          }
          sortOption={filters.sortOption}
          setSortOption={(value) => dispatch(setFilter({ sortOption: value }))}
          action={action}
          setAction={setAction}
          handleActionChange={handleActionChange}
          handlePassAll={handlePassAll}
          toggleStatusFilter={toggleStatusFilter}
          statusFilter={filters.statusFilter}
          totalResults={totalResults}
          totalAvailableResults={totalAvailableResults}
        />
      </div>
      {/* Main content section */}
      <div className='flex-1 overflow-y-auto'>
        {searchTerms.length === 0 ? (
          <div className='mt-8 flex items-center justify-center text-xl text-gray-600 dark:text-gray-400'>
            <Link to='/arbie/research' className='flex items-center'>
              <InformationCircleIcon className='w-8 h-8 text-indigo-500 mr-2' />
              No search terms added yet. Please add search terms on the Product
              Research Dashboard to see opportunities.
            </Link>
          </div>
        ) : allItems[0]?.name === 'loading' ? (
          <div className='flex flex-col items-center justify-center h-full text-center'>
            <Spinner />
          </div>
        ) : (
          <>
            {allItems.length === 0 ? (
              <div className='flex flex-col items-center justify-center h-full text-center p-4 bg-white border rounded-xl shadow-lg dark:bg-gray-900 mt-4'>
                <h2 className='text-2xl font-semibold mb-4 dark:text-white'>
                  No Opportunities Available
                </h2>
                <p className='mb-4 text-gray-600 dark:text-white'>
                  There are no opportunities to display. Add new search terms or
                  review your current terms' performance.
                </p>
                <button
                  onClick={handleRetry}
                  className='bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 dark:bg-indigo-700 dark:hover:bg-indigo-800'
                >
                  Retry Fetching Opportunities
                </button>
              </div>
            ) : viewMode === 'table' ? (
              <div className='hidden md:block'>
                <OpportunityTable
                  currentItems={opportunities}
                  selectedItems={selectedItems}
                  handleCheckboxChange={handleCheckboxChange}
                  handleUpdateStatus={handleUpdateStatus}
                  openOpportunityModal={openOpportunityModal}
                  selectAll={selectAll}
                  openModal={openModal}
                  handleSelectAll={handleSelectAll}
                  calculateBreakEvenPrice={calculateBreakEvenPrice}
                  openImageModal={openImageModal}
                />
              </div>
            ) : (
              <OpportunityCards
                currentItems={opportunities}
                openOpportunityModal={openOpportunityModal}
                handleCheckboxChange={handleCheckboxChange}
                handleSelectItem={handleSelectItem}
                selectedItems={selectedItems}
                openImageModal={openImageModal}
                openModal={openModal}
              />
            )}
            {loadingMore && (
              <div className='flex justify-center'>
                <Spinner />
              </div>
            )}
          </>
        )}
      </div>
      {/* Modals for additional information */}
      <PriceInfoModal
        isOpen={modalState.priceInfo}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            priceInfo: false,
          }))
        }
      />
      <AttributesInfoModal
        isOpen={modalState.attributesInfo}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            attributesInfo: false,
          }))
        }
      />
      <LabelInfoModal
        isOpen={modalState.labelInfo}
        onClose={() =>
          setModalState((prevState) => ({
            ...prevState,
            labelInfo: false,
          }))
        }
      />
      <MarketResearchModal
        isOpen={isModalOpen}
        onClose={closeOpportunityModal}
        opportunity={selectedOpportunity}
        handleStatusUpdateAndNext={handleUpdateStatus}
        navigateToNext={() => handleNextOpportunity(1)}
        navigateToPrevious={() => handleNextOpportunity(-1)}
        openImageModal={openImageModal}
      />
      <Modal isOpen={modalState.imageModal} onClose={closeImageModal}>
        {selectedOpportunity && (
          <img
            src={selectedOpportunity.image.imageUrl}
            alt={selectedOpportunity.title}
            className='w-full h-auto object-cover'
          />
        )}
      </Modal>
      <div className='last-item'></div>
    </div>
  )
}

export default ArbieResultsAFV2

import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import { XIcon } from '@heroicons/react/solid';
import { v4 as uuidv4 } from 'uuid'; // Importing uuid for unique IDs

import { uploadEditedImagesForItem } from '../../../features/inventory/inventorySlice';
import Spinner from '../../Spinner';

// ImageItem Component with Drag-and-Drop Functionality
const ImageItem = ({ image, index, moveImage, onDelete }) => {
  const ref = React.useRef(null);

  // Drop Hook
  const [, drop] = useDrop({
    accept: 'image',
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the item's width
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;

      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  // Drag Hook
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: { id: image.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="relative bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <button
        onClick={() => onDelete(image.id)}
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 focus:outline-none"
        aria-label={`Delete Image ${index + 1}`}
      >
        <XIcon className="h-4 w-4" />
      </button>
      <div className="p-4  dark:border dark:rounded-tr-2xl dark:rounded-tl-2xl dark:border-gray-700">
        <span className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2 ">
          Image #{index + 1}
        </span>
        <img
          src={image.preview}
          alt={`Preview ${index + 1}`}
          className="w-full h-40 object-cover rounded"
        />
      </div>
      <div className="bg-gray-100 dark:bg-gray-700 p-2 text-center text-xs text-gray-600 dark:text-gray-400 dark:border dark:border-gray-700 dark:border-t-0 dark:rounded-br-2xl dark:rounded-bl-2xl">
        Drag to reorder
      </div>
    </div>
  );
};

const EditPhotographyModal = ({ isOpen, onClose, item }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [images, setImages] = useState(() =>
    item.images.map((img, index) => ({
      ...img,
      preview: img.awsUrl,
      id: img._id || uuidv4(),
      _id: img._id || null,
      order: index,
      fileName: img.fileName || null,
    }))
  );

  const [deletedImages, setDeletedImages] = useState([]);

  const handleImageChange = (e) => {
    if (e.target.files) {
      const maxImagesAllowed = 24;
      const selectedFiles = Array.from(e.target.files);
      const spaceAvailable = maxImagesAllowed - images.length;

      const filesToAdd = selectedFiles.slice(0, spaceAvailable);

      if (filesToAdd.length < selectedFiles.length) {
        toast.warn(`You can only add up to ${spaceAvailable} more image(s).`);
      }

      const newImages = filesToAdd.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
        id: uuidv4(),
        _id: null,
        order: images.length + filesToAdd.indexOf(file),
        fileName: file.name,
      }));

      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const moveImage = useCallback(
    (dragIndex, hoverIndex) => {
      const dragImage = images[dragIndex];
      const newImages = [...images];
      newImages.splice(dragIndex, 1);
      newImages.splice(hoverIndex, 0, dragImage);
      setImages(newImages.map((img, index) => ({ ...img, order: index })));
    },
    [images]
  );

  const handleDeleteImage = (id) => {
    const imageToDelete = images.find((image) => image.id === id);
    if (!imageToDelete) return;

    if (imageToDelete.preview && !imageToDelete.awsUrl) {
      URL.revokeObjectURL(imageToDelete.preview);
    }

    const updatedImages = images.filter((image) => image.id !== id);

    if (imageToDelete && imageToDelete._id) {
      setDeletedImages((prev) => [...prev, imageToDelete]);
    }

    setImages(updatedImages);
  };

  const handleSubmit = async () => {
    if (images.length === 0 || images.length > 24) {
      toast.error(
        'Please adjust the number of images. You must have at least 1 and no more than 24.'
      );
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    const imageOrderMapping = [];

    images.forEach((image, index) => {
      if (image.file) {
        formData.append('images', image.file);
        imageOrderMapping.push({
          _id: image._id || null,
          fileName: image.file.name,
          order: index,
          status: image._id ? 'updated' : 'new',
        });
      } else if (image._id && !image.file) {
        imageOrderMapping.push({
          _id: image._id,
          fileName: image.fileName || null,
          awsUrl: image.awsUrl,
          order: index,
          status: 'existing',
        });
      }
    });

    deletedImages.forEach((deletedImage) => {
      imageOrderMapping.push({
        _id: deletedImage._id,
        fileName: deletedImage.fileName || null,
        awsUrl: deletedImage.awsUrl,
        status: 'deleted',
      });
    });

    formData.append('imagesMetadata', JSON.stringify(imageOrderMapping));

    try {
      await dispatch(
        uploadEditedImagesForItem({ inventoryItemId: item._id, formData })
      ).unwrap();
      toast.success('Images updated successfully');
      onClose();
      setDeletedImages([]);
    } catch (error) {
      toast.error(`Error updating images: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isOpen, onClose]);

  useEffect(() => {
    return () => {
      images.forEach((image) => {
        if (image.preview && !image.awsUrl) {
          URL.revokeObjectURL(image.preview);
        }
      });
    };
  }, [images]);

  if (!isOpen) return null;

  return (
    <>
      {isSubmitting && <Spinner />}
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={onClose}>
          <div className="absolute inset-0 overflow-hidden">
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* Drawer Panel */}
            <div className="fixed inset-y-0 right-0 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-6xl">
                  <div className="h-full flex flex-col bg-white dark:bg-gray-800 shadow-xl">
                    {/* Header */}
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
                      <Dialog.Title className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                        Edit Product Images
                      </Dialog.Title>
                      <button
                        onClick={onClose}
                        className="text-gray-400 dark:text-gray-300 hover:text-gray-500 focus:outline-none"
                        aria-label="Close panel"
                      >
                        <XIcon className="h-6 w-6" />
                      </button>
                    </div>

                    {/* Content */}
                    <div className="flex-1 p-4 overflow-y-auto">
                      <div className="mb-6">
                        <label
                          htmlFor="image-upload"
                          className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2"
                        >
                          Upload Images
                        </label>
                        <input
                          type="file"
                          id="image-upload"
                          multiple
                          accept="image/*"
                          onChange={handleImageChange}
                          disabled={images.length >= 24}
                          className="block w-full text-sm text-gray-500 dark:text-gray-300
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-md file:border-0
                            file:text-sm file:font-semibold
                            file:bg-indigo-50 dark:file:bg-indigo-900 file:text-indigo-700 dark:file:text-indigo-400
                            hover:file:bg-indigo-100 dark:hover:file:bg-indigo-800"
                        />
                        <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
                          You can upload up to 24 images.
                        </p>
                      </div>

                      <DndProvider backend={HTML5Backend}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                          {images
                            .sort((a, b) => a.order - b.order)
                            .map((image, index) => (
                              <ImageItem
                                key={image.id}
                                image={image}
                                index={index}
                                moveImage={moveImage}
                                onDelete={handleDeleteImage}
                              />
                            ))}
                        </div>
                      </DndProvider>
                    </div>

                    {/* Actions */}
                    <div className="p-4 border-t border-gray-200 dark:border-gray-700 flex justify-end space-x-3">
                      <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-300 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:focus:ring-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmit}
                        disabled={
                          isSubmitting ||
                          images.length === 0 ||
                          images.length > 24
                        }
                        className={`px-4 py-2 bg-indigo-600 dark:bg-indigo-800 text-white rounded-md hover:bg-indigo-700 dark:hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400
                          ${isSubmitting || images.length === 0 || images.length > 24
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                          }`}
                      >
                        {isSubmitting ? 'Processing...' : 'Save Changes'}
                      </button>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default EditPhotographyModal;

import React, { useState, useEffect } from 'react';
import {
  InformationCircleIcon,
  ClipboardListIcon,
  UserIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  CheckIcon,
  GlobeAltIcon,
} from '@heroicons/react/outline';
import { format } from 'date-fns';

const platformLogos = {
  ebay: {
    lightmodeImage: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
    darkmodeImage: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
  },
  // Add more platforms here
  default: {
    lightmodeImage: 'path/to/default-light.png',
    darkmodeImage: 'path/to/default-dark.png',
  },
};

const PurchasesCard = ({ purchase, onViewDetails, onConvertToInventory }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const totalItems = purchase.items.length;
  const convertedItems = purchase.items.filter(item => item.convertedToInventory).length;

  // Safely handle the purchase timestamp and provide a fallback
  let purchaseDate = 'Unknown';
  if (purchase.purchaseDate) {
    try {
      purchaseDate = format(new Date(purchase.purchaseDate), 'MMMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting purchase date:', error);
    }
  }

  const sellerOrigin = purchase.sellerOrigin || 'Unknown';
  const platform = purchase.platformOrigin?.toLowerCase() || 'default';
  const logos = platformLogos[platform] || platformLogos.default;
  const isDarkMode = themeMode === 'dark';

  return (
    <div className="bg-white bg-gradient-to-r border dark:from-gray-800 dark:to-gray-900 shadow-md rounded-lg p-4 flex flex-col space-y-4">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div className="flex items-center space-x-4 mb-4 md:mb-0">
          <div className="w-20 h-20">
            <img src={isDarkMode ? logos.darkmodeImage : logos.lightmodeImage} alt={`${platform} logo`} className="w-full h-full object-contain rounded-md" />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Order #{purchase.orderNumber}
            </h2>
            <p className="text-gray-500 dark:text-gray-400">Purchased with {purchase.usernameConnectedAccount} on {purchaseDate}</p>
          </div>
        </div>
        <div className="flex space-x-2 flex-wrap justify-start md:justify-end w-full md:w-auto">
          <button
            onClick={onViewDetails}
            className="flex items-center px-3 py-1.5 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
          >
            <InformationCircleIcon className="h-5 w-5 mr-1" />
            View Details
          </button>
          <button
            onClick={onConvertToInventory}
            className="flex items-center px-3 py-1.5 bg-green-600 text-white rounded-md hover:bg-green-700 transition"
          >
            <ClipboardListIcon className="h-5 w-5 mr-1" />
            Convert to Inventory
          </button>
          <div className="flex items-center text-gray-700 dark:text-gray-300">
            <CheckIcon className="h-5 w-5 text-gray-500 dark:text-gray-400 mr-1" />
            {convertedItems}/{totalItems}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-1 mt-4">
        <div className="flex items-center space-x-2">
          <UserIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300"><strong>Seller:</strong> {purchase.sellerUsername}</p>
        </div>
        <div className="flex items-center space-x-2">
          <CurrencyDollarIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300"><strong>Purchase Price:</strong> ${purchase.purchasePrice.toFixed(2)}</p>
        </div>
        <div className="flex items-center space-x-2">
          <ShoppingCartIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300"><strong>Items Purchased:</strong> {totalItems}</p>
        </div>
        <div className="flex items-center space-x-2">
          <GlobeAltIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
          <p className="text-gray-700 dark:text-gray-300"><strong>Origin:</strong> {purchase.platformOrigin}</p>
        </div>
      </div>
    </div>
  );
};

export default PurchasesCard;

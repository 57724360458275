import React, { useRef, useEffect } from 'react'

const CustomModal = ({ isOpen, onClose, title, children }) => {
  const modalRef = useRef(null)

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose()
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick)
    } else {
      document.removeEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [isOpen])

  return (
    isOpen && (
      <div className='fixed inset-0 z-50 flex items-center justify-center bg-opacity-30 bg-black'>
        <div
          className='bg-white w-full max-w-md mx-auto rounded-lg shadow-lg overflow-hidden'
          ref={modalRef}
        >
          <div className='flex justify-between items-center p-4 border-b border-gray-200'>
            <p className='text-2xl font-bold'>{title}</p>
            <div className='cursor-pointer' onClick={onClose}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='w-6 h-6 text-gray-500 hover:text-gray-700'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
          </div>
          <div className='modal-content p-4 max-h-96 overflow-auto'>
            {children}
          </div>
        </div>
      </div>
    )
  )
}

export default CustomModal

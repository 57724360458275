import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const BASE_API_URL = `${apiUrl}/api/connections`;

export const signInToPlatform = async (token, platform, accountType, accountName, connectionId = null) => {
  const API_URL = `${BASE_API_URL}/${platform}/signin`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const payload = { accountType, accountName };
  if (connectionId) {
    payload.connectionId = connectionId;
  }

  try {
    const signInResponse = await axios.post(API_URL, payload, config);
    const { authorizeUrl } = signInResponse.data;

    window.location.href = authorizeUrl;
    return authorizeUrl;
  } catch (error) {
    throw error;
  }
};

export const checkPlatformToken = async (token, platform, accountType, accountName) => {
  const API_URL = `${BASE_API_URL}/${platform}/check-token`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { accountType, accountName }
  };

  try {
    const response = await axios.get(API_URL, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function for updating a connection's details directly
export const updateUserConnectionDocument = async (token, connectionId, newAccountName, listToByDefault) => {
  const API_URL = `${BASE_API_URL}/connection/update`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.put(API_URL, { connectionId, newAccountName, listToByDefault }, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteConnection = async (token, connectionId, platform) => {
  const API_URL = `${BASE_API_URL}/${platform}/delete-connection`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { connectionId },
  };

  try {
    const response = await axios.delete(API_URL, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getConnectionStatus = async (token) => {
  const API_URL = `${BASE_API_URL}/status`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.get(API_URL, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

import React from 'react'

const MainPagination = ({
  totalItems,
  currentPage,
  paginate,
  itemsPerPage,
}) => {
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
  }

  // Show current items range
  const currentStartItem = Math.min(
    (currentPage - 1) * itemsPerPage + 1,
    totalItems
  )
  const currentEndItem = Math.min(currentPage * itemsPerPage, totalItems)

  // Handling pagination
  const handlePreviousClick = () => currentPage > 1 && paginate(currentPage - 1)
  const handleNextClick = () =>
    currentPage < pageNumbers.length && paginate(currentPage + 1)

  // Pagination view improvement for large datasets
  const maxPagesToShow = 5 // Adjustable for desired view
  let dynamicPages = pageNumbers
  if (pageNumbers.length > maxPagesToShow) {
    const startPageIndex = Math.max(
      currentPage - Math.floor(maxPagesToShow / 2),
      0
    )
    const endPageIndex = Math.min(
      startPageIndex + maxPagesToShow,
      pageNumbers.length
    )
    dynamicPages = pageNumbers.slice(startPageIndex, endPageIndex)
    if (!dynamicPages.includes(1)) dynamicPages.unshift(1)
    if (!dynamicPages.includes(pageNumbers.length))
      dynamicPages.push(pageNumbers.length)
  }

  return (
    <div className='flex flex-col items-center space-y-2 my-4'>
      <div className='flex justify-center items-center space-x-1'>
        <button
          className='px-4 py-2 border rounded-l bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 disabled:opacity-50'
          onClick={handlePreviousClick}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {dynamicPages.map((number) => (
          <button
            key={number}
            className={`px-4 py-2 border-t border-b ${
              number === currentPage
                ? 'bg-indigo-600 text-white'
                : 'bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
            }`}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        ))}
        <button
          className='px-4 py-2 border rounded-r bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 disabled:opacity-50'
          onClick={handleNextClick}
          disabled={currentPage === pageNumbers.length}
        >
          &gt;
        </button>
      </div>
      <div className='text-center text-gray-700 dark:text-gray-300'>
        Showing {currentStartItem}-{currentEndItem} of {totalItems}
      </div>
    </div>
  )
}

export default MainPagination

import axios from 'axios'

const API_URL = `${process.env.REACT_APP_API_URL}/api/product-research`

// Helper function to get config with token
const getConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
})

// Perform initial product research
export const performProductResearch = async (researchData, token) => {
  const response = await axios.post(
    `${API_URL}/perform`,
    researchData,
    getConfig(token)
  )
  return response.data
}

// List all categories
export const listAllCategories = async (userId, token) => {
  const response = await axios.get(`${API_URL}/categories`, {
    ...getConfig(token),
    params: { userId },
  })
  return response.data
}

// Get details for a specific category
export const getCategoryDetails = async (categoryId, token) => {
  const response = await axios.get(
    `${API_URL}/categories/${categoryId}`,
    getConfig(token)
  )
  return response.data
}

// Update existing product research
export const updateProductResearch = async (researchId, data, token) => {
  const response = await axios.put(
    `${API_URL}/${researchId}`,
    data,
    getConfig(token)
  )
  return response.data
}

// Delete product research
export const deleteProductResearch = async (researchId, token) => {
  const response = await axios.delete(
    `${API_URL}/${researchId}`,
    getConfig(token)
  )
  return response.data
}

// Function to add a new search term to eBay and user preferences
export const addSearchTerm = async (data, token) => {
  const response = await axios.post(
    `${API_URL}/addSearchTerm`,
    data,
    getConfig(token)
  )
  return response.data
}

export const getUserSearchTermStatistics = async (userId, token) => {
  const response = await axios.post(
    `${API_URL}/user-search-term-statistics`,
    { userId },
    getConfig(token)
  )
  return response.data
}

export const deleteUserSearchTerm = async (userId, termKey, token) => {
  const response = await axios.post(
    `${API_URL}/deleteSearchTerm`,
    { userId, termKey },
    getConfig(token)
  )
  return response.data
}

export const deleteCategory = async (userId, categoryId, token) => {
  const response = await axios.post(
    `${API_URL}/deleteCategory`,
    { userId, categoryId },
    getConfig(token)
  )
  return response.data
}

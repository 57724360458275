import React from 'react';

const CurrentSubscriptionCard = ({ subscription, onCancel, tiers }) => {
  const handleScrollToTiers = () => {
    document
      .getElementById('subscription-tiers')
      .scrollIntoView({ behavior: 'smooth' });
  };

  const planPrices = {
    Explorer: '$29/month',
    Accelerator: '$69/month',
    Professional: '$129/month',
    Enterprise: 'Custom pricing',
  };

  const getPlanPrice = (planName) => {
    return planPrices[planName] || 'N/A';
  };

  if (!subscription || !subscription.items || subscription.items.length === 0) {
    return (
      <div className='bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-4 border rounded-lg shadow-lg  text-center'>
        <h2 className='text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2'>
          No Current Subscription
        </h2>
        <p className='text-gray-600 dark:text-gray-300 mb-2'>
          You currently do not have an active subscription. You can choose a
          plan below to get started.
        </p>
        <button
          onClick={handleScrollToTiers}
          className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-3 rounded'
        >
          View Subscription Plans
        </button>
      </div>
    );
  }

  const currentPlanItem = subscription.items.find(
    (item) =>
      ['Explorer', 'Accelerator', 'Professional'].includes(item.plan) &&
      item.status === 'active'
  );

  if (!currentPlanItem) {
    return (
      <div className='bg-white dark:bg-gray-700 p-4 rounded-lg shadow-md text-center'>
        <h2 className='text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2'>
          No Current Subscription
        </h2>
        <p className='text-gray-600 dark:text-gray-300 mb-2'>
          You currently do not have an active subscription. You can choose a
          plan below to get started.
        </p>
        <button
          onClick={handleScrollToTiers}
          className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-3 rounded'
        >
          View Subscription Plans
        </button>
      </div>
    );
  }

  const handleCancel = () => {
    onCancel(subscription.stripeSubscriptionId, currentPlanItem.itemId);
  };

  return (
    <div className='bg-white dark:bg-gray-700 p-4 border rounded-lg shadow-lg bg-gradient-to-r  dark:from-gray-800 dark:to-gray-900'>
      <h2 className='text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2'>
        Current Subscription
      </h2>
      <p className='text-gray-600 dark:text-gray-300 mb-1'>
        Plan: {currentPlanItem.plan}
      </p>
      <p className='text-gray-600 dark:text-gray-300 mb-1'>
        Price: {getPlanPrice(currentPlanItem.plan)}
      </p>
      <p className='text-gray-600 dark:text-gray-300 mb-1'>
        Status: {currentPlanItem.status}
      </p>

      <button
        onClick={handleCancel}
        className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
      >
        Cancel Subscription
      </button>
    </div>
  );
};

export default CurrentSubscriptionCard;

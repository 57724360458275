import React from 'react';

const teamMembers = [
  {
    id: 1,
    name: 'John Capovani',
    title: 'Founder',
    description:
      'John is the visionary driving our company forward, fueled by an unwavering passion for transforming innovative ideas into reality. In today’s data-rich environment, he believes it is crucial to harness the power of open-source information to create impactful solutions. At Arbi, John is dedicated to developing software that delivers tangible outcomes, empowering both enterprises and individuals to build sustainable businesses, regardless of their skill level. With a solid background in finance and marketing, John possesses a keen understanding of how data and information drive the modern world.',
    image: 'https://avatars.githubusercontent.com/u/107085436?v=4',
    linkedin: 'https://www.linkedin.com/in/john-capovani-66121a170',
  },
  {
    id: 2,
    name: 'Mahmood Shilleh',
    title: 'Co-Founder',
    description:
      'Mahmood Shilleh, as the Co-Founder of our company, brings a wealth of knowledge and a diverse background in Site Reliability Engineering, Software Development, and Mechanical Engineering. His strategic vision and technical expertise have been instrumental in shaping our company\'s direction and growth. At Arbi, Mahmood is dedicated to ensuring the reliability and efficiency of our systems, focusing on automation and performance enhancement. His background in Computer Science and Mechanical Engineering has equipped him with a profound understanding of how technology can drive business success.',
    image: 'https://media.licdn.com/dms/image/C5603AQEeLt4DXsVy3A/profile-displayphoto-shrink_200_200/0/1655692833149?e=2147483647&v=beta&t=9DfrE_Wgx_On2Kv1p9pHqkYBSp5L6ly9OJip-FmHghI',
    linkedin: 'https://www.linkedin.com/in/mahmood-mustafa-youssef-shilleh-0385bb133',
  },
];

const Team = () => {
  return (
    <section className="w-full py-16 sm:py-24 text-black-900 dark:text-white relative">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
        <div className="mb-12 text-left">
          <h2 className="text-4xl sm:text-5xl font-bold mb-4">Leadership</h2>
          <p className="text-lg sm:text-xl leading-snug tracking-tight text-gray-600 dark:text-gray-400">
            Led by visionary founders and industry experts driven by a passion for innovation and automation.
          </p>

        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {teamMembers.map((member) => (
            <div
              key={member.id}
              className="flex flex-col items-center p-6"
            >
              <img
                alt={member.name}
                src={member.image}
                className="rounded-full h-40 w-40 object-cover mb-4 border"
              />
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {member.name}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">{member.title}</p>
              <p className="mt-4 text-base text-gray-700 dark:text-gray-300 text-center">
                {member.description}
              </p>
              <a
                href={member.linkedin}
                target='_blank'
                rel='noopener noreferrer'
                className="mt-4 inline-flex items-center text-base font-medium text-indigo-500 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-300 transition-colors duration-200"
              >
                Follow on LinkedIn
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  className="ml-2 w-5 h-5"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11.75 20h-2.5v-10h2.5v10zm-1.25-11.428c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5zm12.75 11.428h-2.5v-5.428c0-1.292-.028-2.953-1.8-2.953-1.8 0-2.075 1.406-2.075 2.857v5.524h-2.5v-10h2.4v1.377h.035c.334-.635 1.152-1.3 2.372-1.3 2.539 0 3.007 1.67 3.007 3.842v6.081z"></path>
                </svg>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;

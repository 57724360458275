import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import PurchaseDetailModal from './PurchaseDetailModal';

function RecentArbitragePurchases({ purchases }) {
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const viewDetails = (purchase) => {
    setSelectedPurchase(purchase);
    setIsModalOpen(true);
  };

  return (
    <div className='flex flex-col h-[500px]'>
      <div className='mb-4 flex items-center justify-between'>
        <div>
          <h3 className='text-xl font-bold text-gray-900 dark:text-white mb-2'>
            Recent Arbitrage Purchases
          </h3>
          <span className='text-base font-normal text-gray-500 dark:text-gray-400'>
            This is a list of the latest purchases
          </span>
        </div>
        <Link
          to='/purchases'
          className='text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-600 flex items-center'
        >
          View All Purchases
          <ExternalLinkIcon className='ml-2 h-4 w-4' />
        </Link>
      </div>
      <div className='overflow-x-auto flex-grow'>
        <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-700'>
          <thead className='bg-gray-50 dark:bg-gray-700 sticky top-0'>
            <tr>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                Purchase Date
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                Order Number
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider'>
                Total Order Amount
              </th>
            </tr>
          </thead>
          <tbody className='bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700'>
            {purchases.map((purchase) => (
              <tr
                key={purchase._id}
                onClick={() => viewDetails(purchase)}
                className='cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700'
              >
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>
                  {new Date(purchase.purchaseDate).toLocaleDateString()}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>
                  {purchase.orderNumber}
                </td>
                <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-300'>
                  ${purchase.purchasePrice.toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <PurchaseDetailModal
          purchase={selectedPurchase}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default RecentArbitragePurchases;

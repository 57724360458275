import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const InventoryAgeChart = ({ inventoryAgeMetrics }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  // Ensure inventoryAgeMetrics is an array
  const inventoryAgeArray = Array.isArray(inventoryAgeMetrics.top5OldestItems) ? inventoryAgeMetrics.top5OldestItems : [];

  // Calculate average inventory age
  const averageAge = inventoryAgeMetrics.averageAge || 0;

  // Calculate total inventory count
  const totalInventoryCount = inventoryAgeMetrics.totalItems || 0;

  const chartData = {
    categories: inventoryAgeArray.map(item => item.item_description || 'Unnamed Item'),
    series: [{
      name: 'Age (Days)',
      data: inventoryAgeArray.map(item => item.age),
    }],
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => val,
        style: {
          colors: textColor,
        },
      },
    },
    fill: {
      colors: ['#9b34d3'],
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(0)} days`,
      },
    },
    theme: {
      mode: themeMode,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4">
        <div className="text-xl font-bold text-gray-800 dark:text-white">
          Top 5 Oldest Inventory Items
        </div>
        <div className="text-sm text-gray-600 dark:text-gray-400">
          Total Average Inventory Age: {Math.round(averageAge)} days
        </div>
        <div className="text-sm text-gray-600 dark:text-gray-400">
          Total Inventory Count: {totalInventoryCount}
        </div>
      </div>

      <Chart
        options={chartOptions}
        series={chartData.series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default InventoryAgeChart;

import React from 'react';

const statistics = [
  { value: '200k+', label: 'Products Analyzed' },
  { value: '10K+', label: 'Hours in Time Savings' },
  { value: '5+', label: 'Countries Reached' },
];

const Statistics = () => {
  return (
    <section
      id="statistics-section"
      className="w-full py-16 sm:py-24 text-black-900 dark:text-white relative"
    >
      <div className="max-w-7xl mx-auto px-6 sm:px-8 lg:px-12">
        {/* Header Section */}
        <div className="text-center lg:text-left mb-12">
          <h2 className="text-4xl sm:text-5xl font-bold">
            ArbiEngine's Reach
          </h2>
          <p className="mt-6 text-lg sm:text-xl leading-snug tracking-tight text-gray-600 dark:text-gray-400">
            We empower businesses worldwide with tools to scale multi-figure e-commerce ventures, drive data-driven decisions, and optimize operations.
          </p>
          <a
            href="/register-closed"
            className="mt-6 inline-flex items-center text-lg font-medium leading-none text-indigo-500 dark:text-indigo-400 hover:text-indigo-800 dark:hover:text-indigo-200 transition-colors duration-200"
          >
            Join the Beta Waitlist and be among the first to experience the power of ArbiEngine.
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="10"
              fill="none"
              viewBox="0 0 20 10"
              className="ml-2 transition-transform duration-200 group-hover:translate-x-2"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h18m0 0-4-4m4 4-4 4"
              />
            </svg>
          </a>
        </div>

        {/* Statistics Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16 text-center">
          {statistics.map((stat, index) => (
            <div key={index} className="flex flex-col items-center">
              <p className="text-8xl font-bold leading-none tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-pink-400">
                {stat.value}
              </p>
              <p className="mt-4 text-xl leading-snug tracking-tight text-gray-600 dark:text-gray-400">
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Statistics;

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { TrashIcon } from '@heroicons/react/outline'
import DeleteProductModal from '../Dashboard/Ecommerce/DeleteProductModal'
import {
  fetchAllCategories,
  deleteCategory,
} from '../../features/productResearch/productResearchSlice'

const CategoryGallery = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { filteredCategories, loading, error } = useSelector(
    (state) => state.productResearch
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchAllCategories(user._id))
    }
  }, [dispatch, user?._id])

  const handleDeleteClick = (category) => {
    setSelectedCategory(category)
    setIsModalOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (user && selectedCategory) {
      dispatch(
        deleteCategory({ userId: user._id, categoryId: selectedCategory._id })
      )
      setIsModalOpen(false)
      setSelectedCategory(null)
    }
  }

  const handleDeleteCancel = () => {
    setIsModalOpen(false)
    setSelectedCategory(null)
  }

  if (loading)
    return (
      <div className='flex justify-center items-center h-screen dark:text-white'>
        Loading...
      </div>
    )
  if (error)
    return (
      <div className='text-red-500 dark:text-red-400 text-center mt-4'>
        Error: {error}
      </div>
    )

  return (
    <div className='p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
      {filteredCategories.map((category) => (
        <div
          key={category._id}
          className='relative block bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 ease-in-out'
        >
          <Link
            to={`/arbie/categories/${category._id}`}
            className='flex flex-col justify-between h-full p-6'
          >
            <div>
              <h5 className='text-xl font-semibold text-gray-900 dark:text-white mb-2'>
                {category.searchTerm}
              </h5>
              <p className='text-sm text-gray-500 dark:text-gray-400'>
                {new Date(category.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </div>
          </Link>
          <div
            className='absolute top-2 right-2 p-1 rounded-full bg-transparent hover:bg-red-600 group transition-colors duration-300 cursor-pointer'
            onClick={() => handleDeleteClick(category)}
          >
            <TrashIcon className='h-6 w-6 text-gray-500 dark:text-gray-400 group-hover:text-white transition-colors duration-300' />
          </div>
        </div>
      ))}
      <DeleteProductModal
        isOpen={isModalOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        product={selectedCategory?.searchTerm}
      />
    </div>
  )
}

export default CategoryGallery

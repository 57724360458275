import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const InventoryCostChart = ({ inventoryHistory }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  // Ensure inventoryHistory is an array
  const inventoryHistoryArray = Array.isArray(inventoryHistory) ? inventoryHistory : [];

  // Generate all months in the past year
  const aggregatedData = {};
  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear(), today.getMonth() - 11, 1);

  for (let d = new Date(oneYearAgo); d <= today; d.setMonth(d.getMonth() + 1)) {
    const monthYear = `${d.getFullYear()}-${d.getMonth() + 1}`;
    aggregatedData[monthYear] = 0;
  }

  // Aggregate the data by month
  inventoryHistoryArray.forEach(({ purchaseDate, purchasePrice }) => {
    const date = new Date(purchaseDate);
    const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`;
    if (aggregatedData[monthYear] !== undefined) {
      aggregatedData[monthYear] += purchasePrice;
    }
  });

  // Calculate the total inventory spend over the past 12 months
  const totalInventorySpend = Object.values(aggregatedData).reduce(
    (acc, cost) => acc + cost,
    0
  );

  // Prepare chart data with sorted keys
  const sortedKeys = Object.keys(aggregatedData).sort((a, b) => {
    const [yearA, monthA] = a.split('-').map(Number);
    const [yearB, monthB] = b.split('-').map(Number);
    return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
  });

  const chartData = {
    labels: sortedKeys.map((key) => {
      const [year, month] = key.split('-');
      const date = new Date(year, month - 1);
      return date.toLocaleString('default', { month: 'short', year: '2-digit' });
    }),
    datasets: [
      {
        name: 'Monthly Purchase Cost',
        data: sortedKeys.map((key) => aggregatedData[key]),
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartData.labels,
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => `$${Math.round(val)}`, // Round values and remove decimals
        style: {
          colors: textColor,
        },
      },
    },
    fill: {
      colors: ['#9b34d3'],
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val.toFixed(0)}`, // Show whole numbers in tooltip
      },
    },
    theme: {
      mode: themeMode,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4">
        <div className="text-xl font-bold text-gray-800 dark:text-white">
          Inventory Cost Over Time
        </div>
        <div className="text-sm text-gray-600 dark:text-gray-400">
          Total Spend: ${Math.round(totalInventorySpend)}
        </div>
      </div>

      <Chart
        options={chartOptions}
        series={[{ name: 'Monthly Purchase Cost', data: chartData.datasets[0].data }]}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default InventoryCostChart;

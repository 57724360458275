import React, { useState, useEffect } from 'react';
import { LockClosedIcon } from '@heroicons/react/solid';

import MetricsLight from '../../images/Dashboard.png';
import MetricsDark from '../../images/Dashboard_Dark.png';

const ProductInformationSection = () => {
  const [selectedTab, setSelectedTab] = useState('Market Insights');
  const [theme, setTheme] = useState(
    () => localStorage.getItem('theme') || 'light'
  );

  useEffect(() => {
    const handleThemeChange = (e) => {
      setTheme(e.detail.theme);
    };
    window.addEventListener('themeChange', handleThemeChange);
    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const tabs = [
    {
      name: 'Market Insights',
      description:
        'Access comprehensive market insights to stay ahead of the competition. Our analytics tools help you understand trends and make informed decisions.',
      image: theme === 'dark' ? MetricsDark : MetricsLight,
    },
    {
      name: 'Automated Arbitrage',
      // description:
      //   'Leverage our automated pipelines to identify arbitrage opportunities. Our system continuously analyzes data to find profitable buy and resell opportunities in your niche.',
      description:
        'Cutting-edge technology to help you discover opportunities in your market. More details coming soon with the beta.',
      image: 'https://via.placeholder.com/800x600.png?text=Automated+Arbitrage+Placeholder',
    },
    {
      name: 'Results Feed',
      // description:
      //   'Get real-time updates on actionable opportunities. Our results feed provides you with data-driven insights and recommendations tailored to your specific market niche.',
      description:
        'Stay tuned for a real-time stream of insights and opportunities. More details coming soon with the beta.',
      image: 'https://via.placeholder.com/800x600.png?text=Results+Feed+Placeholder',
    },
    {
      name: 'Multichannel Integration',
      // description:
      //   "Seamlessly integrate with ecommerce channels to manage your listings, track sales, and optimize your store's performance with our advanced tools.",
      description:
        'Integrate with multiple channels effortlessly. Full details will be available with the beta launch.',
      image: 'https://via.placeholder.com/800x600.png?text=Ebay+Integration+Placeholder',
    },
    {
      name: 'Product Research',
      // description:
      //   'Conduct thorough product research with our comprehensive tools. Analyze trends, evaluate competition, and identify profitable niches.',
      description:
        'Advanced research tools to help you identify your next big product. Stay tuned for more.',
      image: 'https://via.placeholder.com/800x600.png?text=Product+Research+Placeholder',
    },
  ];

  const handleTabClick = (tabName) => {
    if (tabName === 'Market Insights') {
      setSelectedTab(tabName);
    }
  };

  return (
    <section
      id="features"
      aria-label="Features for ArbiEngine"
      className="relative overflow-hidden bg-indigo-600 pb-28 pt-20 sm:py-20 dark:bg-gray-900"
    >
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl">
            Empower Your Business with ArbiEngine
          </h2>
          <p className="mt-6 text-lg tracking-tight text-indigo-100">
            ArbiEngine provides automated pipelines and real-time insights to
            help you scale with data.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
          <div className="lg:col-span-5">
            <div
              className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal"
              role="tablist"
              aria-orientation="vertical"
            >
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  className={`group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6 ${selectedTab === tab.name
                      ? 'bg-white text-indigo-600 dark:bg-indigo-600 dark:text-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                      : 'hover:bg-white/10 lg:hover:bg-white/5'
                    }`}
                  onClick={() => handleTabClick(tab.name)}
                >
                  <h3>
                    <button
                      className={`flex items-center font-display text-lg ui-not-focus-visible:outline-none ${selectedTab === tab.name
                          ? 'text-indigo-600 dark:text-white lg:text-white'
                          : 'text-indigo-100 hover:text-white lg:text-white'
                        }`}
                      aria-selected={selectedTab === tab.name}
                      role="tab"
                      type="button"
                    >
                      {tab.name}
                      {tab.name !== 'Market Insights' && (
                        <span className="ml-2 text-gray-400 group-hover:text-gray-200">
                          <LockClosedIcon className="h-5 w-5" />
                        </span>
                      )}
                    </button>
                  </h3>
                  <p
                    className={`mt-2 hidden text-sm lg:block ${selectedTab === tab.name
                        ? 'text-white'
                        : 'text-indigo-100 group-hover:text-white'
                      }`}
                  >
                    {tab.description}
                  </p>
                  {tab.name !== 'Market Insights' && (
                    <div className="absolute -top-2 -right-2 hidden group-hover:block">
                      <div className="relative bg-gray-700 text-white text-xs p-2 rounded-md">
                        More details coming soon with the beta.
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="lg:col-span-7 lg:col-start-6">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                id={`tab-content-${tab.name}`}
                role="tabpanel"
                aria-labelledby={`tab-${tab.name}`}
                hidden={selectedTab !== tab.name}
              >
                <div className="relative sm:px-6 lg:hidden">
                  <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"></div>
                  <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                    {tab.description}
                  </p>
                </div>
                <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-indigo-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem] dark:bg-gray-800">
                  <img
                    alt={tab.name}
                    className="w-full object-fit lg:object-none"
                    style={{ color: 'transparent' }}
                    src={tab.image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductInformationSection;

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  sendVerificationEmail,
  verifyCode,
  disable2FA,
} from '../../features/userSettings/settingsSlice'
import { setIs2FAEnabled } from '../../features/auth/authSlice';

function SecuritySettings() {
  const [isVerificationOpen, setIsVerificationOpen] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [message, setMessage] = useState('')
  const { is2FAEnabled } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const handleEnable2FA = () => {
    dispatch(sendVerificationEmail())
      .unwrap()
      .then(() => {
        setIsVerificationOpen(true);
        setMessage('A verification code has been sent to your email.');
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const handleDisable2FA = () => {
    dispatch(disable2FA())
      .unwrap()
      .then(() => {
        setMessage('Two-factor authentication has been disabled.');
        dispatch(setIs2FAEnabled(false)); // Update the state to false
      })
      .catch((error) => {
        setMessage(error.message);
      });
  };

  const handleVerifyCode = (e) => {
    e.preventDefault();
    dispatch(verifyCode({ verificationCode }))
      .unwrap()
      .then(() => {
        setMessage('Two-factor authentication has been enabled.');
        setIsVerificationOpen(false);
        dispatch(setIs2FAEnabled(true)); // Update the state to true
      })
      .catch((error) => {
        setMessage('Verification failed. Please try again.');
      });
  };

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-5'>
        Security Settings
      </h1>

      {message && (
        <p className='text-sm text-gray-700 dark:text-gray-300 mb-4'>
          {message}
        </p>
      )}

      <div className='mb-6'>
        <div className='mb-4'>
          <label className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'>
            Two-Factor Authentication
          </label>
          <div className='flex items-center'>
            <button
              onClick={handleEnable2FA}
              className={`${is2FAEnabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'
                } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2`}
              disabled={is2FAEnabled}
            >
              Enable
            </button>
            <button
              onClick={handleDisable2FA}
              className={`${!is2FAEnabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-red-500 hover:bg-red-600'
                } text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
              disabled={!is2FAEnabled}
            >
              Disable
            </button>
          </div>
        </div>
      </div>

      {isVerificationOpen && (
        <div className='mb-6'>
          <form onSubmit={handleVerifyCode}>
            <div className='mb-4'>
              <label
                htmlFor='verificationCode'
                className='block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2'
              >
                Enter Verification Code
              </label>
              <input
                type='text'
                id='verificationCode'
                name='verificationCode'
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              />
            </div>
            <div className='flex items-center justify-between'>
              <button
                type='submit'
                className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              >
                Verify
              </button>
              <button
                type='button'
                onClick={() => setIsVerificationOpen(false)}
                className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default SecuritySettings

// SkuPrefixSection.jsx - Updated with larger text and left-aligned layout
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSkuPrefix, updateUserSkuPrefix } from '../../features/inventory/inventorySlice';
import { TagIcon, InformationCircleIcon } from '@heroicons/react/solid';

const SkuPrefixSection = () => {
  const dispatch = useDispatch();
  const { skuPrefix, updatingSkuPrefix, skuPrefixError } = useSelector((state) => state.inventory);

  const [newSkuPrefix, setNewSkuPrefix] = useState('ARB_');
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    dispatch(fetchSkuPrefix());
  }, [dispatch]);

  useEffect(() => {
    if (skuPrefix !== null && skuPrefix !== undefined) {
      setNewSkuPrefix(skuPrefix);
      setIsModified(false);
    }
  }, [skuPrefix]);

  const handleChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9_]*$/.test(value)) {
      setNewSkuPrefix(value);
      setIsModified(value !== skuPrefix);
    }
  };

  const handleSave = () => {
    if (newSkuPrefix.trim() && newSkuPrefix !== skuPrefix) {
      dispatch(updateUserSkuPrefix(newSkuPrefix));
      setIsModified(false);
    }
  };

  if (skuPrefix === null || skuPrefix === undefined) {
    return <div className="text-center text-gray-500 dark:text-gray-400">Loading SKU Prefix...</div>;
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-3 space-y-4">
      <div className="flex items-center space-x-2">
        <TagIcon className="w-6 h-6 text-indigo-500" />
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">SKU Prefix</h2>
        <InformationCircleIcon className="w-5 h-5 text-gray-400" />
      </div>

      <p className="text-sm text-gray-600 dark:text-gray-400">
        Set a prefix for all SKUs. This helps in organizing and identifying items.
      </p>

      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={newSkuPrefix}
          onChange={handleChange}
          className="w-full max-w-xs px-4 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 text-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100 placeholder-gray-400"
          maxLength="10"
          placeholder="E.g., ARB_"
        />
        {isModified && (
          <button
            onClick={handleSave}
            className={`px-4 py-2 text-md font-medium text-white rounded transition-colors ${updatingSkuPrefix
              ? 'bg-indigo-300 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700'
              }`}
            disabled={updatingSkuPrefix}
          >
            {updatingSkuPrefix ? 'Saving...' : 'Save'}
          </button>
        )}
      </div>

      {skuPrefixError && <div className="text-sm text-red-500">{skuPrefixError}</div>}
    </div>
  );
};

export default SkuPrefixSection;

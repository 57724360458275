import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnections, signIn, updateUserConnection, deleteConnectionThunk } from '../../features/connections/connectionSlice';
import ConnectionCard from '../../components/connections/ConnectionCard';
import ConnectionModal from '../../components/connections/ConnectionModal';
import ReauthenticateModal from '../../components/connections/ReauthenticateModal';
import DeleteModal from '../../components/connections/DeleteModal';

const platformConfig = {
  ebay: {
    name: 'eBay',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/EBay_logo.png/800px-EBay_logo.png?20120913164145',
    allowMultipleAccounts: true,
    defaultAccountType: 'selling',
    eligibilityTier: 'Explorer',
    requestLimit: 100,
    allowedAccounts: {
      Explorer: { sellingAccounts: 1, buyingAccounts: 0 },
      Accelerator: { sellingAccounts: 2, buyingAccounts: 1 },
      Professional: { sellingAccounts: 3, buyingAccounts: 2 },
      Enterprise: { sellingAccounts: 5, buyingAccounts: 5 },
    },
  },
  amazon: {
    name: 'Amazon',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg',
    allowMultipleAccounts: true,
    defaultAccountType: 'selling',
    eligibilityTier: 'Accelerator',
    requestLimit: 200,
    allowedAccounts: {
      Explorer: { sellingAccounts: 0, buyingAccounts: 0 },
      Accelerator: { sellingAccounts: 1, buyingAccounts: 0 },
      Professional: { sellingAccounts: 2, buyingAccounts: 1 },
      Enterprise: { sellingAccounts: 4, buyingAccounts: 2 },
    },
  },
};

const ConnectionSettings = () => {
  const dispatch = useDispatch();
  const { connections, isLoading, isError } = useSelector((state) => state.connection);
  const { subscription } = useSelector((state) => state.subscription);
  const globalActivePlan = subscription?.selectedPlan || 'Explorer';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReauthenticateModalOpen, setIsReauthenticateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPlatform, setCurrentPlatform] = useState(null);
  const [accountType, setAccountType] = useState('');
  const [editingConnection, setEditingConnection] = useState(null);
  const [deletingConnection, setDeletingConnection] = useState(null);

  useEffect(() => {
    if (subscription?.userId) {
      dispatch(fetchConnections(subscription.userId));
    }
  }, [dispatch, subscription]);

  const handleAddAccount = (platform, accountType) => {
    setIsModalOpen(true);
    setCurrentPlatform(platform);
    setAccountType(accountType);
  };

  const handleEditAccount = (connection) => {
    setIsModalOpen(true);
    setCurrentPlatform(connection.platform);
    setAccountType(connection.accountType);
    setEditingConnection(connection);
  };

  const handleDeleteAccount = (connection) => {
    setIsDeleteModalOpen(true);
    setDeletingConnection(connection);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentPlatform(null);
    setAccountType('');
    setEditingConnection(null);
  };

  const handleCloseReauthenticateModal = () => {
    setIsReauthenticateModalOpen(false);
    setEditingConnection(null);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeletingConnection(null);
  };

  const handleModalSubmit = (connectionDetails) => {
    console.log("Marshmellow")
    console.log(connectionDetails)
    if (editingConnection) {
      dispatch(updateUserConnection({
        connectionId: connectionDetails.connectionId,
        newAccountName: connectionDetails.accountName,
        listToByDefault: connectionDetails.listToByDefault,
      }));
    } else {
      dispatch(signIn(connectionDetails));
    }
    setIsModalOpen(false);
    setCurrentPlatform(null);
    setAccountType('');
    setEditingConnection(null);
  };

  const handleReauthenticate = (connection) => {
    setIsReauthenticateModalOpen(true);
    setEditingConnection(connection);
  };

  const handleReauthenticateConfirm = () => {
    dispatch(signIn({
      platform: editingConnection.platform,
      accountType: editingConnection.accountType,
      accountName: editingConnection.accountName,
      connectionId: editingConnection.id,
    }));
    handleCloseReauthenticateModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteConnectionThunk({ connectionId: deletingConnection.id, platform: deletingConnection.platform }));
    handleCloseDeleteModal();
  };

  const isEligible = (platform) => {
    const tierOrder = ['Explorer', 'Accelerator', 'Professional', 'Enterprise'];
    const platformTier = platformConfig[platform].eligibilityTier;
    const userTierIndex = tierOrder.indexOf(globalActivePlan);
    const platformTierIndex = tierOrder.indexOf(platformTier);
    return userTierIndex >= platformTierIndex;
  };

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-8'>Connection Settings</h1>
      <div className='bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-6 mb-8 rounded-lg shadow-md border'>
        <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2'>Summary</h2>
        <p className='text-gray-700 dark:text-gray-300 mb-2'>
          You can manage your connections to various platforms here. Some connections may have limits on the number of requests. Upgrade your access to unlock more connections.
        </p>
      </div>

      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {Object.keys(platformConfig).map((platform) => (
          <div key={platform}>
            <ConnectionCard
              key={platform}
              platform={platform}
              logoUrl={platformConfig[platform].logoUrl}
              allowMultipleAccounts={platformConfig[platform].allowMultipleAccounts}
              requestLimit={platformConfig[platform].requestLimit}
              allowedAccounts={platformConfig[platform].allowedAccounts}
              defaultAccountType={platformConfig[platform].defaultAccountType}
              isEligible={isEligible(platform)}
              connections={connections.filter(conn => conn.platform === platform)}
              isLoading={isLoading}
              isError={isError}
              onConnect={(accountType) => handleAddAccount(platform, accountType)}
              onEdit={handleEditAccount}
              onDelete={handleDeleteAccount}
              onReauthenticate={handleReauthenticate}
            />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ConnectionModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleModalSubmit}
          platform={currentPlatform}
          accountType={accountType}
          subscription={subscription}
          platformConfig={platformConfig[currentPlatform]}
          connections={connections.filter(conn => conn.platform === currentPlatform)}
          editingConnection={editingConnection}
        />
      )}

      {isReauthenticateModalOpen && (
        <ReauthenticateModal
          isOpen={isReauthenticateModalOpen}
          onClose={handleCloseReauthenticateModal}
          onConfirm={handleReauthenticateConfirm}
          connection={editingConnection}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteConfirm}
          connection={deletingConnection}
        />
      )}
    </div>
  );
};

export default ConnectionSettings;

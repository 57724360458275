import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getPendingPhotographyItems } from '../../features/inventory/inventorySlice'
import EditPhotographyModal from '../../components/Dashboard/Photography/EditPhotographyModal'
import ItemsTable from '../../components/Dashboard/Photography/ItemsTable'
import PhotographyActions from '../../components/Dashboard/Photography/PhotographyActions'
import MainPagination from '../../components/Dashboard/MainPagination'

const PhotographyQueue = () => {
  const dispatch = useDispatch()
  const { pendingPhotographyItems, loading, error } = useSelector(
    (state) => state.inventory
  )
  const [selectedItem, setSelectedItem] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    dispatch(getPendingPhotographyItems())
  }, [dispatch])

  const handlePhotographItem = (item) => {
    setSelectedItem(item)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedItem(null)
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber)

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value))
    setCurrentPage(1)
  }

  const filteredItems = searchTerm
    ? pendingPhotographyItems.filter(
        (item) =>
          item.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.item_description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : pendingPhotographyItems

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <div className='min-h-screen flex flex-col p-4 pt-0'>
      <div className='container mx-auto'>
        <div className='top-0 z-20 bg-gray-50 dark:bg-gray-900'>
          <PhotographyActions
            value={searchTerm}
            onChange={handleSearchChange}
            pendingRecordsCount={filteredItems.length}
          />
        </div>

        {loading && (
          <div className='text-center text-gray-700 dark:text-gray-300'>
            Loading items...
          </div>
        )}
        {error && <div className='text-center text-red-500'>{error}</div>}

        <div className='bg-white dark:bg-gray-800 shadow rounded-lg'>
          <ItemsTable
            items={currentItems}
            onPhotographItem={handlePhotographItem}
          />
        </div>

        <div className='flex justify-between items-center my-4'>
          <div className='text-gray-700 dark:text-gray-300'>
            Show
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className='mx-2 p-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md'
            >
              {[10, 25, 50, 100, 'All'].map((size) => (
                <option
                  key={size}
                  value={size === 'All' ? filteredItems.length : size}
                >
                  {size}
                </option>
              ))}
            </select>
            items per page
          </div>
          <MainPagination
            totalItems={filteredItems.length}
            currentPage={currentPage}
            paginate={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
            itemsPerPage={itemsPerPage}
          />
        </div>

        {modalOpen && selectedItem && (
          <EditPhotographyModal
            isOpen={modalOpen}
            onClose={handleModalClose}
            item={selectedItem}
          />
        )}
      </div>
    </div>
  )
}

export default PhotographyQueue

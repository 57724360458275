import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import {
  addInventoryItem,
  updateItem,
} from '../../../features/inventory/inventorySlice'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from '../../Spinner'

const ProductFormModal = ({ isOpen, onClose, product = null, onSuccess }) => {
  const dispatch = useDispatch()

  const uspsShippingOptions = [
    { label: 'USPS First Class', value: 'USPSFirstClass' },
    {
      label: 'USPS Priority Padded Flat Rate Envelope',
      value: 'USPSPriorityMailPaddedFlatRateEnvelope',
    },
    {
      label: 'USPS Priority Small Flat Rate Box',
      value: 'USPSPriorityMailSmallFlatRateBox',
    },
    {
      label: 'USPS Priority Mail Medium Flat Rate Box',
      value: 'USPSPriorityFlatRateBox',
    },
    {
      label: 'USPS Priority Mail Large Flat Rate Box',
      value: 'USPSPriorityMailLargeFlatRateBox',
    },
  ]

  const initialFormState = {
    brand: '',
    model: '',
    item_description: '',
    condition: 'Select Condition',
    quantity: '',
    price: '',
    bestOffer: true, // Default to true for new product
    autoAcceptPrice: '',
    autoDeclinePrice: '',
    ebayListingStatus: 'draft',
    dimensions: { length: '', width: '', height: '', unit: 'INCH' },
    weight: { value: '', unit: 'POUND' },
    category: '',
    additional_info: '',
    acceptsReturns: true,
    shippingMethods: ['UPSGround'],
  }

  const [formData, setFormData] = useState(initialFormState)
  const [uspsSelected, setUspsSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (product) {
      // Always calculate autoAcceptPrice and autoDeclinePrice from product's price
      const calculatedAutoAcceptPrice = calculateAutoAcceptPrice(product.price)
      const calculatedAutoDeclinePrice = calculateAutoDeclinePrice(
        product.price
      )

      setFormData({
        ...initialFormState, // Reset to initial form state to ensure a clean slate
        ...product, // Spread existing product details
        bestOffer:
          product.ebayDetails?.bestOfferTerms?.bestOfferEnabled || false,
        autoAcceptPrice: calculatedAutoAcceptPrice, // Use calculated value
        autoDeclinePrice: calculatedAutoDeclinePrice, // Use calculated value
        dimensions: product.dimensions ?? {
          length: '',
          width: '',
          height: '',
          unit: 'INCH',
        },
        weight: { value: product.weight.value, unit: product.weight.unit } ?? {
          value: '',
          unit: 'POUND',
        },
        category: product.category ?? '',
        additional_info: product.additional_info ?? '',
        shippingMethods: product.shippingMethods?.length
          ? product.shippingMethods
          : ['UPSGround'],
      })
      setUspsSelected(
        product.shippingMethods?.some((method) =>
          uspsShippingOptions.some((option) => option.value === method)
        )
      )
    } else {
      // If there is no product (adding a new product), reset to initial form state
      setFormData(initialFormState)
    }
  }, [product])

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target

    if (type === 'checkbox') {
      setFormData((prev) => ({ ...prev, [name]: checked }))
    } else if (name === 'shippingMethods') {
      const updatedMethods = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      )
      setFormData((prev) => ({ ...prev, shippingMethods: updatedMethods }))
      setUspsSelected(
        updatedMethods.some((method) =>
          uspsShippingOptions.map((option) => option.value).includes(method)
        )
      )
    } else if (name.includes('dimensions.') || name.includes('weight.')) {
      // Handling nested state updates for dimensions and weight
      const [parentKey, childKey] = name.split('.')
      setFormData((prev) => ({
        ...prev,
        [parentKey]: {
          ...prev[parentKey],
          [childKey]: value,
        },
      }))
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }))
    }

    // Automatically recalculate autoAcceptPrice and autoDeclinePrice if price changes
    if (name === 'price') {
      const autoAcceptPrice = calculateAutoAcceptPrice(value)
      const autoDeclinePrice = calculateAutoDeclinePrice(value)
      setFormData((prev) => ({
        ...prev,
        autoAcceptPrice,
        autoDeclinePrice,
      }))
    }
  }

  const handleClear = () => {
    setUspsSelected(false)
    setFormData((prevFormData) => ({
      ...prevFormData,
      shippingMethods: ['UPSGround'],
    }))
  }

  const calculateAutoAcceptPrice = (price) => (price * 0.95).toFixed(2)
  const calculateAutoDeclinePrice = (price) => (price * 0.9).toFixed(2)

  const alwaysRequiredFields = ['brand', 'model']
  const conditionallyRequiredFields = [
    'item_description',
    'condition',
    'price',
    'dimensions.length',
    'dimensions.width',
    'dimensions.height',
    'weight.value',
    'quantity',
    'category',
    'additional_info',
  ]

  const getNestedFieldValue = (object, path) => {
    return path
      .split('.')
      .reduce(
        (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
        object
      )
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setIsLoading(true)

    const allRequiredFieldsFilled = checkAllRequiredFieldsFilled()
    const listingStatus = determineListingStatus(
      product,
      allRequiredFieldsFilled
    )
    const submissionData = {
      ...formData,
      listingStatus,
      photographyStatus: product ? formData.photographyStatus : 'pending',
    }

    const action = product
      ? updateItem({ id: product._id, inventoryData: submissionData })
      : addInventoryItem(submissionData)

    dispatch(action)
      .then(() => {
        toast.success('Product saved successfully')
        onClose()
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error) => {
        console.error('Saving product failed:', error)
        toast.error('Failed to save the product')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validateForm = () => {
    let isValid = true

    alwaysRequiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === '') {
        toast.error(`${field} is required.`)
        isValid = false
      }
    })

    const totalCharacterCount =
      `${formData.brand} ${formData.model} ${formData.item_description}`.trim()
        .length
    if (totalCharacterCount > 80) {
      toast.error(
        'The combination of Brand, Model/MPN, and Item Description must not exceed 80 characters.'
      )
      isValid = false
    }

    if (
      formData.ebayListingStatus !== 'draft' ||
      (product && product.ebayListingStatus !== 'draft')
    ) {
      conditionallyRequiredFields.forEach((field) => {
        const fieldValue = field.includes('.')
          ? getNestedFieldValue(formData, field)
          : formData[field]
        if (field.includes('dimensions.') || field === 'weight.value') {
          if (
            !fieldValue ||
            isNaN(parseFloat(fieldValue)) ||
            parseFloat(fieldValue) <= 0
          ) {
            toast.error(`${field.replace('.', ' ')} must be greater than 0.`)
            isValid = false
          }
        } else {
          if (!fieldValue || fieldValue.toString().trim() === '') {
            toast.error(`${field.replace('.', ' ')} is required.`)
            isValid = false
          }
        }
      })
    }

    const quantityValue = Number(formData.quantity)
    if (isNaN(quantityValue) || quantityValue <= 0) {
      toast.error('Quantity must be greater than 0.')
      isValid = false
    }

    if (
      formData.shippingMethods.includes('USPSFirstClass') &&
      formData.weight.unit !== 'OUNCE'
    ) {
      toast.error('USPS First Class requires weight to be in OUNCE.')
      isValid = false
    }
    if (
      formData.shippingMethods.some((method) => method !== 'USPSFirstClass') &&
      formData.weight.unit !== 'POUND'
    ) {
      toast.error(
        'Non-USPS First Class shipping requires weight to be in POUND.'
      )
      isValid = false
    }

    return isValid
  }

  const checkAllRequiredFieldsFilled = () => {
    const requiredFields = [
      ...alwaysRequiredFields,
      ...conditionallyRequiredFields,
    ]
    return requiredFields.every((field) => {
      const fieldValue = field.includes('.')
        ? getNestedFieldValue(formData, field)
        : formData[field]
      return fieldValue && fieldValue.toString().trim() !== ''
    })
  }

  const determineListingStatus = (product, allRequiredFieldsFilled) => {
    return allRequiredFieldsFilled ? 'completed' : 'draft'
  }

  const getEbayListingOptions = (currentStatus, photographyStatus) => {
    const capitalizeFirstLetter = (string) =>
      string.charAt(0).toUpperCase() + string.slice(1)

    if (photographyStatus !== 'completed') {
      return []
    }

    let options = []

    if (['active', 'inactive', 'draft'].includes(currentStatus)) {
      options.push({
        label: capitalizeFirstLetter(currentStatus),
        value: currentStatus,
      })
    }

    switch (currentStatus) {
      case 'active':
        options.push({ label: 'Inactive', value: 'inactive' })
        break
      case 'inactive':
        options.push({ label: 'Relist', value: 'relist' })
        break
      case 'draft':
        options.push({ label: 'Active', value: 'active' })
        break
      case 'relist':
        options.push({ label: 'Relist', value: 'relist' })
        break
      case 'deleted':
        options.push({ label: 'Draft', value: 'draft' })
        break
      default:
        options.push({ label: 'Draft', value: 'draft' })
        options.push({ label: 'Deleted', value: 'deleted' })
        break
    }

    return options
  }

  const ebayListingOptions = product
    ? getEbayListingOptions(
        product.ebayListingStatus,
        product.photographyStatus
      )
    : []

  if (!isOpen) return null

  return (
    <div
      className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full ${isOpen ? '' : 'hidden'} z-50`}
    >
      {isLoading && <Spinner />}
      <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 '>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>

        <div className='inline-block align-bottom bg-white dark:bg-gray-800 rounded-2xl text-left overflow-hidden shadow-2xl transform transition-all sm:my-10 sm:align-middle sm:max-w-7xl w-full '>
          <div className='flex justify-between items-start p-4 border-b border-gray-200 dark:border-gray-700'>
            <h3 className='text-lg font-medium text-gray-900 dark:text-gray-100'>
              {product ? 'Edit Product' : 'Add New Product'}
            </h3>
            <button
              onClick={onClose}
              className='text-gray-400 hover:text-gray-500 dark:hover:text-gray-300'
            >
              <svg
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>

          <form
            onSubmit={handleSubmit}
            className='px-4 py-5 sm:p-6 bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden'
          >
            <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
              <div>
                <label
                  htmlFor='brand'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  Brand
                </label>
                <input
                  type='text'
                  name='brand'
                  id='brand'
                  value={formData.brand}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  placeholder='e.g., Apple'
                />
              </div>

              <div>
                <label
                  htmlFor='model'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  Model
                </label>
                <input
                  type='text'
                  name='model'
                  id='model'
                  value={formData.model}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  placeholder='e.g., iPhone 12'
                />
              </div>

              <div>
                <label
                  htmlFor='itemDescription'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  Item Description
                </label>
                <input
                  type='text'
                  name='item_description'
                  id='itemDescription'
                  value={formData.item_description}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  placeholder='Brief description'
                />
              </div>

              <div>
                <label
                  htmlFor='condition'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  Condition
                </label>
                <select
                  id='condition'
                  name='condition'
                  value={formData.condition}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                >
                  <option value='Select-Condition'>Select Condition</option>
                  <option value='NEW'>New</option>
                  <option value='USED_EXCELLENT'>Used</option>
                  <option value='NEW_OTHER'>New Other</option>
                  <option value='FOR_PARTS_OR_NOT_WORKING'>
                    For Parts Only
                  </option>
                </select>
              </div>

              <div>
                <label
                  htmlFor='quantity'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  Quantity
                </label>
                <input
                  type='number'
                  name='quantity'
                  id='quantity'
                  value={formData.quantity}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  placeholder='e.g., 5'
                />
              </div>

              <div className='md:col-span-1'>
                <label
                  htmlFor='category'
                  className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                >
                  eBay Category ID
                </label>
                <input
                  type='text'
                  name='category'
                  id='category'
                  value={formData.category}
                  onChange={handleChange}
                  className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>

              <fieldset className='md:col-span-3 border p-4 rounded-md dark:border-gray-600 mb-4'>
                <legend className='text-lg font-medium text-gray-900 dark:text-gray-100'>
                  Pricing and eBay Listing Details
                </legend>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
                  <div>
                    <label
                      htmlFor='price'
                      className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                    >
                      Price
                    </label>
                    <input
                      type='number'
                      name='price'
                      id='price'
                      value={formData.price}
                      onChange={handleChange}
                      className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      placeholder='$'
                    />

                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 pt-2'>
                      {formData.bestOffer && (
                        <>
                          <div>
                            <label className='block text-sm font-medium text-gray-700 dark:text-gray-300'>
                              Auto Accept Price
                            </label>
                            <div className='mt-1 block w-full p-2 bg-gray-100 dark:bg-gray-600 border border-gray-300 dark:border-gray-600 rounded-md'>
                              ${formData.autoAcceptPrice}
                            </div>
                          </div>
                          <div>
                            <label className='block text-sm font-medium text-gray-700 dark:text-gray-300'>
                              Auto Decline Price
                            </label>
                            <div className='mt-1 block w-full p-2 bg-gray-100 dark:bg-gray-600 border border-gray-300 dark:border-gray-600 rounded-md'>
                              ${formData.autoDeclinePrice}
                            </div>
                          </div>
                        </>
                      )}

                      {product &&
                        product.photographyStatus === 'completed' &&
                        product.listingStatus === 'completed' && (
                          <div className='md:col-span-2'>
                            <label
                              htmlFor='ebayListingStatus'
                              className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                            >
                              eBay Listing Status
                            </label>
                            <select
                              id='ebayListingStatus'
                              name='ebayListingStatus'
                              value={formData.ebayListingStatus}
                              onChange={handleChange}
                              className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                            >
                              {ebayListingOptions.length > 0 ? (
                                ebayListingOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))
                              ) : (
                                <option value='draft'>Draft</option>
                              )}
                            </select>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='pt-2'>
                    <div className='flex items-center mb-4'>
                      <input
                        type='checkbox'
                        name='bestOffer'
                        id='bestOffer'
                        checked={formData.bestOffer}
                        onChange={handleChange}
                        className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                      />
                      <label
                        htmlFor='bestOffer'
                        className='text-sm font-medium text-gray-700 dark:text-gray-300'
                      >
                        Enable Best Offer
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        type='checkbox'
                        name='acceptsReturns'
                        id='acceptsReturns'
                        checked={formData.acceptsReturns}
                        onChange={handleChange}
                        className='form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2'
                      />
                      <label
                        htmlFor='acceptsReturns'
                        className='text-sm font-medium text-gray-700 dark:text-gray-300'
                      >
                        Accepts Returns
                      </label>
                    </div>
                  </div>

                  <div className='md:col-span-2'>
                    <label
                      htmlFor='additionalInfo'
                      className='block text-sm font-medium text-gray-700 dark:text-gray-300'
                    >
                      Additional Information
                    </label>
                    <textarea
                      id='additionalInfo'
                      name='additional_info'
                      rows='3'
                      value={formData.additional_info}
                      onChange={handleChange}
                      className='mt-1 block w-full h-3/4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    ></textarea>
                  </div>
                </div>
              </fieldset>

              <div className='md:col-span-3'>
                <fieldset className='border p-4 rounded-md dark:border-gray-600'>
                  <legend className='text-lg font-medium text-gray-900 dark:text-gray-100'>
                    Package Dimensions & Weight
                  </legend>
                  <div className='grid grid-cols-4 gap-4'>
                    <input
                      type='number'
                      name='dimensions.length'
                      id='dimensions.length'
                      value={formData.dimensions.length}
                      onChange={handleChange}
                      className='col-span-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      placeholder='Length (in)'
                    />
                    <input
                      type='number'
                      name='dimensions.width'
                      id='dimensions.width'
                      value={formData.dimensions.width}
                      onChange={handleChange}
                      className='col-span-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      placeholder='Width (in)'
                    />
                    <input
                      type='number'
                      name='dimensions.height'
                      id='dimensions.height'
                      value={formData.dimensions.height}
                      onChange={handleChange}
                      className='col-span-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      placeholder='Height (in)'
                    />
                    <input
                      type='number'
                      name='weight.value'
                      id='weight.value'
                      value={formData.weight.value}
                      onChange={handleChange}
                      className='col-span-1 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                      placeholder='Weight'
                    />
                    <select
                      name='weight.unit'
                      id='weightUnit'
                      value={formData.weight.unit}
                      onChange={handleChange}
                      className='col-span-1 mt-4 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    >
                      <option value='POUND'>POUND</option>
                      <option value='OUNCE'>OUNCE</option>
                    </select>
                    <div className='md:col-span-3 mt-4'>
                      <label
                        htmlFor='shippingMethods'
                        className='text-sm font-medium text-gray-700 dark:text-gray-300 mr-2'
                      >
                        Shipping Method
                      </label>
                      {uspsSelected && (
                        <button
                          type='button'
                          className='p-1 text-sm rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                          onClick={handleClear}
                        >
                          Reset
                        </button>
                      )}
                      <div className='relative mt-1'>
                        <select
                          name='shippingMethods'
                          id='shippingMethods'
                          value={formData.shippingMethods}
                          onChange={handleChange}
                          className='mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm py-2 px-3 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                          multiple
                        >
                          <option value='UPSGround'>UPS Ground Only</option>
                          {uspsShippingOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className='md:col-span-3'>
                <button
                  type='submit'
                  className={`w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${isLoading ? 'bg-indigo-300' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  disabled={isLoading}
                >
                  {isLoading
                    ? 'Processing...'
                    : product
                      ? 'Update Product'
                      : 'Add Product'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ProductFormModal

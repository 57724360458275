import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as productResearchService from './productResearchService'

const initialState = {
  categories: [],
  filteredCategories: [],
  researchDetail: {},
  userSearchTerms: [],
  userSearchTermStatistics: [],
  researchResults: [],
  last30DaysData: [],
  subscriptionDetails: [],
  loading: false,
  loading_stats: false,
  error: null,
  dataFetched: false, 
}

export const fetchUserSearchTermStatistics = createAsyncThunk(
  'productResearch/fetchUserSearchTermStatistics',
  async ({ userId }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      return await productResearchService.getUserSearchTermStatistics(
        userId,
        token
      )
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
          'Could not fetch user search term statistics'
      )
    }
  }
)

export const deleteUserSearchTerm = createAsyncThunk(
  'productResearch/deleteUserSearchTerm',
  async ({ userId, termKey }, { getState }) => {
    const {
      auth: { token },
    } = getState()
    const data = await productResearchService.deleteUserSearchTerm(
      userId,
      termKey,
      token
    )
    return data
  }
)

// Async thunk for filtering categories based on a search term
export const filterCategories = createAsyncThunk(
  'productResearch/filterCategories',
  async (searchTerm, { getState }) => {
    const allCategories = getState().productResearch.categories
    return searchTerm
      ? allCategories.filter((category) =>
          category.broadCategory
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : allCategories
  }
)

// Async thunks for various product research operations
export const fetchAllCategories = createAsyncThunk(
  'productResearch/fetchAllCategories',
  async (userId, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      return await productResearchService.listAllCategories(userId, token)
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Could not fetch categories'
      )
    }
  }
)

export const fetchCategoryDetails = createAsyncThunk(
  'productResearch/fetchCategoryDetails',
  async (categoryId, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      const response = await productResearchService.getCategoryDetails(
        categoryId,
        token
      )
      return response
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Could not fetch category details'
      )
    }
  }
)

export const performResearch = createAsyncThunk(
  'productResearch/performResearch',
  async (researchCriteria, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      return await productResearchService.performProductResearch(
        researchCriteria,
        token
      )
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Unable to perform product research'
      )
    }
  }
)

export const updateResearch = createAsyncThunk(
  'productResearch/updateResearch',
  async ({ researchId, updateData }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      return await productResearchService.updateProductResearch(
        researchId,
        updateData,
        token
      )
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to update product research'
      )
    }
  }
)

export const deleteResearch = createAsyncThunk(
  'productResearch/deleteResearch',
  async (researchId, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      return await productResearchService.deleteProductResearch(
        researchId,
        token
      )
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to delete product research'
      )
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'productResearch/deleteCategory',
  async ({ userId, categoryId }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      await productResearchService.deleteCategory(userId, categoryId, token)
      return categoryId
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to delete category'
      )
    }
  }
)

// Async thunk for adding a new search term
export const addSearchTerm = createAsyncThunk(
  'productResearch/addSearchTerm',
  async ({ term, termKey }, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState()
      const data = { term, termKey }
      return await productResearchService.addSearchTerm(data, token)
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to add search term'
      )
    }
  }
)

const productResearchSlice = createSlice({
  name: 'productResearch',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSearchTermStatistics.pending, (state) => {
        state.loading_stats = true
      })
      .addCase(fetchUserSearchTermStatistics.fulfilled, (state, action) => {
        state.userSearchTermStatistics = action.payload.searchTermStatistics
        state.last30DaysData = action.payload.last30DaysData
        state.loading_stats = false
        state.error = null
        state.dataFetched = true // Set flag to true when data is fetched
      })
      .addCase(fetchUserSearchTermStatistics.rejected, (state, action) => {
        state.loading_stats = false
        state.error = action.payload
      })
      .addCase(fetchAllCategories.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.filteredCategories = action.payload.categories
        state.categories = action.payload.categories
        state.loading = false
        state.error = null
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(filterCategories.fulfilled, (state, action) => {
        state.filteredCategories = action.payload
      })
      .addCase(fetchCategoryDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCategoryDetails.fulfilled, (state, action) => {
        state.researchDetail = action.payload.categoryDetails
        state.userSearchTerms = action.payload.userSearchTerms
        state.subscriptionDetails = action.payload.subscriptionDetails
        state.loading = false
        state.error = null
      })
      .addCase(fetchCategoryDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(performResearch.pending, (state) => {
        state.loading = true
      })
      .addCase(performResearch.fulfilled, (state, action) => {
        state.researchResults.push(action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(performResearch.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateResearch.pending, (state) => {
        state.loading = true
      })
      .addCase(updateResearch.fulfilled, (state, action) => {
        const index = state.researchResults.findIndex(
          (r) => r.id === action.payload.id
        )
        if (index !== -1) {
          state.researchResults[index] = action.payload
        }
        state.loading = false
        state.error = null
      })
      .addCase(updateResearch.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(deleteResearch.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteResearch.fulfilled, (state, action) => {
        state.researchResults = state.researchResults.filter(
          (r) => r.id !== action.meta.arg
        )
        state.loading = false
        state.error = null
      })
      .addCase(deleteResearch.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(deleteUserSearchTerm.fulfilled, (state, action) => {
        state.userSearchTermStatistics = state.userSearchTermStatistics.filter(
          (termStat) => termStat.termKey !== action.payload.termKey
        )
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.categories = state.categories.filter(
          (category) => category._id !== action.payload
        )
        state.filteredCategories = state.filteredCategories.filter(
          (category) => category._id !== action.payload
        )
        state.loading = false
        state.error = null
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(addSearchTerm.pending, (state) => {
        state.loading = true
      })
      .addCase(addSearchTerm.fulfilled, (state, action) => {
        state.userSearchTerms = action.payload.userPrefs.searchTerms
        state.loading = false
        state.error = null
      })
      .addCase(addSearchTerm.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export default productResearchSlice.reducer

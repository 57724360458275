import React from 'react';
import {
  ExternalLinkIcon,
  PencilIcon,
  TrashIcon,
  CameraIcon,
} from '@heroicons/react/outline';
import { Tooltip } from 'react-tooltip';

const ProductsTable = ({ items, onEdit, onDelete, onEditPhotos }) => {
  const defaultImageUrl = 'https://cdn-icons-png.flaticon.com/512/3342/3342243.png';

  const StatusIndicator = ({ status, tooltip }) => (
    <div className="relative flex items-center z-0">
      <div
        className={`h-3 w-3 rounded-full ${status === 'active' || status === 'completed' ? 'bg-green-500' : 'bg-gray-400'
          }`}
        data-tip={tooltip}
      ></div>
      <Tooltip place="top" type="dark" effect="solid" />
    </div>
  );

  return (
    <div className="overflow-x-auto relative mt-1 h-[70VH]">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="sticky top-0 z-10 text-xs text-gray-700 dark:text-gray-300 uppercase bg-gray-100 dark:bg-gray-700">
          <tr>
            <th scope="col" className="py-3 px-4">Image</th>
            <th scope="col" className="py-3 px-4">SKU</th>
            <th scope="col" className="py-3 px-4">Product Name</th>
            <th scope="col" className="py-3 px-4">Category</th>
            <th scope="col" className="py-3 px-4">Price</th>
            <th scope="col" className="py-3 px-4">Qty</th>
            <th scope="col" className="py-3 px-4">Status</th>
            <th scope="col" className="py-3 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item, index) => {
              const isDraftOrNotPhotographed = item.listingStatus === 'draft' || item.photographyStatus !== 'completed' || item.ebayListingStatus !== 'active';
              const rowClass = isDraftOrNotPhotographed ? 'bg-gray-50 dark:bg-gray-800' : 'bg-white dark:bg-gray-900';

              return (
                <tr
                  key={item._id}
                  className={`${rowClass} border-b dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700`}
                >
                  <td className="py-3 px-4">
                    <img
                      src={item.images && item.images.length > 0 ? item.images[0].awsUrl : defaultImageUrl}
                      alt={item.name}
                      className="h-16 w-16 rounded-lg object-cover cursor-pointer"
                      onClick={() => item.photographyStatus === 'completed' && onEditPhotos(item)}
                    />
                  </td>
                  <td className="py-3 px-4">{item.sku}</td>
                  <td className="py-3 px-4">{`${item.brand || ''} ${item.model || ''} ${item.item_description || ''}`}</td>
                  <td className="py-3 px-4">{item.category || 'N/A'}</td>
                  <td className="py-3 px-4">${item.price?.toFixed(2) || '0.00'}</td>
                  <td className="py-3 px-4">{item.quantity || 0}</td>
                  <td className="py-3 px-4">
                    <div className="flex flex-col space-y-1">
                      <div className="flex items-center space-x-1">
                        <StatusIndicator status={item.ebayListingStatus} tooltip={`eBay Status: ${item.ebayListingStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">eBay</span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <StatusIndicator status={item.photographyStatus} tooltip={`Photography Status: ${item.photographyStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">Photo</span>
                      </div>
                      <div className="flex items-center space-x-1">
                        <StatusIndicator status={item.listingStatus} tooltip={`Record Status: ${item.listingStatus}`} />
                        <span className="text-xs text-gray-600 dark:text-gray-300">Record</span>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-4 flex space-x-2">
                    <button
                      onClick={() => onEdit(item)}
                      className="text-indigo-600 hover:text-indigo-800 dark:text-indigo-400 dark:hover:text-indigo-500"
                      title="Edit"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    {item.ebayDetails?.listingId && (
                      <a
                        href={`https://www.ebay.com/itm/${item.ebayDetails.listingId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-500"
                        title="View on eBay"
                      >
                        <ExternalLinkIcon className="h-5 w-5" />
                      </a>
                    )}
                    <button
                      onClick={() => onDelete(item)}
                      className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-500"
                      title="Delete"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                    {item.photographyStatus === 'completed' && (
                      <button
                        onClick={() => onEditPhotos(item)}
                        className="text-gray-600 hover:text-gray-800 dark:text-gray-300 dark:hover:text-gray-400"
                        title="Edit Photos"
                      >
                        <CameraIcon className="h-5 w-5" />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" className="text-center py-6 text-gray-500 dark:text-gray-400">
                No products available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsTable;

import React from 'react'

const SubscriptionDetails = ({ selectedPlan, currentSubscription }) => {
  const planPrices = {
    Explorer: 29,
    Accelerator: 69,
    Professional: 129,
    Enterprise: 'Custom pricing',
  }

  const getPlanPrice = (planName) => {
    return planPrices[planName] !== 'Custom pricing'
      ? `$${planPrices[planName]}/month`
      : planPrices[planName]
  }

  return (
    <div className='flex flex-col space-y-6'>
      {selectedPlan ? (
        <div className='p-4 border rounded-lg shadow-lg bg-gradient-to-r from-indigo-500 to-indigo-600 dark:from-indigo-800 dark:to-gray-900'>
          <h2 className='text-2xl font-bold text-white mb-2'>
            {selectedPlan.name}
          </h2>
          <p className='text-xl text-white mb-2'>
            {getPlanPrice(selectedPlan.name)}
          </p>
          <p className='text-white mb-2'>{selectedPlan.description}</p>
          <ul className='mb-4 space-y-1'>
            {selectedPlan.features.map((feature, index) => (
              <li key={index} className='text-white flex items-center'>
                <svg
                  className='w-5 h-5 text-green-300 mr-2'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm4.293-11.707a1 1 0 00-1.414-1.414L9 9.586 7.121 7.707a1 1 0 00-1.414 1.414l2.586 2.586a1 1 0 001.414 0l5.586-5.586z'
                    clipRule='evenodd'
                  />
                </svg>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='p-4 border rounded-lg shadow-lg bg-white dark:bg-gray-800'>
          <h3 className='text-xl font-bold text-gray-900 dark:text-gray-100'>
            Subscription Details
          </h3>
          <p className='text-gray-600 dark:text-gray-300'>No plan selected.</p>
        </div>
      )}
      {currentSubscription &&
        currentSubscription.items &&
        currentSubscription.items.length > 0 && (
        <div className='p-4 border rounded-lg shadow-lg bg-gradient-to-r from-red-500 to-red-600 dark:from-red-800 dark:to-gray-900'>
            <h2 className='text-2xl font-bold text-white mb-2'>
              Current Subscription
            </h2>
            <div className='space-y-1'>
              <p className='text-xl text-white'>
                {currentSubscription.items[0].plan}
              </p>
              <p className='text-lg text-white'>
                {getPlanPrice(currentSubscription.items[0].plan)}
              </p>
            </div>
          </div>
        )}
    </div>
  )
}

export default SubscriptionDetails

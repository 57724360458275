import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const API_URL = `${apiUrl}/api/subscriptions/`;

// Fetch user's subscription
const fetchSubscription = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}${userId}`, config);
  return response.data;
};

// Create a new subscription
const createSubscription = async (subscriptionData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, subscriptionData, config);
  return response.data;
};

// Update an existing subscription
const updateSubscription = async (subscriptionData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL, subscriptionData, config);
  return response.data;
};

// Cancel a subscription item
const cancelSubscriptionItem = async (subscriptionId, subscriptionItemId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_URL, {
    data: { subscriptionId, subscriptionItemId }, // Send data in the body
    headers: config.headers,
  });
  return response.data;
};

const subscriptionService = {
  fetchSubscription,
  createSubscription,
  updateSubscription,
  cancelSubscriptionItem,
};

export default subscriptionService;

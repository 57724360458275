import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  UserIcon,
  BellIcon,
  LinkIcon,
  CreditCardIcon,
  ShieldCheckIcon,
  SupportIcon,
  GlobeAltIcon,
  CubeIcon
} from '@heroicons/react/outline'

import Logo from '../../images/arbie_gray_logo.png'

const SidebarNav = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { user, profileImageUrl } = useSelector((state) => state.auth)

  const navItems = [
    {
      name: 'Profile Settings',
      link: '/settings',
      icon: <UserIcon className='w-6 h-6' />,
    },
    {
      name: 'Notification Settings',
      link: '/settings/notifications',
      icon: <BellIcon className='w-6 h-6' />,
    },
    {
      name: 'Data Connection Settings',
      link: '/settings/connections',
      icon: <LinkIcon className='w-6 h-6' />,
    },
    {
      name: 'Subscription Management',
      link: '/settings/subscription',
      icon: <CreditCardIcon className='w-6 h-6' />,
    },
    {
      name: 'Security Settings',
      link: '/settings/security',
      icon: <ShieldCheckIcon className='w-6 h-6' />,
    },
    {
      name: 'Help & Support',
      link: '/settings/help',
      icon: <SupportIcon className='w-6 h-6' />,
    },
    {
      name: 'Social Media Connections',
      link: '/settings/social-media-connections',
      icon: <GlobeAltIcon className='w-6 h-6' />,
    },
    {
      name: 'Inventory Settings',
      link: '/settings/inventory-settings',
      icon: <CubeIcon className='w-6 h-6' />,
    },
  ]

  return (
    <div
      className={`fixed inset-0 z-10 lg:static lg:z-auto lg:inset-auto transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 bg-white dark:bg-gray-800 shadow-lg h-full lg:w-64 w-72`}
    >
      <div className='flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700'>
        <NavLink to='/' className='flex items-center'>
          <img src={Logo} className='h-8 w-auto' alt='Logo' />
        </NavLink>
        <button
          className='lg:hidden text-gray-500 dark:text-gray-400'
          onClick={() => setIsSidebarOpen(false)}
        >
          <svg
            className='w-6 h-6'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      </div>
      <div className='p-4'>
        <div className='flex items-center mb-4'>
          <img
            src={
              profileImageUrl ||
              'https://cdn-icons-png.flaticon.com/512/9131/9131529.png'
            }
            className='h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600'
            alt='User Avatar'
          />
          <div className='ml-3'>
            <h2 className='text-sm font-semibold text-gray-700 dark:text-gray-200'>
              Welcome, {user?.name}
            </h2>
          </div>
        </div>
        <nav className='space-y-1'>
          {navItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.link}
              className='flex items-center p-2 text-gray-700 rounded-lg hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700 transition'
              activeClassName='bg-gray-200 dark:bg-gray-700'
              onClick={() => setIsSidebarOpen(false)}
            >
              {item.icon}
              <span className='ml-3'>{item.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  )
}

export default SidebarNav

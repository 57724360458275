import React from 'react'

const ItemsTable = ({ items, onPhotographItem }) => {
  return (
    <div className='overflow-x-auto relative shadow-md mt-1 h-[60VH] bg-white dark:bg-gray-800 rounded-lg'>
      <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
        <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
          <tr>
            <th scope='col' className='py-3 px-6'>
              SKU
            </th>
            <th scope='col' className='py-3 px-6'>
              Title
            </th>
            <th scope='col' className='py-3 px-6'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr
              key={item._id}
              className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
            >
              <td className='py-4 px-6'>{item.sku}</td>
              <td className='py-4 px-6'>{item.item_description}</td>
              <td className='py-4 px-6'>
                <button
                  onClick={() => onPhotographItem(item)}
                  className='text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-200 dark:focus:ring-indigo-900 font-medium rounded-lg text-sm px-3 py-2 text-center'
                >
                  Photograph Item
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {items.length === 0 && (
        <div className='text-center py-4 px-6 text-gray-500 dark:text-gray-400'>
          No items pending photography
        </div>
      )}
    </div>
  )
}

export default ItemsTable

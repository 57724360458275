// PrivacyPolicy.jsx

import React from 'react';
import Header from '../components/Header.jsx';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="relative min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <Header />
      <main className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-center text-indigo-600 dark:text-indigo-400">
            Privacy Policy
          </h1>
          <p className="mt-6">
            Welcome to ArbiEngine. We value your privacy and want to be transparent about how we collect, use, and protect your information. This Privacy Policy outlines the types of data we collect, why we collect it, and how we ensure its protection.
          </p>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">1. Information We Collect</h2>
            <p className="mt-4">
              ArbiEngine collects personal information such as your name, email address, and usage data when you interact with our platform. This information helps us provide personalized services, optimize our platform, and communicate effectively with you.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">2. How We Use Your Information</h2>
            <p className="mt-4">
              We use the information collected to improve our services, provide support, and offer tailored content and marketing based on your preferences. Your information is never shared or sold to third parties without your consent.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">3. Data Security</h2>
            <p className="mt-4">
              We take data security seriously and implement a variety of measures to protect your information. We use encryption and access controls to safeguard data from unauthorized access.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">4. Your Rights</h2>
            <p className="mt-4">
              You have the right to access, modify, or delete your personal information. If you have any concerns or requests regarding your data, feel free to contact us at support@arbiengine.com.
            </p>
          </section>

          <section className="mt-8">
            <h2 className="text-2xl font-semibold">5. Changes to This Policy</h2>
            <p className="mt-4">
              We may update this Privacy Policy from time to time. If there are significant changes, we will notify you through the platform or via email. We encourage you to review this page periodically.
            </p>
          </section>

          <p className="mt-8">
            If you have any questions or need further information, please feel free to reach out to us.
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

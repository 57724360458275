// inventorySlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchInventoryItems,
  fetchInventoryItemById,
  createInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  fetchPendingPhotographyItems,
  uploadPhotographyImages,
  getDefaultCustomFields,
  updateDefaultCustomFields,
  syncInventoryItems,
  getSkuPrefix,
  updateSkuPrefix,
  deleteCustomField,
} from './inventoryService';

const initialState = {
  items: [],
  displayedItems: [],
  pendingPhotographyItems: [],
  itemDetails: {
    customFields: [], // Ensure customFields is initialized
    // Include other item details as necessary
  },
  loading: false,
  loadingMore: false,
  error: null,
  page: 1,
  itemsPerPage: 10,
  hasMoreItems: true,
  skuPrefix: 'ARB_',
  updatingSkuPrefix: false,
  skuPrefixError: null,
  defaultCustomFields: [], // User-configured default custom fields
  autoFillMissingFields: false,
  autoFillMissingFieldsValue: '',
  updatingCustomFields: false,
  customFieldsError: null,
  missingCustomFields: [],
};

// Async Thunks remain the same...

// Fetch initial inventory items
export const getInventoryItems = createAsyncThunk(
  'inventory/getInventoryItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchInventoryItems(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to fetch inventory items';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch more inventory items from local state
export const getMoreInventoryItems = createAsyncThunk(
  'inventory/getMoreInventoryItems',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().inventory;
      const startIndex = state.page * state.itemsPerPage;
      const endIndex = startIndex + state.itemsPerPage;
      const moreItems = state.items.slice(startIndex, endIndex);

      return moreItems;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Fetch individual inventory item by ID
export const getInventoryItem = createAsyncThunk(
  'inventory/getInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const data = await fetchInventoryItemById(token, id);

      // Extract default custom fields and preferences
      const {
        defaultCustomFields = [],
        autoFillMissingFields = false,
        autoFillMissingFieldsValue = 'N/A',
        ...inventoryData
      } = data;

      return {
        inventoryData, // Contains the actual inventory item details
        defaultCustomFields,
        autoFillMissingFields,
        autoFillMissingFieldsValue,
      };
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || 'Failed to fetch inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add a new inventory item
export const addInventoryItem = createAsyncThunk(
  'inventory/addInventoryItem',
  async (inventoryData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await createInventoryItem(token, inventoryData);
      return await fetchInventoryItems(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to add inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update an existing inventory item
export const updateItem = createAsyncThunk(
  'inventory/updateItem',
  async ({ id, inventoryData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const response = await updateInventoryItem(token, id, inventoryData);
      return response; // Assuming the backend returns the updated item on success
    } catch (error) {
      // Check if the error response contains missingFields
      if (error.response && error.response.status === 400 && error.response.data.missingFields) {
        return thunkAPI.rejectWithValue({
          message: error.response.data.message,
          missingFields: error.response.data.missingFields,
          canAutoFill: error.response.data.canAutoFill,
        });
      }

      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to update inventory item';
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

// Delete an inventory item
export const removeInventoryItem = createAsyncThunk(
  'inventory/removeInventoryItem',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await deleteInventoryItem(token, id);
      return id;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to delete inventory item';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch pending photography items
export const getPendingPhotographyItems = createAsyncThunk(
  'inventory/getPendingPhotographyItems',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await fetchPendingPhotographyItems(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to fetch pending photography items';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Upload images for a specific inventory item
export const uploadImagesForItem = createAsyncThunk(
  'inventory/uploadImagesForItem',
  async ({ inventoryItemId, formData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await uploadPhotographyImages(token, inventoryItemId, formData);
      return await fetchPendingPhotographyItems(token);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to upload images';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Upload edited images and refresh both inventory and pending photography lists
export const uploadEditedImagesForItem = createAsyncThunk(
  'inventory/uploadEditedImagesForItem',
  async ({ inventoryItemId, formData }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await uploadPhotographyImages(token, inventoryItemId, formData);
      const [refreshedItems, pendingPhotographyItems] = await Promise.all([
        fetchInventoryItems(token),
        fetchPendingPhotographyItems(token),
      ]);
      return { refreshedItems, pendingPhotographyItems };
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to upload edited images';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Sync inventory items
export const syncInventory = createAsyncThunk(
  'inventory/syncInventory',
  async ({ id, platform }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const user = thunkAPI.getState().auth.user;
      return await syncInventoryItems(token, id, platform, user); // Pass account and platform to the service
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Fetch default custom fields
export const fetchDefaultCustomFields = createAsyncThunk(
  'inventory/fetchDefaultCustomFields',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const data = await getDefaultCustomFields(token);
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to fetch default custom fields';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update default custom fields
export const updateUserDefaultCustomFields = createAsyncThunk(
  'inventory/updateUserDefaultCustomFields',
  async (customFieldSettings, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const data = await updateDefaultCustomFields(token, customFieldSettings);
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to update default custom fields';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Fetch SKU prefix
export const fetchSkuPrefix = createAsyncThunk(
  'inventory/fetchSkuPrefix',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const data = await getSkuPrefix(token);
      return data.skuPrefix;
    } catch (error) {
      // Extract error message
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to fetch SKU prefix';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update SKU prefix
export const updateUserSkuPrefix = createAsyncThunk(
  'inventory/updateUserSkuPrefix',
  async (newPrefix, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const data = await updateSkuPrefix(token, newPrefix);
      return data.skuPrefix;
    } catch (error) {
      // Extract error message
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to update SKU prefix';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete custom field
export const removeCustomField = createAsyncThunk(
  'inventory/removeCustomField',
  async (fieldId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      await deleteCustomField(token, fieldId);
      return fieldId; // Return the ID to remove it from the Redux state
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        'Failed to delete custom field';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    // Action to clear itemDetails
    clearItemDetails: (state) => {
      state.itemDetails = {
        customFields: [],
        // Reset other properties if necessary
      };
    },
    // Action to update itemDetails.customFields
    updateItemCustomFields: (state, action) => {
      state.itemDetails.customFields = action.payload;
    },
    // Action to update a specific custom field's value
    updateItemCustomFieldValue: (state, action) => {
      const { fieldName, value } = action.payload;
      const existingFieldIndex = state.itemDetails.customFields.findIndex(
        (field) => field.fieldName === fieldName
      );

      if (existingFieldIndex !== -1) {
        // Update existing field value
        state.itemDetails.customFields[existingFieldIndex].value = value;
      } else {
        // Add new field
        state.itemDetails.customFields.push({
          fieldName,
          value,
          isRequired: false, // Adjust as necessary
          platform: 'General', // Adjust as necessary
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Get inventory items and populate displayed items for the first page
      .addCase(getInventoryItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.page = 1;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch more items from the local state
      .addCase(getMoreInventoryItems.pending, (state) => {
        state.loadingMore = true;
        state.error = null;
      })
      .addCase(getMoreInventoryItems.fulfilled, (state, action) => {
        state.displayedItems = [...state.displayedItems, ...action.payload];
        state.page += 1;
        state.loadingMore = false;
        state.hasMoreItems = action.payload.length === state.itemsPerPage;
      })
      .addCase(getMoreInventoryItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingMore = false;
      })
      // Fetch individual inventory item by ID
      .addCase(getInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInventoryItem.fulfilled, (state, action) => {
        state.itemDetails = {
          ...state.itemDetails, // Preserve existing properties
          ...action.payload.inventoryData, // Merge new data
          customFields: action.payload.inventoryData.customFields || [],
        };
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.missingCustomFields = []; // Reset missing custom fields
        state.loading = false;
        state.error = null;
      })
      .addCase(getInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Add new inventory item and refresh list
      .addCase(addInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addInventoryItem.fulfilled, (state, action) => {
        state.items = action.payload;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(addInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Update inventory item and handle missing custom fields
      .addCase(updateItem.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.customFieldsError = null;
        state.missingCustomFields = []; // Reset missing fields on new update attempt
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        const updatedItem = action.payload;
        const index = state.items.findIndex((item) => item._id === updatedItem._id);
        if (index !== -1) {
          state.items[index] = updatedItem;
          // Update displayedItems if necessary
          const displayedIndex = state.displayedItems.findIndex(
            (item) => item._id === updatedItem._id
          );
          if (displayedIndex !== -1) {
            state.displayedItems[displayedIndex] = updatedItem;
          }
        }
        state.itemDetails = {
          ...state.itemDetails,
          ...updatedItem,
          customFields: updatedItem.customFields || [],
        };
        state.missingCustomFields = [];
        state.loading = false;
        state.error = null;
      })
      .addCase(updateItem.rejected, (state, action) => {
        const { message, missingFields } = action.payload || {};

        state.error = message || 'Failed to update inventory item.';
        state.loading = false;

        if (missingFields && missingFields.length > 0) {
          state.missingCustomFields = missingFields.map((field) => ({
            fieldName: field.fieldName,
            platform: field.platform || 'General',
            isRequired: true,
            value: '', // Initialize value
          }));
        }
      })
      // Delete inventory item and refresh displayed items
      .addCase(removeInventoryItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeInventoryItem.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item._id !== action.payload);
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(removeInventoryItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Fetch pending photography items
      .addCase(getPendingPhotographyItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPendingPhotographyItems.fulfilled, (state, action) => {
        state.pendingPhotographyItems = action.payload;
        state.loading = false;
      })
      .addCase(getPendingPhotographyItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Upload images for an item and update pending photography list
      .addCase(uploadImagesForItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadImagesForItem.fulfilled, (state, action) => {
        state.pendingPhotographyItems = action.payload;
        state.loading = false;
      })
      .addCase(uploadImagesForItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Upload edited images, refresh both inventory and pending photography lists
      .addCase(uploadEditedImagesForItem.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadEditedImagesForItem.fulfilled, (state, action) => {
        state.items = action.payload.refreshedItems;
        state.pendingPhotographyItems = action.payload.pendingPhotographyItems;
        state.displayedItems = state.items.slice(0, state.itemsPerPage);
        state.loading = false;
        state.hasMoreItems = state.items.length > state.itemsPerPage;
      })
      .addCase(uploadEditedImagesForItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      // Sync inventory items
      .addCase(syncInventory.pending, (state) => {
        state.loading = true;
      })
      .addCase(syncInventory.fulfilled, (state, action) => {
        state.loading = false;
        state.migratedListings = action.payload.migrationResults;
      })
      .addCase(syncInventory.rejected, (state, action) => {
        state.error = action.payload.message;
        state.loading = false;
      })
      // Handle SKU Prefix Fetching
      .addCase(fetchSkuPrefix.pending, (state) => {
        state.loading = true;
        state.skuPrefixError = null;
      })
      .addCase(fetchSkuPrefix.fulfilled, (state, action) => {
        state.skuPrefix = action.payload;
        state.loading = false;
      })
      .addCase(fetchSkuPrefix.rejected, (state, action) => {
        state.skuPrefixError = action.payload;
        state.loading = false;
      })
      // Handle SKU Prefix Updating
      .addCase(updateUserSkuPrefix.pending, (state) => {
        state.updatingSkuPrefix = true;
        state.skuPrefixError = null;
      })
      .addCase(updateUserSkuPrefix.fulfilled, (state, action) => {
        state.skuPrefix = action.payload;
        state.updatingSkuPrefix = false;
      })
      .addCase(updateUserSkuPrefix.rejected, (state, action) => {
        state.skuPrefixError = action.payload;
        state.updatingSkuPrefix = false;
      })
      // Custom Fields Handlers
      .addCase(fetchDefaultCustomFields.pending, (state) => {
        state.loading = true;
        state.customFieldsError = null;
      })
      .addCase(fetchDefaultCustomFields.fulfilled, (state, action) => {
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.loading = false;
      })
      .addCase(fetchDefaultCustomFields.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.loading = false;
      })
      .addCase(updateUserDefaultCustomFields.pending, (state) => {
        state.updatingCustomFields = true;
        state.customFieldsError = null;
      })
      .addCase(updateUserDefaultCustomFields.fulfilled, (state, action) => {
        state.defaultCustomFields = action.payload.defaultCustomFields;
        state.autoFillMissingFields = action.payload.autoFillMissingFields;
        state.autoFillMissingFieldsValue = action.payload.autoFillMissingFieldsValue;
        state.updatingCustomFields = false;
      })
      .addCase(updateUserDefaultCustomFields.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.updatingCustomFields = false;
      })
      // Handle removing a custom field by ID
      .addCase(removeCustomField.pending, (state) => {
        state.loading = true;
        state.customFieldsError = null;
      })
      .addCase(removeCustomField.fulfilled, (state, action) => {
        state.defaultCustomFields = state.defaultCustomFields.filter(
          (field) => field._id !== action.payload
        );
        state.loading = false;
      })
      .addCase(removeCustomField.rejected, (state, action) => {
        state.customFieldsError = action.payload;
        state.loading = false;
      });
  },
});

// Export actions
export const { clearItemDetails, updateItemCustomFields, updateItemCustomFieldValue } =
  inventorySlice.actions;

export default inventorySlice.reducer;

import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const AnalyzedListingsStatusChart = ({ statusCounts }) => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const chartData = {
    series: [statusCounts.passed, statusCounts.watching, statusCounts.pending],
  };

  const chartOptions = {
    chart: {
      type: 'pie',
      background: 'transparent',
    },
    labels: ['Passed', 'Watching', 'Pending'], // Move the labels here to chartOptions
    dataLabels: {
      enabled: true,
      style: {
        colors: [textColor, textColor, textColor],
      },
    },
    legend: {
      position: 'bottom',
      labels: {
        colors: textColor,
      },
    },
    theme: {
      mode: themeMode,
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(0)} listings`,
      },
    },
  };

  return (
    <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="mb-4">
        <div className="text-xl font-bold text-gray-800 dark:text-white">
          Listings Status Distribution
        </div>
        <div className="text-sm text-gray-600 dark:text-gray-400">
          Arbi-Found Listings: {statusCounts.total}
        </div>
      </div>

      <Chart
        options={chartOptions} // This should have the `labels` key for correct labeling
        series={chartData.series}
        type="pie"
        height={300}
      />
    </div>
  );
};

export default AnalyzedListingsStatusChart;

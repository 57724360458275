import React from 'react'

import Modal from './Modal'
import silverbackImage from '../../../images/silverback.png'
import monkeyImage from '../../../images/monkey.png'
import chimpImage from '../../../images/chimp.png'
import smallMonkeyImage from '../../../images/small_monkey.png'
import bananaImage from '../../../images/banana.png'

const LabelInfoModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-3xl font-bold mb-6 border-b-2 border-gray-200 pb-2'>
          Label Information
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
          <div className='flex items-center space-x-4'>
            <img src={silverbackImage} alt='Silverback' className='w-12 h-12' />
            <p className='text-lg'>
              <strong>Silverback Buy:</strong> High confidence buying
              opportunity based on algorithmic analysis.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <img src={chimpImage} alt='Strong Buy' className='w-12 h-12' />
            <p className='text-lg'>
              <strong>Strong Buy:</strong> Strong buying recommendation,
              slightly less confidence than Silverback Buy.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <img src={monkeyImage} alt='Mild Buy' className='w-12 h-12' />
            <p className='text-lg'>
              <strong>Mild Buy:</strong> Moderate buying recommendation. Worth
              considering but involves more risk.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <img src={smallMonkeyImage} alt='Buy' className='w-12 h-12' />
            <p className='text-lg'>
              <strong>Buy:</strong> Confident buying recommendation with low
              margins, represented by the smallest monkey.
            </p>
          </div>
          <div className='flex items-center space-x-4'>
            <img src={bananaImage} alt='Watch' className='w-12 h-12' />
            <p className='text-lg'>
              <strong>Watch:</strong> Indicates potential opportunities to keep
              an eye on.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LabelInfoModal

/**
 * autoFeedSlice
 *
 * This Redux slice manages the state for fetching, filtering, sorting, and managing opportunities for arbitrage.
 * It includes actions and reducers to handle fetching opportunities, updating their status, and applying filters and sorting.
 *
 * Main Features:
 * - **Fetch Opportunities**: Triggers a POST request to `/api/arbie/opportunities` to retrieve a list of arbitrage opportunities from the backend based on the user's ID.
 * - **Update Status**: Triggers a PATCH request to `/api/arbie/opportunities/status` to update the status of one or multiple opportunities.
 * - **Fetch Search Terms**: Triggers a GET request to `/api/arbie/opportunities/search-terms` to retrieve unique search terms defined by the user.
 * - **Fetch Market Research Data**: Triggers a GET request to `/api/arbie/market-research/{predictedMPN}` to fetch additional market research data based on a predicted MPN (Manufacturer Part Number).
 * - **Filters and Sorting**: Enables filtering and sorting of opportunities based on various criteria:
 *   - **Condition Filter**: Filters items based on their condition (e.g., new, used, all).
 *   - **Search Term Filter**: Filters items based on specific search terms defined by the user.
 *   - **Search Query**: Filters items based on a text query matching the item's title.
 *   - **Status Filter**: Filters items based on their status (e.g., pending, watching).
 *   - **Sort Option**: Sorts items based on criteria such as potential profit or price.
 * - **State Management**: Uses Redux to manage the state of opportunities, filters, and user information.
 *
 * State Structure:
 * - `opportunities`: List of arbitrage opportunities currently being displayed.
 * - `allItems`: Complete list of all fetched items.
 * - `searchTerms`: List of search terms defined by the user.
 * - `predictedMPNData`: Data related to the predicted Manufacturer Part Number.
 * - `loading`: Flag indicating if data is currently being fetched.
 * - `loadingMore`: Flag indicating if more items are being loaded.
 * - `loadingSimilarOpportunities`: Flag indicating if similar opportunities are being loaded.
 * - `error`: Error message, if any.
 * - `fetchFailed`: Flag indicating if a fetch operation failed.
 * - `page`: Current page number for paginated data fetching.
 * - `itemsPerPage`: Number of items to fetch per page.
 * - `hasMoreItems`: Flag indicating if there are more items to load.
 * - `isOpportunitiesFetched`: Flag indicating if opportunities have been fetched at least once.
 * - `filters`: Object containing the current filter settings.
 *
 * Lifecycle:
 * - Fetches opportunities and search terms when the user logs in or refreshes the authentication token.
 * - Applies filters and sorting to the fetched data to manage the displayed opportunities.
 *
 * Dependencies:
 * - Redux Toolkit for creating slices and handling asynchronous actions.
 * - `autoFeedService` for API calls related to fetching opportunities, updating status, and fetching search terms and market research data.
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  fetchAutoFeedOpportunities,
  updateOpportunityStatus,
  fetchUniqueSearchTerms,
  fetchMarketResearch,
} from './autoFeedService'

// Initial state for the autoFeed slice
export const initialState = {
  opportunities: [],
  allItems: [],
  searchTerms: [],
  filteredItems: [], // Added filteredItems to the state
  predictedMPNData: null,
  loading: false,
  loadingMore: false,
  error: null,
  fetchFailed: false,
  page: 1,
  itemsPerPage: 25,
  hasMoreItems: true,
  isOpportunitiesFetched: false,
  totalAvailableResults: 0,
  filters: {
    conditionFilter: 'all',
    searchTermFilter: '',
    searchQuery: '',
    statusFilter: 'pending',
    sortOption: '',
  },
}

// Async thunk for fetching search terms (GET request)
export const fetchSearchTerms = createAsyncThunk(
  'autoFeed/fetchSearchTerms',
  async (userId, thunkAPI) => {
    const token = thunkAPI.getState().auth.token
    const searchTerms = await fetchUniqueSearchTerms(token, userId)
    return searchTerms
  }
)

// Async thunk for fetching opportunities (POST request)
export const fetchOpportunities = createAsyncThunk(
  'autoFeed/fetchOpportunities',
  async (userId, thunkAPI) => {
    const token = thunkAPI.getState().auth.token
    return await fetchAutoFeedOpportunities(token, userId)
  }
)

// Async thunk for updating opportunity status (PATCH request)
export const updateStatus = createAsyncThunk(
  'autoFeed/updateStatus',
  async ({ itemIds, status }, thunkAPI) => {
    const token = thunkAPI.getState().auth.token
    await updateOpportunityStatus(token, itemIds, status)
    return { itemIds, status }
  }
)

// Async thunk for fetching market research data (GET request)
export const fetchMarketResearchData = createAsyncThunk(
  'autoFeed/fetchMarketResearchData',
  async (predictedMPN, thunkAPI) => {
    const token = thunkAPI.getState().auth.token
    try {
      return await fetchMarketResearch(token, predictedMPN)
    } catch (error) {
      console.error('Failed to fetch market research data:', error)
      return thunkAPI.rejectWithValue('Failed to fetch market research data')
    }
  }
)

// Helper function to apply filters and sorting to items
const applyFiltersAndSort = (items, filters) => {
  const {
    conditionFilter,
    searchTermFilter,
    searchQuery,
    statusFilter,
    sortOption,
  } = filters

  // Apply filters
  let filteredItems = items.filter((item) => {
    const conditionMatch =
      conditionFilter === 'all' ||
      item.generalized_condition.toLowerCase() === conditionFilter
    const searchTermMatch =
      !searchTermFilter ||
      item.searchTerm.toLowerCase().includes(searchTermFilter.toLowerCase())
    const searchQueryMatch =
      !searchQuery ||
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    const statusMatch = item.status.toLowerCase() === statusFilter
    return conditionMatch && searchTermMatch && searchQueryMatch && statusMatch
  })

  // Always sort by date first
  filteredItems.sort(
    (a, b) => new Date(b.dateArbieFound) - new Date(a.dateArbieFound)
  )

  // Apply additional sort options
  switch (sortOption) {
    case 'profit':
      filteredItems.sort((a, b) => {
        const profitA =
          a.summary_statistics.averageLifeTimePrice - a.latestPrice
        const profitB =
          b.summary_statistics.averageLifeTimePrice - b.latestPrice
        return profitB - profitA
      })
      break
    case 'price-high2low':
      filteredItems.sort((a, b) => b.latestPrice - a.latestPrice)
      break
    case 'price-low2high':
      filteredItems.sort((a, b) => a.latestPrice - b.latestPrice)
      break
    default:
      break
  }

  return filteredItems
}

// Create the autoFeed slice
const autoFeedSlice = createSlice({
  name: 'autoFeed',
  initialState,
  reducers: {
    toggleOpportunitySelection: (state, action) => {
      const itemId = action.payload
      if (state.selectedOpportunities.includes(itemId)) {
        state.selectedOpportunities = state.selectedOpportunities.filter(
          (id) => id !== itemId
        )
      } else {
        state.selectedOpportunities.push(itemId)
      }
    },
    clearSelectedOpportunities: (state) => {
      state.selectedOpportunities = []
    },
    clearMarketResearchData: (state) => {
      state.predictedMPNData = null
      state.fetchFailed = false
    },
    resetOpportunities: (state) => {
      state.opportunities = []
      state.allItems = []
      state.page = 1
      state.hasMoreItems = true
      state.isOpportunitiesFetched = false
      state.totalAvailableResults = 0 // Reset totalAvailableResults
    },
    resetOpportunitiesFetched: (state) => {
      state.isOpportunitiesFetched = false
    },
    fetchMoreOpportunitiesFromState: (state) => {
      const startIndex = state.page * state.itemsPerPage
      const endIndex = startIndex + state.itemsPerPage
      const filteredItems = applyFiltersAndSort(state.allItems, state.filters)
      const newOpportunities = filteredItems.slice(startIndex, endIndex)
      state.opportunities = [...state.opportunities, ...newOpportunities]
      state.page += 1
      if (newOpportunities.length < state.itemsPerPage) {
        state.hasMoreItems = false
      }
    },
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
      state.page = 1
      const filteredItems = applyFiltersAndSort(state.allItems, state.filters)
      state.filteredItems = filteredItems // Update filteredItems
      const newOpportunities = filteredItems.slice(0, state.itemsPerPage)
      state.opportunities = newOpportunities
      state.totalAvailableResults = filteredItems.length
      state.hasMoreItems = filteredItems.length > state.itemsPerPage
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOpportunities.pending, (state) => {
        state.loading = true
        state.allItems = [{ name: 'loading' }]
      })
      .addCase(fetchOpportunities.fulfilled, (state, action) => {
        state.allItems = action.payload.opportunities
        state.totalAvailableResults = state.allItems.length
        const filteredItems = applyFiltersAndSort(state.allItems, state.filters)
        state.filteredItems = filteredItems // Set filteredItems
        state.opportunities = filteredItems.slice(0, state.itemsPerPage)
        state.loading = false
        state.page = 1
        state.hasMoreItems = filteredItems.length > state.itemsPerPage
        state.isOpportunitiesFetched = true
      })
      .addCase(fetchOpportunities.rejected, (state, action) => {
        state.error = action.error.message
        state.loading = false
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        const { itemIds } = action.payload
        state.filteredItems = state.filteredItems.filter(
          (opportunity) => !itemIds.includes(opportunity.itemId)
        )
        state.opportunities = state.opportunities.filter(
          (opportunity) => !itemIds.includes(opportunity.itemId)
        )
      })
      .addCase(fetchSearchTerms.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSearchTerms.fulfilled, (state, action) => {
        state.searchTerms = action.payload
        state.loading = false
      })
      .addCase(fetchSearchTerms.rejected, (state, action) => {
        state.error = action.error.message
        state.loading = false
      })
      .addCase(fetchMarketResearchData.pending, (state) => {
        state.fetchFailed = false
      })
      .addCase(fetchMarketResearchData.fulfilled, (state, action) => {
        state.predictedMPNData = action.payload
      })
      .addCase(fetchMarketResearchData.rejected, (state, action) => {
        state.error = action.payload
        state.fetchFailed = true
      })
  },
})

export const {
  toggleOpportunitySelection,
  clearSelectedOpportunities,
  clearMarketResearchData,
  resetOpportunities,
  resetOpportunitiesFetched,
  fetchMoreOpportunitiesFromState,
  setFilter,
} = autoFeedSlice.actions

export default autoFeedSlice.reducer

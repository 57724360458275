// GeneralCustomFields.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDefaultCustomFields,
  updateUserDefaultCustomFields,
  removeCustomField,
} from '../../features/inventory/inventorySlice';
import {
  PencilAltIcon,
  TrashIcon,
  PlusIcon,
  InformationCircleIcon,
} from '@heroicons/react/solid';

const GeneralCustomFields = () => {
  const dispatch = useDispatch();
  const {
    defaultCustomFields,
    customFieldsError,
    loading,
  } = useSelector((state) => state.inventory);

  const [fields, setFields] = useState([]);
  const [editedFields, setEditedFields] = useState({});
  const [newField, setNewField] = useState({
    fieldName: '',
    isRequired: false,
    useInTemplates: false,
    platform: 'General',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchDefaultCustomFields());
  }, [dispatch]);

  useEffect(() => {
    if (defaultCustomFields) {
      const generalFields = defaultCustomFields.filter(
        (field) => field.platform === 'General'
      );
      setFields(generalFields);
    }
  }, [defaultCustomFields]);

  const handleAddField = () => {
    if (newField.fieldName.trim()) {
      // Check for duplicates
      if (fields.some((field) => field.fieldName === newField.fieldName)) {
        setErrors({ addField: 'This field is already added.' });
        return;
      }
      const updatedFields = [...fields, { ...newField }];
      setFields(updatedFields);
      setNewField({
        fieldName: '',
        isRequired: false,
        useInTemplates: false,
        platform: 'General',
      });
      setErrors({});

      // Dispatch update action
      dispatch(
        updateUserDefaultCustomFields({
          defaultCustomFields: [
            ...defaultCustomFields.filter(
              (field) => field.platform !== 'General'
            ),
            ...updatedFields,
          ],
        })
      );
    } else {
      setErrors({ addField: 'Field name is required.' });
    }
  };

  const handleDeleteField = (fieldId) => {
    dispatch(removeCustomField(fieldId))
      .unwrap()
      .then(() => {
        const updatedFields = fields.filter((field) => field._id !== fieldId);
        setFields(updatedFields);
        setErrors({});
      })
      .catch((error) => {
        console.error('Failed to delete custom field:', error);
      });
  };

  const handleEditField = (fieldId) => {
    setEditedFields({
      ...editedFields,
      [fieldId]: true,
    });
    setErrors({});
  };

  const handleSaveField = (fieldId) => {
    // Check for duplicates
    const field = fields.find((field) => field._id === fieldId);
    const duplicate = fields.some(
      (f) => f.fieldName === field.fieldName && f._id !== fieldId
    );
    if (duplicate) {
      setErrors({ [fieldId]: 'This field is already added.' });
      return;
    }

    setEditedFields({
      ...editedFields,
      [fieldId]: false,
    });
    setErrors({});

    // Dispatch update action
    dispatch(
      updateUserDefaultCustomFields({
        defaultCustomFields: [
          ...defaultCustomFields.filter(
            (field) => field.platform !== 'General'
          ),
          ...fields,
        ],
      })
    );
  };

  const handleFieldChange = (fieldId, key, value) => {
    const updatedFields = fields.map((field) =>
      field._id === fieldId ? { ...field, [key]: value } : field
    );
    setFields(updatedFields);
  };

  const handleNewFieldChange = (key, value) => {
    setNewField({ ...newField, [key]: value });
  };

  if (loading) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400">
        Loading custom fields...
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-2 space-y-6">
      {/* Header */}
      <div className="flex items-center space-x-2">
        <InformationCircleIcon className="w-6 h-6 text-indigo-500" />
        <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          General Custom Fields
        </h3>
      </div>

      <p className="text-sm text-gray-600 dark:text-gray-400">
        Manage custom fields that apply to all inventory items. Use these to
        collect additional information not covered by default fields.
      </p>

      {/* Input for adding new field */}
      {!Object.values(editedFields).includes(true) && (
        <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md">
          <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
            <input
              type="text"
              value={newField.fieldName}
              onChange={(e) =>
                handleNewFieldChange('fieldName', e.target.value)
              }
              placeholder="Field Name"
              className="flex-grow px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-md text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <div className="flex items-center space-x-4 mt-2 sm:mt-0">
              <label className="flex items-center text-gray-700 dark:text-gray-200">
                <input
                  type="checkbox"
                  checked={newField.isRequired}
                  onChange={(e) =>
                    handleNewFieldChange('isRequired', e.target.checked)
                  }
                  className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-md">Required</span>
              </label>
              <label className="flex items-center text-gray-700 dark:text-gray-200">
                <input
                  type="checkbox"
                  checked={newField.useInTemplates}
                  onChange={(e) =>
                    handleNewFieldChange('useInTemplates', e.target.checked)
                  }
                  className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <span className="ml-2 text-md">Use in Templates</span>
              </label>
            </div>
            <button
              onClick={handleAddField}
              className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-md mt-2 sm:mt-0"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              Add Field
            </button>
          </div>
          {errors.addField && (
            <p className="text-red-500 text-sm mt-2">{errors.addField}</p>
          )}
        </div>
      )}

      {/* List of existing fields */}
      {fields.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {fields.map((field) => {
            const fieldId = field._id || field.fieldName;
            return (
              <div
                key={fieldId}
                className="bg-gray-50 dark:bg-gray-700 p-4 rounded-md shadow-sm flex flex-col justify-between"
              >
                {editedFields[fieldId] ? (
                  <>
                    <div className="space-y-2">
                      {/* Field Name Input */}
                      <input
                        type="text"
                        value={field.fieldName}
                        onChange={(e) =>
                          handleFieldChange(
                            fieldId,
                            'fieldName',
                            e.target.value
                          )
                        }
                        className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-md text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Field Name"
                      />
                      <div className="flex items-center space-x-4">
                        <label className="flex items-center text-gray-700 dark:text-gray-200">
                          <input
                            type="checkbox"
                            checked={field.isRequired}
                            onChange={(e) =>
                              handleFieldChange(
                                fieldId,
                                'isRequired',
                                e.target.checked
                              )
                            }
                            className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-md">Required</span>
                        </label>
                        <label className="flex items-center text-gray-700 dark:text-gray-200">
                          <input
                            type="checkbox"
                            checked={field.useInTemplates}
                            onChange={(e) =>
                              handleFieldChange(
                                fieldId,
                                'useInTemplates',
                                e.target.checked
                              )
                            }
                            className="h-5 w-5 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                          />
                          <span className="ml-2 text-md">Use in Templates</span>
                        </label>
                      </div>
                      {errors[fieldId] && (
                        <p className="text-red-500 text-sm mt-2">
                          {errors[fieldId]}
                        </p>
                      )}
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        onClick={() => handleSaveField(fieldId)}
                        className="px-3 py-1 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md"
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="space-y-2">
                      <h4 className="text-lg font-medium text-gray-800 dark:text-gray-100">
                        {field.fieldName}
                      </h4>
                      <div className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300">
                        <span
                          className={`inline-block px-2 py-1 rounded-full ${field.isRequired
                              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                            }`}
                        >
                          {field.isRequired ? 'Required' : 'Optional'}
                        </span>
                        <span
                          className={`inline-block px-2 py-1 rounded-full ${field.useInTemplates
                              ? 'bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100'
                            }`}
                        >
                          {field.useInTemplates
                            ? 'Used in Templates'
                            : 'Not Used in Templates'}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        onClick={() => handleEditField(fieldId)}
                        className="text-gray-500 hover:text-indigo-500"
                      >
                        <PencilAltIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteField(fieldId)}
                        className="text-red-500 hover:text-red-600"
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-gray-500 dark:text-gray-400">
          No custom fields added yet.
        </div>
      )}

      {customFieldsError && (
        <div className="text-red-500 mt-2">{customFieldsError}</div>
      )}
    </div>
  );
};

export default GeneralCustomFields;

/**
 * autoFeedService
 *
 * This module provides functions to interact with the backend API for fetching and managing arbitrage opportunities.
 * It includes functions to fetch opportunities, update their status, fetch search terms, and retrieve market research data.
 *
 * Functions:
 * - **fetchAutoFeedOpportunities**: Sends a POST request to `/api/arbie/opportunities` to fetch opportunities based on the user's ID.
 * - **fetchAllItems**: Sends a POST request to `/api/arbie/allopportunities` to fetch all opportunities for the user.
 * - **updateOpportunityStatus**: Sends a PATCH request to `/api/arbie/opportunities/status` to update the status of specified opportunities.
 * - **fetchUniqueSearchTerms**: Sends a GET request to `/api/arbie/opportunities/search-terms` to fetch the user's search terms.
 * - **fetchMarketResearch**: Sends a GET request to `/api/arbie/market-research/{predictedMPN}` to fetch market research data based on a predicted MPN.
 */

import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/arbie`

// Fetch opportunities based on the user's ID (POST request)
export const fetchAutoFeedOpportunities = async (token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const body = {
    userId,
  }

  const { data } = await axios.post(`${baseUrl}/opportunities`, body, config)
  return data
}

// Fetch all opportunities for the user (POST request)
export const fetchAllItems = async (token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const body = {
    userId,
  }
  const { data } = await axios.post(`${baseUrl}/allopportunities`, body, config)
  return data
}

// Update the status of specified opportunities (PATCH request)
export const updateOpportunityStatus = async (token, itemIds, status) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const body = {
    itemIds,
    status,
  }
  const { data } = await axios.post(
    `${baseUrl}/opportunities/status`,
    body,
    config
  )
  return data
}

// Fetch the user's search terms (GET request)
export const fetchUniqueSearchTerms = async (token, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const { data } = await axios.get(
    `${baseUrl}/opportunities/search-terms?userId=${userId}`,
    config
  )
  return data
}

// Fetch market research data based on a predicted MPN (GET request)
export const fetchMarketResearch = async (token, predictedMPN) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const { data } = await axios.get(
    `${baseUrl}/market-research/${predictedMPN}`,
    config
  )
  return data
}

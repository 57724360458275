import React from 'react'
import {
  ArrowDownIcon,
  FireIcon,
  ExclamationIcon,
  ShoppingCartIcon,
  TruckIcon,
  BadgeCheckIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  UserIcon,
} from '@heroicons/react/solid'

import Modal from './Modal'

const AttributesInfoModal = ({ isOpen, onClose }) => {
  const iconSize = 'w-12 h-12' // Define a uniform size for all icons

  const attributes = [
    {
      icon: <ArrowDownIcon className={`${iconSize} text-green-500`} />,
      title: 'Min Price',
      description: 'Minimum price for this MPN.',
    },
    {
      icon: <FireIcon className={`${iconSize} text-red-500`} />,
      title: 'Fast Moving',
      description: 'Sold more than once in the past 30 days.',
    },
    {
      icon: <ExclamationIcon className={`${iconSize} text-yellow-500`} />,
      title: 'Saturated Listing',
      description: 'Multiple quantities on the listing.',
    },
    {
      icon: <ShoppingCartIcon className={`${iconSize} text-red-500`} />,
      title: 'Sold on Listing',
      description: 'Indicates a popular item.',
    },
    {
      icon: <TruckIcon className={`${iconSize} text-blue-500`} />,
      title: 'Free Shipping',
      description: 'Seller offers free shipping.',
    },
    {
      icon: <BadgeCheckIcon className={`${iconSize} text-purple-500`} />,
      title: 'Top Rated',
      description: 'Seller offers a top-rated buyer experience.',
    },
    {
      icon: <CurrencyDollarIcon className={`${iconSize} text-yellow-500`} />,
      title: 'Best Offer',
      description: 'Best offer option is available.',
    },
    {
      icon: <GlobeAltIcon className={`${iconSize} text-blue-500`} />,
      title: 'Auction',
      description: 'Bids can be placed.',
    },
    {
      icon: <UserIcon className={`${iconSize} text-gray-500`} />,
      title: 'Low Reviews',
      description: 'Seller has low reviews, indicating inexperience.',
    },
  ]

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-3xl font-bold mb-6 border-b-2 border-gray-200 pb-2'>
          Attributes Information
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-6'>
          {attributes.map((attribute, index) => (
            <div key={index} className='flex items-center space-x-4'>
              {attribute.icon}
              <div>
                <p className='text-lg'>
                  <strong>{attribute.title}:</strong> {attribute.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default AttributesInfoModal

// components/subscriptions/PaymentHeader.jsx
import React from 'react'
import { Link } from 'react-router-dom'
import { SunIcon, MoonIcon } from '@heroicons/react/outline'

import arbiEngineLogoDark from '../../images/arbienginelogo.png'
import arbiEngineLogoLight from '../../images/arbieBlackNew.png'

const PaymentHeader = ({ darkMode, toggleDarkMode }) => {
  return (
    <header className='bg-white dark:bg-gray-800 shadow-md p-4'>
      <div className='flex justify-between items-center container mx-auto'>
        <Link to='/'>
          <img
            src={darkMode ? arbiEngineLogoDark : arbiEngineLogoLight}
            className='h-8'
            alt='Arbi Engine Logo'
          />
        </Link>
        <button
          onClick={toggleDarkMode}
          className='p-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full'
        >
          {darkMode ? (
            <SunIcon className='h-6 w-6' />
          ) : (
            <MoonIcon className='h-6 w-6' />
          )}
        </button>
      </div>
    </header>
  )
}

export default PaymentHeader

// AutoFillPreferences.jsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDefaultCustomFields,
  updateUserDefaultCustomFields,
} from '../../features/inventory/inventorySlice';
import { AdjustmentsIcon, InformationCircleIcon } from '@heroicons/react/solid';

const AutoFillPreferences = () => {
  const dispatch = useDispatch();

  const {
    autoFillMissingFields,
    autoFillMissingFieldsValue,
    updatingCustomFields,
    customFieldsError,
    loading,
  } = useSelector((state) => state.inventory);

  const [autoFillEnabled, setAutoFillEnabled] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    // Fetch preferences if they are undefined
    if (
      autoFillMissingFields === undefined ||
      autoFillMissingFieldsValue === undefined
    ) {
      dispatch(fetchDefaultCustomFields());
    } else {
      setAutoFillEnabled(autoFillMissingFields);
      setDefaultValue(autoFillMissingFieldsValue);
    }
  }, [
    autoFillMissingFields,
    autoFillMissingFieldsValue,
    dispatch,
    loading,
  ]);

  useEffect(() => {
    // Detect changes to enable the "Save Changes" button
    const hasChanges =
      autoFillEnabled !== autoFillMissingFields ||
      defaultValue !== autoFillMissingFieldsValue;
    setIsModified(hasChanges);
  }, [
    autoFillEnabled,
    defaultValue,
    autoFillMissingFields,
    autoFillMissingFieldsValue,
  ]);

  const handleToggleAutoFill = () => {
    setAutoFillEnabled(!autoFillEnabled);
  };

  const handleSavePreferences = () => {
    const updatedSettings = {
      autoFillMissingFields: autoFillEnabled,
      autoFillMissingFieldsValue: defaultValue || 'N/A',
    };
    dispatch(updateUserDefaultCustomFields(updatedSettings));
    setIsModified(false);
  };

  if (loading) {
    return (
      <div className="text-center text-gray-500 dark:text-gray-400">
        Loading auto-fill preferences...
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-3 space-y-4">
      {/* Header */}
      <div className="flex items-center space-x-2">
        <AdjustmentsIcon className="w-6 h-6 text-indigo-500" />
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
          Auto-Fill Preferences
        </h2>
        <InformationCircleIcon className="w-5 h-5 text-gray-400" />
      </div>

      <p className="text-sm text-gray-600 dark:text-gray-400">
        Configure your auto-fill settings for missing required fields. Enabling
        auto-fill will automatically populate missing fields with a default value
        you specify.
      </p>

      {/* Auto-fill toggle */}
      <div className="flex items-center space-x-2">
        <label className="text-gray-700 dark:text-gray-200 font-medium">
          Enable Auto-Fill:
        </label>
        <button
          onClick={handleToggleAutoFill}
          className={`relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none ${autoFillEnabled ? 'bg-indigo-600' : 'bg-gray-300 dark:bg-gray-600'
            }`}
        >
          <span
            className={`transform transition ease-in-out duration-200 ${autoFillEnabled ? 'translate-x-6' : 'translate-x-1'
              } inline-block w-4 h-4 rounded-full bg-white`}
          />
        </button>
      </div>

      {/* Default value input */}
      <div className="flex items-center space-x-2">
        <label
          htmlFor="defaultValue"
          className="font-medium text-gray-700 dark:text-gray-200"
        >
          Default Value:
        </label>
        <input
          id="defaultValue"
          type="text"
          value={defaultValue}
          onChange={(e) => setDefaultValue(e.target.value)}
          placeholder="E.g., N/A"
          className="w-full max-w-xs px-4 py-2 border border-gray-300 dark:border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500 text-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-100 placeholder-gray-400"
        />
      </div>

      {/* Save Changes Button */}
      {isModified && (
        <button
          onClick={handleSavePreferences}
          className={`px-4 py-2 text-md font-medium text-white rounded transition-colors ${updatingCustomFields
              ? 'bg-indigo-300 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700'
            }`}
          disabled={updatingCustomFields}
        >
          {updatingCustomFields ? 'Saving...' : 'Save Changes'}
        </button>
      )}

      {/* Error handling */}
      {customFieldsError && (
        <div className="text-red-500 mt-2">{customFieldsError}</div>
      )}
    </div>
  );
};

export default AutoFillPreferences;

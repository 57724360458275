// ModalComponents/ProductInfoCard.js
import React from 'react';
import {
  SearchIcon,
  StatusOnlineIcon,
  FireIcon,
  ExclamationIcon,
  TruckIcon,
  BadgeCheckIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  ShoppingCartIcon,
  ArrowDownIcon,
  UserIcon,
} from '@heroicons/react/solid';
import Tooltip from '@mui/material/Tooltip';
import silverbackImage from '../../../../images/silverback.png';
import monkeyImage from '../../../../images/monkey.png';
import smallMonkeyImage from '../../../../images/small_monkey.png';
import chimpImage from '../../../../images/chimp.png';
import bananaImage from '../../../../images/banana.png';

const ProductInfoCard = ({
  imageUrl,
  title,
  latestPrice,
  averageLifeTimePrice,
  potentialProfit,
  condition,
  Label,
  attributes,
  latestShippingCost,
  opportunity,
  feedbackScore,
  feedbackPercentage,
  sellerUsername,
  formatPrice,
}) => {
  return (
    <div className='bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 p-4 rounded-lg shadow-lg '>
      <div className='flex items-center'>
        <img
          className='rounded-lg w-24 h-24 md:w-32 md:h-32 object-cover'
          src={imageUrl}
          alt='Product'
        />
        <div className='ml-4 flex flex-col justify-center'>
          <div className='text-gray-600 dark:text-gray-400 text-center md:text-left'>
            <p className='font-medium text-gray-900 dark:text-white mb-1'>
              {title || 'N/A'}
            </p>
            <p className='font-medium text-gray-900 dark:text-white mb-1'>
              <CurrencyDollarIcon className='w-4 h-4 text-indigo-600 inline-block mr-1' />
              Buy It Now: ${formatPrice(latestPrice)}
            </p>
            <p className='font-medium text-gray-900 dark:text-white mb-1'>
              <CalculatorIcon className='w-4 h-4 text-green-500 inline-block mr-1' />
              Average Price: ${formatPrice(averageLifeTimePrice)}
            </p>
            <p className='font-medium text-gray-900 dark:text-white mb-1'>
              <FireIcon className='w-4 h-4 text-red-500 inline-block mr-1' />
              Potential Profit: ${potentialProfit(averageLifeTimePrice, latestPrice)}
            </p>
            <p className='font-medium text-gray-900 dark:text-white mb-1'>
              <StatusOnlineIcon className='w-4 h-4 text-yellow-500 inline-block mr-1' />
              Condition: {condition}
            </p>
          </div>
        </div>
        <div className='text-gray-600 dark:text-gray-400 mt-4 flex flex-col items-center justify-center flex-wrap max-h-96 mr-10'>
          <Tooltip title={Label}>
            <img
              src={
                Label === 'Silverback Buy'
                  ? silverbackImage
                  : Label === 'Strong Buy'
                  ? chimpImage
                  : Label === 'Mild Buy'
                  ? monkeyImage
                  : Label === 'Buy'
                  ? smallMonkeyImage
                  : bananaImage
              }
              alt={Label}
              className='w-12 h-12 inline-block ml-4 mr-4 cursor-pointer mb-2'
            />
          </Tooltip>
          {attributes && attributes.minPrice && (
            <Tooltip title='Minimum Price'>
              <ArrowDownIcon className='w-6 h-6 text-green-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {attributes && attributes.sold_count_30days && (
            <Tooltip title='Fast Moving (30 days)'>
              <FireIcon className='w-6 h-6 text-red-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {attributes && attributes.saturated_listing && (
            <Tooltip title='Saturated Listing'>
              <ExclamationIcon className='w-6 h-6 text-yellow-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {attributes && attributes.sold_listing && (
            <Tooltip title='Sold Listing'>
              <ShoppingCartIcon className='w-6 h-6 text-red-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {latestShippingCost == 0 && (
            <Tooltip title='Free Shipping'>
              <TruckIcon className='w-6 h-6 text-indigo-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {opportunity.topRatedBuyingExperience && (
            <Tooltip title='Top Rated Buying Experience'>
              <BadgeCheckIcon className='w-6 h-6 text-purple-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {feedbackScore <= 50 && (
            <Tooltip title='Inexperienced Seller (Low Reviews)'>
              <UserIcon className='w-6 h-6 text-gray-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
          {opportunity.buyingOptions && opportunity.buyingOptions.includes('BEST_OFFER') && (
            <Tooltip title='Best Offer'>
              <CurrencyDollarIcon className='w-6 h-6 text-green-500 cursor-pointer ml-2' />
            </Tooltip>
          )}
        </div>
      </div>
      <div className='text-gray-600 dark:text-gray-400 mt-4'>
        <div className='grid grid-cols-2 gap-2'>
          <Tooltip title='Search Term'>
            <p className='text-sm flex items-center'>
              <SearchIcon className='w-4 h-4 text-gray-600 inline-block mr-1' />
              {opportunity?.searchTerm || 'N/A'}
            </p>
          </Tooltip>
          <Tooltip title='Created Date'>
            <p className='text-sm flex items-center'>
              <CalendarIcon className='w-4 h-4 text-gray-600 inline-block mr-1' />
              {new Date(opportunity.itemCreationDate).toLocaleString()}
            </p>
          </Tooltip>
          <Tooltip title='Seller Information'>
            <p className='text-sm flex items-center'>
              <BadgeCheckIcon className='w-4 h-4 text-purple-500 inline-block mr-1' />
              {sellerUsername} ({feedbackScore} feedback, {feedbackPercentage}%)
            </p>
          </Tooltip>
          <Tooltip title='Shipping Cost'>
            <p className='text-sm flex items-center'>
              <TruckIcon className='w-4 h-4 text-indigo-500 inline-block mr-1' />
              ${formatPrice(latestShippingCost)}
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCard;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import MarketResearchDataCard from './MarketResearchDataCard';
import NavigationActionBar from './NavigationActionBar';
import PriceDistributionChart from './ModalComponents/PriceDistributionChart';
import PriceRangeChart from './ModalComponents/PriceRangeChart';
import SoldItemsChart from './ModalComponents/SoldItemsChart';
import ModalHeader from './ModalComponents/ModalHeader';  // Import the new component
import ProductInfoCard from './ModalComponents/ProductInfoCard';

const DesktopMarketResearchModal = ({
  onClose,
  opportunity,
  handleStatusUpdateAndNext,
  navigateToNext,
  navigateToPrevious,
}) => {
  const { predictedMPNData } = useSelector((state) => state.autoFeed);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  useEffect(() => {
    if (opportunity) {
      setFilteredItems(opportunity.dataPoints || []);
    }
  }, [opportunity]);

  useEffect(() => {
    if (predictedMPNData) {
      setFilteredItems(predictedMPNData.items);
    }
  }, [predictedMPNData]);

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme);
    };

    window.addEventListener('themeChange', handleThemeChange);

    return () => {
      window.removeEventListener('themeChange', handleThemeChange);
    };
  }, []);

  const {
    title = 'Unknown Item',
    seller = {},
    condition = 'Unknown',
    image = {},
    itemWebUrl = '#',
    Label,
    latestPrice = 0,
    latestShippingCost,
    attributes,
    summary_statistics: summaryStatistics = {},
    generalized_condition = 'Unknown',
  } = opportunity || {};

  const { averageLifeTimePrice = 0 } = summaryStatistics;

  const imageUrl = image?.imageUrl || 'https://via.placeholder.com/150';
  const sellerUsername = seller?.username || 'Unknown Seller';
  const feedbackScore = seller?.feedbackScore ?? 'N/A';
  const feedbackPercentage = seller?.feedbackPercentage?.$numberDecimal || 'N/A';
  const soldItemData = predictedMPNData ? predictedMPNData.soldItemData : [];
  const priceRangeData = predictedMPNData ? predictedMPNData.priceRangeData : [];
  const stats = predictedMPNData ? predictedMPNData.statistics : [];

  const handleEbaySearch = () => {
    const conditionMap = {
      new: 3,
      used: 4,
    };
    const conditionId = conditionMap[generalized_condition.toLowerCase()] || 0;

    const sortOption = 15;

    const searchUrl = `https://www.ebay.com/sch/i.html?_nkw=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}&_sacat=0&LH_Sold=1&LH_Complete=1&LH_ItemCondition=${conditionId}&_sop=${sortOption}`;
    window.open(searchUrl, '_blank');
  };

  const handleAmazonSearch = () => {
    const searchUrl = `https://www.amazon.com/s?k=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}`;
    window.open(searchUrl, '_blank');
  };

  const handleTerapeakSearch = () => {
    const conditionMap = {
      new: 1000,
      used: 3000,
    };
    const conditionId = conditionMap[generalized_condition.toLowerCase()] || 0;

    const dayRange = 180;
    const endDate = Date.now();
    const startDate = endDate - dayRange * 24 * 60 * 60 * 1000;

    const searchUrl = `https://www.ebay.com/sh/research?marketplace=EBAY-US&keywords=${encodeURIComponent(opportunity?.searchTerm || '')} ${encodeURIComponent(opportunity?.predictedMPN || '')}&dayRange=${dayRange}&endDate=${endDate}&startDate=${startDate}&categoryId=0&conditionId=${conditionId}&offset=0&limit=50&tabName=SOLD&tz=America%2FChicago`;

    window.open(searchUrl, '_blank');
  };

  const filteredAndSearchedItems = filteredItems.filter((item) => {
    return (
      (selectedCondition === 'All' || item.condition === selectedCondition) &&
      (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.latestPrice.toString().includes(searchTerm))
    );
  });

  const potentialProfit = (averagePrice, currentPrice) => {
    return (averagePrice - currentPrice).toFixed(2);
  };

  const formatPrice = (price) => {
    return price !== null && price !== undefined ? price.toFixed(2) : 'N/A';
  };

  const handleStatusUpdateAndNavigate = (itemIds, status) => {
    handleStatusUpdateAndNext(itemIds, status, true);
  };

  return (
    <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50'>
      <div
        className='relative w-full max-w-7xl bg-white dark:bg-gray-800 rounded-lg shadow-lg mx-auto flex flex-col max-h-full overflow-hidden border'
        style={{ height: '80%' }}
      >
        <ModalHeader 
          handleAmazonSearch={handleAmazonSearch}
          handleEbaySearch={handleEbaySearch}
          handleTerapeakSearch={handleTerapeakSearch}
          itemWebUrl={itemWebUrl}
          onClose={onClose}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full overflow-auto p-4">
          {/* Top Row: Product Info Card on the left and Available Listings on the right */}
          <div className="flex flex-col space-y-4">
            <ProductInfoCard
              imageUrl={imageUrl}
              title={title}
              latestPrice={latestPrice}
              averageLifeTimePrice={averageLifeTimePrice}
              potentialProfit={potentialProfit}
              condition={condition}
              Label={Label}
              attributes={attributes}
              latestShippingCost={latestShippingCost}
              opportunity={opportunity}
              feedbackScore={feedbackScore}
              feedbackPercentage={feedbackPercentage}
              sellerUsername={sellerUsername}
              formatPrice={formatPrice}
            />
          </div>

          <div className="bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 p-4 shadow-sm rounded-lg flex-grow">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Available Listings
            </h2>
            <div className="overflow-y-auto" style={{ maxHeight: '300px' }}>
              {filteredAndSearchedItems.map((item, index) => (
                <MarketResearchDataCard key={index} item={item} />
              ))}
            </div>
          </div>

          {/* Middle Row: Two Charts side by side */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 col-span-2">
            <PriceDistributionChart stats={stats} themeMode={themeMode} />
            <PriceRangeChart priceRangeData={priceRangeData} themeMode={themeMode} />
          </div>

          {/* Bottom Row: Full-width chart */}
          <div className="col-span-2">
            <SoldItemsChart soldItemData={soldItemData} themeMode={themeMode} />
          </div>
        </div>

        <div className='bg-white dark:bg-gray-800'>
          <NavigationActionBar
            onWatch={() => handleStatusUpdateAndNavigate([opportunity.itemId], 'watched')}
            onPass={() => handleStatusUpdateAndNavigate([opportunity.itemId], 'passed')}
            onNext={navigateToNext}
            onPrev={navigateToPrevious}
          />
        </div>
      </div>
    </div>
  );
};

export default DesktopMarketResearchModal;

import React from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
  EyeOffIcon,
} from '@heroicons/react/solid'

const NavigationActionBar = ({
  onWatch,
  onPass,
  onNext,
  onPrev,
  isLast,
  isFirst,
}) => {
  return (
    <div className='flex justify-between items-center p-4 bg-gradient-to-r bg-white dark:from-gray-800 dark:to-gray-900 border'>
      <button
        onClick={onPrev}
        disabled={isFirst}
        className='disabled:opacity-50'
      >
        <ChevronLeftIcon className='h-5 w-5 text-gray-600 dark:text-white' />
      </button>
      <div>
        <button
          onClick={onWatch}
          className='px-4 py-2 mx-2 rounded-lg text-white bg-indigo-500 hover:bg-indigo-700'
        >
          <EyeIcon className='h-5 w-5 inline mr-2' /> Mark as Watched
        </button>
        <button
          onClick={onPass}
          className='px-4 py-2 mx-2 rounded-lg text-white bg-red-500 hover:bg-red-700'
        >
          <EyeOffIcon className='h-5 w-5 inline mr-2' /> Mark as Passed
        </button>
      </div>
      <button
        onClick={onNext}
        disabled={isLast}
        className='disabled:opacity-50'
      >
        <ChevronRightIcon className='h-5 w-5 text-gray-600 dark:text-white' />
      </button>
    </div>
  )
}

export default NavigationActionBar

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CallToAction = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    if (user) {
      navigate('/arbie');
    } else {
      navigate('/register-closed');
    }
  };

  return (
    <div className="relative bg-gradient-to-r from-purple-500 via-indigo-600 to-blue-500 p-1 rounded-lg shadow-lg mt-40 mb-40 mx-4 sm:mx-6 md:mx-8 lg:mx-auto max-w-4xl">
      <div className="bg-white dark:bg-gray-800 p-6 lg:p-10 rounded-lg text-center">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
          Become a Beta Tester
        </h2>
        <p className="mb-6 text-gray-600 dark:text-gray-300">
          We are currently accepting applications for beta testers. Join us to be one of the first to experience our innovative platform and provide valuable feedback.
        </p>
        <button
          onClick={handleGetStartedClick}
          className="rounded-md bg-gradient-to-r from-indigo-500 to-indigo-800 px-6 py-3 text-lg font-semibold text-white shadow-lg hover:bg-indigo-500 transition-colors duration-300 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:scale-105"
          aria-label={user ? "Go to Dashboard" : "Join the Waitlist"}
        >
          {user ? 'Go to Dashboard' : 'Join the Waitlist'}
        </button>
      </div>
    </div>
  );
};

export default CallToAction;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Placeholder for fetching categories
export const fetchCategories = createAsyncThunk('organization/fetchCategories', async () => {
  // Simulate an API call to fetch categories
  return [
    { id: 1, name: 'Electronics' },
    { id: 2, name: 'Home Appliances' },
    { id: 3, name: 'Furniture' },
    { id: 4, name: 'Books' },
  ];
});

// Placeholder for fetching labels
export const fetchLabels = createAsyncThunk('organization/fetchLabels', async () => {
  // Simulate an API call to fetch labels
  return [
    { id: 1, name: 'New Arrival' },
    { id: 2, name: 'On Sale' },
    { id: 3, name: 'Best Seller' },
    { id: 4, name: 'Clearance' },
  ];
});

const organizationSlice = createSlice({
  name: 'organization',
  initialState: {
    categories: [],
    labels: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchLabels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.labels = action.payload;
      })
      .addCase(fetchLabels.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default organizationSlice.reducer;

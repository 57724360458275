import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PurchasesHeader from '../../components/Dashboard/Purchases/PurchasesHeader';
import PurchasesCard from '../../components/Dashboard/Purchases/PurchasesCard';
import PurchasesModal from '../../components/Dashboard/Purchases/PurchasesModal';
import ConvertToInventoryModal from '../../components/Dashboard/Purchases/ConvertToInventoryModal';
import Spinner from '../../components/Spinner';
import {
  fetchPurchases,
  fetchPurchaseById,
  updatePurchase,
  createPurchase,
  createOrUpdateInventoryDrafts,
  fetchAllPurchases,
  setFilter,
} from '../../features/purchases/purchasesSlice';

const PurchasesPage = () => {
  const dispatch = useDispatch();
  const { filteredItems, isLoading, isError, message, hasMoreItems } = useSelector((state) => state.purchases);
  const { connections } = useSelector((state) => state.connection);
  const [selectedConnection, setSelectedConnection] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showConvertToInventoryModal, setShowConvertToInventoryModal] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [isNewPurchase, setIsNewPurchase] = useState(false);
  const observer = useRef();

  // Apply filters
  useEffect(() => {
    dispatch(setFilter({
      connectionFilter: selectedConnection,
      searchQuery,
      dateRange: { start: startDate, end: endDate }
    }));
  }, [dispatch, selectedConnection, searchQuery, startDate, endDate]);

  useEffect(() => {
    dispatch(fetchPurchases());
  }, [dispatch]);

  const loadMoreItems = useCallback(() => {
    if (hasMoreItems) {
      dispatch(fetchPurchases());
    }
  }, [dispatch, hasMoreItems]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    }, options);

    const lastItem = document.querySelector('.last-item');
    if (observer.current && lastItem) {
      observer.current.observe(lastItem);
    }
  }, [filteredItems.length, loadMoreItems]);

  const handleConnectionChange = (value) => setSelectedConnection(value);
  const handleSearchChange = (query) => setSearchQuery(query);
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleRefreshAll = () => {
    dispatch(fetchAllPurchases());
  };

  const handleViewDetailsClick = (purchaseId) => {
    dispatch(fetchPurchaseById(purchaseId)).then((action) => {
      if (action.type.endsWith('fulfilled')) {
        setSelectedPurchase(action.payload);
        setIsNewPurchase(false);
        setShowPurchaseModal(true);
      }
    });
  };

  const handleConvertToInventoryClick = (purchaseId) => {
    dispatch(fetchPurchaseById(purchaseId)).then((action) => {
      if (action.type.endsWith('fulfilled')) {
        setSelectedPurchase(action.payload);
        setShowConvertToInventoryModal(true);
      }
    });
  };

  const handlePurchaseModalClose = () => {
    setShowPurchaseModal(false);
    setSelectedPurchase(null);
    setIsNewPurchase(false);
  };

  const handleConvertToInventoryModalClose = () => {
    setShowConvertToInventoryModal(false);
    setSelectedPurchase(null);
  };

  const handleSaveChanges = (updatedPurchase) => {
    if (isNewPurchase) {
      dispatch(createPurchase(updatedPurchase)).then(() => {
        setShowPurchaseModal(false);
        setSelectedPurchase(null);
      });
    } else {
      dispatch(updatePurchase(updatedPurchase)).then(() => {
        setShowPurchaseModal(false);
        setSelectedPurchase(null);
      });
    }
  };

  const handleAddPurchase = () => {
    setSelectedPurchase(null);
    setIsNewPurchase(true);
    setShowPurchaseModal(true);
  };

  const handleConvertToInventory = (purchaseId, lineItems) => {
    dispatch(createOrUpdateInventoryDrafts({ purchaseId, lineItems })).then(() => {
      setShowConvertToInventoryModal(false);
      setSelectedPurchase(null);
    });
  };

  return (
    <div className="min-h-screen flex flex-col p-4 pt-0">
      {/* Header section */}
      <div className="sticky top-0 z-20 bg-gray-50 dark:bg-gray-900">
        <PurchasesHeader
          selectedConnection={selectedConnection}
          onConnectionChange={handleConnectionChange}
          connections={connections}
          onAddPurchase={handleAddPurchase}
          onRefreshAll={handleRefreshAll}
          onSearch={handleSearchChange}
          onDateRangeChange={handleDateRangeChange}
        />
      </div>

      {/* Main content section */}
      <div className="flex-1 overflow-y-auto">
        {isLoading && filteredItems.length === 0 && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

        {isError && <div className="text-center text-red-500">{message}</div>}

        {!isLoading && filteredItems.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-600 dark:text-gray-300">No purchases found</p>
          </div>
        )}

        <div className="grid grid-cols-1 gap-4 mt-2">
          {Array.isArray(filteredItems) && filteredItems.map((purchase) => (
            <PurchasesCard
              key={purchase._id}
              purchase={purchase}
              onViewDetails={() => handleViewDetailsClick(purchase._id)}
              onConvertToInventory={() => handleConvertToInventoryClick(purchase._id)}
            />
          ))}
        </div>

        {isLoading && filteredItems.length > 0 && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

        <div className="last-item"></div>
      </div>

      {showPurchaseModal && (
        <PurchasesModal
          isOpen={showPurchaseModal}
          onClose={handlePurchaseModalClose}
          purchase={selectedPurchase}
          onSave={handleSaveChanges}
          isNew={isNewPurchase}
        />
      )}

      {showConvertToInventoryModal && selectedPurchase && (
        <ConvertToInventoryModal
          isOpen={showConvertToInventoryModal}
          closeModal={handleConvertToInventoryModalClose}
          purchase={selectedPurchase}
          onConvert={handleConvertToInventory}
        />
      )}
    </div>
  );
};

export default PurchasesPage;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/ResultsFeed/Modals/Modal'; // Import your existing modal component
import { referAFriend, resetReferralStatus } from '../features/marketing/marketingSlice'; // Import actions from marketingSlice

const ReferFriendModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.marketing); // Accessing the marketing slice state

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Dispatch the referAFriend action
    dispatch(referAFriend({ email }));
  };

  // Handle modal close
  const handleClose = () => {
    onClose();
    if (success || error) {
      dispatch(resetReferralStatus()); // Reset the referral status when the modal is closed
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg max-w-2xl mx-auto dark:text-white transform transition-all'>
        <h2 className='text-3xl font-bold mb-6 border-b-2 border-gray-200 pb-2'>
          Refer a Friend and Get 50% Off!
        </h2>
        <p className='mb-4'>
          Share the benefits of ArbiEngine with your friends. If they sign up using your referral,
          you’ll get 50% off your next month’s membership!
        </p>
        <input
          type='email'
          placeholder='Enter your email'
          value={email}
          onChange={handleEmailChange}
          className='w-full p-2 mb-4 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100'
          disabled={loading} // Disable input while loading
        />
        <div className='flex justify-end'>
          <button
            className='mr-2 px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-100 rounded-md hover:bg-gray-400 dark:hover:bg-gray-600'
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className='px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-500'
            onClick={handleSubmit}
            disabled={loading} // Disable button while loading
          >
            {loading ? 'Sending...' : 'Submit'}
          </button>
        </div>
        {success && <p className="mt-4 text-green-500">Referral email sent successfully!</p>}
        {error && <p className="mt-4 text-red-500">Failed to send referral email. Please try again.</p>}
      </div>
    </Modal>
  );
};

export default ReferFriendModal;

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { verifyUser } from '../features/auth/authSlice'
import CryptoJS from 'crypto-js';


const TwoFactorAuth = () => {
  const [code, setCode] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { unverifiedUser, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)
  const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
  const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();


  useEffect(() => {
    if (isSuccess) {
      navigate('/arbie') // Redirect to the desired page after successful verification
    }
  }, [isSuccess, navigate])

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(verifyUser({ email: unverifiedUser.email, code, is2FA: true, encryptedPassword }))
  }

  return (
    <div className="w-screen mx-auto flex justify-center items-center relative h-screen overflow-hidden dark:bg-gray-900">
      <div className="container mx-auto p-8 flex">
        <div className="max-w-md w-full mx-auto form-container">
          <div className="bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white rounded-lg overflow-hidden shadow-2xl border border-gray-300">
            <div className="p-8">
              <h2 className="text-2xl font-bold mb-4 dark:text-white">Two-Factor Authentication</h2>
              <form onSubmit={onSubmit}>
                <div className="mb-5">
                  <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Authentication Code
                  </label>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    name="code"
                    className="block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 dark:text-white border border-transparent focus:outline-none hover:shadow-md"
                  />
                </div>
                <button className="w-full p-3 mt-4 bg-indigo-600 dark:bg-indigo-500 text-white rounded shadow hover:bg-indigo-700 dark:hover:bg-indigo-600">
                  {isLoading ? 'Verifying...' : 'Verify'}
                </button>
                {isError && <p className="mt-2 text-red-600">{message}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoFactorAuth

// InventorySettings.jsx

import React, { useState } from 'react';
import SkuPrefixSection from '../../components/Settings/SkuPrefixSection';
import CustomFieldsManager from '../../components/Settings/CustomFieldsManager';
import AutoFillPreferences from '../../components/Settings/AutoFillPreferences';
import { CogIcon } from '@heroicons/react/solid';

const tabs = [
  { key: 'skuPrefix', label: 'SKU Prefix' },
  { key: 'customFields', label: 'Custom Fields' },
  { key: 'autoFill', label: 'Auto-Fill Preferences' },
];

const InventorySettings = () => {
  const [activeTab, setActiveTab] = useState('skuPrefix');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'skuPrefix':
        return <SkuPrefixSection />;
      case 'customFields':
        return <CustomFieldsManager />;
      case 'autoFill':
        return <AutoFillPreferences />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full bg-white dark:bg-gray-800 rounded-md p-6">
      {/* Header */}
      <div className="mb-4">
        <div className="flex items-center space-x-2">
          <CogIcon className="w-6 h-6 text-indigo-500" />
          <h1 className="text-2xl font-semibold text-gray-800 dark:text-gray-100">
            Inventory Settings
          </h1>
        </div>
      </div>

      {/* Tab Navigation */}
      <div className="mb-6 border-b border-gray-200 dark:border-gray-700">
        <nav className="-mb-px flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${activeTab === tab.key
                  ? 'border-indigo-500 text-indigo-600 dark:text-indigo-400'
                  : 'border-transparent text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100'
                }`}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>

      {/* Tab Content */}
      <div>{renderTabContent()}</div>
    </div>
  );
};

export default InventorySettings;

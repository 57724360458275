import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, PlusIcon, ViewGridIcon, ViewListIcon, RefreshIcon, FilterIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { syncInventory, getInventoryItems } from '../../../features/inventory/inventorySlice';
import SyncInventoryModal from './SyncInventoryModal';
import { toast } from 'react-toastify';

const ProductsActions = ({ toggleView, cardView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    condition: '',
    brand: '',
    category: '',
    recordCompletionStatus: '',
    photographyStatus: '',
  });

  const connections = useSelector(state => state.connection.connections);

  const handleAddProduct = () => {
    navigate('/arbie/products/');
  };

  const handleSyncInventory = async (id, platform) => {
    try {
      const resultAction = await dispatch(syncInventory({ id, platform }));
      if (syncInventory.fulfilled.match(resultAction)) {
        toast.success('Inventory synced successfully');
  
        // Refresh the inventory list after syncing
        dispatch(getInventoryItems());
      } else if (syncInventory.rejected.match(resultAction)) {
        throw new Error('Failed to sync inventory');
      }
    } catch (error) {
      const errorMessage = error.message || 'Failed to sync inventory';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const openSyncModal = () => setIsModalOpen(true);
  const closeSyncModal = () => setIsModalOpen(false);

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  return (
    <div className="flex flex-col space-y-4 bg-gray-50 dark:bg-gray-900 pt-4">
      {/* Header Section */}
      <div className="flex flex-col lg:flex-row items-center justify-between p-4 bg-white rounded-lg shadow-lg dark:bg-gray-800 border dark:border-gray-700 space-y-4 lg:space-y-0">
        <div className="flex items-center space-x-4">
          <ViewGridIcon className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Inventory Management</h1>
        </div>
        <div className="flex flex-wrap justify-center gap-3">
          {/* View Toggle Button */}
          <button
            onClick={toggleView}
            className="bg-indigo-600 text-white font-medium py-1.5 px-3 rounded-md hover:bg-indigo-700 flex items-center transition duration-300 ease-in-out text-base"
          >
            {cardView ? (
              <>
                <ViewListIcon className="h-5 w-5 mr-1.5" />
                <span>Table View</span>
              </>
            ) : (
              <>
                <ViewGridIcon className="h-5 w-5 mr-1.5" />
                <span>Card View</span>
              </>
            )}
          </button>

          {/* Add Product Button */}
          <button
            onClick={handleAddProduct}
            className="bg-indigo-600 text-white font-medium py-1.5 px-3 rounded-md hover:bg-indigo-700 flex items-center transition duration-300 ease-in-out text-base"
          >
            <PlusIcon className="h-5 w-5 mr-1.5" />
            <span>Add Product</span>
          </button>

          {/* Sync Inventory Button */}
          <button
            onClick={openSyncModal}
            className={`bg-green-600 text-white font-medium py-1.5 px-3 rounded-md hover:bg-green-700 flex items-center transition duration-300 ease-in-out text-base ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            <RefreshIcon className="h-5 w-5 mr-1.5" />
            <span>{loading ? 'Syncing...' : 'Sync Inventory'}</span>
          </button>

          {/* Toggle Filters on Mobile */}
          <button
            onClick={() => setShowFilter(!showFilter)}
            className="lg:hidden bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-300 font-medium py-1.5 px-3 rounded-md flex items-center transition duration-300 ease-in-out text-base"
          >
            <FilterIcon className="h-5 w-5 mr-1.5" />
            <span>Filters</span>
          </button>
        </div>
      </div>

      {/* Search and Filters Section */}
      <div className="flex flex-col lg:flex-row items-center bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border dark:border-gray-700 space-y-2 lg:space-y-0 lg:space-x-4">
        {/* Search Bar */}
        <div className="relative flex items-center flex-grow">
          <SearchIcon className="absolute w-5 h-5 text-gray-400 left-3" />
          <input
            type="text"
            id="products-search"
            className="block w-full pl-10 pr-3 py-1.5 text-base text-gray-900 border border-gray-300 dark:border-gray-700 rounded-md bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white"
            placeholder="Search for products"
          />
        </div>

        {/* Desktop Filters */}
        <div className="hidden lg:flex space-x-3">
          <FilterDropdown label="Condition" options={['New', 'Used']} value={filters.condition} onChange={(value) => handleFilterChange('condition', value)} />
          <FilterDropdown label="Brand" options={['Brand A', 'Brand B']} value={filters.brand} onChange={(value) => handleFilterChange('brand', value)} />
          <FilterDropdown label="Category" options={['Electronics', 'Industrial']} value={filters.category} onChange={(value) => handleFilterChange('category', value)} />
          <FilterDropdown label="Completion Status" options={['Draft', 'Completed', 'Inactive', 'Sold']} value={filters.recordCompletionStatus} onChange={(value) => handleFilterChange('recordCompletionStatus', value)} />
          <FilterDropdown label="Photography Status" options={['Pending', 'Completed', 'Hold']} value={filters.photographyStatus} onChange={(value) => handleFilterChange('photographyStatus', value)} />
        </div>
      </div>

      {/* Mobile Filters */}
      {showFilter && (
        <div className="flex flex-col space-y-3 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md border dark:border-gray-700 lg:hidden">
          <FilterDropdown label="Condition" options={['New', 'Used']} value={filters.condition} onChange={(value) => handleFilterChange('condition', value)} />
          <FilterDropdown label="Brand" options={['Brand A', 'Brand B']} value={filters.brand} onChange={(value) => handleFilterChange('brand', value)} />
          <FilterDropdown label="Category" options={['Electronics', 'Industrial']} value={filters.category} onChange={(value) => handleFilterChange('category', value)} />
          <FilterDropdown label="Completion Status" options={['Draft', 'Completed', 'Inactive', 'Sold']} value={filters.recordCompletionStatus} onChange={(value) => handleFilterChange('recordCompletionStatus', value)} />
          <FilterDropdown label="Photography Status" options={['Pending', 'Completed', 'Hold']} value={filters.photographyStatus} onChange={(value) => handleFilterChange('photographyStatus', value)} />
        </div>
      )}

      {/* Sync Inventory Modal */}
      <SyncInventoryModal
        isOpen={isModalOpen}
        onClose={closeSyncModal}
        onConfirm={(connection) => handleSyncInventory(connection.id, connection.platform)}
        connections={connections}
      />
    </div>
  );
};

// Helper Component for Filter Dropdowns
const FilterDropdown = ({ label, options, value, onChange }) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="bg-gray-50 dark:bg-gray-900 text-base text-gray-700 dark:text-gray-300 rounded-md border border-gray-300 dark:border-gray-600 px-3 py-1.5"
  >
    <option value="">{label}</option>
    {options.map((option) => (
      <option key={option} value={option.toLowerCase()}>
        {option}
      </option>
    ))}
  </select>
);

export default ProductsActions;

import React from 'react'

import DataCard from './DataCard' // Ensure the path is correct for your project structure

const OpportunityCards = ({
  currentItems,
  openOpportunityModal,
  handleCheckboxChange,
  handleSelectItem,
  selectedItems,
  openModal,
  openImageModal,
}) => {
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4 mt-4'>
      {currentItems.map((opportunity) => (
        <DataCard
          key={opportunity.itemId}
          opportunity={opportunity}
          openOpportunityModal={openOpportunityModal}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectItem={handleSelectItem}
          selectedItems={selectedItems}
          openModal={openModal}
          openImageModal={openImageModal}
        />
      ))}
    </div>
  )
}

export default OpportunityCards

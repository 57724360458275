// components/subscriptions/PaymentFooter.jsx
import React from 'react'

const PaymentFooter = () => {
  return (
    <footer className='bg-white dark:bg-gray-800 shadow-md p-4'>
      <div className='container mx-auto text-center'>
        <p className='text-gray-500 dark:text-gray-400'>
          &copy; 2024 Arbiengine - Capilleh LLC. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default PaymentFooter

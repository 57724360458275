import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  XCircleIcon,
  ClipboardCheckIcon,
  ArrowCircleDownIcon,
  BadgeCheckIcon,
} from '@heroicons/react/solid';

const ConvertToInventoryModal = ({ isOpen, closeModal, purchase, onConvert }) => {
  const [lineItems, setLineItems] = useState([...purchase.items]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setLineItems([...purchase.items]);
  }, [purchase]);

  const handleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleConvertToInventory = () => {
    const selectedLineItems = lineItems.filter((item) => selectedItems.includes(item._id));
    onConvert(purchase._id, selectedLineItems);
  };

  const getActionButtonText = () => {
    const selectedCount = selectedItems.length;
    const validatedCount = selectedItems.filter(
      (itemId) =>
        lineItems.find((item) => item._id === itemId)?.validationStatus === 'validated'
    ).length;

    return `Convert ${validatedCount} of ${selectedCount} Selected Item(s) to Inventory`;
  };

  const getStatusIcon = (status, convertedSKU) => {
    if (convertedSKU) {
      return <CheckCircleIcon className="h-6 w-6 text-green-500 dark:text-green-400" />;
    }

    switch (status) {
      case 'inbound':
        return <ArrowCircleDownIcon className="h-6 w-6 text-blue-500 dark:text-blue-400" />;
      case 'validated':
        return <ClipboardCheckIcon className="h-6 w-6 text-yellow-500 dark:text-yellow-400" />;
      case 'rejected':
        return <XCircleIcon className="h-6 w-6 text-red-500 dark:text-red-400" />;
      default:
        return null;
    }
  };

  const renderDataCard = (item) => {
    const isSelected = selectedItems.includes(item._id);
    const isConverted = item.convertedToInventory && item.convertedSKU;
    const statusIcon = getStatusIcon(item.validationStatus, item.convertedSKU);

    return (
      <div
        key={item._id}
        onClick={() => !isConverted && handleSelectItem(item._id)}
        className={`relative p-6 rounded-lg shadow-lg border m-2 ${isConverted
            ? 'border-green-500 bg-green-50 dark:bg-green-900'
            : isSelected
              ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900'
              : 'border-gray-200 bg-white dark:bg-gray-800 dark:border-gray-700'
          } cursor-pointer transition-transform transform hover:scale-95`}
      >
        <div className="absolute top-2 right-2">{statusIcon}</div>
        <div className="space-y-2">
          <p className="text-sm text-gray-600 dark:text-gray-300">Brand: {item.brand || 'N/A'}</p>
          <p className="text-sm text-gray-600 dark:text-gray-300">Model: {item.model || 'N/A'}</p>
          <p className="text-sm text-gray-600 dark:text-gray-300">Category: {item.category || 'N/A'}</p>
          <p className="text-sm text-gray-600 dark:text-gray-300">Condition: {item.expectedCondition || 'N/A'}</p>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            Purchase Price: ${item.purchasePrice?.toFixed(2) || 'N/A'}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            Resell Price: ${item.resellPrice?.toFixed(2) || 'N/A'}
          </p>

          {isConverted && (
            <div className="text-sm text-gray-600 dark:text-gray-300 mt-2 p-2 border rounded-lg bg-green-100 dark:bg-green-800">
              <div className="flex items-center">
                <BadgeCheckIcon className="h-5 w-5 text-green-500 mr-2" />
                <p className="font-semibold">Converted to Inventory</p>
              </div>
              <p className="mt-2">SKU: {item.convertedSKU || 'N/A'}</p>
              <p>Converted on: {new Date(item.convertedToInventoryDate).toLocaleDateString()}</p>
            </div>
          )}

          {!isConverted && (
            <select
              value={item.validationStatus}
              onChange={(e) =>
                setLineItems((prevItems) =>
                  prevItems.map((prevItem) =>
                    prevItem._id === item._id
                      ? { ...prevItem, validationStatus: e.target.value }
                      : prevItem
                  )
                )
              }
              className="mt-3 w-full py-2 px-3 border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white"
              disabled={isConverted}
            >
              <option value="inbound">Inbound</option>
              <option value="validated">Validated</option>
              <option value="rejected">Rejected</option>
            </select>
          )}
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 p-8 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-2xl font-bold leading-6 text-gray-900 dark:text-gray-100">
                  Convert Line Items to Inventory
                </Dialog.Title>

                {/* Status Legend */}
                <div className="sticky top-0 z-10 bg-white dark:bg-gray-900 py-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div className="flex items-center p-4 bg-indigo-50 dark:bg-indigo-800 rounded-lg shadow-sm">
                      <ArrowCircleDownIcon className="h-8 w-8 text-blue-500 dark:text-blue-400" />
                      <div className="ml-3">
                        <p className="text-lg font-semibold text-gray-800 dark:text-gray-100">Inbound</p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">Item is awaiting validation.</p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-yellow-50 dark:bg-yellow-800 rounded-lg shadow-sm">
                      <ClipboardCheckIcon className="h-8 w-8 text-yellow-500 dark:text-yellow-400" />
                      <div className="ml-3">
                        <p className="text-lg font-semibold text-gray-800 dark:text-gray-100">Validated</p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">Item is validated and ready for conversion.</p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-red-50 dark:bg-red-800 rounded-lg shadow-sm">
                      <XCircleIcon className="h-8 w-8 text-red-500 dark:text-red-400" />
                      <div className="ml-3">
                        <p className="text-lg font-semibold text-gray-800 dark:text-gray-100">Rejected</p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">Item has been rejected and will not be converted.</p>
                      </div>
                    </div>
                    <div className="flex items-center p-4 bg-green-50 dark:bg-green-800 rounded-lg shadow-sm">
                      <CheckCircleIcon className="h-8 w-8 text-green-500 dark:text-green-400" />
                      <div className="ml-3">
                        <p className="text-lg font-semibold text-gray-800 dark:text-gray-100">Converted</p>
                        <p className="text-sm text-gray-600 dark:text-gray-300">Item has been successfully converted to inventory.</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Data Cards */}
                <div className="h-96 overflow-y-auto mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {lineItems.map((item) => renderDataCard(item))}
                </div>

                <div className="mt-8 flex justify-end">
                  <button
                    onClick={handleConvertToInventory}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 dark:bg-indigo-700 px-6 py-3 text-lg font-medium text-white hover:bg-indigo-700 dark:hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {getActionButtonText()}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConvertToInventoryModal;

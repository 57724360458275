import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import { forgotPassword, reset, setMessage, setError } from '../features/auth/authSlice'
import { PolygonBackground1, PolygonBackground2 } from '../components/PolygonBackground'
import Header from '../components/Header'
import 'react-toastify/dist/ReactToastify.css'

import CryptoJS from 'crypto-js';

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const [errorShake, setErrorShake] = useState(false)

  const { isLoading, isError, message } = useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      console.error(message)
      setErrorShake(true)

      setTimeout(() => {
        setErrorShake(false)
      }, 800)
    }
    dispatch(reset())
  }, [isError, dispatch])

  const onSubmitForgot = async (e) => {
    e.preventDefault();
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        await dispatch(setError(true));
        await dispatch(setMessage('Email Syntax is Incorrect'));
      } else {
        const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
        const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();
        const userData = {
          email: email,
          encryptedPassword,
          homeUrl: window.location.origin,
        };
        await dispatch(forgotPassword(userData));
        handleForgotPasswordSuccess();
      }
    } catch (error) {
      console.error('Failed to send email:', error.message);
    }
  };

  const handleForgotPasswordSuccess = () => {
    toast.dismiss();
    toast.success('If a user exists, an email has been sent.', {
      position: 'top-right',
      autoClose: 5000,
    });
  };

  return (
    <div className='relative min-h-screen overflow-hidden dark:bg-gray-900'>
      <Header hideGetStarted={true} />
      <ToastContainer position='top-right' autoClose={5000} />
      <PolygonBackground1 />
      <div className='flex flex-col justify-center items-center h-screen'>
        <h1 className='text-5xl text-center mb-12 font-bold dark:text-white'>
          Forgot Password
        </h1>
        <div className='max-w-md w-full p-8 bg-white dark:bg-gray-800 dark:border-gray-700 dark:text-white rounded-lg overflow-hidden shadow-2xl border border-gray-300'>
          <div className={`${errorShake ? 'shake' : ''}`}>
            <form onSubmit={onSubmitForgot}>
              <div className='mb-6'>
                <label
                  htmlFor='email'
                  className='block mb-2 text-sm font-medium text-gray-600 dark:text-gray-300'
                >
                  Email
                </label>
                <input
                  type='email'
                  id='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='block w-full p-3 rounded bg-gray-200 dark:bg-gray-700 dark:text-white border border-transparent focus:outline-none hover:shadow-md'
                />
              </div>
              <button className='w-full p-3 mt-4 bg-indigo-600 dark:bg-indigo-500 text-white rounded shadow hover:bg-indigo-700 dark:hover:bg-indigo-600'>
                {isLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <PolygonBackground2 />
    </div>
  )
}

export default ForgotPassword

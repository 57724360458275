import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const ProductMediaSection = ({ media = [], darkMode }) => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        top: 0,
        left: direction === 'left' ? -300 : 300,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="relative mt-2 ">
      <div className="relative w-full ">
        <button
          className={`ml-2 absolute left-0 z-10 p-2 bg-gray-200 dark:bg-gray-700 rounded-full top-1/2 transform -translate-y-1/2 hover:bg-gray-300 dark:hover:bg-gray-600 transition ${media.length > 0 ? 'visible' : 'invisible'
            }`}
          onClick={() => scroll('left')}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-700 dark:text-gray-200" />
        </button>

        <div
          ref={scrollRef}
          className={`flex space-x-4 overflow-x-auto scrollbar-hide p-4 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-gray-100'
            }`}
        >
          {media.length > 0 ? (
            media.map((image, index) => (
              <div key={index} className="w-64 h-64 flex-shrink-0 rounded-lg overflow-hidden shadow-md transition transform hover:scale-105">
                <img src={image.awsUrl} alt={`Product Image ${index + 1}`} className="w-full h-full object-cover" />
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 dark:text-gray-400 w-full">No images available</div>
          )}
        </div>

        <button
          className={`mr-2 absolute right-0 z-10 p-2 bg-gray-200 dark:bg-gray-700 rounded-full top-1/2 transform -translate-y-1/2 hover:bg-gray-300 dark:hover:bg-gray-600 transition ${media.length > 0 ? 'visible' : 'invisible'
            }`}
          onClick={() => scroll('right')}
        >
          <ChevronRightIcon className="h-6 w-6 text-gray-700 dark:text-gray-200" />
        </button>
      </div>
    </div>
  );
};

export default ProductMediaSection;

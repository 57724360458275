import React from 'react'
import {
  ExternalLinkIcon,
  CurrencyDollarIcon,
  BadgeCheckIcon,
  CalendarIcon,
  ShoppingCartIcon,
  LocationMarkerIcon,
  StatusOnlineIcon,
} from '@heroicons/react/solid'

const MarketResearchDataCard = ({ item }) => {
  const mostRecentPrice = item.latestPrice !== null ? item.latestPrice : 'N/A'
  const condition = item.condition || 'N/A'
  const itemCreationDate = item.itemCreationDate
    ? new Date(item.itemCreationDate).toLocaleString()
    : 'N/A'
  const buyingOptions =
    item.buyingOptions && item.buyingOptions.length > 0
      ? item.buyingOptions.join(', ')
      : 'N/A'
  const sellerFeedback = item.seller
    ? `${item.seller.feedbackPercentage.$numberDecimal}% (${item.seller.feedbackScore} scores)`
    : 'N/A'
  const itemLocation =
    item.itemLocation && item.itemLocation.country
      ? item.itemLocation.country
      : 'N/A'
  const imageUrl = item.image
    ? item.image.imageUrl
    : 'https://i.pinimg.com/736x/fa/dd/6b/fadd6bf8ccb8cc5297764444a347cbdc.jpg'
  const itemWebUrl = item.itemAffiliateWebUrl || '#'
  const title = item.title || 'N/A'

  return (
    <div className='bg-white bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 border shadow-lg rounded-lg p-4 mr-4 mb-4 flex items-start space-x-4'>
      <div className='flex-shrink-0'>
        <img
          src={imageUrl}
          alt='Product'
          className='w-24 h-24 object-cover rounded'
        />
        <a
          href={itemWebUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='mt-2 inline-flex items-center bg-green-500 hover:bg-green-700 text-white font-semibold py-1 px-2 rounded text-xs w-24 justify-center'
        >
          View on eBay <ExternalLinkIcon className='ml-2 w-4 h-4' />
        </a>
      </div>
      <div className='flex-grow'>
        <h3 className='text-md font-semibold text-gray-900 dark:text-white mb-1'>
          {title}
        </h3>
        <div className='text-sm text-gray-600 dark:text-gray-400 space-y-1'>
          <p className='flex items-center'>
            <CurrencyDollarIcon className='w-4 h-4 text-green-500 mr-1' />$
            {mostRecentPrice}
          </p>
          <p className='flex items-center'>
            <StatusOnlineIcon className='w-4 h-4 text-yellow-500 mr-1' />
            Condition: {condition}
          </p>
          <p className='flex items-center'>
            <CalendarIcon className='w-4 h-4 text-blue-500 mr-1' />
            Created On: {itemCreationDate}
          </p>
          <p className='flex items-center'>
            <ShoppingCartIcon className='w-4 h-4 text-purple-500 mr-1' />
            Type: {buyingOptions}
          </p>
          <p className='flex items-center'>
            <BadgeCheckIcon className='w-4 h-4 text-indigo-500 mr-1' />
            Seller: {sellerFeedback}
          </p>
          <p className='flex items-center'>
            <LocationMarkerIcon className='w-4 h-4 text-red-500 mr-1' />
            Location: {itemLocation}
          </p>
        </div>
      </div>
    </div>
  )
}

export default MarketResearchDataCard

import React, { useState } from 'react';

const PricingSection = () => {
  const [selectedTier, setSelectedTier] = useState('Explorer'); // Initialize with 'Explorer' selected

  return (
    <section
      id="pricing"
      aria-label="Pricing"
      className="dark:bg-gray-900 py-20 sm:py-24 mb-40"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <h2 className="font-display text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl">
            <span className="relative whitespace-nowrap">
              <svg
                aria-hidden="true"
                viewBox="0 0 281 40"
                preserveAspectRatio="none"
                className="absolute left-0 top-1/2 h-[1em] w-full fill-indigo-500"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Zm40.321-9.93c.1-.066.231-.085.29-.041.059.043-.024.096-.183.119-.177.024-.219-.007-.107-.079ZM172.299 26.22c9.364-6.058 5.161-12.039-12.304-17.51-11.656-3.653-23.145-5.47-35.243-5.576-22.552-.198-33.577 7.462-21.321 14.814 12.012 7.205 32.994 10.557 61.531 9.831 4.563-.116 5.372-.288 7.337-1.559Z"
                ></path>
              </svg>
              <span className="relative">Unlocking value,</span>
            </span>{' '}
            for everyone.
          </h2>
          <p className="mt-6 text-lg tracking-tight text-gray-600 dark:text-gray-300">
            It doesn’t matter the size of your business—our software is designed to scale with you.
          </p>
        </div>
        <div className="mt-16 grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 gap-x-6">
          {['Explorer', 'Accelerator', 'Professional', 'Enterprise'].map(
            (tier) => (
              <PricingCard
                key={tier}
                tier={tier}
                selectedTier={selectedTier}
                setSelectedTier={setSelectedTier}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};

const PricingCard = ({ tier, selectedTier, setSelectedTier }) => {
  const tierDetails = {
    Explorer: {
      price: '$29/month',
      description: 'Good for anyone who is just getting started.',
      features: [
        'Tier details coming soon!',
        // '100 Inventory Items',
        // '3 Search Terms',
        // '1 Industry/Category Supported',
        // 'Metrics and Results Feed',
        // 'Basic Real-time Market Insights',
      ],
      buttonLabel: 'Join the Waitlist',
      buttonHref: '/register',
    },
    Accelerator: {
      price: '$69/month',
      description: 'Perfect for small/medium sized businesses.',
      features: [
        'Tier details coming soon!',
        // '500 Inventory Items',
        // '10 Search Terms',
        // '3 Industries/Categories Supported',
        // 'Standard Dashboard Features',
        // 'Basic Inventory Management',
        // 'Multiple Marketplaces (up to 2)',
        // 'Basic Ebay Integration',
        // '50 Product Research Tokens/month',
        // 'Basic Photography Queue',
        // 'Standard Real-time Market Insights',
        // 'Email & Chat Support',
        // 'Webinars for Training',
      ],
      buttonLabel: 'Join the Waitlist',
      buttonHref: '/register',
    },
    Professional: {
      price: '$129/month',
      description: 'For professional sellers looking to scale.',
      features: [
        'Tier details coming soon!',
        // '2000 Inventory Items',
        // '25 Search Terms',
        // '10 Industries/Categories Supported',
        // 'Advanced Dashboard Features',
        // 'Standard Inventory Management',
        // 'Multiple Marketplaces (up to 5)',
        // 'Advanced Ebay Integration',
        // 'Shopify Integration',
        // '200 Product Research Tokens/month',
        // 'Standard Photography Queue',
        // 'Advanced Real-time Market Insights',
        // 'Priority Support',
        // 'Personalized Training',
      ],
      buttonLabel: 'Join the Waitlist',
      buttonHref: '/register',
    },
    Enterprise: {
      price: 'Quoted',
      description: 'For the biggest enterprise companies.',
      features: [
        'Tier details coming soon!',
        // 'Unlimited Inventory Items',
        // 'Unlimited Search Terms',
        // 'Unlimited Industries/Categories Supported',
        // 'Premium Dashboard Features',
        // 'Premium Inventory Management',
        // 'All supported marketplaces',
        // 'Full Ebay Integration',
        // 'Shopify Integration',
        // 'Unlimited Product Research Tokens',
        // 'Premium Photography Queue',
        // 'Premium Real-time Market Insights',
        // 'Dedicated Account Manager',
        // 'On-site Training & Consultation',
      ],
      buttonLabel: 'Join the Waitlist',
      buttonHref: '/register',
    },
  };

  const { price, description, features, buttonLabel, buttonHref } =
    tierDetails[tier];

  return (
    <div
      onClick={() => setSelectedTier(tier)}
      className={`cursor-pointer flex flex-col rounded-3xl p-8 transition-transform transform hover:scale-105 ${selectedTier === tier
          ? 'bg-indigo-600 text-white'
          : 'bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-white'
        } shadow-lg`}
    >
      <h3 className="mt-5 font-display text-2xl">{tier}</h3>
      <p className="mt-2 text-lg">{description}</p>
      <p className="order-first font-display text-5xl font-light tracking-tight mt-4">
        {price}
      </p>
      <ul
        role="list"
        className="order-last mt-10 flex flex-col gap-y-3 text-base"
      >
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="ml-4">{feature}</span>
          </li>
        ))}
      </ul>
      <a
        className={`mt-8 group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 ${selectedTier === tier
            ? 'bg-white text-indigo-600 hover:bg-gray-50 active:bg-gray-200'
            : 'ring-1 ring-gray-700 dark:ring-gray-300 text-gray-900 dark:text-white hover:ring-gray-500 active:ring-gray-700 active:text-gray-400 focus-visible:outline-indigo-600'
          }`}
        href={buttonHref}
      >
        {buttonLabel}
      </a>
    </div>
  );
};

export default PricingSection;

import React, { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const EnterpriseFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    phone: '',
    businessSize: '',
    requirements: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // Handle form submission (e.g., send data to the backend)
    console.log('Form Data Submitted:', formData)
    onClose()
  }

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-10 overflow-y-auto'
        onClose={onClose}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />
          </Transition.Child>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-lg'>
              <Dialog.Title
                as='h3'
                className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100'
              >
                Contact Us for Enterprise Plan
              </Dialog.Title>
              <form onSubmit={handleSubmit} className='space-y-4 mt-2'>
                <input
                  type='text'
                  name='companyName'
                  placeholder='Company Name'
                  value={formData.companyName}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                />
                <input
                  type='text'
                  name='contactPerson'
                  placeholder='Contact Person'
                  value={formData.contactPerson}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                />
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  value={formData.email}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                />
                <input
                  type='tel'
                  name='phone'
                  placeholder='Phone'
                  value={formData.phone}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                />
                <select
                  name='businessSize'
                  value={formData.businessSize}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                >
                  <option value=''>Select Business Size</option>
                  <option value='small'>Small (1-50 employees)</option>
                  <option value='medium'>Medium (51-200 employees)</option>
                  <option value='large'>Large (201+ employees)</option>
                </select>
                <textarea
                  name='requirements'
                  placeholder='Describe your requirements'
                  value={formData.requirements}
                  onChange={handleChange}
                  className='w-full p-3 border rounded'
                  required
                />
                <button
                  type='submit'
                  className='bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded w-full'
                >
                  Submit
                </button>
              </form>
              <div className='mt-4'>
                <button
                  type='button'
                  className='inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600'
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EnterpriseFormModal

import React from 'react';
import ApexCharts from 'react-apexcharts';

const PriceDistributionChart = ({ stats, themeMode }) => {
  const textColor = themeMode === 'dark' ? '#fff' : '#000';

  const priceDistributionOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      background: 'transparent',
    },
    title: {
      text: 'Price Distribution and Statistics by Item Condition',
      align: 'left',
      style: {
        color: textColor,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: stats.map((stat) => stat._id),
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: textColor,
        },
        labels: {
          style: {
            colors: textColor,
          },
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
        title: {
          text: 'Prices ($)',
          style: {
            color: textColor,
          },
        },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (value) {
          return `$${value.toFixed(2)}`;
        },
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
      labels: {
        colors: textColor,
      },
    },
    theme: {
      mode: themeMode,
    },
    colors: ['#6366F1', '#34D399', '#F59E0B', '#EF4444'],
  };

  const priceDistributionSeries = [
    {
      name: 'Average Price',
      type: 'column',
      data: stats.map((stat) => stat.averagePrice),
    },
    {
      name: 'Min Price',
      type: 'column',
      data: stats.map((stat) => stat.minPrice),
    },
    {
      name: 'Max Price',
      type: 'column',
      data: stats.map((stat) => stat.maxPrice),
    },
  ];

  return (
    <div className='bg-gradient-to-r bg-white border dark:from-gray-800 dark:to-gray-900 p-4 shadow-sm rounded-lg'>
      <ApexCharts
        options={priceDistributionOptions}
        series={priceDistributionSeries}
        type='bar'
        height={350}
        className='mb-4'
      />
    </div>
  );
};

export default PriceDistributionChart;

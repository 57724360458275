import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ReauthenticateModal = ({ isOpen, onClose, onConfirm, connection }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full border">
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
                  Reauthenticate Connection
                </h2>
                <p className="mb-4 text-gray-700 dark:text-gray-300">
                  Are you sure you want to reauthenticate the connection for <strong>{connection.accountName}</strong>?
                </p>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-500 dark:bg-gray-600 text-white font-semibold py-2 px-4 rounded-full mr-2"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-indigo-500 dark:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-full"
                    onClick={onConfirm}
                  >
                    Reauthenticate
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReauthenticateModal;

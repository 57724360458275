import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateReddit } from '../../features/socials/socialSlice'; // Import the action from the slice

// Add Facebook to platform configuration
const platformConfig = {
  reddit: {
    name: 'Reddit',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/b/b4/Reddit_logo.svg',
  },
  facebook: {
    name: 'Facebook',
    logoUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
  },
};

const SocialConnectionSettings = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector((state) => state.social); // Access state from Redux

  // State to hold Facebook login status
  const [fbLoginStatus, setFbLoginStatus] = useState('unknown'); // Initial status is 'unknown'

  // Initialize Facebook SDK and check login status
  useEffect(() => {
    if (!window.FB) {
      // Load the Facebook SDK script dynamically
      const script = document.createElement('script');
      script.id = 'facebook-jssdk';
      script.src = 'https://connect.facebook.net/en_US/all.js';
      script.async = true;
      script.onload = () => {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: 2279113235782287, // Replace with your Facebook App ID
            cookie: true,
            xfbml: true, // Enable XFBML parsing
            version: 'v20', // Replace with the API version you want to use
          });

          // Check Facebook login status
          window.FB.getLoginStatus(function (response) {
            statusChangeCallback(response);
          });
        };
      };
      document.body.appendChild(script);
    } else {
      // If SDK already loaded, check login status
      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    }
  }, []);

  // Callback to handle the Facebook login status response
  const statusChangeCallback = (response) => {
    setFbLoginStatus(response.status); // Update the status in state
    console.log('Facebook login status:', response);
    if (response.status === 'connected') {
      console.log('User is logged in with Facebook and connected to the app.');
    } else if (response.status === 'not_authorized') {
      console.log('User is logged in to Facebook, but not connected to the app.');
    } else {
      console.log('User is not logged in to Facebook.');
    }
  };

  // Check the login state after the user interacts with the Facebook login button
  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };

  // Handle adding a connection and trigger OAuth flow
  const handleAddConnection = (platform) => {
    if (platform === 'reddit') {
      dispatch(authenticateReddit()); // Dispatch the action to start OAuth for Reddit
    } else if (platform === 'facebook') {
      if (fbLoginStatus !== 'connected') {
        window.FB.login(
          function (response) {
            if (response.authResponse) {
              alert('Successfully logged in with Facebook!');
              statusChangeCallback(response); // Update login status
            } else {
              alert('User cancelled login or did not fully authorize.');
            }
          },
          { scope: 'public_profile,email' }
        );
      } else {
        alert('You are already connected with Facebook.');
      }
    } else {
      alert(`OAuth for ${platformConfig[platform].name} is not yet implemented.`);
    }
  };

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      <h1 className='text-2xl font-bold text-gray-800 dark:text-gray-200 mb-8'>
        Social Media Connections
      </h1>
      <div className='bg-white dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 p-6 mb-8 rounded-lg shadow-md border'>
        <h2 className='text-lg font-semibold text-gray-800 dark:text-gray-200 mb-2'>
          Manage your Social Media Connections
        </h2>
        <p className='text-gray-700 dark:text-gray-300 mb-2'>
          Connect your social media accounts to share your content and automate posts.
          Click the "Connect" button below to authenticate your account.
        </p>
        {isLoading && <p className='text-blue-600'>Starting authentication flow...</p>}
        {isError && <p className='text-red-600'>Error: {message}</p>}
      </div>

      {/* Render the Facebook login button inside a styled container for better positioning */}
      <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
        {Object.keys(platformConfig).map((platform) => (
          <div key={platform} className='flex flex-col items-center'>
            <img
              src={platformConfig[platform].logoUrl}
              alt={`${platformConfig[platform].name} logo`}
              className='w-16 h-16 mb-4'
            />
            {platform === 'facebook' && fbLoginStatus !== 'connected' ? (
              <div className='relative w-full flex flex-col items-center'>
                {/* Add a wrapper to the fb-login-button to keep it in place */}
                <div
                  id='fb-login-button-container'
                  className='fb-login-button-container'
                >
                  <div
                    className='fb-login-button'
                    data-width=''
                    data-size='large'
                    data-button-type='continue_with'
                    data-layout='default'
                    data-auto-logout-link='false'
                    data-use-continue-as='true'
                    data-scope='public_profile,email'
                    data-onlogin='checkLoginState();'
                  ></div>
                </div>
              </div>
            ) : (
              <button
                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
                onClick={() => handleAddConnection(platform)}
              >
                {fbLoginStatus === 'connected' && platform === 'facebook'
                  ? `Connected to ${platformConfig[platform].name}`
                  : `Connect ${platformConfig[platform].name}`}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialConnectionSettings;

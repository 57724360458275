import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import contents from './blogContents';

import { Link } from 'react-router-dom';

function ContentCard({ img, title, desc, index }) {
  return (
    <Link to={`/blog/${index}`} className="group">
      <div
        className="relative min-h-[28rem] rounded-xl overflow-hidden bg-gray-200 dark:bg-gray-700 shadow-md transition-transform duration-300 transform hover:scale-105"
        style={{ animationDelay: `${index * 0.1}s` }}
      >
        <img src={img} alt="Background" className="absolute inset-0 h-full w-full object-cover object-center" />
        
        {/* Darker gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />

        {/* Content aligned to the bottom */}
        <div className="absolute bottom-0 w-full p-6 text-white">
          <h4 className="text-2xl font-semibold text-shadow">{title}</h4>
          <p className="mt-2 text-sm line-clamp-3 text-shadow">{desc}</p>
        </div>
      </div>
    </Link>
  );
}

export function BlogLayout() {
  return (
    <div className="relative min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
      <Header />
      <main className="flex-grow container mx-auto px-6 lg:px-16 py-12 lg:py-24">
        <h2 className="text-4xl lg:text-5xl font-bold text-gray-800 dark:text-white leading-tight mb-6">
          Empower Your eCommerce Journey
        </h2>
        <p className="max-w-2lg text-gray-500 dark:text-gray-300 text-xl mb-12">
          At ArbiEngine, we’re dedicated to helping you streamline your eCommerce business. Dive into the latest insights on automation, APIs, and strategies to simplify listing, selling, and advertising—so you can focus on growth and success.
        </p>
        <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
          {contents.map(({ img, title, desc }, index) => (
            <ContentCard key={title} img={img} title={title} desc={desc} index={index} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default BlogLayout;

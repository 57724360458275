import React from 'react';
import { TrashIcon } from '@heroicons/react/solid';

function SearchTermCard({ termStat, onDelete }) {
  const handleDelete = () => {
    onDelete(termStat.termKey);
  };

  // Calculate Silverback + Strong Buys / Items Found Ratio with up to 4 decimal points
  const silverbackStrongRatio = termStat.itemCount
    ? (((termStat.silverbackBuyCount + termStat.strongBuyCount) / termStat.itemCount) * 100).toFixed(2)
    : '0.00';

  return (
    <div
      key={termStat.termKey}
      className='flex-none w-1/3 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg flex flex-col items-center justify-center transform transition-transform duration-300 hover:scale-105'
      style={{ height: '350px' }}
    >
      <h3 className='text-2xl font-bold text-gray-800 dark:text-white text-center'>
        {termStat.term}
      </h3>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-2'>
        Items Found since Creation:{' '}
        <span className='font-semibold'>{termStat.itemCount}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        Silverback Buys:{' '}
        <span className='font-semibold'>{termStat.silverbackBuyCount}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        Strong Buys:{' '}
        <span className='font-semibold'>{termStat.strongBuyCount}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        Silverback/Strong Buys %:{' '}
        <span className='font-semibold'>{silverbackStrongRatio}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        Mild Buys:{' '}
        <span className='font-semibold'>{termStat.mildBuyCount}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        No Buys: <span className='font-semibold'>{termStat.noBuyCount}</span>
      </p>
      <p className='text-lg text-gray-700 dark:text-gray-300 text-center mt-1'>
        Created On:{' '}
        <span className='font-semibold'>
          {new Date(termStat.createdAt).toLocaleDateString()}
        </span>
      </p>
      <button
        onClick={handleDelete}
        className='mt-4 bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-700 transition-colors duration-300 flex items-center space-x-2'
      >
        <TrashIcon className='w-5 h-5' />
        <span>Delete</span>
      </button>
    </div>
  );
}

export default SearchTermCard;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRedditAuthUrl } from './socialService';

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

// Thunk to initiate Reddit OAuth flow
export const authenticateReddit = createAsyncThunk(
  'social/authenticateReddit',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      const id = thunkAPI.getState().auth.user._id
      const authUrl = await getRedditAuthUrl(token, id); // Get Reddit auth URL from backend
      window.location.href = authUrl; // Redirect to Reddit OAuth
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const socialSlice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateReddit.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authenticateReddit.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(authenticateReddit.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = socialSlice.actions;
export default socialSlice.reducer;

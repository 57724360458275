import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';
import { fetchOpportunities, resetOpportunities } from '../../features/arbitrage/autoFeedSlice';
import { fetchConnections, clearConnections } from '../../features/connections/connectionSlice';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  profileImageUrl: localStorage.getItem('profileImageUrl') || null,
  lastImageRefresh: localStorage.getItem('lastImageRefresh') || null,
  sessionStartTime: localStorage.getItem('sessionStartTime') || null,
  sessionExpirationTime: localStorage.getItem('sessionExpirationTime') || null,
  unverifiedUser: null,
  sessionTimeout: false,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  bypassSuccessful: false,
  needs2FA: false,
};

const updateLocalStorage = (state) => {
  if (state.user) {
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('token', state.token);
    localStorage.setItem('refreshToken', state.refreshToken);
    localStorage.setItem('profileImageUrl', state.profileImageUrl);
    localStorage.setItem('lastImageRefresh', state.lastImageRefresh);
    localStorage.setItem('sessionStartTime', state.sessionStartTime);
    localStorage.setItem('sessionExpirationTime', state.sessionExpirationTime);
  } else {
    clearLocalStorage();
  }
};

const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('profileImageUrl');
  localStorage.removeItem('lastImageRefresh');
  localStorage.removeItem('sessionStartTime');
  localStorage.removeItem('sessionExpirationTime');
};

const handleAuthResponse = async (response) => {
  let imageUrlResponse = null;

  try {
    imageUrlResponse = await authService.fetchProfileImage(response.token);
  } catch (imageError) {
    console.warn('No profile image found or error occurred:', imageError);
  }

  const sessionStartTime = Date.now();
  const sessionExpirationTime = sessionStartTime + 120 * 60 * 1000; // 120 minutes

  return {
    user: response,
    token: response.token,
    refreshToken: response.refreshToken,
    profileImageUrl: imageUrlResponse ? imageUrlResponse.imageUrl : null,
    sessionStartTime,
    sessionExpirationTime,
  };
};

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update user profile
export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await authService.updateProfile(userData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    const response = await authService.login(user);

    if (response.is2FAEnabled) {
      return thunkAPI.fulfillWithValue({ needs2FA: true, user: response });
    } else {
      const authData = await handleAuthResponse(response);
      thunkAPI.dispatch(resetOpportunities());
      thunkAPI.dispatch(fetchOpportunities(authData.user._id));
      thunkAPI.dispatch(setAuthData(authData)); // New action to set auth data
      await thunkAPI.dispatch(fetchConnections());
      return authData;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Verify user
export const verifyUser = createAsyncThunk(
  'auth/verifyUser',
  async ({ email, authToken, code, is2FA }, thunkAPI) => {
    try {
      const response = await authService.verifyUser({
        email,
        authToken,
        verificationCode: code,
        is2FA,
      });
      const authData = await handleAuthResponse(response);
      thunkAPI.dispatch(resetOpportunities());
      thunkAPI.dispatch(fetchOpportunities(authData.user._id));
      thunkAPI.dispatch(setAuthData(authData)); // New action to set auth data
      await thunkAPI.dispatch(fetchConnections());
      return authData;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Refresh token
export const refreshAuthToken = createAsyncThunk(
  'auth/refreshAuthToken',
  async ({ encryptedPassword }, thunkAPI) => {
    try {
      const refreshToken = thunkAPI.getState().auth.refreshToken;
      const response = await authService.refreshToken(refreshToken, encryptedPassword);
      const sessionStartTime = Date.now();
      const sessionExpirationTime = sessionStartTime + 120 * 60 * 1000; // 120 minutes
      const authData = {
        token: response.token,
        refreshToken: response.refreshToken,
        sessionStartTime,
        sessionExpirationTime,
      };
      thunkAPI.dispatch(resetOpportunities());
      thunkAPI.dispatch(fetchOpportunities(thunkAPI.getState().auth.user._id));
      return authData;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout user
export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    const refreshToken = thunkAPI.getState().auth.refreshToken;
    await authService.logout(refreshToken);
    thunkAPI.dispatch(clearConnections());
  } catch (error) {
    console.error('Error during logout:', error);
  }
});

// Get user profile
export const getUserProfile = createAsyncThunk(
  'auth/getUserProfile',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await authService.getUserProfile(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for uploading profile image
export const uploadProfileImage = createAsyncThunk(
  'auth/uploadProfileImage',
  async (imageData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await authService.uploadProfileImage(imageData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to upload image';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for fetching the profile image URL
export const fetchProfileImage = createAsyncThunk(
  'auth/fetchProfileImage',
  async (_, thunkAPI) => {
    try {
      return await authService.fetchProfileImage(thunkAPI.getState().auth.token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to fetch profile image URL';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for changing password
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (passwordData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.token;
      return await authService.changePassword(passwordData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to change password';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for forgot password email
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (userData, thunkAPI) => {
    try {
      return await authService.forgotPassword(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to Send Forgot Password Email';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Async thunk for forgot password reset after getting email
export const forgotPasswordConfirm = createAsyncThunk(
  'auth/forgotPasswordConfirm',
  async (userData, thunkAPI) => {
    try {
      return await authService.forgotPasswordConfirm(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        'Failed to Reset Password';
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Subscribe to newsletter
export const subscribeNewsletter = createAsyncThunk(
  'auth/subscribeNewsletter',
  async (newsletterData, thunkAPI) => {
    try {
      const response = await authService.subscribeNewsletter(newsletterData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Register bypass
export const registerBypass = createAsyncThunk(
  'auth/registerBypass',
  async (password, thunkAPI) => {
    try {
      const response = await authService.registerBypass(password);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
      state.needs2FA = false; // reset 2FA state
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSessionTimeout: (state, action) => {
      state.sessionTimeout = action.payload;
    },
    reset2FA: (state) => {
      state.needs2FA = false;
    },
    clearState: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      state.profileImageUrl = null;
      state.lastImageRefresh = null;
      state.sessionStartTime = null;
      state.sessionExpirationTime = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = '';
      state.sessionTimeout = false;
      clearLocalStorage();
      console.log('Logged out and state cleared');
    },
    setIs2FAEnabled: (state, action) => {
      state.is2FAEnabled = action.payload;
    },
    setAuthData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.profileImageUrl = action.payload.profileImageUrl;
      state.lastImageRefresh = Date.now();
      state.sessionStartTime = action.payload.sessionStartTime;
      state.sessionExpirationTime = action.payload.sessionExpirationTime;
      updateLocalStorage(state);
    }
  },
  extraReducers: (builder) => {
    builder
      // User registration
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unverifiedUser = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.unverifiedUser = null;
      })
      // User login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;

        if (action.payload.needs2FA) {
          state.needs2FA = true;
          state.unverifiedUser = action.payload.user;
        } else {
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.refreshToken = action.payload.refreshToken;
          state.profileImageUrl = action.payload.profileImageUrl;
          state.lastImageRefresh = Date.now();
          state.sessionStartTime = action.payload.sessionStartTime;
          state.sessionExpirationTime = action.payload.sessionExpirationTime;
          state.is2FAEnabled = action.payload.user.is2FAEnabled;
          updateLocalStorage(state);
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        clearLocalStorage();
      })
      // Verify user
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken;
        state.profileImageUrl = action.payload.profileImageUrl;
        state.lastImageRefresh = Date.now();
        state.sessionStartTime = action.payload.sessionStartTime;
        state.sessionExpirationTime = action.payload.sessionExpirationTime;
        state.needs2FA = false;
        state.is2FAEnabled = action.payload.user.is2FAEnabled;
        updateLocalStorage(state);
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        clearLocalStorage();
      })
      // Refresh token
      .addCase(refreshAuthToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(refreshAuthToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = action.payload.token;
        state.refreshToken = action.payload.refreshToken;
        state.sessionStartTime = action.payload.sessionStartTime;
        state.sessionExpirationTime = action.payload.sessionExpirationTime;
        updateLocalStorage(state);
      })
      .addCase(refreshAuthToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        clearLocalStorage();
      })
      // Logout user
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        state.profileImageUrl = null;
        state.lastImageRefresh = null;
        state.sessionStartTime = null;
        state.sessionExpirationTime = null;
        state.isError = false;
        state.isSuccess = false;
        state.isLoading = false;
        state.message = '';
        state.sessionTimeout = false;
        clearLocalStorage();
        console.log('Logged out and state cleared');
      })
      // Update user profile
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        updateLocalStorage(state);
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get user profile
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        updateLocalStorage(state);
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Upload profile image
      .addCase(uploadProfileImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadProfileImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileImageUrl = action.payload.imageUrl; // Update the profile image URL
        state.lastImageRefresh = Date.now();
        updateLocalStorage(state);
      })
      .addCase(uploadProfileImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Refresh profile image URL
      .addCase(fetchProfileImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfileImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.profileImageUrl = action.payload.imageUrl;
        state.lastImageRefresh = Date.now();
        updateLocalStorage(state);
      })
      .addCase(fetchProfileImage.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      // Change password
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = 'Password successfully changed';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Forgot password
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      // Forgot password confirm
      .addCase(forgotPasswordConfirm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPasswordConfirm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(forgotPasswordConfirm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(subscribeNewsletter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscribeNewsletter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(subscribeNewsletter.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Register bypass
      .addCase(registerBypass.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerBypass.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bypassSuccessful = true;
        state.message = action.payload.message;
      })
      .addCase(registerBypass.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const {
  reset,
  setMessage,
  setError,
  setUser,
  setSessionTimeout,
  clearState,
  setIs2FAEnabled,
  setAuthData // New action to set auth data
} = authSlice.actions;
export default authSlice.reducer;

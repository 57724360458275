import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { InformationCircleIcon } from '@heroicons/react/solid'

import CustomModal from './CustomModal'

const InventoryInvestmentVsRevenueChart = ({ metricsData }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalContent, setInfoModalContent] = useState('')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  )

  const [startDate, setStartDate] = useState(() => {
    const date = new Date()
    date.setDate(date.getDate() - 30)
    return date
  })
  const [endDate, setEndDate] = useState(new Date())

  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    const newFilteredData = metricsData.filter((data) => {
      const date = new Date(data._id)
      return date >= startDate && date <= endDate
    })
    setFilteredData(newFilteredData)
  }, [metricsData, startDate, endDate])

  useEffect(() => {
    const handleThemeChange = (event) => {
      setThemeMode(event.detail.theme)
    }

    window.addEventListener('themeChange', handleThemeChange)

    return () => {
      window.removeEventListener('themeChange', handleThemeChange)
    }
  }, [])

  const textColor = themeMode === 'dark' ? '#fff' : '#000'

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      background: 'transparent',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '70%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      categories: filteredData.map((data) => data._id),
      labels: {
        style: {
          colors: textColor,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => `$${val}`,
        style: {
          colors: textColor,
        },
      },
    },
    fill: {
      colors: ['#9b34d3', '#60A5FA'],
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val.toFixed(2)}`,
      },
    },
    theme: {
      mode: themeMode,
    },
    legend: {
      labels: {
        colors: textColor,
      },
    },
  }

  const series = [
    {
      name: 'Total Purchase Cost',
      data: filteredData.map((data) => data.totalPurchaseCost),
    },
    {
      name: 'Total Potential Revenue',
      data: filteredData.map((data) => data.totalPotentialRevenue),
    },
  ]

  const handleStartDateChange = (date) => {
    setStartDate(date)
    setIsFilterActive(true)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
    setIsFilterActive(true)
  }

  const clearDateFilters = () => {
    if (metricsData.length > 0) {
      setStartDate(new Date(metricsData[0]._id))
      setEndDate(new Date(metricsData[metricsData.length - 1]._id))
    }
    setIsFilterActive(false)
  }

  const inventoryValue = filteredData.reduce(
    (acc, data) => acc + data.totalPotentialRevenue,
    0
  )

  const totalCostOfInventory = filteredData.reduce(
    (acc, data) => acc + data.totalPurchaseCost,
    0
  )

  const totalCostInRange = filteredData.reduce(
    (acc, data) => acc + data.totalPurchaseCost,
    0
  )
  const totalRevenueInRange = filteredData.reduce(
    (acc, data) => acc + data.totalPotentialRevenue,
    0
  )

  const performancePercentage =
    ((totalRevenueInRange - totalCostInRange) / Math.abs(totalCostInRange)) *
    100
  const estimatedProfit = (totalRevenueInRange - totalCostInRange) * 0.86
  const arrowDirection = performancePercentage >= 0 ? 'up' : 'down'

  const toggleInfoModal = (content) => {
    setInfoModalContent(content)
    setInfoModalOpen(!infoModalOpen)
  }

  return (
    <div className='p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 2xl:p-6 dark:bg-gray-800'>
      <div className='flex flex-col space-y-4 2xl:space-y-0 2xl:flex-row 2xl:justify-between 2xl:items-center mb-5'>
        <div className='mb-4'>
          <h2 className='text-xl md:text-2xl font-bold text-gray-800 dark:text-white'>
            Inventory Value Growth Tracker
          </h2>
          <p className='text-sm text-gray-600 dark:text-gray-400'>
            Visualizing the balance between potential revenue and inventory
            costs over time.
          </p>
        </div>
        <div className='flex flex-wrap items-center gap-4 bg-slate-50 p-2 rounded-lg border border-gray-200 dark:border-gray-700 dark:bg-slate-700'>
          <div>
            <label
              htmlFor='startDate'
              className='block text-sm font-bold text-gray-700 dark:text-gray-300 mb-1'
            >
              Start Date
            </label>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              id='startDate'
              wrapperClassName='date-picker'
              className='form-input block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-gray-300'
              calendarClassName='rounded-md'
            />
          </div>
          <div>
            <label
              htmlFor='endDate'
              className='block text-sm font-bold text-gray-700 dark:text-gray-300 mb-1'
            >
              End Date
            </label>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              id='endDate'
              wrapperClassName='date-picker'
              className='form-input block w-full text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:text-gray-300'
              calendarClassName='rounded-md'
            />
          </div>
          {isFilterActive && (
            <button
              onClick={clearDateFilters}
              className='self-end bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm transition-colors duration-200'
            >
              Clear Date Filters
            </button>
          )}
        </div>
      </div>

      <div className='flex flex-col 2xl:flex-row items-center justify-between mb-4'>
        <div className='flex-shrink-0'>
          <div className='flex items-center space-x-4'>
            <div className='text-green-500'>
              <span className='text-2xl 2xl:text-3xl leading-none font-bold text-gray-900 dark:text-white'>
                $
                {inventoryValue.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
              <h3 className='text-base font-semibold'>
                Potential Inventory Value
              </h3>
              <p className='text-xs text-gray-600 dark:text-gray-400'>
                The estimated total worth of inventory.
              </p>
              <InformationCircleIcon
                className='w-5 h-5 ml-1 cursor-pointer'
                onClick={() => toggleInfoModal('potential_inventory')}
              />
            </div>
            <div className='text-red-500'>
              <span className='text-2xl 2xl:text-3xl leading-none font-bold text-gray-900 dark:text-white'>
                $
                {totalCostOfInventory.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
              <h3 className='text-base font-semibold'>Cost of Inventory</h3>
              <p className='text-xs text-gray-600 dark:text-gray-400'>
                The total expense incurred in acquiring inventory.
              </p>
              <InformationCircleIcon
                className='w-5 h-5 ml-1 cursor-pointer'
                onClick={() => toggleInfoModal('cost_of_inventory')}
              />
            </div>
            <div className='text-green-500'>
              <span className='text-2xl 2xl:text-3xl leading-none font-bold text-gray-900 dark:text-white'>
                $
                {estimatedProfit.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}
              </span>
              <h3 className='text-base font-semibold'>Potential Profit</h3>
              <p className='text-xs text-gray-600 dark:text-gray-400'>
                The estimated profit after selling fees.
              </p>
              <InformationCircleIcon
                className='w-5 h-5 ml-1 cursor-pointer'
                onClick={() => toggleInfoModal('potential_inventory')}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex items-center justify-end flex-1 text-${arrowDirection}-500 text-base font-bold mt-2 2xl:mt-0 dark:text-${arrowDirection === 'up' ? 'green-500' : 'red-500'}`}
        >
          <p className='mr-1'>IVPI:</p>
          <span className='font-semibold'>
            {performancePercentage.toFixed(1)}%{' '}
          </span>
          <span className='font-semibold pl-1'>
            ({(performancePercentage / 100).toFixed(2)} times)
          </span>
          <svg
            className={`w-5 h-5 ml-1 ${arrowDirection === 'up' ? 'text-green-500 dark:text-green-500' : 'text-red-500 dark:text-red-500'}`}
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/1999/xlink'
          >
            <path
              fillRule='evenodd'
              d={`M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 ${arrowDirection === 'up' ? '5.414' : '14.586'}V17a1 1 0 11-2 0V${arrowDirection === 'up' ? '5.414' : '14.586'}L6.707 7.707a1 1 0 01-1.414 0z`}
              clipRule='evenodd'
            ></path>
          </svg>
          <InformationCircleIcon
            className='w-5 h-5 ml-1 cursor-pointer'
            onClick={() => toggleInfoModal('performance')}
          />
        </div>
      </div>

      <Chart options={chartOptions} series={series} type='bar' height={300} />

      <CustomModal
        isOpen={infoModalOpen}
        onClose={toggleInfoModal}
        title='Understanding the Metric:'
      >
        {infoModalContent === 'potential_inventory' && (
          <p>
            The "Potential Inventory Value" represents the estimated total worth
            of available inventory. It is calculated based on the selected date
            range and includes the potential revenue that can be generated from
            the inventory items.
          </p>
        )}
        {infoModalContent === 'cost_of_inventory' && (
          <p>
            The "Cost of Inventory" is the total expense incurred in acquiring
            the inventory items within the selected date range. It includes the
            purchase cost of all items.
          </p>
        )}
        {infoModalContent === 'performance' && (
          <div className='space-y-4 text-sm text-gray-700 dark:text-gray-300'>
            <p className='font-bold'>Inventory Value Performance Index:</p>
            <p>
              The performance percentage is a key indicator of the efficiency
              and potential profitability of your inventory. It is calculated by
              comparing the total potential revenue your inventory could
              generate against the total purchase cost of that inventory over a
              selected date range.
            </p>
            <p>
              <strong className='font-semibold'>How It's Calculated:</strong>
              Firstly, we determine the total purchase cost of your inventory at
              the start of the selected date range. Then, we calculate the total
              potential revenue at the end of the date range. The performance
              percentage is the result of the difference between these two
              amounts, divided by the absolute value of the initial total
              purchase cost, all multiplied by 100.
            </p>
            <p>
              <strong className='font-semibold'>Formula:</strong>
              [(Total Potential Revenue at End of Range - Total Purchase Cost at
              Start of Range) / Absolute Value of Total Purchase Cost at Start
              of Range] * 100
            </p>
            <p>
              <strong className='font-semibold'>Interpretation:</strong>A
              positive performance percentage (indicated by an up-arrow) means
              that the potential revenue is greater than the initial purchase
              cost, suggesting a good return on investment. Conversely, a
              negative performance (down-arrow) indicates that the potential
              revenue is less than the initial cost, which might call for a
              reevaluation of your inventory strategy.
            </p>
            <p>
              <strong className='font-semibold'>Usage:</strong>
              This metric is valuable for assessing the effectiveness of your
              inventory management and purchase decisions. It helps in
              identifying trends, informing pricing strategies, and making
              decisions about which products to restock. It's important to
              consider this metric alongside other factors like market
              conditions, storage costs, and product demand to make well-rounded
              business decisions.
            </p>
            <p>
              Remember, while this metric provides insights, it doesn’t account
              for other costs like storage, insurance, or potential loss, nor
              does it reflect product quality or customer satisfaction. It's
              best used as one of several tools for comprehensive inventory
              analysis.
            </p>
          </div>
        )}
      </CustomModal>
    </div>
  )
}

export default InventoryInvestmentVsRevenueChart

import React from 'react'

const SessionTimeoutModal = ({
  show,
  handleContinue,
  handleLogout,
  countdown,
}) => {
  if (!show) return null

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50'>
      <div className='bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full'>
        <h2 className='text-xl font-bold mb-4 text-gray-900 dark:text-white'>
          Session Timeout
        </h2>
        <p className='mb-4 text-gray-700 dark:text-gray-300'>
          Your session is about to expire in {countdown} seconds. Do you want to
          continue?
        </p>
        <div className='flex justify-end space-x-4'>
          <button
            onClick={handleLogout}
            className='px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition'
          >
            Logout
          </button>
          <button
            onClick={handleContinue}
            className='px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 transition'
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default SessionTimeoutModal

import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  XCircleIcon,
  CurrencyDollarIcon,
  CalendarIcon,
  ShoppingCartIcon,
  CheckCircleIcon,
  UserIcon,
  ClipboardListIcon,
  PlusCircleIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/solid';

const OrdersModal = ({ isOpen, onClose, order = {}, onSave, isNew }) => {
  const [orderDetails, setOrderDetails] = useState({
    orderNumber: order?.orderNumber || '',
    sellerUsername: order?.sellerUsername || '',
    totalAmount: order?.totalAmount || 0,
    fulfilmentStatus: order?.fulfilmentStatus || 'pending',
    paymentStatus: order?.paymentStatus || 'pending',
    shippingDetails: order?.shippingDetails || {},
    billingDetails: order?.billingDetails || {},
    platformOrigin: order?.platformOrigin || '',
    timestamp: order?.timestamp || new Date().toISOString(),
    items: order?.items || [],
  });
  const [editableItems, setEditableItems] = useState([]);
  const [newItems, setNewItems] = useState([]);
  const [orderComments, setOrderComments] = useState(order?.orderComments || '');
  const [editMode, setEditMode] = useState({});
  const [isEditingDetails, setIsEditingDetails] = useState(isNew);
  const [editShipping, setEditShipping] = useState(false);
  const [editBilling, setEditBilling] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (order) {
      setOrderDetails({
        orderNumber: order?.orderNumber || '',
        sellerUsername: order?.sellerUsername || '',
        totalAmount: order?.totalAmount || 0,
        fulfilmentStatus: order?.fulfilmentStatus || 'pending',
        paymentStatus: order?.paymentStatus || 'pending',
        shippingDetails: order?.shippingDetails || {},
        billingDetails: order?.billingDetails || {},
        platformOrigin: order?.platformOrigin || '',
        timestamp: order?.timestamp || new Date().toISOString(),
        items: order?.items || [],
      });
      setOrderComments(order?.orderComments || '');
      setEditableItems(order?.items || []);
    }
  }, [order]);

  useEffect(() => {
    setUnsavedChanges(true);
  }, [orderDetails, editableItems, newItems, orderComments]);

  const handleSaveChanges = () => {
    const updatedOrder = {
      ...orderDetails,
      items: [...editableItems, ...newItems],
      orderComments,
    };
    onSave(updatedOrder);
    setUnsavedChanges(false);
    onClose();
  };

  const handleAddItem = () => {
    setNewItems([...newItems, { title: '', quantityPurchased: 0, soldPrice: 0 }]);
    setUnsavedChanges(true);
  };

  const handleItemChange = (index, key, value, isEditable = true) => {
    setUnsavedChanges(true);
    if (isEditable) {
      const updatedItems = [...editableItems];
      updatedItems[index] = { ...updatedItems[index], [key]: value };
      setEditableItems(updatedItems);
    } else {
      const updatedItems = [...newItems];
      updatedItems[index] = { ...updatedItems[index], [key]: value };
      setNewItems(updatedItems);
    }
  };

  const handleDeleteItem = (index, isEditable = true) => {
    setUnsavedChanges(true);
    if (isEditable) {
      const updatedItems = [...editableItems];
      updatedItems.splice(index, 1);
      setEditableItems(updatedItems);
    } else {
      const updatedItems = [...newItems];
      updatedItems.splice(index, 1);
      setNewItems(updatedItems);
    }
  };

  const handleDetailsChange = (key, value) => {
    setUnsavedChanges(true);
    setOrderDetails((prevDetails) => ({ ...prevDetails, [key]: value }));
  };

  const handleEditToggle = (index, isEditable = true) => {
    setEditMode((prev) => ({
      ...prev,
      [isEditable ? `editable-${index}` : `new-${index}`]: !prev[isEditable ? `editable-${index}` : `new-${index}`],
    }));
  };

  const handleClose = () => {
    if (unsavedChanges) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const confirmCloseWithoutSaving = () => {
    setShowConfirmation(false);
    setUnsavedChanges(false);
    onClose();
  };

  const cancelClose = () => {
    setShowConfirmation(false);
  };

  const formatPrice = (price) => (typeof price === 'number' ? price.toFixed(2) : 'N/A');

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen px-4">
          <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-6xl mx-auto overflow-hidden border">
            <div className="bg-gradient-to-r from-indigo-500 to-indigo-600 dark:from-gray-800 dark:to-gray-900 p-5 rounded-t-lg flex items-center justify-between border-b">
              <h3 className="text-white font-semibold">
                {isNew ? 'Create New Order' : `Order Details | Order # ${orderDetails.orderNumber}`}
              </h3>
              <button onClick={handleClose} className="text-white focus:outline-none">
                <XCircleIcon className="h-6 w-6" />
              </button>
            </div>

            <div className="p-6 max-h-[75vh] overflow-y-auto">
              {/* Order Details */}
              <div className="border-b pb-4 mb-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <ClipboardListIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Order Number:</strong> {orderDetails.orderNumber || 'N/A'}
                  </div>
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <UserIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Seller Username:</strong> {orderDetails.sellerUsername || 'N/A'}
                  </div>
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <CurrencyDollarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Order Total:</strong> ${formatPrice(orderDetails.totalAmount)}
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <ShoppingCartIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Platform Origin:</strong> {orderDetails.platformOrigin || 'N/A'}
                  </div>
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <CheckCircleIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Fulfilment Status:</strong> {orderDetails.fulfilmentStatus || 'N/A'}
                  </div>
                  <div className="text-gray-700 dark:text-gray-300 flex items-center">
                    <CalendarIcon className="h-5 w-5 text-indigo-500 mr-2" />
                    <strong>Payment Status:</strong> {orderDetails.paymentStatus || 'N/A'}
                  </div>
                </div>
              </div>

              {/* Items Section */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                <div>
                  <div className="flex justify-between items-center">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Items in Order</h4>
                    <button
                      onClick={handleAddItem}
                      className="flex items-center px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition"
                    >
                      <PlusCircleIcon className="h-5 w-5 mr-1" />
                      Add Item
                    </button>
                  </div>
                  <div className="flex gap-4 overflow-x-auto mt-4">
                    {editableItems.map((item, index) => (
                      <div
                        key={index}
                        className="min-w-[200px] max-w-[300px] bg-gradient-to-r from-white to-gray-100 border dark:from-gray-800 dark:to-gray-900 rounded-lg shadow p-4 flex flex-col space-y-2 relative"
                      >
                        {editMode[`editable-${index}`] ? (
                          <>
                            <div className="absolute top-2 right-2 flex space-x-2">
                              <CheckCircleIcon
                                onClick={() => handleEditToggle(index, true)}
                                className="h-5 w-5 text-green-500 cursor-pointer"
                              />
                              <TrashIcon
                                onClick={() => handleDeleteItem(index, true)}
                                className="h-5 w-5 text-red-500 cursor-pointer"
                              />
                            </div>
                            <div className="text-gray-700 dark:text-gray-300 space-y-2">
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Title:</strong>
                                <input
                                  type="text"
                                  value={item.title}
                                  onChange={(e) => handleItemChange(index, 'title', e.target.value)}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Quantity:</strong>
                                <input
                                  type="number"
                                  value={item.quantityPurchased}
                                  onChange={(e) => handleItemChange(index, 'quantityPurchased', Number(e.target.value))}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Sold Price:</strong>
                                <input
                                  type="number"
                                  value={item.soldPrice}
                                  onChange={(e) => handleItemChange(index, 'soldPrice', Number(e.target.value))}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="absolute top-2 right-2 flex space-x-2">
                              <PencilIcon
                                onClick={() => handleEditToggle(index, true)}
                                className="h-5 w-5 text-indigo-500 cursor-pointer"
                              />
                              <TrashIcon
                                onClick={() => handleDeleteItem(index, true)}
                                className="h-5 w-5 text-red-500 cursor-pointer"
                              />
                            </div>
                            <div className="text-gray-700 dark:text-gray-300 space-y-2">
                              <p>
                                <strong>Title:</strong> {item.title || 'N/A'}
                              </p>
                              <p>
                                <strong>Quantity:</strong> {item.quantityPurchased || 'N/A'}
                              </p>
                              <p>
                                <strong>Sold Price:</strong> ${formatPrice(item.soldPrice)}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    ))}

                    {newItems.map((item, index) => (
                      <div
                        key={index}
                        className="min-w-[200px] max-w-[300px] bg-gradient-to-r from-white to-gray-100 border dark:from-gray-800 dark:to-gray-900 rounded-lg shadow p-4 flex flex-col space-y-2 relative"
                      >
                        {editMode[`new-${index}`] ? (
                          <>
                            <div className="absolute top-2 right-2 flex space-x-2">
                              <CheckCircleIcon
                                onClick={() => handleEditToggle(index, false)}
                                className="h-5 w-5 text-green-500 cursor-pointer"
                              />
                              <TrashIcon
                                onClick={() => handleDeleteItem(index, false)}
                                className="h-5 w-5 text-red-500 cursor-pointer"
                              />
                            </div>
                            <div className="text-gray-700 dark:text-gray-300 space-y-2">
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Title:</strong>
                                <input
                                  type="text"
                                  value={item.title}
                                  onChange={(e) => handleItemChange(index, 'title', e.target.value, false)}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Quantity:</strong>
                                <input
                                  type="number"
                                  value={item.quantityPurchased}
                                  onChange={(e) => handleItemChange(index, 'quantityPurchased', Number(e.target.value), false)}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                              <div className="flex items-center space-x-2">
                                <strong className="mr-1">Sold Price:</strong>
                                <input
                                  type="number"
                                  value={item.soldPrice}
                                  onChange={(e) => handleItemChange(index, 'soldPrice', Number(e.target.value), false)}
                                  className="bg-gray-50 dark:bg-gray-700 rounded-md p-1 w-full"
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="absolute top-2 right-2 flex space-x-2">
                              <PencilIcon
                                onClick={() => handleEditToggle(index, false)}
                                className="h-5 w-5 text-indigo-500 cursor-pointer"
                              />
                              <TrashIcon
                                onClick={() => handleDeleteItem(index, false)}
                                className="h-5 w-5 text-red-500 cursor-pointer"
                              />
                            </div>
                            <div className="text-gray-700 dark:text-gray-300 space-y-2">
                              <p>
                                <strong>Title:</strong> {item.title || 'N/A'}
                              </p>
                              <p>
                                <strong>Quantity:</strong> {item.quantityPurchased || 'N/A'}
                              </p>
                              <p>
                                <strong>Sold Price:</strong> ${formatPrice(item.soldPrice)}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Order Comments and Summary */}
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Order Comments</h4>
                  <textarea
                    value={orderComments}
                    onChange={(e) => setOrderComments(e.target.value)}
                    rows="6"
                    className="bg-gray-50 dark:bg-gray-700 dark:text-white rounded-md p-2 w-full mt-2 mb-4"
                  />

                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white">Summary</h4>
                  <div className="text-gray-700 dark:text-gray-300 mt-2">
                    <div className="flex items-center mb-2">
                      <ShoppingCartIcon className="h-5 w-5 text-indigo-500 mr-2" />
                      <p>
                        <strong>Total Items:</strong>{' '}
                        {editableItems.reduce((total, item) => total + item.quantityPurchased, 0)}
                      </p>
                    </div>
                    <div className="flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                      <p>
                        <strong>Total Sold Items:</strong>{' '}
                        {editableItems.reduce((total, item) => total + item.quantityPurchased, 0)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shipping and Billing Info */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-t pt-4">
                {/* Shipping Information */}
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                    Shipping Information
                    <PencilIcon
                      className="h-5 w-5 text-indigo-500 ml-2 cursor-pointer"
                      onClick={() => setEditShipping(!editShipping)}
                    />
                  </h4>
                  {editShipping ? (
                    <div className="text-gray-700 dark:text-gray-300 mt-2 space-y-2">
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.name || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.name', e.target.value)}
                        placeholder="Name"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.street1 || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.street1', e.target.value)}
                        placeholder="Street 1"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.street2 || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.street2', e.target.value)}
                        placeholder="Street 2"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.city || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.city', e.target.value)}
                        placeholder="City"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.state || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.state', e.target.value)}
                        placeholder="State"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.postalCode || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.postalCode', e.target.value)}
                        placeholder="Postal Code"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.country || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.country', e.target.value)}
                        placeholder="Country"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.shippingDetails.phone || ''}
                        onChange={(e) => handleDetailsChange('shippingDetails.phone', e.target.value)}
                        placeholder="Phone"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-500 cursor-pointer mt-2"
                        onClick={() => setEditShipping(false)}
                      />
                    </div>
                  ) : (
                    <div className="text-gray-700 dark:text-gray-300 mt-2 space-y-2">
                      <p>
                        <strong>Name:</strong> {orderDetails.shippingDetails.name || 'N/A'}
                      </p>
                      <p>
                        <strong>Street 1:</strong> {orderDetails.shippingDetails.street1 || 'N/A'}
                      </p>
                      <p>
                        <strong>Street 2:</strong> {orderDetails.shippingDetails.street2 || 'N/A'}
                      </p>
                      <p>
                        <strong>City:</strong> {orderDetails.shippingDetails.city || 'N/A'}
                      </p>
                      <p>
                        <strong>State:</strong> {orderDetails.shippingDetails.state || 'N/A'}
                      </p>
                      <p>
                        <strong>Postal Code:</strong> {orderDetails.shippingDetails.postalCode || 'N/A'}
                      </p>
                      <p>
                        <strong>Country:</strong> {orderDetails.shippingDetails.country || 'N/A'}
                      </p>
                      <p>
                        <strong>Phone:</strong> {orderDetails.shippingDetails.phone || 'N/A'}
                      </p>
                    </div>
                  )}
                </div>

                {/* Billing Information */}
                <div>
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                    Billing Information
                    <PencilIcon
                      className="h-5 w-5 text-indigo-500 ml-2 cursor-pointer"
                      onClick={() => setEditBilling(!editBilling)}
                    />
                  </h4>
                  {editBilling ? (
                    <div className="text-gray-700 dark:text-gray-300 mt-2 space-y-2">
                      <input
                        type="text"
                        value={orderDetails.billingDetails.name || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.name', e.target.value)}
                        placeholder="Name"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.street1 || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.street1', e.target.value)}
                        placeholder="Street 1"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.street2 || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.street2', e.target.value)}
                        placeholder="Street 2"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.city || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.city', e.target.value)}
                        placeholder="City"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.state || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.state', e.target.value)}
                        placeholder="State"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.postalCode || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.postalCode', e.target.value)}
                        placeholder="Postal Code"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.country || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.country', e.target.value)}
                        placeholder="Country"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <input
                        type="text"
                        value={orderDetails.billingDetails.phone || ''}
                        onChange={(e) => handleDetailsChange('billingDetails.phone', e.target.value)}
                        placeholder="Phone"
                        className="bg-gray-50 dark:bg-gray-700 rounded-md p-2 w-full"
                      />
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-500 cursor-pointer mt-2"
                        onClick={() => setEditBilling(false)}
                      />
                    </div>
                  ) : (
                    <div className="text-gray-700 dark:text-gray-300 mt-2 space-y-2">
                      <p>
                        <strong>Name:</strong> {orderDetails.billingDetails.name || 'N/A'}
                      </p>
                      <p>
                        <strong>Street 1:</strong> {orderDetails.billingDetails.street1 || 'N/A'}
                      </p>
                      <p>
                        <strong>Street 2:</strong> {orderDetails.billingDetails.street2 || 'N/A'}
                      </p>
                      <p>
                        <strong>City:</strong> {orderDetails.billingDetails.city || 'N/A'}
                      </p>
                      <p>
                        <strong>State:</strong> {orderDetails.billingDetails.state || 'N/A'}
                      </p>
                      <p>
                        <strong>Postal Code:</strong> {orderDetails.billingDetails.postalCode || 'N/A'}
                      </p>
                      <p>
                        <strong>Country:</strong> {orderDetails.billingDetails.country || 'N/A'}
                      </p>
                      <p>
                        <strong>Phone:</strong> {orderDetails.billingDetails.phone || 'N/A'}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="p-6 flex justify-between">
              <button
                className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                onClick={handleSaveChanges}
              >
                {isNew ? 'Create Order' : 'Save Changes'}
              </button>
              <button
                className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>

        {showConfirmation && (
          <Transition show={showConfirmation} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={cancelClose}>
              <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed inset-0 bg-black opacity-50" aria-hidden="true" />
                <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-md w-full mx-auto p-6">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">Unsaved Changes</h3>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                    You have unsaved changes. Do you want to save them before closing?
                  </p>
                  <div className="mt-4 flex justify-end space-x-3">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                      onClick={confirmCloseWithoutSaving}
                    >
                      Don't Save
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </button>
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={cancelClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        )}
      </Dialog>
    </Transition>
  );
};

export default OrdersModal;

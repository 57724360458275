import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeNewsletter, registerBypass } from '../features/auth/authSlice';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ArbiEngineLogo from '../images/logo.png';
import ReCAPTCHA from 'react-google-recaptcha';
import CryptoJS from 'crypto-js';
import { Helmet } from 'react-helmet';

function RegistrationClosed() {
  const [captcha, setCaptcha] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [reason, setReason] = useState('');
  const [bypassPassword, setBypassPassword] = useState('');
  const [showBypass, setShowBypass] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bypassSuccessful } = useSelector((state) => state.auth);

  useEffect(() => {
    if (bypassSuccessful) {
      toast.success('Bypass successful! Redirecting to registration...', {
        onClose: () => navigate('/register'),
      });
    }
  }, [bypassSuccessful, navigate]);

  const onCaptchaChange = (value) => {
    setCaptcha(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
  
    if (process.env.REACT_APP_NODE_ENV !== 'development' && !captcha) {
      toast.error('Please complete the CAPTCHA');
      return;
    }
  
    const newsletterData = { email, name, reason, captcha };
    dispatch(subscribeNewsletter(newsletterData));
    toast.success('Successfully joined the newsletter!', {
      onClose: () => navigate('/'),
    });
  };

  const onBypass = async (e) => {
    e.preventDefault();
  
    const preSharedPassword = process.env.REACT_APP_ROUTE_SECRET;
    const encryptedPassword = CryptoJS.AES.encrypt(preSharedPassword, process.env.REACT_APP_ENCRYPTION_KEY).toString();
  
    dispatch(registerBypass({ password: bypassPassword, encryptedPassword }));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative min-h-screen overflow-hidden dark:bg-gray-900">
      <Helmet>
        <title>ArbiEngine - Join the Beta Waitlist</title>
        <meta name="description" content="Join the waitlist for ArbiEngine, the ultimate tool for discovering eCommerce arbitrage opportunities. Get early access and stay informed about our beta release." />
        <meta name="keywords" content="ecommerce, arbitrage, beta waitlist, ecommerce tools, ecommerce opportunities" />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href="https://arbiengine.com/registration-closed" />
        <meta property="og:title" content="Join the ArbiEngine Beta Waitlist" />
        <meta property="og:description" content="ArbiEngine is launching soon! Join our beta waitlist to gain early access to the ultimate tool for discovering eCommerce arbitrage opportunities." />
        <meta property="og:image" content="https://arbiengine.com/path-to-image.jpg" />
        <meta property="og:url" content="https://arbiengine.com/registration-closed" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Join the ArbiEngine Beta Waitlist" />
        <meta name="twitter:description" content="ArbiEngine is launching soon! Join our beta waitlist to gain early access to the ultimate tool for discovering eCommerce arbitrage opportunities." />
        <meta name="twitter:image" content="https://arbiengine.com/path-to-image.jpg" />
      </Helmet>

      <Header />
      <div
        className="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-pink-500 to-blue-400 opacity-30 dark:opacity-70 dark:from-purple-800 dark:to-indigo-800 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>

      <main className="flex flex-col items-center justify-normal min-h-screen px-4 sm:px-6 lg:px-8 mt-8">
        <div className="bg-white dark:bg-gray-800 p-6 lg:p-10 rounded-lg shadow-lg lg:max-w-lg lg:w-1/2 h-fit border">
          <img
            src={ArbiEngineLogo}
            alt='ArbiEngine Logo - Discover Ecommerce Arbitrage Opportunities'
            className='mx-auto mb-8'
            style={{ width: '300px' }}
          />
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Join the Waitlist
          </h1>
          <p className="mb-6 text-gray-600 dark:text-gray-300">
            Our beta release is expected to launch in Q4 of 2024. Join our waitlist to get notified and explain why you should be selected as a beta tester to get early access.
          </p>
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="reason"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Why do you want to be a beta tester?
              </label>
              <textarea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            {process.env.REACT_APP_NODE_ENV !== 'development' && (
              <div className="flex justify-center mb-4">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={onCaptchaChange}
                />
              </div>
            )}
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              aria-label="Join Waitlist"
            >
              Join Waitlist
            </button>
          </form>
          <div className="mt-8">
            <button
              type="button"
              className="text-sm font-semibold text-gray-900 dark:text-white"
              onClick={() => setShowBypass(!showBypass)}
            >
              {showBypass ? 'Hide Bypass' : 'Bypass Waitlist'}
            </button>
            <button
              type="button"
              className="text-sm font-semibold text-gray-900 dark:text-green-300 ml-2"
              onClick={() => navigate('/login')}
            >
              <span className="text-indigo-500 dark:text-indigo-300">Log In</span>
            </button>
            {showBypass && (
              <form onSubmit={onBypass} className="mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="bypassPassword"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  >
                    Bypass Password
                  </label>
                  <input
                    type="password"
                    id="bypassPassword"
                    value={bypassPassword}
                    onChange={(e) => setBypassPassword(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-red-600 text-white rounded-md shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  aria-label="Bypass Waitlist"
                >
                  Bypass
                </button>
              </form>
            )}
          </div>
        </div>
      </main>

      <Footer />
      <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default RegistrationClosed;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OrdersHeader from '../../components/Dashboard/Orders/OrdersHeader';
import OrdersCard from '../../components/Dashboard/Orders/OrdersCard';
import OrdersModal from '../../components/Dashboard/Orders/OrdersModal';
import Spinner from '../../components/Spinner';
import { fetchOrders, fetchOrderById, fetchMoreOrdersFromState, fetchAllOrders, setFilter } from '../../features/orders/ordersSlice';

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { orders, filteredItems, isLoading, isError, message, hasMoreItems } = useSelector((state) => state.orders);
  const { connections } = useSelector((state) => state.connection);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const observer = useRef();

  const [selectedConnection, setSelectedConnection] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch orders on mount
  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const loadMoreItems = useCallback(() => {
    if (hasMoreItems) {
      dispatch(fetchMoreOrdersFromState());
    }
  }, [dispatch, hasMoreItems]);

  // Setup intersection observer for lazy loading
  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreItems();
      }
    }, options);

    const lastItem = document.querySelector('.last-item');
    if (observer.current && lastItem) {
      observer.current.observe(lastItem);
    }
  }, [orders.length, loadMoreItems]);

  const handleConnectionChange = (value) => {
    setSelectedConnection(value);
    dispatch(setFilter({ connectionFilter: value }));
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    dispatch(setFilter({ searchQuery: query }));
  };

  const handleViewDetailsClick = (orderId) => {
    dispatch(fetchOrderById(orderId)).then((action) => {
      if (action.type.endsWith('fulfilled')) {
        setSelectedOrder(action.payload);
        setShowOrderModal(true);
      }
    });
  };

  const handleGenerateLabel = (order) => {
    console.log('Generate Label for Order:', order);
  };

  const handleOrderModalClose = () => {
    setShowOrderModal(false);
    setSelectedOrder(null);
    setIsNewOrder(false);
  };

  const handleAddOrder = () => {
    setSelectedOrder(null);
    setIsNewOrder(true);
    setShowOrderModal(true);
  };

  const handleRefreshAllOrders = () => {
    dispatch(fetchAllOrders()).then(() => {
      dispatch(fetchOrders()); // Refresh the view with the updated data
    });
  };

  return (
    <div className="min-h-screen flex flex-col p-4 pt-0">
      {/* Header section */}
      <div className="sticky top-0 z-20 bg-gray-50 dark:bg-gray-900">
        <OrdersHeader
          selectedConnection={selectedConnection}
          onConnectionChange={handleConnectionChange}
          connections={connections}
          onAddOrder={handleAddOrder}
          onRefreshAll={handleRefreshAllOrders}
          searchQuery={searchQuery}
          onSearch={handleSearchChange}
        />
      </div>

      {/* Main content section */}
      <div className="flex-1 overflow-y-auto">
        {isLoading && filteredItems.length === 0 && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

        {isError && <div className="text-center text-red-500">{message}</div>}

        {!isLoading && filteredItems.length === 0 && (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-600 dark:text-gray-300">No orders found</p>
          </div>
        )}

        <div className="grid grid-cols-1 gap-4 mt-2">
          {Array.isArray(filteredItems) && filteredItems.map((order) => (
            <OrdersCard
              key={order._id}
              order={order}
              onViewDetails={() => handleViewDetailsClick(order._id)}
              onGenerateLabel={() => handleGenerateLabel(order)}
            />
          ))}
        </div>

        {isLoading && filteredItems.length > 0 && (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )}

        <div className="last-item"></div>
      </div>

      {showOrderModal && (
        <OrdersModal
          isOpen={showOrderModal}
          onClose={handleOrderModalClose}
          order={selectedOrder}
          isNew={isNewOrder}
        />
      )}
    </div>
  );
};

export default OrdersPage;

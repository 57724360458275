import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  cancelSubscription,
  getSubscription,
} from '../../features/subscriptions/subscriptionSlice';
import CurrentSubscriptionCard from '../../components/subscriptions/CurrentSubscriptionCard';
import SubscriptionTierCard from '../../components/subscriptions/SubscriptionTierCard';
import ConfirmationModal from '../../components/subscriptions/ConfirmationModal';
import EnterpriseFormModal from '../../components/subscriptions/EnterpriseFormModal';

const tiers = [
  {
    name: 'Explorer',
    price: '$29/month',
    description: 'Good for anyone who is just getting started.',
    features: [
      '100 Inventory Items',
      '3 Search Terms',
      '1 Industry/Category Supported',
      'Metrics and Results Feed',
      'Basic Real-time Market Insights',
    ],
    hierarchy: 1,
  },
  {
    name: 'Accelerator',
    price: '$69/month',
    description: 'Perfect for small/medium sized businesses.',
    features: [
      '500 Inventory Items',
      '10 Search Terms',
      '3 Industries/Categories Supported',
      'Standard Dashboard Features',
      'Basic Inventory Management',
      'Multiple Marketplaces (up to 2)',
      'Basic Ebay Integration',
      '50 Product Research Tokens/month',
      'Basic Photography Queue',
      'Standard Real-time Market Insights',
      'Email & Chat Support',
      'Webinars for Training',
    ],
    hierarchy: 2,
  },
  {
    name: 'Professional',
    price: '$129/month',
    description: 'For professional sellers looking to scale.',
    features: [
      '2000 Inventory Items',
      '25 Search Terms',
      '10 Industries/Categories Supported',
      'Advanced Dashboard Features',
      'Standard Inventory Management',
      'Multiple Marketplaces (up to 5)',
      'Advanced Ebay Integration',
      'Shopify Integration',
      '200 Product Research Tokens/month',
      'Standard Photography Queue',
      'Advanced Real-time Market Insights',
      'Priority Support',
      'Personalized Training',
    ],
    hierarchy: 3,
  },
  {
    name: 'Enterprise',
    price: 'Custom pricing',
    description: 'For the biggest enterprise companies.',
    features: [
      'Unlimited Inventory Items',
      'Unlimited Search Terms',
      'Unlimited Industries/Categories Supported',
      'Premium Dashboard Features',
      'Premium Inventory Management',
      'All supported marketplaces',
      'Full Ebay Integration',
      'Shopify Integration',
      'Unlimited Product Research Tokens',
      'Premium Photography Queue',
      'Premium Real-time Market Insights',
      'Dedicated Account Manager',
      'On-site Training & Consultation',
    ],
    hierarchy: 4,
  },
];

const SubscriptionManagementPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { subscription, isLoading, isError, message } = useSelector(
    (state) => state.subscription
  );
  const [selectedTier, setSelectedTier] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEnterpriseFormOpen, setIsEnterpriseFormOpen] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(getSubscription(user._id));
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (subscription && subscription.items) {
      const current = subscription.items.find((item) =>
        tiers.some((tier) => tier.name === item.plan)
      );
      setCurrentItem(current);
    }
  }, [subscription]);

  const handleSubscribe = (tier) => {
    console.log(tier)
    if (tier.name === 'Enterprise') {
      setIsEnterpriseFormOpen(true);
    } else {
      setSelectedTier(tier);
      dispatch({ type: 'subscription/setSelectedTier', payload: tier });
      localStorage.setItem('selectedPlan', JSON.stringify(tier));
      navigate('/payment');
    }
  };

  const handleCancelSubscription = () => {
    if (subscription && currentItem) {
      dispatch(
        cancelSubscription({
          subscriptionId: subscription.stripeSubscriptionId,
          subscriptionItemId: currentItem.itemId,
        })
      );
    }
  };

  const handleUpgradeDowngrade = (tier) => {
    setSelectedTier(tier);
    if (tier.name === 'Enterprise') {
      setIsEnterpriseFormOpen(true);
    } else {
      dispatch({ type: 'subscription/setSelectedTier', payload: tier });
      localStorage.setItem('selectedPlan', JSON.stringify(tier));
      navigate('/payment');
    }
  };

  return (
    <div className='container mx-auto p-6 bg-white dark:bg-gray-800 shadow-lg rounded-lg'>
      {isError && <div className='text-red-500 mb-4'>{message}</div>}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <CurrentSubscriptionCard
            subscription={subscription}
            onCancel={handleCancelSubscription}
            tiers={tiers}
          />
          <div
            id='subscription-tiers'
            className='grid gap-6 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mt-4'
          >
            {tiers.map((tier) => (
              <SubscriptionTierCard
                key={tier.name}
                tier={tier}
                subscription={subscription}
                onSubscribe={() => handleSubscribe(tier)}
                onUpgradeDowngrade={() => handleUpgradeDowngrade(tier)}
                tiers={tiers}
              />
            ))}
          </div>
        </>
      )}

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        plan={selectedTier}
      />

      <EnterpriseFormModal
        isOpen={isEnterpriseFormOpen}
        onClose={() => setIsEnterpriseFormOpen(false)}
      />
    </div>
  );
};

export default SubscriptionManagementPage;
